import { ThemeConfig } from 'antd';
import { useTheme } from 'styled-components';

const Token = () => {
  const theme = useTheme();
  const config: ThemeConfig = {
    hashed: false,
    token: {
      fontSize: 14,
      colorPrimary: theme.colors.primary,
      colorText: theme.colors.grayDarkPrimary,
      colorLink: theme.colors.primary,
      colorLinkHover: theme.colors.blueDark,
      fontFamily: theme.font.family,
      colorBgContainer: 'transparent',
      colorTextDisabled: theme.colors.graySecondary,
      controlOutline: 'none',
      fontSizeHeading1: 36,
    },
    components: {
      Layout: {
        algorithm: true, // Enable algorithm
        footerPadding: '0px',
        bodyBg: theme.colors.blueLightest,
        headerPadding: '0 24px',
        headerBg: theme.colors.blueLightest,
        headerHeight: 60,
        siderBg: theme.colors.blueLightest,
      },
      Button: {
        padding: 0,
        defaultBg: theme.colors.light,
        defaultColor: theme.colors.bluePrimary,
        defaultBorderColor: theme.colors.bluePrimary,
        colorText: '#FFF',
        colorPrimaryHover: theme.colors.blueLightMedium,
        colorPrimary: theme.colors.primary,
        paddingInlineLG: '0px',
        primaryShadow: 'none',
      },
      Input: {
        controlOutline: 'none',
        colorTextPlaceholder: theme.colors.graySecondary,
      },
      Typography: {
        // titleMarginTop: '0em',
        titleMarginBottom: '0em',
      },
      Table: {
        borderColor: theme.colors.grayVeryLight,
        rowHoverBg: theme.colors.hoverBg,
      },
      Menu: {
        iconSize: 16,
        // collapsedIconSize: 18,
        // itemBorderRadius: theme.main.borderRadius,
        // darkItemHoverBg: theme.colors.hoverBg,
        // darkItemSelectedBg: theme.colors.hoverBg,
        itemHoverBg: theme.colors.blueLightMedium,
        // itemHoverColor: theme.colors.primary,
        itemSelectedBg: theme.colors.blueLightMedium,
        itemSelectedColor: theme.colors.primary,
      },
      Card: {
        borderRadius: theme.main.borderRadius,
        headerBg: theme.colors.light,
      },
      Tooltip: {
        colorBgContainer: 'red',
      },
      Select: {
        clearBg: theme.colors.light,
        colorTextPlaceholder: theme.colors.graySecondary,
        colorIcon: 'red',
      },
      Skeleton: {
        colorFillContent: theme.colors.primaryDarkAlt,
      },
      Drawer: {
        colorBgElevated: theme.colors.blueLightest,
      },
      Steps: {
        colorPrimary: theme.colors.bluePrimary,
        colorText: theme.colors.bluePrimary,
        colorTextDescription: theme.colors.bluePrimary,
      },
    },
  };
  return config;
};

export default Token;
