import { Drawer } from 'antd';
import styled from 'styled-components';

export default styled(Drawer)`
  padding: 12px 24px 24px 24px;
  .title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
  }

  .description {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 4px;
  }
  .ant-drawer-header {
    padding: 0px;
    border: none;
    .right-side {
      display: flex;
      align-items: center;
      margin-right: 17px;
      margin-left: 17px;
    }
    .anticon-close {
      color: ${({ theme }) => theme.colors.dark};
      font-size: 18.2px;
      align-self: flex-start;
    }
    .team-plan-cont {
      width: 100% !important;
    }
  }
  .ant-drawer-body {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.blueLightest} !important;
    padding: 0px;
  }
`;
