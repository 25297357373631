import styled from 'styled-components';

export default styled.div`
  .ant-table-row {
    .ant-table-row-expand-icon-cell {
      display: none;
    }
  }
  .ant-table-wrapper {
    margin: 0 !important;
  }

  .ant-table-tbody > tr > td {
    padding: 3.5px 12px !important;
    color: ${({ theme }) => theme.colors.grayPrimary} !important;
    border-bottom: none !important;
  }
  .ant-table-tbody > tr > td:nth-child(n + 2) {
    text-align: right;
  }
  .ant-table-tbody > tr > td:first-child {
    font-weight: 400 !important;
  }
  .ant-table-row[data-row-key='1'] > td:first-child {
    font-weight: 700 !important;
  }
  .ant-table-row[data-row-key='1'] > td {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.grayPrimary} !important;
  }
  .ant-table-row[data-row-key='10'] > td:first-child {
    font-weight: 700 !important;
  }
  .ant-table-row[data-row-key='10'] > td {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.grayPrimary} !important;
  }
  .ant-table-row[data-row-key='13'] > td:first-child {
    font-weight: 700 !important;
  }
  .ant-table-row[data-row-key='13'] > td {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.grayPrimary} !important;
  }
  .ant-table-pagination-right {
    margin: 16px 0 0 0 !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > tbody
    > tr:last-child
    > *:last-child {
    border-end-end-radius: 0px !important;
  }
  .ant-table-wrapper
    .ant-table-container
    table
    > tbody
    > tr:last-child
    > *:first-child {
    border-end-start-radius: 0px !important;
  }
`;
