import { FC, useMemo, useState } from 'react';

import { Col, Flex, Spin, Typography } from 'antd';
import { useTheme } from 'styled-components';

import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Card from '@/components/RCard';
import RToggleButtons from '@/components/RToggleButtons';
import Tooltip from '@/components/RTooltip';
import { DataProps } from '@/types/result';
import { formatDollars } from '@/utils';

import StyledTable from './styles';

const KeyMetricsTable: FC<DataProps> = ({ data, isLoading, error }) => {
  const theme = useTheme();
  const buttons = ['Before Storage', 'After Storage', 'Storage'];
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleButtonClick = (index: number) => {
    setActiveIndex(index);
  };

  const metrics = useMemo(() => {
    if (!data) return null;

    const { result } = data;
    const metricsData = [
      {
        capex: formatDollars(result.capexWithoutStorage || 0),
        earnings: formatDollars(result.revenueWos || 0),
        grossProfit: formatDollars(result.grossProfitWos || 0),
        irr: `% ${result.irrValueWos.toFixed(2) || 0}`,
        payback:
          result.discountedPaybackPeriodWos === 0
            ? '0'
            : `${result.discountedPaybackPeriodWos.toFixed(0)} Years`,
      },
      {
        capex: formatDollars(result.capexWithStorage || 0),
        earnings: formatDollars(result.totalRevenue || 0),
        grossProfit: formatDollars(result.grossProfit || 0),
        irr: `% ${result.irrValue.toFixed(2) || 0}`,
        payback:
          result.discountedPaybackPeriod === 0
            ? '0'
            : `${result.discountedPaybackPeriod.toFixed(0)} Years`,
      },
      {
        capex: formatDollars(result.totalStorageCapex || 0),
        earnings: formatDollars(result.revenueBattery || 0),
        grossProfit: formatDollars(result.grossProfitOfStorage || 0),
        irr: `% ${result.irrBattery.toFixed(2) || 0}`,
        payback:
          result.discountedPaybackPeriodBattery === 0
            ? '0'
            : `${result.discountedPaybackPeriodBattery.toFixed(0)} Years`,
      },
    ];

    return metricsData[activeIndex];
  }, [data, activeIndex]);
  return (
    <Card
      title={
        <Flex gap={8} align="center">
          <Typography.Title level={4} className="fs-17-bold">
            Key Metrics
          </Typography.Title>
          <Tooltip
            title="Key Metrics"
            description="Provides essential financial metrics to evaluate the financial
            performance and viability of the project across three different
            cases: After Storage, Before Storage, and only Storage."
          >
            <IconInfo />
          </Tooltip>
        </Flex>
      }
      extra={
        <RToggleButtons labels={buttons} onButtonClick={handleButtonClick} />
      }
      $paddingBody="12px 0"
      styles={{ header: { padding: '14px 24px' } }}
      style={{ marginTop: '24px' }}
    >
      {isLoading ? (
        <Flex justify="center" align="center" style={{ height: 81 }}>
          <Spin style={{ margin: '20px' }} />
        </Flex>
      ) : error ? (
        <Flex
          vertical
          gap={12}
          justify="center"
          align="center"
          style={{ height: 81 }}
        >
          <IconError width={50} height={50} fill={theme.colors.tagFailure} />
          <Typography.Text className="fs-14-regular text-gray-color">
            An error occured while fetching data. Please check your inputs or
            contact support if the issue persists.
          </Typography.Text>
        </Flex>
      ) : (
        <StyledTable vertical gap={8} justify="center" align="center">
          <Flex gap={8} justify="center" align="center">
            <Typography.Text className="title">
              {buttons[activeIndex]}
            </Typography.Text>
          </Flex>
          <Flex justify="space-around" className="w-100">
            <Col className="column">
              <Typography.Text className="value">
                {metrics?.capex}
              </Typography.Text>
              <Typography.Text className="name">
                Investment CAPEX
              </Typography.Text>
            </Col>

            <Col className="column">
              <Typography.Text className="value">
                {metrics?.earnings}
              </Typography.Text>
              <Typography.Text className="name">Total Earnings</Typography.Text>
            </Col>

            <Col className="column">
              <Typography.Text className="value">
                {metrics?.grossProfit}
              </Typography.Text>
              <Typography.Text className="name">Gross Profit</Typography.Text>
            </Col>

            <Col className="column">
              <Typography.Text className="value">
                {metrics?.irr}
              </Typography.Text>
              <Typography.Text className="name">IRR</Typography.Text>
            </Col>

            <Col className="column">
              <Typography.Text className="value">
                {metrics?.payback}
              </Typography.Text>
              <Typography.Text className="name">
                Discounted Payback Period
              </Typography.Text>
            </Col>
          </Flex>
        </StyledTable>
      )}
    </Card>
  );
};
export default KeyMetricsTable;
