import {
  LoginResponse,
  UserResponse,
  UserPost,
  UserList,
  UserVerify,
  OrganizationPost,
  OrganizationResponse,
  ResetPasswordPayload,
  ContactUs,
} from '@/types/auth';
import { FilterParams } from '@/types/general';
import { SimulationsRaw } from '@/types/simulations';

import { apiV1 } from '../instances';

export default {
  login(email: string, password: string): Promise<LoginResponse> {
    return apiV1.postFormData(
      '/auth/token/',
      { email, password },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },

  refresh(refresh: string): Promise<LoginResponse> {
    return apiV1.post(
      '/auth/token/refresh/',
      { refresh },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },

  me(): Promise<UserResponse> {
    return apiV1.get('/auth/user/me/', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getUsers(): Promise<UserList> {
    return apiV1.get('/auth/user/', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getSimulation(): Promise<SimulationsRaw> {
    return apiV1.get('/simulation/', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  patchUser(id: number, user: UserPost): Promise<UserResponse> {
    return apiV1.patch(`/auth/user/${id}/`, user, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  patchOrganization(
    id: number,
    organization: OrganizationPost
  ): Promise<OrganizationResponse> {
    return apiV1.patch(`/auth/organization/${id}/`, organization, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  createOrganization(
    organization: OrganizationPost
  ): Promise<OrganizationResponse> {
    return apiV1.post(`/auth/organization/`, organization, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  postUser(user: UserPost): Promise<UserResponse> {
    return apiV1.post(`/auth/user/`, user, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  sendEmailVerificationCode(email: string): Promise<LoginResponse> {
    return apiV1.post(
      `/auth/admin/`,
      {
        email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },

  resetPasswordSend(email: string): Promise<{ email: string }> {
    return apiV1.post(
      `/auth/password-reset/send/`,
      {
        email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },

  resetPasswordConfirm(payload: ResetPasswordPayload): Promise<LoginResponse> {
    return apiV1.post(
      `/auth/password-reset/confirm/`,
      {
        code: payload.code,
        userId: payload.userId,
        password: payload.password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },

  verifyRegistrationCode(code: string): Promise<UserVerify> {
    return apiV1.post(
      `/auth/verification/`,
      {
        code,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },

  fetchUsers(params: FilterParams): Promise<UserList> {
    const fullFill = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== '')
    );
    const query = new URLSearchParams({
      ...fullFill,
      limit: fullFill.limit?.toString(),
      offset: fullFill.offset?.toString(),
    });
    return apiV1.get(`/auth/user/?${query.toString()}`);
  },

  deleteUser(id: number) {
    return apiV1.delete(`/auth/user/${id}/`);
  },

  deleteOrganization(id: number) {
    return apiV1.delete(`/auth/organization/${id}/`);
  },

  reactivateOrganization(reactivation_id: string) {
    return apiV1.post(
      `/auth/organization/reactivate/`,
      { reactivation_id },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },

  createUserRole(role: string) {
    return apiV1.post(
      `/auth/role/`,
      { role },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },

  changeUserRole(id: number, role: number) {
    return apiV1.post(
      `/auth/user/${id}/assign_role/`,
      { role },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },

  searchOrganizationByExactName(organizationName: string) {
    return apiV1.get(
      `/auth/organization/exact_name_query/?name=${organizationName}`
    );
  },

  checkRegistrationStatus() {
    return apiV1.get(`/auth/admin/`);
  },

  postContact(contact: ContactUs) {
    return apiV1.post(`/contact/`, contact, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
