import { useEffect, useRef } from 'react';

import { StepReferences } from '@/components/RProductTour/steps';
import useProductTourStore from '@/store/useProductTourStore';

const useProductTour = (stepKey: keyof StepReferences) => {
  const ref = useRef<HTMLDivElement>(null);
  const { setStepReference, currentStep } = useProductTourStore();

  useEffect(() => {
    const observer = new MutationObserver(() => {
      // Mutation Observer : to use DOM's change in real-time
      if (ref.current) {
        setStepReference(stepKey, ref.current);
        observer.disconnect();
      }
    });

    if (ref.current) {
      // If ref is already assigned, store the reference
      setStepReference(stepKey, ref.current);
    } else {
      // If ref is not assigned yet, observe the DOM
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [setStepReference, stepKey, currentStep]);

  return ref;
};

export default useProductTour;
