import { Col } from 'antd';
import styled from 'styled-components';

interface StyledPlansCardProps {
  $isTeam: boolean;
  $isEnterprise?: boolean;
}

export default styled(Col)<StyledPlansCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ $isEnterprise }) => ($isEnterprise ? '0' : '16px')};
  border: 0.5px solid ${({ theme }) => theme.colors.grayLightAccent};
  border-radius: 8px;
  padding: 16px !important;
  height: 500px;
  flex-wrap: wrap;
  background-color: ${({ theme, $isTeam }) =>
    $isTeam ? theme.colors.primaryHighlight : theme.colors.light};

  .first-row .ant-space {
    width: 100%;
  }
  .second-row {
    width: 100%;
  }
  .second-row .ant-space {
    width: 100%;
  }

  &:hover {
    border: 0.5px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.primaryHighlight};
  }

  .btn {
    width: 118px;
    height: 40px;
    padding: 4px 12px;
    border-radius: 6px !important;
    border-color: ${({ theme }) => theme.colors.blueLightMedium} !important;
    color: ${({ theme }) => theme.colors.blueLightMedium} !important;
  }
  .btn:hover {
    background-color: ${({ theme }) => theme.colors.blueLightMedium} !important;
    color: ${({ theme }) => theme.colors.light} !important;
    border: none;
  }

  @media ((max-width: 1390px) and (min-width: 992px)) {
    height: 565px;
  }
  @media (max-width: 992px) {
    height: auto;
    background-color: ${({ theme }) => theme.colors.light};
  }
  @media (max-width: 768px) {
    width: 500px;
  }
  @media (max-width: 568px) {
    display: flex;
    flex-direction: column;
    height: auto;
    .title-amount {
      font-size: 25px !important;
    }
    .title-plan {
      font-size: 14px !important;
      padding-top: 6px;
    }
  }
`;
