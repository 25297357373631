import { Row } from 'antd';
import styled from 'styled-components';

export default styled(Row)`
  .recharts-default-legend {
    margin-top: 7px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 30px;
    li {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      margin: 0 !important;
      gap: 8px !important;
      svg {
        margin: 0 !important;
      }
    }
  }
  .recharts-layer .recharts-bar-rectangle:first-child > path {
    fill: ${({ theme }) => theme.colors.purpleSecondary} !important;
  }
  .recharts-layer .recharts-bar-rectangle:first-child {
    .recharts-active-bar:first-child > path {
      fill: ${({ theme }) => theme.colors.purpleSecondary} !important;
    }
  }
`;
