import { Row } from 'antd';
import styled from 'styled-components';

export default styled(Row)`
  padding: 20px 290px;
  background-color: ${({ theme }) => theme.colors.blueLightest};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .container {
    padding: 60px;
    width: 860px;

    background-color: ${({ theme }) => theme.colors.light};
    border: 0.5px solid ${({ theme }) => theme.colors.grayLightAccent};
    border-radius: 10px;
    .title {
      font-size: 42px;
      line-height: 63px;
      font-weight: 600;
    }
    .description {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
    }
    .cta-btn {
      width: 100%;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
    }
  }
`;
