import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { Col, DrawerProps, Row, Typography } from 'antd';

import Cross from '@/assets/icons/icon-close.svg?react';
import useAuth from '@/hooks/useAuth';

import renderMaskContent from './maskContent';
import StyledDrawer from './styles';

export type DrawerLockState = {
  isLocked: boolean;
  lockText: string;
  buttons?: ReactNode[];
};

interface ExtendedDrawerProps extends DrawerProps {
  title?: string;
  description?: string | ReactNode;
  rightSideSection?: ReactNode;
  width?: string;
  onClose: () => void;
  open: boolean;
  closeOnEscape?: boolean;
  maskClosable?: boolean;
  drawerLock?: DrawerLockState;
  delayMask?: boolean;
}

const RDrawer: FC<ExtendedDrawerProps> = ({
  title,
  description,
  rightSideSection,
  children,
  open,
  width = '85%',
  closeOnEscape = true,
  maskClosable = true,
  drawerLock,
  placement = 'right',
  delayMask = false,
  onClose,
  ...props
}) => {
  const { isMembershipActive } = useAuth();
  const [isMaskVisible, setIsMaskVisible] = useState(false);

  useEffect(() => {
    if (drawerLock?.isLocked) {
      if (delayMask) {
        const timer = setTimeout(() => {
          setIsMaskVisible(true);
        }, 1500);
        return () => clearTimeout(timer);
      } else {
        setIsMaskVisible(true);
      }
    } else {
      setIsMaskVisible(false);
    }
  }, [drawerLock?.isLocked, delayMask]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && open) {
        onClose();
      }
    },
    [onClose, open]
  );

  useEffect(() => {
    if (closeOnEscape) {
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, [closeOnEscape, handleKeyDown]);

  return (
    <StyledDrawer
      {...props}
      title={
        <Row justify="space-between">
          <Col flex="1">
            <Row>
              <Typography.Title level={2} className="title">
                {title}
              </Typography.Title>
            </Row>
            <Row className="description">
              <Typography.Text>{description}</Typography.Text>
            </Row>
          </Col>
          <Col className="right-side">{rightSideSection}</Col>
          <Cross
            onClick={onClose}
            style={{ zIndex: '9999', cursor: 'pointer' }}
            fill="black"
          />
        </Row>
      }
      keyboard={closeOnEscape}
      open={open}
      placement={placement}
      width={width}
      maskClosable={maskClosable}
      onClose={onClose}
      closable={false}
    >
      {renderMaskContent(
        isMembershipActive,
        isMaskVisible,
        drawerLock,
        onClose
      )}
      {children}
    </StyledDrawer>
  );
};

export default RDrawer;
