import { Col } from 'antd';
import styled from 'styled-components';

export default styled(Col)<{ currentStep: number }>`
  height: ${({ currentStep }) =>
    currentStep === 3 ? 'auto' : 'calc(100vh - 60px)'};
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, currentStep }) =>
    currentStep === 3 && theme.colors.blueLightest};
  padding: ${({ currentStep }) =>
    currentStep === 3 ? '60px 60px 120px' : '120px 270px 120px 270px'};

  .container {
    width: 100%;
    border: 0.5px solid ${({ theme }) => theme.colors.grayLightAccent};
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.light};
    padding: 60px;
  }

  .ant-steps-item-process .ant-steps-item-tail::after,
  .ant-steps-item-wait .ant-steps-item-tail::before,
  .ant-steps-item-wait .ant-steps-item-tail::after,
  .ant-steps-item-finish .ant-steps-item-tail::before {
    background-color: ${({ theme }) => theme.colors.grayLightAccent} !important;
  }
  .ant-steps-item-finish .ant-steps-item-title {
    color: ${({ theme }) => theme.colors.bgBlack} !important;
  }
  .ant-steps-item-finish .ant-steps-item-description {
    color: ${({ theme }) => theme.colors.grayAccent} !important;
  }
  .ant-steps-item-process .ant-steps-item-title {
    color: ${({ theme }) => theme.colors.dark} !important;
  }
  .ant-steps-item-process .ant-steps-item-description {
    color: ${({ theme }) => theme.colors.bgBlack} !important;
  }
  .ant-steps-item-wait .ant-steps-item-title {
    color: ${({ theme }) => theme.colors.grayAccent} !important;
  }
  .ant-steps-item-wait .ant-steps-item-description {
    color: ${({ theme }) => theme.colors.grayAccent} !important;
  }

  .ant-steps-item-tail::after {
    background-color: ${({ theme }) => theme.colors.grayLightAccent};
  }

  .line {
    width: 100%;
    color: ${({ theme }) => theme.colors.grayLightAccent};
    margin: 0;
    height: 1px;
    margin: 24px 0;
  }

  .input-style {
    height: 32px;
    border: 0.5px solid ${({ theme }) => theme.colors.grayLightAccent} !important;
    border-radius: 4px !important;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    padding: 5.5px 12px !important;
  }

  .ant-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  // EmailStepVerification

  .btn-style {
    display: flex;
    justify-content: center;
    margin-left: auto;
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }
  .resend {
    cursor: pointer;
  }
  .info-text {
    margin-bottom: 24px !important;
  }
  .receive-text {
    margin: 8px 0 16px 0 !important;
  }
  .btn-group {
    margin-top: 16px;
    display: flex;
    margin-left: auto;
    gap: 16px;
    margin-top: 24px;
    .btn-back {
      color: ${({ theme }) => theme.colors.blueLightMedium};
      border-color: ${({ theme }) => theme.colors.blueLightMedium};
      width: 90px;
      height: 40px;
      border-radius: 6px;
    }
    .btn-next {
      width: 90px;
      height: 40px;
      border-radius: 6px;
    }
    .btn-send {
      width: 97px;
      height: 40px;
      border-radius: 6px;
    }
  }

  .ant-form-item-explain-error {
    display: block !important;
  }

  .ant-form-item {
    margin-bottom: 40px;
  }

  .ant-form-item-label {
    padding-bottom: 8px !important;
  }

  .ant-form-item-explain-error {
    display: none;
  }
`;
