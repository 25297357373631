import { FC } from 'react';

import { Typography, Image, Flex } from 'antd';

import AvatarFrame from '@/assets/files/avatar-frame.svg';
import { getNameInitials } from '@/utils';

import StyledUserAvatarCard from './styles';

type UserAvatarCardProps = {
  companyName: string;
  userRole: string;
};

const RUserAvatarCard: FC<UserAvatarCardProps> = ({
  companyName,
  userRole,
}) => {
  return (
    <StyledUserAvatarCard gap={8} align="center">
      <div className="avatar-cont">
        <Image preview={false} className="team-avatar" src={AvatarFrame} />
        <span className="avatar-company-name">
          {getNameInitials(companyName)}
        </span>
      </div>
      <Flex gap={5} vertical>
        <Typography.Text className="company-name">
          {companyName}
        </Typography.Text>
        <Typography.Text className="company-role">{userRole}</Typography.Text>
      </Flex>
    </StyledUserAvatarCard>
  );
};
export default RUserAvatarCard;
