import { FC, useState } from 'react';

import { Dropdown, Button } from 'antd';
import { useTheme } from 'styled-components';

import Delete from '@/assets/icons/icon-delete.svg?react';
import Edit from '@/assets/icons/icon-edit.svg?react';
import More from '@/assets/icons/icon-three-dots-vertical.svg?react';
import RNotificationCard from '@/components/RNotificationCard';

interface ActionsMenuProps {
  onDelete: () => void;
  onEdit: () => void;
}

export const ActionsMenu: FC<ActionsMenuProps> = ({ onDelete, onEdit }) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const theme = useTheme();
  const handleMenuClick = (e: { key: string }) => {
    if (e.key === 'edit') {
      onEdit();
    }
    if (e.key === 'delete') {
      setModalVisible(true);
    }
  };
  const handleDeleteConfirm = () => {
    onDelete();
    setModalVisible(false);
  };

  const menuItems = [
    { key: 'edit', icon: <Edit width={16} height={16} />, label: 'Edit' },
    {
      key: 'delete',
      icon: <Delete width={16} height={16} />,
      label: 'Delete',
    },
  ];

  return (
    <>
      <Dropdown
        menu={{ items: menuItems, onClick: handleMenuClick }}
        trigger={['click']}
      >
        <Button
          className="default-icon-button"
          icon={<More fill={theme.colors.bluePrimary} />}
        />
      </Dropdown>
      <RNotificationCard
        title="Delete Item"
        message="Are you sure you want to delete this user?"
        type="warning"
        confirmText="OK"
        cancelText="Cancel"
        open={modalVisible}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setModalVisible(false)}
      />
    </>
  );
};
