import { FC } from 'react';

import { Col, Form, Input, Row } from 'antd';
import { useTheme } from 'styled-components';

import { ScenarioForm } from '@/types/scenario';
import { normalizePositiveNumericValuesInput } from '@/utils';

type Props = {
  disabled?: boolean;
};

const FinancialMetrics: FC<Props> = ({ disabled = false }) => {
  const theme = useTheme();
  return (
    <Form.Item<ScenarioForm>
      className="p-16 pb-40"
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
      wrapperCol={{ span: 24 }}
    >
      <Row>
        <Col className="pr-8" span={12}>
          <Form.Item<ScenarioForm>
            normalize={normalizePositiveNumericValuesInput}
            name="taxRate"
            label="Tax Rate"
            rules={[
              {
                required: true,
                message: 'Tax Rate is required.',
              },
            ]}
          >
            <Input disabled={disabled} placeholder="Tax Rate" addonAfter="%" />
          </Form.Item>
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<ScenarioForm>
            normalize={normalizePositiveNumericValuesInput}
            name="hurdleRate"
            label="Hurdle Rate"
            rules={[
              {
                required: true,
                message: 'Hurdle Rate is required.',
              },
            ]}
          >
            <Input
              disabled={disabled}
              placeholder="Hurdle Rate"
              addonAfter="%"
            />
          </Form.Item>
        </Col>
        <Col className="pr-8" span={12}>
          <Form.Item<ScenarioForm>
            normalize={normalizePositiveNumericValuesInput}
            name="batteryPriceDeclinePercentagePerYear"
            label="Battery Price Decline Percentage per Year"
            rules={[
              {
                required: true,
                message:
                  'Battery Price Decline Percentage per Year is required.',
              },
            ]}
          >
            <Input
              disabled={disabled}
              placeholder="Battery Price Decline Percentage per Year"
              addonAfter="%"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default FinancialMetrics;
