import { Row } from 'antd';
import styled from 'styled-components';

export default styled(Row)`
  background-color: ${({ theme }) => theme.colors.light} !important;
  display: flex;
  justify-content: center;
  height: calc(100vh - 40px);
  .ant-modal {
    top: 0;
  }
  .header-title {
    padding: 12px 64px;
  }
  .pad-switch {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }
  .btn {
    border-radius: 6px !important;
  }
  .btn-row {
    display: flex;
    flex-wrap: wrap;
  }
  .pad-plans {
    margin: 0 !important;
    padding: 0 ${({ theme }) => theme.main.paddingSide}px;
  }
  .recommended-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .recommended-text {
    width: 97px;
    height: 20px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.blueSecondary};
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.light};
  }
  .compare-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    cursor: pointer;
    .ant-space-item {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 992px) {
    .pad-plans {
      padding: 0 ${({ theme }) => theme.main.paddingSideSm}px 0;
    }
    .pad-switch {
      padding: 32px;
    }
    .header-title {
      padding: 12px 32px;
    }
  }

  @media (max-width: 400px) {
    .pad-plans {
      padding: 0 ${({ theme }) => theme.main.paddingSideXs}px 0;
    }
  }
`;
