import { useEffect, useState } from 'react';

import { Button, Col, Row, Typography } from 'antd';
import moment, { Moment } from 'moment';

import IconUpdate from '@/assets/icons/icon-update-time.svg?react';

interface UpdateTimeProps {
  fetchFunction: () => void;
}

const RUpdateTime = ({ fetchFunction }: UpdateTimeProps) => {
  const [passedMins, setPassedMins] = useState<number>();
  const [lastUpdatedTime, setLastUpdatedTime] = useState<Moment>(moment());

  useEffect(() => {
    if (lastUpdatedTime) {
      const interval = 60 * 1000;
      const fixedDate = lastUpdatedTime;

      const calculateDiff = () => {
        const now = moment();
        const diff = moment.duration(now.diff(fixedDate)).asMinutes();
        setPassedMins(diff);
      };

      calculateDiff();

      const intervalId = setInterval(() => {
        calculateDiff();
      }, interval);

      return () => clearInterval(intervalId);
    }
    setPassedMins(0);
  }, [lastUpdatedTime]);

  const formatUpdateTime = (minutes: number) => {
    if (minutes < 15) {
      return 'Updated just now';
    } else if (minutes < 45) {
      const rounded = 15 * Math.round(minutes / 15);
      return `Last updated at ${rounded} minutes ago`;
    } else if (minutes < 120) {
      return `Last updated at ${Math.round(minutes / 60)} hour ago`;
    } else {
      return `Last updated at ${Math.round(minutes / 60)} hours ago`;
    }
  };

  return (
    <Row align="middle" justify="start">
      <Col>
        <Button
          type="primary"
          icon={<IconUpdate fill="white"></IconUpdate>}
          onClick={() => {
            fetchFunction();
            setLastUpdatedTime(moment());
          }}
        ></Button>
      </Col>
      <Col>
        <Typography.Text className="smoke fs-12 pl-15">
          {passedMins ? formatUpdateTime(passedMins) : 'Last updated recently'}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default RUpdateTime;
