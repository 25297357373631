import styled from 'styled-components';

export const StyledTable = styled.div`
  .ant-table-wrapper {
    margin-top: 0px;
    .ant-table-content tbody tr > td {
      border: none !important;
    }
    tbody > tr:first-child > td:last-child {
      background-color: ${({ theme }) => theme.colors.bgPrimary} !important;
      border-top: 3px solid ${({ theme }) => theme.colors.light} !important;
      border-left: 3px solid ${({ theme }) => theme.colors.light} !important;
    }
    tbody > tr > td:nth-child(n + 2) {
      /* border: 1px solid ${({ theme }) =>
        theme.colors.blueLightest} !important; */
      padding: 10px 12px !important;
    }
    tr > th {
      padding: 10px 24px !important;
    }
    tr > td {
      padding: 0px !important;
      text-align: center;
    }
  }
`;
