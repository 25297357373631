import { Dropdown } from 'antd';
import styled from 'styled-components';

export default styled(Dropdown)`
  .account-card-user-name {
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    font-weight: 400;
  }

  .account-card-row-avatar {
    padding: 0px;
    background-color: none;
    height: 60px;
    gap: 9px;
    padding-left: ${({ theme }) => theme.spacing.spacing14}px;
  }
`;
