import { FC } from 'react';

import NotFoundContent from '@/components/RNotFoundContent';

import StyledTable from './styles';
import { ExtendedTableProps } from './types';

const RTable: FC<ExtendedTableProps> = ({ cta, ...props }) => {
  const isNoData = props.dataSource?.length === 0;

  const locale = {
    emptyText: isNoData && (
      <NotFoundContent
        cta={cta}
        imageStyle={{ minHeight: 120 }}
        style={{ minHeight: '400px' }}
      />
    ),
  };

  return (
    <>
      <StyledTable
        showSorterTooltip={{ target: 'sorter-icon' }}
        className={isNoData ? 'show-locale' : 'remove-locale'}
        {...props}
        locale={locale}
      />
    </>
  );
};

export default RTable;
