import styled from 'styled-components';

export const StyledTable = styled.div`
  .ant-table-wrapper {
    margin-top: 0px;
    .ant-table-content tbody tr > td {
      border: none !important;
    }
    tbody > tr > td:nth-child(n + 2) {
      /* border: 1px solid ${({ theme }) =>
        theme.colors.blueLightest} !important; */
      padding: 10px 12px !important;
    }
    tr > th {
      padding: 10px 24px !important;
    }
    tr > td {
      padding: 0px !important;
      text-align: center;
    }
  }
`;
