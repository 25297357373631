import { Row, Col, Flex, Grid } from 'antd';

import { PlansDesktopCardsProps } from '@/types/plan';

import PlansCard from '../PlansCard';
import SwitchPlans from '../SwitchPlans';

const { useBreakpoint } = Grid;

const PlansDesktopCards: React.FC<PlansDesktopCardsProps> = ({
  monthlyPlan,
  yearlyPlan,
  yearly,
  plansWithFeatures,
  handleSwitch,
  percentage,
  data,
  setLoading,
}) => {
  const { xl } = useBreakpoint();
  return (
    <>
      <SwitchPlans
        yearly={yearly}
        handleSwitch={handleSwitch}
        percentage={percentage}
      />
      <Col className="pad-plans">
        <Flex vertical gap={16} wrap="wrap">
          <Row gutter={[16, 16]} justify="center" className="btn-row">
            {!yearly ? (
              <>
                {/*DEMO-MO*/}
                <Col
                  xs={24}
                  sm={24}
                  md={16}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={xl ? { marginTop: '20px' } : {}}
                >
                  <PlansCard
                    title={
                      data[0].name.charAt(0).toUpperCase() +
                      data[0].name.slice(1)
                    }
                    description={data[0].subTitle}
                    amount={data[0].amount}
                    save={false}
                    payPlan={data[0].durationUnit}
                    details={[...(data[0].feature || [])]}
                    yearly={yearly}
                    planKey={data[0]?.key}
                    setLoading={setLoading}
                  />
                </Col>
                {/*INDIVIDUAL-MO*/}
                <Col
                  xs={24}
                  sm={24}
                  md={16}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={xl ? { marginTop: '20px' } : {}}
                >
                  <PlansCard
                    title={
                      monthlyPlan?.[0]?.name.charAt(0).toUpperCase() +
                      monthlyPlan?.[0]?.name.slice(1)
                    }
                    description={monthlyPlan?.[0]?.subTitle}
                    amount={monthlyPlan?.[0]?.amount}
                    payPlan="mo"
                    button="Get contact"
                    savedAmount={monthlyPlan?.[0]?.saves}
                    details={[...(plansWithFeatures[0]?.feature || [])]}
                    yearly={yearly}
                    $isIndividual={true}
                    planKey={monthlyPlan?.[0]?.key}
                  />
                </Col>
                {/*TEAM-MO*/}
                <Col
                  xs={24}
                  sm={24}
                  md={16}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={xl ? { marginTop: '20px' } : {}}
                >
                  <PlansCard
                    title={
                      monthlyPlan?.[1]?.name.charAt(0).toUpperCase() +
                      monthlyPlan?.[1]?.name.slice(1)
                    }
                    description={monthlyPlan?.[1]?.subTitle}
                    amount={monthlyPlan?.[1]?.amount}
                    payPlan="mo"
                    button="Get contact"
                    savedAmount={monthlyPlan?.[1]?.saves}
                    details={[...(plansWithFeatures[1]?.feature || [])]}
                    yearly={yearly}
                    $isTeam={true}
                    planKey={monthlyPlan?.[1]?.key}
                  />
                </Col>

                {/*ENTERPRISE-MO*/}
                <Col xs={24} sm={24} md={16} lg={12} xl={24} xxl={24}>
                  <PlansCard
                    title={
                      monthlyPlan?.[2]?.name.charAt(0).toUpperCase() +
                      monthlyPlan?.[2]?.name.slice(1)
                    }
                    description={monthlyPlan?.[2]?.subTitle}
                    amount={monthlyPlan?.[2]?.amount}
                    $isEnterpriseXL={xl ? true : false}
                    $isEnterprise={true}
                    save={false}
                    button="Get contact"
                    details={[...(plansWithFeatures[2]?.feature || [])]}
                    yearly={yearly}
                    planKey={monthlyPlan?.[2]?.key}
                  />
                </Col>
              </>
            ) : (
              <>
                {/*DEMO-AN*/}
                <Col
                  xs={24}
                  sm={24}
                  md={16}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={xl ? { marginTop: '20px' } : {}}
                >
                  <PlansCard
                    title={
                      data[0].name.charAt(0).toUpperCase() +
                      data[0].name.slice(1)
                    }
                    description={data[0].subTitle}
                    amount={data[0].amount}
                    save={false}
                    payPlan={data[0].durationUnit}
                    details={[...(data[0].feature || [])]}
                    yearly={yearly}
                    planKey={data[0]?.key}
                    setLoading={setLoading}
                  />
                </Col>
                {/*INDIVIDUAL-AN*/}
                <Col
                  xs={24}
                  sm={24}
                  md={16}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={xl ? { marginTop: '20px' } : {}}
                >
                  <PlansCard
                    title={
                      yearlyPlan?.[0]?.name.charAt(0).toUpperCase() +
                      yearlyPlan?.[0]?.name.slice(1)
                    }
                    description={yearlyPlan?.[0]?.subTitle}
                    amount={yearlyPlan?.[0]?.amount}
                    payPlan="an"
                    button="Get contact"
                    savedAmount={yearlyPlan?.[0]?.saves}
                    details={[...(plansWithFeatures[0]?.feature || [])]}
                    yearly={yearly}
                    $isIndividual={true}
                    planKey={yearlyPlan?.[0]?.key}
                  />
                </Col>
                {/*TEAM-AN*/}
                <Col
                  xs={24}
                  sm={24}
                  md={16}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={xl ? { marginTop: '20px' } : {}}
                >
                  <PlansCard
                    title={
                      yearlyPlan?.[1]?.name.charAt(0).toUpperCase() +
                      yearlyPlan?.[1]?.name.slice(1)
                    }
                    description={yearlyPlan?.[1]?.subTitle}
                    amount={yearlyPlan?.[1]?.amount}
                    payPlan="an"
                    button="Get contact"
                    savedAmount={yearlyPlan?.[1]?.saves}
                    details={[...(plansWithFeatures[1]?.feature || [])]}
                    yearly={yearly}
                    $isTeam={true}
                    planKey={yearlyPlan?.[1]?.key}
                  />
                </Col>

                {/*ENTERPRISE-AN*/}
                <Col xs={24} sm={24} md={16} lg={12} xl={24} xxl={24}>
                  <PlansCard
                    title={
                      yearlyPlan?.[2]?.name.charAt(0).toUpperCase() +
                      yearlyPlan?.[2]?.name.slice(1)
                    }
                    description={yearlyPlan?.[2]?.subTitle}
                    $isEnterpriseXL={xl ? true : false}
                    $isEnterprise={true}
                    amount={yearlyPlan?.[2]?.amount}
                    save={false}
                    button="Get contact"
                    details={[...(plansWithFeatures[2]?.feature || [])]}
                    yearly={yearly}
                    planKey={yearlyPlan?.[2]?.key}
                  />
                </Col>
              </>
            )}
          </Row>
        </Flex>
      </Col>
    </>
  );
};

export default PlansDesktopCards;
