import { notification } from 'antd';
import {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import CookieService from '@/services/CookieService';
import { TokenRefresh } from '@/types/auth';

class Interceptor {
  axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.setRequestInterceptor();
    this.setResponseInterceptor();
  }

  setRequestInterceptor(): void {
    this.axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const authToken = CookieService.getAuthToken();
        if (!config.headers['Authorization'] && authToken) {
          config.headers['Authorization'] = authToken;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  setResponseInterceptor(): void {
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.data && response.data.message) {
          notification.info({ message: response.data.user_message });
        }
        if (response.data) {
          return response.data;
        }
        return response;
      },
      async (error) => {
        const prevRequest = error?.config;

        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const refreshToken = CookieService.getRefreshToken();
          const res: TokenRefresh = await this.axiosInstance.post(
            '/auth/token/refresh/',
            { refresh: refreshToken }
          );
          CookieService.setAuthToken(res.access);
          prevRequest.headers['Authorization'] = `Bearer ${res.access}`;

          return this.axiosInstance(prevRequest);
        } else if (error.data && error.data.user_message) {
          notification.error({ message: error.data.user_message });
          throw error.data;
        } else if (error.status === 403) {
          CookieService.clearCookies();
        } else if (error.status === 404) {
          console.log('not-found');
        }
        return Promise.reject(error);
      }
    );
  }
}

export default Interceptor;
