import { ReactNode } from 'react';

import { Row, Image, Col } from 'antd';
import { Link } from 'react-router-dom';

import Logo from '@/assets/logo/logo.png';
import paths from '@/routes/paths';

import StyledHeader from './styles';

const Header: React.FC<{ children?: ReactNode }> = (props) => {
  // const items: MenuProps['items'] = [
  //   {
  //     label: <a target="_blank" rel="noreferrer" href={paths.external.features}>Features</a>,
  //     key: 'features',
  //   },
  //   {
  //     label: <a target="_blank" rel="noreferrer" href={paths.external.pricing}>Pricing</a>,
  //     key: 'pricing',
  //   },
  //   {
  //     label: <a href="/login">Login</a>,
  //     key: 'login',
  //   },
  //   {
  //     label: <Button href={paths.external.register} rel="noreferrer" type='primary'>Get Started</Button>,
  //     key: 'get-started',
  //   },
  // ];

  return (
    <StyledHeader {...props}>
      <Col flex={1}>
        <Row align="middle" justify="space-between">
          <Col>
            <Link to={paths.login}>
              <Image src={Logo} alt="RatioSIM" preview={false} />
            </Link>
          </Col>
          {/* <Col className='toolbar'>
            <Row gutter={24}>
              <Menu mode="horizontal" items={items} />
            </Row>
          </Col> */}
        </Row>
      </Col>
    </StyledHeader>
  );
};

export default Header;
