import { Fragment, FC, useState } from 'react';

import { Button, Flex, Spin, Typography } from 'antd';
import { useParams } from 'react-router';
import {
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconDownload from '@/assets/icons/icon-download.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Card from '@/components/RCard';
import CustomGraphicTooltip from '@/components/RGraphicTooltip';
import RToggleButtons from '@/components/RToggleButtons';
import RTooltip from '@/components/RTooltip';
import {
  SimulationResult,
  SimulationResultYearlyFinancialAnalysis,
} from '@/types/simulations';
import { formatNumberWithCommas, getTickStep, tickFormatter } from '@/utils';

import StyledCashFlow from './styles';

type CashFlowProps = {
  barWidth?: number;
};

const CashFlow: FC<CashFlowProps> = ({ barWidth = 16 }) => {
  const resultId = useParams<Record<string, string>>();
  const {
    data: financialData,
    isLoading,
    error,
  } = useSWR<SimulationResultYearlyFinancialAnalysis>(
    `/simulation/${resultId.resultId}/yearly_financial_analysis/`,
    {
      revalidateOnFocus: false,
    }
  );
  const isDataZero = financialData?.graphData.length === 0;

  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`,
    {
      revalidateOnFocus: false,
    }
  );
  const [closedDataKeys, setClosedDataKeys] = useState<string[]>([]);
  const [selectedSwitch, setSelectedSwitch] = useState('Nominal');
  const theme = useTheme();

  const toggleClosedDataKeys = (key: string = '') => {
    if (closedDataKeys.includes(key)) {
      setClosedDataKeys(closedDataKeys.filter((item) => item !== key));
    } else {
      setClosedDataKeys([...closedDataKeys, key]);
    }
  };

  const data =
    financialData?.graphData
      .slice()
      .sort((a, b) => a.year - b.year)
      .map((item, index) => ({
        id: index,
        name: item.year,
        totalCapex: item.totalStorageCapex.toFixed(0),
        netCashFlow:
          selectedSwitch === 'NPV'
            ? item.netPresentValue.toFixed(0)
            : item.cashFlow.toFixed(0),
        accumulatedCashFlow:
          selectedSwitch === 'NPV'
            ? item.accumulatedCashFlowNpv.toFixed(0)
            : item.accumulatedCashFlow.toFixed(0),
      })) || [];

  const netCashFlowValues = data.map((item) => Number(item.netCashFlow));
  const minNetCashFlow = Math.min(...netCashFlowValues);
  const maxNetCashFlow = Math.max(...netCashFlowValues);

  const roundToNearest = (num: number, nearest: number) => {
    return Math.round(num / nearest) * nearest;
  };

  const tickStep = Math.max(
    getTickStep(Math.max(Math.abs(minNetCashFlow), Math.abs(maxNetCashFlow))),
    getTickStep(
      Math.max(...data.map((item) => Number(item.accumulatedCashFlow)))
    )
  );
  const upperBound = roundToNearest(maxNetCashFlow * 1.33, tickStep);
  const lowerBound = roundToNearest(minNetCashFlow * 1.33, tickStep);
  const maxAbsBound = Math.max(
    Math.abs(upperBound),
    Math.abs(lowerBound),
    Math.abs(Math.max(...data.map((item) => Number(item.accumulatedCashFlow))))
  );
  const domain = [-maxAbsBound, maxAbsBound];

  const step = maxAbsBound / 3;
  const ticks = [
    -3 * step,
    -2 * step,
    -1 * step,
    0,
    1 * step,
    2 * step,
    3 * step,
  ]
    .map((value) => roundToNearest(value, tickStep))
    .filter(
      (value) => value !== 0 || maxNetCashFlow !== 0 || minNetCashFlow !== 0
    );

  const handleSwitchChange = (value: string) => {
    setSelectedSwitch(value);
  };

  return (
    <StyledCashFlow>
      <Card
        title={
          <Flex gap={8} align="center">
            <Typography.Title level={4} className="fs-17-bold">
              Cash Flow
            </Typography.Title>
            <RTooltip
              title="Cash Flow"
              description=" Representation of net cash flow, upfront cost, and accumulated
              cash flow in both net present value and nominal terms, with net
              cash flow calculated after tax."
            >
              <IconInfo />
            </RTooltip>
          </Flex>
        }
        extra={
          <Flex gap={16} align="center">
            <RToggleButtons
              labels={['Nominal', 'NPV']}
              onLabelChange={handleSwitchChange}
            />
            <Button icon={<IconDownload fill={theme.colors.bluePrimary} />} />
          </Flex>
        }
        $paddingBody="24px"
        styles={{ header: { padding: '14px 24px' } }}
        style={{ marginTop: '24px', width: '100%' }}
      >
        {isLoading ? (
          <Flex justify="center" align="center" style={{ height: 400 }}>
            <Spin style={{ margin: '20px' }} />
          </Flex>
        ) : error || isDataZero ? (
          <Flex
            vertical
            gap={12}
            justify="center"
            align="center"
            style={{ height: 400 }}
          >
            <IconError width={50} height={50} fill={theme.colors.tagFailure} />
            {error && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                An error occured while fetching data. Please check your inputs
                and contact support if the issue persists.
              </Typography.Text>
            )}
            {isDataZero && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                No data is currently available. Please review your inputs.
              </Typography.Text>
            )}
          </Flex>
        ) : (
          <>
            <ResponsiveContainer width="100%" height={400}>
              <ComposedChart data={data} stackOffset="sign">
                <CartesianGrid vertical={false} horizontal={true} />
                <XAxis dataKey="name" axisLine={false} tickLine={false} />
                <YAxis
                  domain={domain}
                  tickFormatter={tickFormatter}
                  ticks={ticks}
                  axisLine={false}
                  tickLine={false}
                />
                <Tooltip
                  content={({ active, payload, label }) => {
                    const typedPayload = payload as Payload<number, string>[];
                    return (
                      <CustomGraphicTooltip
                        active={active}
                        payload={typedPayload}
                        label={label}
                        extraData={[
                          {
                            name: 'Investment/Augmentation Cost',
                            value: (
                              <Typography.Text className="text-gray-color">
                                {formatNumberWithCommas(
                                  Number(
                                    data.find((d) => d.name === label)
                                      ?.totalCapex
                                  )
                                ) || 0}
                              </Typography.Text>
                            ),
                            unit: '$',
                            color: theme.colors.yellowSecondary,
                            type: 'circle',
                          },
                        ]}
                      />
                    );
                  }}
                />

                <Legend
                  iconSize={10}
                  payload={[
                    {
                      value: 'Net Cash Flow',
                      id: 'netCashFlow',
                      type: 'circle',
                      color: theme.colors.brownDark,
                      inactive: closedDataKeys.includes('netCashFlow'),
                    },
                    {
                      value: 'Accumulated Cash Flow',
                      id: 'accumulatedCashFlow',
                      type: 'line',
                      color: theme.colors.blueLight,
                      inactive: closedDataKeys.includes('accumulatedCashFlow'),
                    },
                  ]}
                  onClick={({ id }) => toggleClosedDataKeys(id)}
                />
                {!closedDataKeys.includes('accumulatedCashFlow') && (
                  <Line
                    dot={(props: { cy: number; cx: number; index: number }) => {
                      const { cy, cx, index } = props;
                      if (index === 0 || index === data.length - 1) {
                        return (
                          <circle
                            key={`circle-${index}`}
                            cx={cx}
                            cy={cy}
                            r={5}
                            fill={theme.colors.blueLight}
                          />
                        );
                      }
                      return <Fragment key={`dot-${index}`} />;
                    }}
                    name="Accumulated Cash Flow"
                    unit={'$'}
                    type="natural"
                    dataKey="accumulatedCashFlow"
                    stroke={theme.colors.blueLight}
                  />
                )}
                {!closedDataKeys.includes('netCashFlow') && (
                  <Bar
                    name="Net Cash Flow"
                    unit={'$'}
                    dataKey="netCashFlow"
                    barSize={barWidth}
                    stackId="a"
                    fill={theme.colors.brownDark}
                  />
                )}
              </ComposedChart>
            </ResponsiveContainer>

            <div
              style={{ position: 'absolute', right: '28px', bottom: '28px' }}
            >
              <Typography.Text className="fs-12-bold">
                <span className="fs-12-extra-bold">
                  *Discounted Payback Period:{' '}
                </span>
                {simulationData?.result.discountedPaybackPeriod} YEARS
              </Typography.Text>
            </div>
          </>
        )}
      </Card>
    </StyledCashFlow>
  );
};
export default CashFlow;
