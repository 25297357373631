import { FC } from 'react';

import { Flex, Typography } from 'antd';
import { TooltipProps } from 'recharts';

import { LINE_DATA_KEYS } from '@/constants';
import { formatNumberWithCommas } from '@/utils/index';

import StyledTooltip from './styles';

const getLegendIcon = (
  type: string,
  color: string | undefined,
  dataKey?: string
) => {
  const isLineDataKey = (dataKey: string) => {
    return LINE_DATA_KEYS.includes(dataKey || '');
  };
  if (isLineDataKey(dataKey || '')) {
    return (
      <svg width="11" height="2">
        <line
          x1="0"
          y1="6"
          x2="12"
          y2="6"
          stroke={color}
          strokeWidth="11"
          strokeDasharray="3 1 3 1 3"
          strokeDashoffset="0"
        />
      </svg>
    );
  }
  switch (type) {
    case 'line':
      return (
        <svg width="11" height="2">
          <line
            x1="0"
            y1="6"
            x2="12"
            y2="6"
            stroke={color}
            strokeWidth="11"
            strokeDasharray="3 1 3 1 3"
            strokeDashoffset="0"
          />
        </svg>
      );
    case 'circle':
    default:
      return (
        <svg width="10" height="10">
          <circle cx="5" cy="5" r="5" fill={color} />
        </svg>
      );
  }
};

interface CustomTooltipProps extends TooltipProps<number, string> {
  extraData?: {
    name: string;
    value: string | number | React.ReactNode;
    unit: string;
    color: string;
    type: string;
  }[];
  width?: number;
}

const RGraphicTooltip: FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  extraData,
  width,
}) => {
  if (active && payload && payload.length) {
    const tooltipLabel = payload[0].payload.name;

    return (
      <StyledTooltip vertical width={width}>
        <Typography.Text className="fs-14-semi-bold label">
          {label || tooltipLabel}
        </Typography.Text>
        <hr className="line" />
        {extraData &&
          extraData.map((data, index) => {
            return (
              data.value !== 0 && (
                <Flex key={index} justify="space-between">
                  <Flex gap={4} align="center">
                    {getLegendIcon(data?.type || '', data.color)}
                    <Typography.Text className="fs-14-regular text-gray-color">
                      {data.name}
                    </Typography.Text>
                  </Flex>
                  <Typography.Text className="fs-14-regular">
                    {data.unit === '$' ? `${data.unit} ` : ''}
                    {data.value}
                    {data.unit !== '$' ? `${data.unit} ` : ''}
                  </Typography.Text>
                </Flex>
              )
            );
          })}
        {payload
          .slice()
          .reverse()
          .map((item) => {
            const unit = item.unit || item.payload.unit;
            const value = formatNumberWithCommas(Number(item.value));
            return (
              <Flex key={item.dataKey} justify="space-between">
                <Flex gap={4} align="center">
                  {getLegendIcon(
                    item.payload.type || 'circle',
                    item.color || item.payload.fill,
                    item.dataKey?.toString() // Pass dataKey here
                  )}
                  <Typography.Text className="fs-14-regular text-gray-color">
                    {item.name}
                  </Typography.Text>
                </Flex>
                <Typography.Text className="fs-14-regular  text-gray-color">
                  {unit === '$' ? `${unit} ${value}` : `${value} ${unit || ''}`}
                </Typography.Text>
              </Flex>
            );
          })}
      </StyledTooltip>
    );
  }
  return null;
};

export default RGraphicTooltip;
