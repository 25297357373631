import { Flex, Typography } from 'antd';
import { useParams } from 'react-router';
import useSWR from 'swr';

import Card from '@/components/RCard';
import {
  SimulationBatteryDegradation,
  SimulationResult,
  SimulationResultYearlyFinancialAnalysis,
} from '@/types/simulations';

import StyledInfoCard from './styles';

const InfoCard = () => {
  const resultId = useParams<Record<string, string>>();
  const { data: batteryDegData } = useSWR<SimulationBatteryDegradation>(
    `/simulation/${resultId.resultId}/battery_degradation/`,
    {
      revalidateOnFocus: false,
    }
  );
  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`,
    {
      revalidateOnFocus: false,
    }
  );
  const { data: financialData } =
    useSWR<SimulationResultYearlyFinancialAnalysis>(
      `/simulation/${resultId.resultId}/yearly_financial_analysis/`,
      {
        revalidateOnFocus: false,
      }
    );

  const replacementYears = financialData?.graphData
    .filter((item) => item.batteryReplacementCount !== 0)
    .map((item) => item.year);

  return (
    <Card style={{ marginTop: '24px' }}>
      <StyledInfoCard vertical gap={24} align="center" justify="center">
        <Flex vertical gap={8} align="center" justify="center">
          <Typography.Text className="title">
            Total Cycle Number
          </Typography.Text>
          <Typography.Text className="number">
            {simulationData?.result.cycleNumber.toFixed(1)}
          </Typography.Text>
        </Flex>
        <Flex vertical gap={8} align="center" justify="center">
          <Typography.Text className="title">
            SoH at the end of SIM
          </Typography.Text>
          <Typography.Text className="number">
            %{' '}
            {(batteryDegData && (batteryDegData.endSoh * 100).toFixed(1)) ||
              '0'}
          </Typography.Text>
        </Flex>
        <Flex vertical gap={8} align="center" justify="center">
          <Typography.Text className="title">
            Battery Replacement Year
            {replacementYears && replacementYears.length > 1 ? 's' : ''}
          </Typography.Text>
          <Typography.Text className="number">
            {' '}
            {replacementYears && replacementYears.length > 0
              ? replacementYears.join(' & ')
              : '-'}
          </Typography.Text>
        </Flex>
      </StyledInfoCard>
    </Card>
  );
};
export default InfoCard;
