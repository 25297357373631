import { Layout } from 'antd';
import styled from 'styled-components';

import theme from '../../../theme/index';

const { Content } = Layout;

const headerHeight = theme.header.height + 'px';

export default styled(Content)<{ $isResultPage: boolean }>`
  height: calc(100vh - ${headerHeight});
  overflow-y: scroll;
  overflow-x: ${({ $isResultPage }) => ($isResultPage ? 'clip' : 'auto')};
  padding-left: ${({ theme }) => theme.spacing.spacing24}px;
  padding-right: ${({ theme }) => theme.spacing.spacing24}px;
  padding-bottom: ${({ theme }) => theme.spacing.spacing24}px;
`;
