import { Collapse, Tag, TagProps } from 'antd';
import styled from 'styled-components';

export default styled(Collapse)`
  width: 100%;
  margin-bottom: 50px;

  .ant-collapse-content-box {
    padding: 0px !important;
  }
`;

interface ExtendedTagProps extends TagProps {
  type?: string;
}

export const StyledTag = styled(Tag)<ExtendedTagProps>`
  border: 1px solid
    ${({ theme, type }) =>
      type === 'error' ? theme.colors.tagFailure : theme.colors.bluePrimary};

  background-color: transparent;

  .diesel-text {
    color: ${({ theme }) => theme.colors.bluePrimary};
  }

  .numbercolor-text {
    color: ${({ theme }) => theme.colors.tagFailure};
  }
`;
