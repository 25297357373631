import { Row, Col, Flex, Typography, Grid } from 'antd';

import { PlansModalDesktopCardsProps } from '@/types/plan';

import PlansCard from '../PlansCard';
import SwitchPlans from '../SwitchPlans';

const { useBreakpoint } = Grid;

const PlansDesktopCards: React.FC<PlansModalDesktopCardsProps> = ({
  monthlyPlan,
  yearlyPlan,
  yearly,
  plansWithFeatures,
  handleSwitch,
  percentage,
}) => {
  const { md } = useBreakpoint();

  return (
    <>
      <SwitchPlans
        yearly={yearly}
        handleSwitch={handleSwitch}
        percentage={percentage}
      />
      <Col className="pad-plans">
        <Flex vertical gap={16} wrap="wrap">
          <Row gutter={[16, 16]} justify="center" className="btn-row">
            {!yearly ? (
              <>
                {/*INDIVIDUAL-MO*/}
                <Col
                  xs={24}
                  sm={24}
                  md={16}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={md ? { marginTop: '20px' } : {}}
                >
                  <PlansCard
                    title={
                      monthlyPlan?.[0]?.name.charAt(0).toUpperCase() +
                      monthlyPlan?.[0]?.name.slice(1)
                    }
                    description={monthlyPlan?.[0]?.subTitle}
                    amount={monthlyPlan?.[0]?.amount}
                    payPlan="mo"
                    savedAmount={monthlyPlan?.[0]?.saves}
                    details={[...(plansWithFeatures[0]?.feature || [])]}
                    yearly={yearly}
                    $isIndividual={true}
                  />
                </Col>
                {/*TEAM-MO*/}
                <Col xs={24} sm={24} md={16} lg={12} xl={8} xxl={8}>
                  <Row className="recommended-cont">
                    <Typography.Text className="recommended-text">
                      Recommended
                    </Typography.Text>
                  </Row>
                  <PlansCard
                    title={
                      monthlyPlan?.[1]?.name.charAt(0).toUpperCase() +
                      monthlyPlan?.[1]?.name.slice(1)
                    }
                    description={monthlyPlan?.[1]?.subTitle}
                    amount={monthlyPlan?.[1]?.amount}
                    payPlan="mo"
                    savedAmount={monthlyPlan?.[1]?.saves}
                    details={[...(plansWithFeatures[1]?.feature || [])]}
                    yearly={yearly}
                    $isTeam={true}
                  />
                </Col>
                {/*ENTERPRISE-MO*/}
                <Col
                  xs={24}
                  sm={24}
                  md={16}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={md ? { marginTop: '20px' } : {}}
                >
                  <PlansCard
                    title={
                      monthlyPlan?.[2]?.name.charAt(0).toUpperCase() +
                      monthlyPlan?.[2]?.name.slice(1)
                    }
                    description={monthlyPlan?.[2]?.subTitle}
                    $isEnterprise={true}
                    save={false}
                    details={[...(plansWithFeatures[2]?.feature || [])]}
                    yearly={yearly}
                  />
                </Col>
              </>
            ) : (
              <>
                {/*INDIVIDUAL-AN*/}
                <Col
                  xs={24}
                  sm={24}
                  md={16}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={md ? { marginTop: '20px' } : {}}
                >
                  <PlansCard
                    title={
                      yearlyPlan?.[0]?.name.charAt(0).toUpperCase() +
                      yearlyPlan?.[0]?.name.slice(1)
                    }
                    description={yearlyPlan?.[0]?.subTitle}
                    amount={yearlyPlan?.[0]?.amount}
                    payPlan="an"
                    savedAmount={yearlyPlan?.[0]?.saves}
                    details={[...(plansWithFeatures[0]?.feature || [])]}
                    yearly={yearly}
                    $isIndividual={true}
                  />
                </Col>
                {/*TEAM-AN*/}
                <Col xs={24} sm={24} md={16} lg={12} xl={8} xxl={8}>
                  <Row className="recommended-cont">
                    <Typography.Text className="recommended-text">
                      Recommended
                    </Typography.Text>
                  </Row>
                  <PlansCard
                    title={
                      yearlyPlan?.[1]?.name.charAt(0).toUpperCase() +
                      yearlyPlan?.[1]?.name.slice(1)
                    }
                    description={yearlyPlan?.[1]?.subTitle}
                    amount={yearlyPlan?.[1]?.amount}
                    payPlan="an"
                    savedAmount={yearlyPlan?.[1]?.saves}
                    details={[...(plansWithFeatures[1]?.feature || [])]}
                    yearly={yearly}
                    $isTeam={true}
                  />
                </Col>

                {/*ENTERPRISE-AN*/}
                <Col
                  xs={24}
                  sm={24}
                  md={16}
                  lg={12}
                  xl={8}
                  xxl={8}
                  style={md ? { marginTop: '20px' } : {}}
                >
                  <PlansCard
                    title={
                      yearlyPlan?.[2]?.name.charAt(0).toUpperCase() +
                      yearlyPlan?.[2]?.name.slice(1)
                    }
                    description={yearlyPlan?.[2]?.subTitle}
                    $isEnterprise={true}
                    save={false}
                    button={'Contact sales'}
                    details={[...(plansWithFeatures[2]?.feature || [])]}
                    yearly={yearly}
                  />
                </Col>
              </>
            )}
          </Row>
        </Flex>
      </Col>
    </>
  );
};

export default PlansDesktopCards;
