import {
  Row,
  Typography,
  Dropdown,
  MenuProps,
  Button,
  Modal,
  notification,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTheme } from 'styled-components';

import { plantApi } from '@/api';
import DuplicateIcon from '@/assets/icons/icon-folder.svg?react';
import InfoIcon from '@/assets/icons/icon-info.svg?react';
import DeactivateIcon from '@/assets/icons/icon-power.svg?react';
import VerticalEllipsisIcon from '@/assets/icons/icon-three-dots-vertical.svg?react';
import ViewIcon from '@/assets/icons/icon-view-second.svg?react';
import RNotificationCard from '@/components/RNotificationCard';
import RTag from '@/components/RTag';
import CustomTooltip from '@/components/RTooltip';
import { TourSteps } from '@/constants';
import useProductTourStore from '@/store/useProductTourStore';
import { DrawerType } from '@/types/global';
import { GenerationTypeKey, Plant } from '@/types/plant';
import { capitalizeFirstLetter, handleError } from '@/utils';

import useGenerationTypes from './useGenerationTypes';
import useProductTour from './useProductTour';

const usePlantsTableColumns = (
  mutate: () => void,
  updateDrawer: (
    type: DrawerType<Plant>['type'],
    data: DrawerType<Plant>['data']
  ) => void
) => {
  const theme = useTheme();
  const generationTypes = useGenerationTypes();
  const [modal, contextHolder] = Modal.useModal();
  const { currentStep, isTourActive } = useProductTourStore();
  const dropdownWrapperRef = useProductTour('viewDeactivatePlant');

  const handleActivate = (record: Plant) => {
    return modal.confirm({
      closeIcon: false,
      footer: null,
      width: 475,
      modalRender: () => (
        <RNotificationCard
          title={`Activating "${capitalizeFirstLetter(record.name)}"`}
          message="Are you sure you want to activate this plant?"
          type="warning"
          confirmText="Yes"
          cancelText="Cancel"
          open={!record.isActive}
          onCancel={() => Modal.destroyAll()}
          onConfirm={async () => {
            try {
              await plantApi.activatePlant(record.id);
              mutate();
              Modal.destroyAll();
              notification.success({
                message: `Successfully Activated Plant "${capitalizeFirstLetter(
                  record.name
                )}"`,
              });
            } catch (error) {
              Modal.destroyAll();
              handleError(error);
            }
          }}
        />
      ),
    });
  };

  //TODO DESTROY ALL DISABLES MODAL CLOSE ANIMATION FIX IT LATER
  const handleDeactivate = async (record: Plant) => {
    return modal.confirm({
      // TODO CHANGE THIS OPEN LOGIC
      closeIcon: false,
      footer: null,
      width: 475,
      modalRender: () => (
        <RNotificationCard
          title={`Deactivating "${capitalizeFirstLetter(record.name)}"`}
          message="Are you sure you want to deactivate this plant?"
          type="warning"
          confirmText="Yes"
          cancelText="Cancel"
          open={record.isActive}
          onCancel={() => Modal.destroyAll()}
          onConfirm={async () => {
            try {
              await plantApi.deactivatePlant(record.id);
              mutate();
              Modal.destroyAll();
              notification.success({
                message: `Successfully Deactivated Plant "${capitalizeFirstLetter(
                  record.name
                )}"`,
              });
            } catch (error) {
              Modal.destroyAll();
              handleError(error);
            }
          }}
        />
      ),
    });
  };

  const handleView = (record: Plant) => {
    updateDrawer('view', record);
  };
  const handleDuplicate = (record: Plant) => {
    updateDrawer('duplicate', record);
  };

  const generateItems = (record: Plant) => {
    const items: MenuProps['items'] = [
      {
        label: 'View',
        key: 'view-plant',
        icon: <ViewIcon style={{ width: '16px', height: '16px' }} />,
        disabled: isTourActive,
      },
      {
        label: 'Duplicate',
        key: 'duplicate-plant',
        icon: <DuplicateIcon style={{ width: '16px', height: '16px' }} />,
        disabled: isTourActive,
      },
    ];

    if (record.isActive) {
      items.push({
        label: 'Deactivate',
        key: 'deactivate-plant',
        icon: <DeactivateIcon style={{ width: '16px', height: '16px' }} />,
        disabled: isTourActive,
      });
    } else {
      items.push({
        label: 'Activate',
        key: 'activate-plant',
        icon: <DeactivateIcon style={{ width: '16px', height: '16px' }} />,
        disabled: isTourActive,
      });
    }

    return items;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMenuClick = (info: any, record: Plant) => {
    switch (info.key) {
      case 'view-plant':
        handleView(record);
        break;
      case 'duplicate-plant':
        handleDuplicate(record);
        break;
      case 'deactivate-plant':
        handleDeactivate(record);
        break;
      case 'activate-plant':
        handleActivate(record);
        break;
      default:
        break;
    }
  };

  const columns: ColumnsType<Plant> = [
    {
      title: (
        <Row align="middle" justify="center" className="w-100">
          <Typography.Text className="pr-8">Type</Typography.Text>
        </Row>
      ),
      dataIndex: 'generationType',
      key: 'generationType',
      align: 'center',
      width: '90px',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (type: GenerationTypeKey) => generationTypes[type]?.icon,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '280px',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{ maxWidth: '200px' }}
            className="text"
            ellipsis={{
              rows: 1,
              tooltip: name,
              expandable: false,
            }}
          >
            {name}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description) => (
        <Row align="middle">
          <Typography.Paragraph
            className="text"
            style={{ maxWidth: '200px' }}
            ellipsis={{
              rows: 1,
              tooltip: description,
              expandable: false,
            }}
          >
            {description}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: (
        <CustomTooltip
          className="cursor-pointer"
          title="Installed Power of the Plant"
          description="Installed Power of the Plant in MW"
          trigger="click"
        >
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Ins. Power (MW)</Typography.Text>
            <InfoIcon fill={theme.colors.dark} />
          </Row>
        </CustomTooltip>
      ),
      width: '280px',
      dataIndex: 'installedPowerKw',
      key: 'installedPowerKw',
      sorter: (a, b) => a.installedPowerKw - b.installedPowerKw,
      render: (installedPowerKw) => (
        <Row align="middle" justify="end">
          {installedPowerKw}
        </Row>
      ),
    },
    {
      title: (
        <CustomTooltip
          className="cursor-pointer"
          title="License Power of the Plant"
          description="License Power of the Plant in MW"
          trigger="click"
        >
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">
              License Power (MW)
            </Typography.Text>
            <InfoIcon fill={theme.colors.dark} />
          </Row>
        </CustomTooltip>
      ),
      width: '280px',
      dataIndex: 'licensedPowerKw',
      key: 'licensedPowerKw ',
      sorter: (a, b) => a.licensedPowerKw - b.licensedPowerKw,
      render: (licensedPowerKw) => (
        <Row align="middle" justify="end">
          {licensedPowerKw}
        </Row>
      ),
    },
    {
      title: (
        <CustomTooltip
          className="cursor-pointer"
          title="Status"
          description="Assets can be either active or inactive. If the status is active, this asset will be visible when creating a simulation."
          trigger="click"
        >
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Status</Typography.Text>
            <InfoIcon fill={theme.colors.dark} />
          </Row>
        </CustomTooltip>
      ),
      dataIndex: 'isActive',
      key: 'isActive ',
      align: 'left',
      width: '120px',
      sorter: (a, b) => String(a.isActive).localeCompare(String(b.isActive)),
      render: (dataIndex) =>
        dataIndex ? <RTag type="active" /> : <RTag type="inactive" />,
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions ',
      width: '60px',
      render: (_, record: Plant, index: number) => (
        <>
          {contextHolder}
          <div
            ref={index === 0 ? dropdownWrapperRef : undefined}
            style={{
              width: '32px',
              height: '32px',
            }}
          >
            <Dropdown
              trigger={['click']}
              menu={{
                items: generateItems(record),
                onClick: (info) => handleMenuClick(info, record),
              }}
              open={
                index === 0 &&
                isTourActive &&
                currentStep === TourSteps.VIEW_PLANTS
                  ? true
                  : undefined
              } // Menu will automatically open when tour is active
              placement="bottomRight"
            >
              <Button
                className="default-icon-button"
                type="default"
                icon={<VerticalEllipsisIcon fill={theme.colors.bluePrimary} />}
              />
            </Dropdown>
          </div>
        </>
      ),
    },
  ];

  return columns;
};

export default usePlantsTableColumns;
