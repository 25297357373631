import { Col } from 'antd';
import styled from 'styled-components';

export default styled(Col)`
  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .renew-btn {
    width: 90px;
    height: 40px;
  }
  .renew-text {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
  }
  .payment-btn {
    height: 41px;
    padding: 10px 12px !important;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    &.active {
      border-color: ${({ theme }) => theme.colors.primary} !important;
      color: ${({ theme }) => theme.colors.primary} !important;
      font-weight: 700;
    }
    &.passive {
      border-color: ${({ theme }) => theme.colors.grayAccent} !important;
      color: ${({ theme }) => theme.colors.grayAccent} !important;
    }
  }
  .saved-title {
    display: block;
    font-size: 14px;
    line-height: 14px;
    font-weight: 900;
    margin-bottom: 12px !important;
  }
  .card-info-text {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grayAccent};
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 140%;
  height: 109%;
  top: -24px;
  right: -24px;
  background-color: rgba(0, 41, 107, 0.3);
  z-index: 999;
  .ant-typography {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
  }
`;
