import { Row, Col } from 'antd';
import styled from 'styled-components';

import Drawer from '@/components/RDrawer';

export default styled(Col)`
  .action-button {
    min-width: 101px;
  }
  .action-button-red {
    :hover {
      border: 1px solid ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.red};
    }
  }
  .action-button-green {
    :hover {
      border: 1px solid ${({ theme }) => theme.colors.greenPrimary};
      color: ${({ theme }) => theme.colors.greenPrimary};
    }
  }
  .ant-table-measure-row {
    display: none;
  }
`;

export const TableActionsWrapper = styled(Row)`
  border-bottom: none;
  padding-bottom: 16px;
  padding-top: 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  .search-group {
    height: 32px;
  }
`;

export const StyledDrawer = styled(Drawer)`
  .content-card {
    height: calc(100% - 24px);
  }
  .bottom-button-group {
    padding: 0px 16px;
    position: absolute;
    bottom: 36px;
    width: calc(100% - 48px);

    button {
      padding: 9px 24px;
    }
  }

  .right-side-btn-group > button {
    width: 90px;
  }

  .ant-input-group-addon {
    background-color: ${({ theme }) => theme.colors.primaryHighlight};
  }
`;
