import { FC, useState } from 'react';

import { Button, Empty, Flex, Modal, Row, TabsProps, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';

import IconDownload from '@/assets/icons/icon-download.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Card from '@/components/RCard';
import PageHeader from '@/components/RPageHeader';
// import paths from '@/routes/paths';
import CustomTab from '@/components/RTab';
import Table from '@/components/RTable';
import RTag from '@/components/RTag';
import { CreditCard, Payment } from '@/types/payment';

import PlansModal from './PlansModal';
import StyledUserManagement from './styles';

const Payments: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const tableDataSource: Payment[] = []; //todo change this
  const cardsDataSource: CreditCard[] = []; //todo change this
  // const billingAddressDataSource: CreditCard[] = []; // TODO change this
  //const { user } = useAuth();
  const theme = useTheme();

  // const handleRenewClick = () => {
  //   setIsModalVisible(true);
  // };

  // Function to handle modal close
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const getDataIndex = (dataIndex: string) => {
    switch (dataIndex) {
      case 'SUCCESS':
        return <RTag type={'success'} />;
      case 'FAILURE':
        return <RTag type={'failure'}>{dataIndex}</RTag>;
      case 'REVOKED':
        return <RTag type={'revoked'}>{dataIndex}</RTag>;
      case 'INACTIVE':
        return <RTag type={'inactive'}>{dataIndex}</RTag>;
      case 'ACTIVE':
        return <RTag type={'active'}>{dataIndex}</RTag>;
      default:
        return (
          <RTag type={'default'} style={{ margin: '0' }}>
            {' '}
            {dataIndex.toLocaleUpperCase()}
          </RTag>
        );
    }
  };

  const paymentsTableColumn: ColumnsType<Payment> = [
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      sorter: (a, b) =>
        dayjs(a.invoiceDate).unix() - dayjs(b.invoiceDate).unix(),
      render: (dataIndex) => dayjs(dataIndex).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      render: (text, record) => record.unit + text,
    },
    {
      title: 'Payment Date',
      dataIndex: 'datePayment',
      key: 'amount',
      sorter: (a, b) =>
        dayjs(a.datePayment).unix() - dayjs(b.datePayment).unix(),
      render: (dataIndex) => dayjs(dataIndex).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (dataIndex) => getDataIndex(dataIndex),
    },
    {
      title: 'Download Invoice',
      dataIndex: 'downloadInvoice',
      key: 'downloadInvoice',
      align: 'center',
      render: () => (
        <Button
          type="default"
          style={{
            padding: '6px 8px',
            borderColor: theme.colors.grayLightAccent,
          }}
        >
          <IconDownload fill={theme.colors.bluePrimary} />
        </Button>
      ),
    },
  ];

  const EmptyInfo = (
    <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{ height: 100 }}
      description="No Data Found"
    >
      <Typography.Paragraph className="smoke fs-12">
        This section is currently under development. Thank you for your patience
        and understanding!
      </Typography.Paragraph>
    </Empty>
  );

  const renewCard = (
    <Card $paddingBody="32px" className="mt-24">
      <Flex vertical gap={16} align="center" justify="center">
        <Flex gap={8} align="center" justify="center">
          <IconInfo fill={theme.colors.bluePrimary} />
          <Typography.Text className="renew-text">
            Your plan could not be renewed because we did not receive the
            payment.
          </Typography.Text>
        </Flex>
        {/* <Button
      type="primary"
      className="renew-btn"
      onClick={handleRenewClick}
    >
      Renew
    </Button> */}
      </Flex>
    </Card>
  );

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Payment History',
      children: (
        <>
          {renewCard}
          <Card title="Payment History" className="mt-24">
            <Table
              rowKey="id"
              rowClassName="cursor-pointer"
              cta={
                <Typography.Paragraph className="smoke fs-12">
                  This section is currently under development. Thank you for
                  your patience and understanding!
                </Typography.Paragraph>
              }
              columns={paymentsTableColumn}
              dataSource={tableDataSource}
              pagination={false}
              /*  pagination={{
        total: paginationData?.count,
        onChange: (page) => { setPageNo(page) },
         }} */
            />
          </Card>
        </>
      ),
    },
    {
      key: '2',
      label: 'Saved Cards',
      children: (
        <>
          {renewCard}
          <Card $paddingBody="24px" className="mt-24">
            <Row>
              <Typography.Title level={5} className="saved-title">
                Saved Card
              </Typography.Title>
            </Row>
            <Flex gap={24} justify="center">
              {cardsDataSource.length !== 0 ? <>{/** TODO */}</> : EmptyInfo}
            </Flex>
          </Card>
        </>
      ),
    },
    {
      key: '3',
      label: 'Billing Adress',
      children: (
        <>
          {renewCard}
          <Card $paddingBody="24px" className="mt-24">
            <Row>
              <Typography.Title level={5} className="saved-title">
                Billing Address
              </Typography.Title>
            </Row>
            <Flex gap={24} justify="center">
              <>{EmptyInfo}</>
            </Flex>
          </Card>
        </>
      ),
    },
  ];
  return (
    <StyledUserManagement>
      <PageHeader
        title="Payments"
        description="You can view all the users and and change role quickly."
      />

      <CustomTab items={tabItems}></CustomTab>

      <Modal
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        destroyOnClose
        centered
        width={'100%'}
      >
        <PlansModal />
      </Modal>
    </StyledUserManagement>
  );
};

export default Payments;
