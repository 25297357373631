import { Row } from 'antd';
import styled from 'styled-components';

export default styled(Row)`
  .recharts-default-legend {
    margin-top: 9px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 30px;
    li {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      margin: 0 !important;
      gap: 8px !important;
      svg {
        margin: 0 !important;
      }
    }
  }
  li.recharts-legend-item.legend-item-4 {
    position: absolute;
    rotate: 90deg;
    top: -328px;
    right: -45px;
    svg.recharts-surface {
      rotate: 90deg;
    }
  }
  li.recharts-legend-item.legend-item-5 {
    position: absolute;
    rotate: 90deg;
    top: -208px;
    right: -54px;
    svg.recharts-surface {
      rotate: 90deg;
    }
  }
  li.recharts-legend-item.legend-item-6 {
    position: absolute;
    rotate: 90deg;
    top: -80px;
    right: -53px;
    svg.recharts-surface {
      rotate: 90deg;
    }
  }
`;
