import { Flex, Typography } from 'antd';

import IconInfo from '@/assets/icons/icon-info.svg?react';
import Tooltip from '@/components/RTooltip';

import StyledCard from './styles';

interface DataCardProps {
  title: string;
  withStorage: string;
  withoutStorage: string;
  toolTip: string;
  toolTipDescription: string | JSX.Element;
}

// DataCard Component
const DataCard: React.FC<DataCardProps> = ({
  title,
  withStorage,
  withoutStorage,
  toolTip,
  toolTipDescription,
}) => {
  return (
    <StyledCard vertical justify="center" gap={8}>
      <Flex align="center" justify="center" gap={8}>
        <Typography.Title level={5} className="fs-17-semi-bold title">
          {title}
        </Typography.Title>
        <Tooltip title={toolTip} description={toolTipDescription}>
          <IconInfo fill="black" />
        </Tooltip>
      </Flex>
      <Flex gap={12} justify="center">
        <Flex vertical className="money-card" align="center">
          <Typography.Text className="money-title">
            {withStorage}
          </Typography.Text>
          <Typography.Text className="money-subtitle">
            With Storage
          </Typography.Text>
        </Flex>
        <Flex vertical className="money-card" align="center">
          <Typography.Text className="money-title">
            {withoutStorage}
          </Typography.Text>
          <Typography.Text className="money-subtitle">
            Without Storage
          </Typography.Text>
        </Flex>
      </Flex>
    </StyledCard>
  );
};

export default DataCard;
