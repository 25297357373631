import { FilterState, Action } from './types';

export const initialState: FilterState = {
  years: [],
  selectedYear: null,
  daily: {
    dayOfWeek: 'all',
    season: 'all',
  },
  activeFilter: 'daily',
};

export function filterReducer(state: FilterState, action: Action): FilterState {
  switch (action.type) {
    case 'SET_DAILY_FILTER':
      return {
        ...state,
        daily: {
          ...state.daily,
          ...action.payload,
        },
        activeFilter: 'daily',
      };
    case 'SET_ACTIVE_FILTER':
      return {
        ...state,
        daily: initialState.daily,
        activeFilter: action.payload,
      };
    case 'SET_YEARS':
      return {
        ...state,
        daily: initialState.daily,
        years: action.payload,
        selectedYear: action.payload[0],
      };
    case 'SET_SELECTED_YEAR':
      return {
        ...state,
        selectedYear: action.payload,
      };

    case 'RESET_FILTERS':
      return initialState;

    default:
      return state;
  }
}
