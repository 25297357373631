import { FC } from 'react';

import { Typography } from 'antd';

import StyledInviteNotificationBanner from './styles';

const RExpandTeamBanner: FC = () => {
  return (
    <StyledInviteNotificationBanner>
      <Typography.Text className="fs-12-bold text-dark-color">
        Need more space ?
      </Typography.Text>

      <Typography.Text className="fs-12-regular text-dark-color">
        Invite more people and team up on Ratio SIM.
      </Typography.Text>
    </StyledInviteNotificationBanner>
  );
};

export default RExpandTeamBanner;
