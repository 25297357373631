import { Flex } from 'antd';
import styled from 'styled-components';

export default styled(Flex)`
  .title {
    font-size: 17px;
    line-height: 25px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.bgBlack};
  }
  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    .value {
      font-size: 24px;
      line-height: 30px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.dark};
    }
    .name {
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.bgBlack};
    }
  }
`;
