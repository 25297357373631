import type { FC } from 'react';

import { Button, Col, Flex, Row } from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import Card from '@/components/RCard';
import { RUsageLimitIndicator } from '@/components/RUsageLimitIndicator';
import UserAvatarCard from '@/components/RUserAvatarCard';
import useAuth from '@/hooks/useAuth';
import paths from '@/routes/paths';
import { UserList } from '@/types/auth';
import { formatDateTime, infinitySymbol } from '@/utils';

import StyledPage from './styles';
import PageHeader from '../../components/RPageHeader';

const Organization: FC = () => {
  const { user: data } = useAuth();
  const showingUserNumber: number = 3;

  if (data?.organization.subscription.plan.name === 'enterprise') {
    data.organization.subscription.plan.simultaneousSimLimit = Infinity;
    data.organization.subscription.plan.simAssetLimit = Infinity;
    data.organization.subscription.plan.userLimit = Infinity;
    data.organization.teamSize = Infinity;
  }

  const { data: usersData } = useSWR<UserList>(`/auth/user/`);

  return (
    <StyledPage>
      <PageHeader
        title="Overview"
        description="You can set new rules and change settings for your organization"
      />
      <Card title="Plan" $paddingBody="12px 24px">
        <Flex vertical gap={5}>
          <Row className="plan-subtitle">
            <Flex vertical gap={5}>
              <Col>
                {data?.organization.subscription.plan.name
                  .charAt(0)
                  .toUpperCase()
                  .concat(data?.organization.subscription.plan.name.slice(1))
                  .concat(' Plan')}
              </Col>{' '}
              <Col className="plan-text">
                Created: {formatDateTime(data?.createdAt || '')}
              </Col>
            </Flex>
            <Flex vertical gap={5}>
              <Col>Renewable Date</Col>
              <Col className="plan-text">
                {formatDateTime(
                  data?.organization.subscription.periodEndDate || ''
                )}
              </Col>
            </Flex>
            <Flex vertical gap={5}>
              <Col>User</Col>
              <Col className="plan-text">
                {usersData?.count || 0} /{' '}
                {infinitySymbol(data?.organization.subscription.plan.userLimit)}
              </Col>
            </Flex>
            <Flex vertical gap={5}>
              <Col>Simultaneous Simulations</Col>
              <Col className="plan-text">
                {infinitySymbol(
                  data?.organization.subscription.plan.simultaneousSimLimit
                )}
              </Col>
            </Flex>
            <Flex vertical gap={5}>
              <Col>Plant, Storage, Scenario Templates</Col>
              <Col className="plan-text">
                {infinitySymbol(
                  data?.organization.subscription.plan.simAssetLimit
                )}
              </Col>
            </Flex>
          </Row>
        </Flex>
      </Card>

      <Card className="team-card mt-24" title="Team" $paddingBody="12px 24px">
        <Flex justify="space-between" align="center">
          <Flex gap={37} align="center">
            {/* Avatar Card*/}
            {usersData?.results
              .slice(0, showingUserNumber)
              .map((user) => (
                <UserAvatarCard
                  key={user.id}
                  companyName={user.fullName}
                  userRole={user.email}
                />
              ))}
            <Link
              to={paths.organization.user.management}
              className="fs-14-regular text-blue-color more-users"
            >
              <u>
                {(usersData?.count ?? 0) > 3 &&
                  (usersData?.count
                    ? `+ ${usersData?.count - showingUserNumber} more users`
                    : '')}
              </u>
            </Link>
          </Flex>
          <Flex gap={7} vertical align="center">
            <RUsageLimitIndicator
              text={data?.organization.subscription.plan.name
                .charAt(0)
                .toUpperCase()
                .concat(data?.organization.subscription.plan.name.slice(1))
                .concat(' Plan')}
              currentValue={usersData?.count || 0}
              maxValue={infinitySymbol(
                data?.organization.subscription.plan.userLimit
              )}
            />
            <Link
              to={`${paths.organization.user.management}?openDrawer=true`}
              className="mt-8"
            >
              <Button type="default" className="add-more-btn">
                Add more user
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Card>
    </StyledPage>
  );
};

export default Organization;
