import { useEffect, useMemo, useState } from 'react';

import { Button, Col, Form, notification, Row } from 'antd';
import Lottie from 'lottie-react';

import { storageApi } from '@/api';
import loadingLottie from '@/assets/lotties/ratio-loading-lottie.json';
import Card from '@/components/RCard';
import RNotificationCard from '@/components/RNotificationCard';
import Steps from '@/components/RSteps';
import { AnimatedText } from '@/components/RTextAnimation';
import { RUsageLimitIndicator } from '@/components/RUsageLimitIndicator';
import FinancialMetrics from '@/pages/StorageSystems/Form/FinancialMetrics';
import GridActions from '@/pages/StorageSystems/Form/GridAction';
import { StorageSystemForm } from '@/types/storageSystem';
import { handleError } from '@/utils';

import { StyledDrawer } from './styles';
import AncillaryServices from '../Form/AncillaryServices';
import BatteryCapability from '../Form/BatteryCapability';
import CycleLife from '../Form/CycleLife';
import Description from '../Form/Description';
import RateCapability from '../Form/RateCapability';

type Props = {
  open: boolean;
  setIsOpen: (value: boolean) => void;
  mutate: () => void;
  maxDataCount: number;
  currentDataCount: number;
};

type StorageSystemFormKeys = keyof StorageSystemForm;

const StorageSystemCreateDrawer = (props: Props) => {
  const { open, setIsOpen, mutate, maxDataCount, currentDataCount } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [notificationCardVisible, setNotificationCardVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>();

  const initialValues: StorageSystemForm = {
    investmentLag: 0,
  };

  const hasUnsavedData = () => {
    const fields: Partial<StorageSystemForm> = form.getFieldsValue(true);
    return (Object.keys(fields) as StorageSystemFormKeys[]).some(
      (key) => fields[key] !== initialValues[key]
    );
  };

  const onClose = () => {
    if (hasUnsavedData()) {
      setNotificationCardVisible(true);
    } else {
      setIsOpen(false);
    }
  };

  const resetDrawerData = () => {
    setCurrentStep(0);
    form.resetFields();
  };

  const steps = [
    {
      key: 'first',
      title: 'Description',
      content: <Description />,
    },
    {
      key: 'second',
      title: 'Financial Metrics',
      content: <FinancialMetrics initialValues={initialValues} form={form} />,
    },
    {
      key: 'third',
      title: 'Battery Capability',
      content: <BatteryCapability form={form} />,
    },
    {
      key: 'fourth',
      title: 'Rate Capability',
      content: <RateCapability form={form} />,
    },
    {
      key: 'fifth',
      title: 'Cycle Life',
      content: <CycleLife form={form} />,
    },
    {
      key: 'sixth',
      title: 'Grid Actions',
      content: <GridActions />,
    },
    {
      key: 'seventh',
      title: 'Ancillary Services',
      content: <AncillaryServices form={form} />,
    },
  ];

  const nextStep = async () => {
    if (currentStep < steps.length - 1) {
      try {
        const values = await form.validateFields();
        form.setFieldsValue(values);
        setCurrentStep((prev) => prev + 1);
      } catch (error) {
        console.error('Validation failed:', error);
      }
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const onFinish = async () => {
    const values: StorageSystemForm = form.getFieldsValue(true);
    if (!values.name) {
      throw new Error('Some required values are undefined.');
    }
    const requiredValues = [
      values.name,
      values.investmentLag,
      values.bosLifespan,
      values.depreciationYearsBattery,
      values.depreciationYearsBos,
      values.batteryCapexValue,
      values.pcsCostValue,
      values.bosCostValue,
      values.fixedOpex,
      values.ratedPowerMw,
      values.ratedEnergyMwh,
      values.minAllowedSoh,
      values.minAllowedStateOfCharge,
      values.maxAllowedStateOfCharge,
      values.netOverallRoundTripEfficiency,
      values.minAllowedCRateForCharging,
      values.maxAllowedCRateForCharging,
      values.rateCapabilities,
      values.gridPowerConstraintInflow,
      values.gridPowerConstraintOutflow,
    ];

    if (requiredValues.some((value) => value == null)) {
      throw new Error('Some required values are undefined.');
    }

    try {
      setIsLoading(true);
      await storageApi.createStorageSystems({
        ...values,
      });
      notification.success({
        message: `${values.name} storage system has been created successfully!`,
      });
      setIsLoading(false);

      resetDrawerData();
      mutate();
      setTimeout(() => {
        setIsOpen(false);
      }, 500);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  //TODO Split this into a separate component
  const overlayTextArray = useMemo(() => {
    return [
      'Configuring storage system settings.',
      'Analyzing file...',
      'The final touches are being made.',
    ];
  }, []);

  const [overlayText, setOverlayText] = useState<string>(overlayTextArray[0]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        if (currentIndex < overlayTextArray.length - 1) {
          setCurrentIndex(currentIndex + 1);
          setOverlayText(overlayTextArray[currentIndex + 1]);
        } else {
          clearInterval(interval);
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [currentIndex, overlayTextArray, isLoading]);

  return (
    <StyledDrawer
      title="Create New Storage Systems"
      description="Add a new storage system to the library by providing its details below."
      destroyOnClose
      onClose={onClose}
      open={open}
      rightSideSection={<RUsageLimitIndicator.Storages />}
      drawerLock={{
        isLocked: !!(currentDataCount === maxDataCount),
        lockText:
          'You have reached the limit of storage systems you can create.',
      }}
    >
      <Card $borderColor="transparent" className="px-16 pt-16 content-card">
        <Form
          initialValues={initialValues}
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Steps
            className="mb-24"
            current={currentStep}
            items={steps}
            type="default"
          />
          <div>{steps[currentStep].content}</div>
          <Row
            className="bottom-button-group"
            align="middle"
            justify="space-between"
          >
            <Button onClick={onClose} className="mr-8">
              Cancel
            </Button>
            <div className="right-side-btn-group">
              {currentStep > 0 && (
                <Button className="mr-4" onClick={prevStep}>
                  Previous
                </Button>
              )}
              {currentStep < steps.length - 1 && (
                <Button className="ml-4" onClick={nextStep} type="primary">
                  Next
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button className="ml-4" type="primary" htmlType="submit">
                  Create
                </Button>
              )}
            </div>
          </Row>
        </Form>
      </Card>
      <RNotificationCard
        title="Data will be lost."
        message="Are you sure you want to leave?"
        type="warning"
        confirmText="Leave"
        cancelText="Cancel"
        open={notificationCardVisible}
        onConfirm={() => {
          resetDrawerData();
          setNotificationCardVisible(false);
          setIsOpen(false);
        }}
        onCancel={() => setNotificationCardVisible(false)}
      />
      {isLoading && (
        <Row align="top" justify="center" className="pt-120 loading-wrapper">
          <Col>
            <Lottie
              style={{ height: '120px' }}
              animationData={loadingLottie}
              loop={true}
            />
            <Row>
              <AnimatedText className="animated-text" text={overlayText} />
            </Row>
          </Col>
        </Row>
      )}
    </StyledDrawer>
  );
};

export default StorageSystemCreateDrawer;
