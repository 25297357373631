import { useEffect } from 'react';

import { Input } from 'antd';
import { GroupProps, InputProps } from 'antd/es/input';

import { formattedPhoneNumber } from '@/utils';

import AreaSelect, { AreaSelectProps } from './area-select';
import Wrapper from './styles';
import { CountryPhoneInputValue } from './types';

export interface CountryPhoneInputProps
  extends Omit<InputProps, 'defaultValue' | 'value' | 'onChange'> {
  // defaultValue?: CountryPhoneInputValue;
  value?: CountryPhoneInputValue;
  onChange?: (value: CountryPhoneInputValue) => void;
  selectProps?: AreaSelectProps;
  inputGroupProps?: GroupProps;
  inline?: boolean;
  className?: string;
  disabled?: boolean;
}

export const CountryPhoneInput = ({
  // defaultValue,
  value,
  onChange,
  selectProps = {},
  className,
  disabled,
  ...inputProps
}: CountryPhoneInputProps) => {
  useEffect(() => {
    if (value?.phone) {
      const formattedValue = formattedPhoneNumber(value.phone);
      onChange?.({ ...value, phone: formattedValue, code: value.code });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const cleanedValue = inputValue.replace(/\D/g, '');
    const formattedValue = formattedPhoneNumber(cleanedValue);
    onChange?.({ ...value, phone: formattedValue });
  };

  const handleSelectChange = (code: number) => {
    onChange?.({ ...value, code });
  };

  return (
    <Wrapper>
      <Input
        {...inputProps}
        addonBefore={
          <AreaSelect
            {...selectProps}
            disabled={disabled}
            value={value?.code}
            onChange={handleSelectChange}
          />
        }
        className={
          'antd-country-phone-input' + (className ? ` ${className}` : '')
        }
        value={value?.phone}
        onChange={handleChange}
        disabled={disabled}
        maxLength={18}
      />
    </Wrapper>
  );
};
