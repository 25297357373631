import styled, { keyframes } from 'styled-components';

const slide = keyframes`
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-40%);
    }
`;
export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.light};
  position: relative;

  .grid {
    position: absolute;
    bottom: -60px;
    right: 0;
  }
  .container {
    height: calc(100vh - 60px);
  }
  .sign-col {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 120px 60px 133px 60px;
    overflow: hidden;
  }
  .ant-form-vertical {
    gap: 24px !important;
  }

  .input-style {
    height: 32px;
    border: 0.5px solid ${({ theme }) => theme.colors.grayLightAccent} !important;
    border-radius: 4px !important;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    padding: 5.5px 12px !important;
    margin-bottom: 24px;
  }
  .btn-style {
    height: 40px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }
  .forget-style {
    margin-top: 16px;
    .forget {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.grayAccent};
    }
    .account {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.grayAccent};
    }
  }

  .ant-form-item {
    margin-bottom: 16px;
  }
  .ant-form-item-label {
    padding-bottom: 8px !important;
  }

  .ant-form-item-explain-error {
    display: none;
  }

  .img-col {
    position: absolute !important;
    top: -60px;
    right: 0;
    width: 100%;
    z-index: 999;
    height: 100vh;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.grayDarkSecondary};
    padding: 120px 60px 60px 60px;
    .img-text {
      text-align: center;
      font-size: 17px;
      line-height: 25px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.light};
    }
  }
  .logos {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  .logos-slide {
    display: inline-block;
    animation: 25s ${slide} infinite linear;
  }
  .logos-slide div {
    width: auto;
    margin: 0 24px !important;
  }
  .btn-group {
    .try-btn {
      font-size: 17px;
      line-height: 25px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.grayDarkSecondary};
      width: 139px;
      height: 40px;
      background-color: ${({ theme }) => theme.colors.bluePrimary};
    }
    .sign-btn {
      font-size: 17px;
      line-height: 25px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.bluePrimary};
      width: 139px;
      height: 40px;
      background-color: transparent;
    }
  }
`;
