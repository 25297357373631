import { TourSteps } from '@/constants';

// EN : This function sets the mask for the selected step
export const getOffsetGap = (currentStep: number) => {
  switch (currentStep) {
    case TourSteps.USER_PROFILE:
      return [5, 0];
    case TourSteps.HELP_SECTION:
      return [2, 20];
    case TourSteps.ORGANIZATION_SECTION:
      return 0;
    case TourSteps.PLANTS:
      return [161, 15];
    case TourSteps.CREATE_PLANT:
      return 5;
    case TourSteps.VIEW_PLANTS:
      return 5;
    case TourSteps.SCENARIOS:
      return [161, 15];
    case TourSteps.CREATE_SCENARIO:
      return 5;
    case TourSteps.VIEW_SCENARIOS:
      return 5;
    case TourSteps.STORAGE_SYSTEMS:
      return [161, 15];
    case TourSteps.CREATE_STORAGE_SYSTEM:
      return 5;
    case TourSteps.VIEW_STORAGE_SYSTEMS:
      return 5;
    case TourSteps.CREATE_SIMULATION:
      return 0;
    case TourSteps.SIMULATION_ASSETS:
      return 15;
    case TourSteps.SELECT_ASSET_EMPTY_FACE:
      return 0;
    case TourSteps.SELECT_ASSET_FILL_FACE:
      return 0;
    case TourSteps.REVIEW_SIMULATION_ASSETS:
      return -2;
    case TourSteps.RUNNING_SIMULATION:
      return -2;
    case TourSteps.COMPLETED_SIMULATION:
      return 5;
    case TourSteps.EXAMPLE_SIMULATION:
      return 5;
    default:
      return 0;
  }
};
