import type { FC } from 'react';
import { useCallback, useDeferredValue, useEffect, useState } from 'react';

import { Button, Col, Flex, Row, Select, Tooltip } from 'antd';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconAll from '@/assets/icons/icon-all.svg?react';
import IconPlus from '@/assets/icons/icon-plus.svg?react';
import Card from '@/components/RCard';
import PageHeader from '@/components/RPageHeader';
import SearchInput from '@/components/RSearchInput';
import Table from '@/components/RTable';
import UpdateTime from '@/components/RUpdateTime';
import { RUsageLimitIndicator } from '@/components/RUsageLimitIndicator';
import useAuth from '@/hooks/useAuth';
import useGenerationTypes from '@/hooks/useGenerationTypes';
import usePlantsTableColumns from '@/hooks/usePlantsTableColumns';
import useProductTour from '@/hooks/useProductTour';
import {
  GenerationTypeKey,
  PlantsResponse,
  QueryParamsFilterProps,
} from '@/types/plant';
import { createQueryString, hasNonEmptyValues } from '@/utils';

import PlantCreateDrawer from './Drawer/PlantCreateDrawer';
import PlantGenericDrawer from './Drawer/PlantGenericDrawer';
import { DrawerType } from './Drawer/types';
import StyledPlants from './styles';

const Plants: FC = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const generationTypes = useGenerationTypes();
  const [selectedGenerationType, setSelectedGenerationType] = useState<
    GenerationTypeKey | 'all'
  >('all');
  const [nameSearchValue, setNameSearchValue] = useState<string>();
  const [selectedActiveStatus, setSelectedActiveStatus] = useState<
    'all' | 'active' | 'inactive'
  >('active');

  const deferredNameSearch = useDeferredValue(nameSearchValue);
  const [queryParams, setQueryParams] = useState<QueryParamsFilterProps>({
    name: '',
    generationType: undefined,
    isActive: undefined,
  });

  const pageSize = 7;
  const [offset, setOffset] = useState(1);

  const queryString = createQueryString(queryParams);
  const plantsCreateRef = useProductTour('createPlant');

  useEffect(() => {
    setQueryParams((prev) => ({
      ...prev,
      name: deferredNameSearch || '',
      generationType: selectedGenerationType,
      isActive: selectedActiveStatus,
    }));
  }, [deferredNameSearch, selectedGenerationType, selectedActiveStatus]);

  const {
    data: plantsData,
    isLoading,
    mutate,
  } = useSWR<PlantsResponse>(
    hasNonEmptyValues(queryParams)
      ? `/plant/?limit=${pageSize}&offset=${
          (offset - 1) * pageSize
        }&${queryString}`
      : null
  );

  const assetLimit = user?.organization.subscription.plan.simAssetLimit;
  const [drawer, setDrawer] = useState<DrawerType>({
    type: null,
    data: null,
  });
  const updateDrawer = useCallback(
    (type: DrawerType['type'], data: DrawerType['data']) => {
      setDrawer({ type, data });
    },
    []
  );

  const [isCreatePlantDrawerOpen, setIsCreatePlantDrawerOpen] =
    useState<boolean>(false);

  const plantsTableColumns = usePlantsTableColumns(mutate, updateDrawer);

  const handlePaginationChange = (page: number) => {
    setOffset(page);
  };

  const filterButtons = [
    {
      label: 'All',
      value: 'all',
      icon: <IconAll fill="white" />,
    },
    ...Object.values(generationTypes),
  ];

  const handleCreateDrawerOpen = () => {
    setIsCreatePlantDrawerOpen(true);
  };

  return (
    <StyledPlants span={24}>
      <PageHeader
        title="Plants"
        description="Wind, PV or Diesel Generator power plants that can be combined with a storage plant. You can edit previously created plant variables from here."
        rightSideSection={<RUsageLimitIndicator.Plants />}
      />
      <Card title="All Plants">
        <Row
          justify="space-between"
          className="filter-header"
          style={{ padding: '22px' }}
        >
          <Col className="left-side">
            <Row>
              <Col>
                <Row
                  align="middle"
                  justify="space-between"
                  gutter={[4, 4]}
                  style={{
                    minWidth: '160px',
                    padding: '4px 2px',
                    border: '1px solid',
                    height: '36px',
                    borderColor: theme.colors.grayLightAccent,
                    borderRadius: theme.main.borderRadius,
                  }}
                >
                  {filterButtons.map((item) => (
                    <Tooltip
                      key={item.value}
                      trigger="hover"
                      title={item.label}
                      color={theme.colors.grayDarkPrimary}
                    >
                      <Col flex={'28px'} key={item.value}>
                        <Button
                          className={
                            selectedGenerationType === item.value
                              ? 'selected-filter-buttons-styles'
                              : 'filter-buttons-styles'
                          }
                          onClick={() =>
                            setSelectedGenerationType(
                              item.value as GenerationTypeKey
                            )
                          }
                          type={
                            selectedGenerationType === item.value
                              ? 'primary'
                              : 'link'
                          }
                          icon={item.icon}
                        />
                      </Col>
                    </Tooltip>
                  ))}
                </Row>
              </Col>
              <Col className="pl-16">
                <Row style={{ height: '100%' }} align="middle" justify="center">
                  <SearchInput
                    placeholder="Search Input"
                    onChange={(e) => setNameSearchValue(e.target.value)}
                  />
                </Row>
              </Col>
              <Col className="pl-16">
                <Row style={{ height: '100%' }} align="middle" justify="center">
                  <Select
                    defaultValue={selectedActiveStatus}
                    placeholder="Status"
                    style={{ width: 180 }}
                    onChange={(val) =>
                      setSelectedActiveStatus(
                        val as 'all' | 'active' | 'inactive'
                      )
                    }
                    options={[
                      { value: 'all', label: 'All' },
                      { value: 'active', label: 'Active' },
                      { value: 'inactive', label: 'Inactive' },
                    ]}
                  />
                </Row>
              </Col>
              <Col className="pl-16">
                <Row style={{ height: '100%' }} align="middle" justify="center">
                  <UpdateTime fetchFunction={mutate} />
                </Row>
              </Col>
            </Row>
          </Col>
          <Flex align="center" ref={plantsCreateRef}>
            <Button
              type="primary"
              icon={<IconPlus fill={theme.colors.light} />}
              onClick={() => handleCreateDrawerOpen()}
            >
              Create
            </Button>
          </Flex>
        </Row>
        <Table
          rowKey="id"
          columns={plantsTableColumns}
          dataSource={plantsData?.results}
          loading={isLoading}
          size="small"
          pagination={{
            showSizeChanger: false,
            onChange: handlePaginationChange,
            defaultCurrent: 1,
            pageSize: pageSize,
            hideOnSinglePage: true,
            total: plantsData?.count,
          }}
          scroll={{ x: 1120 }}
        />
      </Card>
      {assetLimit && plantsData && isCreatePlantDrawerOpen && (
        <PlantCreateDrawer
          mutate={mutate}
          open={isCreatePlantDrawerOpen}
          setIsOpen={setIsCreatePlantDrawerOpen}
          maxDataCount={assetLimit}
          currentDataCount={plantsData?.count}
        />
      )}
      {assetLimit && plantsData && drawer.type && (
        <PlantGenericDrawer
          mutate={mutate}
          drawerOptions={drawer}
          setDrawerOptions={setDrawer}
          maxDataCount={assetLimit}
          currentDataCount={plantsData?.count}
        />
      )}
    </StyledPlants>
  );
};

export default Plants;
