import { Col } from 'antd';
import styled from 'styled-components';

interface StyledPlansCardProps {
  $isTeam: boolean;
  $isEnterpriseXL?: boolean;
}

export default styled(Col)<StyledPlansCardProps>`
  display: flex;
  flex-direction: ${({ $isEnterpriseXL }) =>
    $isEnterpriseXL ? 'row' : 'column'};
  justify-content: space-between;
  gap: 16px;
  border: 0.5px solid ${({ theme }) => theme.colors.grayLightAccent};
  border-radius: 8px;
  padding: 16px !important;
  height: ${({ $isEnterpriseXL }) => ($isEnterpriseXL ? 'auto' : 'auto')};
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.light};

  .first-row {
    flex: ${({ $isEnterpriseXL }) => ($isEnterpriseXL ? '1' : '')};
  }
  .first-row .ant-space {
    width: 100%;
  }
  .second-row {
    width: 100%;
    flex: ${({ $isEnterpriseXL }) => ($isEnterpriseXL ? '1' : '')};
  }
  .second-row .ant-space {
    width: 100%;
  }
  .third-row {
    display: flex;
    align-items: center;
  }
  .enterprise-row {
    margin-top: ${({ $isEnterpriseXL }) => (!$isEnterpriseXL ? 'auto' : '')};
  }

  &:hover {
    border: 0.5px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.primaryHighlight};
  }
  .bottom-row {
    margin-top: ${({ $isEnterpriseXL }) => ($isEnterpriseXL ? 'auto' : '')};
    align-self: start;
  }
  .ant-space-item {
    display: flex;
  }
  .btn {
    width: 93px;
    height: 28px;
    padding: 4px 12px;
    border-radius: 6px !important;
    border-color: ${({ theme }) => theme.colors.blueDarkAccent} !important;
    color: ${({ theme }) => theme.colors.blueDarkAccent} !important;
    margin-top: ${({ $isEnterpriseXL }) => ($isEnterpriseXL ? '0' : '8px')};
  }
  .btn:hover {
    background-color: ${({ theme }) => theme.colors.blueLightMedium} !important;
    color: ${({ theme }) => theme.colors.light} !important;
    border: none;
  }

  @media (max-width: 992px) {
    height: auto;
    background-color: ${({ theme }) => theme.colors.light};
  }
  @media (max-width: 568px) {
    display: flex;
    flex-direction: column;
    height: auto;
    .title-amount {
      font-size: 25px !important;
    }
    .title-plan {
      font-size: 14px !important;
      padding-top: 6px;
    }
  }
`;
