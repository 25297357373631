import Cookies from 'js-cookie';

import { UserResponse } from '@/types/auth';
// import dayjs from "dayjs"
class CookieService {
  authTokenKey: string;
  refreshTokenKey: string;
  tokenType: string;
  expDateKey: string;
  userData: string;
  domain: string;

  constructor() {
    const mode = import.meta.env.MODE;
    this.domain = this.buildCookieDomain(mode);
    this.authTokenKey = this.getTokenKey('SIM_AUTH', mode);
    this.refreshTokenKey = this.getTokenKey('SIM_REFRESH', mode);
    this.tokenType = 'Bearer';
    this.expDateKey = 'EXPD';
    this.userData = 'U';
  }

  getTokenKey(baseKey: string, mode: string): string {
    switch (mode) {
      case 'local-mode':
        return `${baseKey}_LOCAL`;
      case 'development':
        return `${baseKey}_DEV`;
      case 'production':
        return `${baseKey}`;
      case 'test':
        return `${baseKey}_TEST`;
      default:
        return baseKey;
    }
  }

  buildCookieDomain(mode: string) {
    return mode === 'local-mode' ? 'localhost' : '.ratiosim.com';
  }

  getAuthToken() {
    return Cookies.get(this.authTokenKey);
  }

  setAuthToken(token = '') {
    return Cookies.set(this.authTokenKey, `${this.tokenType} ${token}`, {
      domain: this.domain,
      path: '/',
    });
  }

  getRefreshToken() {
    return Cookies.get(this.refreshTokenKey);
  }

  setRefreshToken(token = '') {
    return Cookies.set(this.refreshTokenKey, `${token}`, {
      domain: this.domain,
      path: '/',
    });
  }

  clearCookies() {
    Cookies.remove(this.authTokenKey, { path: '/', domain: this.domain });
    Cookies.remove(this.refreshTokenKey, { path: '/', domain: this.domain });
    Cookies.remove(this.expDateKey, { path: '/', domain: this.domain });
    Cookies.remove(this.userData, { path: '/', domain: this.domain });
  }

  setUser(user: UserResponse) {
    return Cookies.set(this.userData, JSON.stringify(user), {
      domain: this.domain,
      path: '/',
    });
  }

  setAuthExpDate(date: number) {
    return Cookies.set(this.expDateKey, `${date}`, {
      domain: this.domain,
      path: '/',
    });
  }

  getAuthExpDate() {
    return Number(Cookies.get(this.expDateKey));
  }
}

export default new CookieService();
