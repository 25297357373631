import { useDeferredValue, useEffect, useState } from 'react';

import {
  Button,
  Flex,
  Row,
  Tag,
  Typography,
  Select,
  Form,
  Col,
  Input,
  notification,
  Space,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import { authApi } from '@/api';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import IconInvite from '@/assets/icons/icon-invite.svg?react';
import IconResend from '@/assets/icons/icon-resend.svg?react';
import Card from '@/components/RCard';
import RDrawer from '@/components/RDrawer/index';
import InviteBanner from '@/components/RExpandTeamBanner';
import PageHeader from '@/components/RPageHeader';
import SearchInput from '@/components/RSearchInput';
import RTable from '@/components/RTable';
import RTag from '@/components/RTag';
import CustomTooltip from '@/components/RTooltip';
import { RUsageLimitIndicator } from '@/components/RUsageLimitIndicator';
import { ROLES_LIST } from '@/constants';
import useAuth from '@/hooks/useAuth';
import paths from '@/routes/paths';
import { UserList, UserListing, UserPost } from '@/types/auth';
import {
  fullNameRegExpPattern,
  handleError,
  infinitySymbol,
  validateEmail,
  validateStrongPassword,
} from '@/utils';

import { ActionsMenu } from './ActionsMenu';
import StyledUserManagement from './styles';

const UserManagement = () => {
  const { user } = useAuth();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserListing>();
  const [users, setUsers] = useState<UserListing[]>([]);

  const navigate = useNavigate();
  const [notificationApi] = notification.useNotification();
  const theme = useTheme();
  const [form] = Form.useForm();
  const [tForm] = Form.useForm();
  const [searchParams, setSearchParams] = useState<string>('');

  const pageSize = 7;
  const [offset, setOffset] = useState<number>(1);

  const deferredNameSearch = useDeferredValue(search);

  //test
  const {
    data: usersData,
    mutate: usersDataMutate,
    isLoading: usersDataIsLoading,
  } = useSWR<UserList>(
    `/auth/user/?limit=${pageSize}&offset=${
      (offset - 1) * pageSize
    }&fullName=${deferredNameSearch}`
  );

  const { data: currentUsers, mutate: currentUsersDataMutate } =
    useSWR<UserList>(`/auth/user/`);

  //FUNCTIONS
  const handlePaginationChange = (page: number) => {
    setOffset(page);
  };

  useEffect(() => {
    if (usersData) {
      setUsers(usersData.results);
    }
  }, [usersData]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setSearchParams(params.get('openDrawer') || '');
    if (params.get('openDrawer')) {
      setIsDrawerVisible(true);
    }
  }, [searchParams]);

  async function createUser() {
    const { password, name, lastName, title, email } = form.getFieldsValue();
    const body = {
      password,
      // role: getRoleID(role),
      fullName: name + ' ' + lastName,
      jobTitle: title,
      email,
    };

    if (!password && !name && !lastName && !title && !email) {
      throw new Error('Please fill all required fields.');
    }

    try {
      await authApi.postUser(body);
      onClose();
      setTimeout(() => {
        navigate(paths.organization.user.management);
        usersDataMutate();
        currentUsersDataMutate();
      }, 1000);
      notification.success({
        message: 'User created successfully!',
      });
    } catch (error) {
      handleError(error);
    } finally {
      form.resetFields();
    }
  }

  async function deleteUser(id: number) {
    try {
      await authApi.deleteUser(id);
    } catch (error) {
      handleError(error, 'Failed to delete user!');
    }
  }

  async function updateUser(id: number, body: UserPost) {
    try {
      await authApi.patchUser(id, body);
      notificationApi.success({
        message: 'User updated successfully!',
      });
    } catch (error) {
      handleError(error, 'Failed to update user!');
    }
  }

  const handleEdit = (user: UserListing) => {
    setSelectedUser(user);
    setEditMode(true);
    tForm.setFieldsValue({
      fullName: user.fullName,
      email: user.email,
      title: user.jobTitle,
    });
  };

  const handleSave = async () => {
    try {
      await tForm.validateFields();

      const { fullName, title, email } = tForm.getFieldsValue();

      const body = {
        fullName: fullName,
        jobTitle: title,
        email,
      };

      if (typeof selectedUser?.id === 'number') {
        await updateUser(selectedUser.id, body);
        setEditMode(false);
        tForm.resetFields();
        usersDataMutate();
        currentUsersDataMutate();
      }
    } catch (error) {
      handleError(error, 'Validation failed!');
    }
  };

  const handleCancel = () => {
    form.resetFields(); // Form alanlarını sıfırlayın
    setEditMode(false); // Düzenleme modunu kapatın
  };

  const handleFormFinish = async () => {
    const fields = [
      'name',
      'lastName',
      'title',
      'email',
      'verifyPassword',
      'password',
    ];

    try {
      await form.validateFields(fields);
      await createUser();
    } catch (error) {
      handleError(error);
    }
  };

  const usersTableColumn: ColumnsType<UserListing> = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      width: '280px',

      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      render: (_, user) => {
        if (editMode && user.id === selectedUser?.id) {
          return (
            <Form.Item
              name="fullName"
              rules={[
                {
                  required: true,
                  message: (
                    <CustomTooltip
                      description="Field is required"
                      placement="top"
                      title={'Error'}
                      open={true}
                    ></CustomTooltip>
                  ),
                },
                {
                  pattern: fullNameRegExpPattern(),
                  message: (
                    <CustomTooltip
                      description="Field does not accept numbers or special characters."
                      placement="top"
                      title={'Error'}
                      open={true}
                    ></CustomTooltip>
                  ),
                },
              ]}
            >
              <Input className="w-100" />
            </Form.Item>
          );
        } else {
          return <Typography.Text>{user.fullName}</Typography.Text>;
        }
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',

      width: '265px',
      render: (_, user) => {
        if (editMode && user.id === selectedUser?.id) {
          return (
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: (
                    <CustomTooltip
                      description="Field is required!"
                      placement="top"
                      title={'Error'}
                      open={true}
                    ></CustomTooltip>
                  ),
                },
                {
                  type: 'email',
                  message: (
                    <CustomTooltip
                      description="Please enter a valid email address!"
                      placement="top"
                      title={'Error'}
                      open={true}
                    ></CustomTooltip>
                  ),
                },
              ]}
            >
              <Input className="w-100" />
            </Form.Item>
          );
        } else {
          return <Typography.Text>{user.email}</Typography.Text>;
        }
      },
    },
    {
      title: 'Job Title',
      dataIndex: 'jobTitle',
      key: 'jobTitle',

      width: '150px',
      sorter: (a, b) => a.jobTitle.localeCompare(b.jobTitle),
      render: (_, user) => {
        if (editMode && user.id === selectedUser?.id) {
          return (
            <Form.Item
              style={{ alignItems: 'start' }}
              name="title"
              rules={[
                {
                  required: true,
                  message: (
                    <CustomTooltip
                      description="Field is required"
                      placement="top"
                      title={'Error'}
                      open={true}
                    />
                  ),
                },
                {
                  pattern: fullNameRegExpPattern(),

                  message: (
                    <CustomTooltip
                      description="Field does not accept numbers or special characters."
                      placement="top"
                      title={'Error'}
                      open={true}
                    ></CustomTooltip>
                  ),
                },
              ]}
            >
              <Input className="w-100" />
            </Form.Item>
          );
        } else {
          return <Typography.Text>{user.jobTitle}</Typography.Text>;
        }
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: '100px',
      sorter: (a, b) => a.role.toString().localeCompare(b.role.toString()),
      align: 'start',
      render: (_, user) => {
        return (
          <Tag
            color="white"
            style={{ color: '#D36B08', border: '1px  solid #D36B08' }}
          >
            {user.role}
          </Tag>
        );
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '146px',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (date: string) => dayjs(date).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: (
        <CustomTooltip
          className="cursor-pointer"
          trigger="click"
          title="Status"
          description="You can see the status of the user."
        >
          <Row
            align="middle"
            justify="start"
            className="w-100"
            style={{ minWidth: '70px' }}
          >
            <Typography.Text className="pr-8">Status</Typography.Text>
            <IconInfo />
          </Row>
        </CustomTooltip>
      ),
      dataIndex: 'isActive',
      key: 'isActive',
      width: '130px',
      align: 'start',
      sorter: (a, b) => {
        const nameA = String(a.status);
        const nameB = String(b.status);

        return nameA.localeCompare(nameB);
      },

      render: () => {
        const isActive = user?.organization.isActive;
        return isActive ? (
          <RTag type="active" />
        ) : (
          <Flex align="center" justify="center" gap={6}>
            <RTag type="revoked" />
            <Tag
              color="blue"
              style={{
                height: 30,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <IconResend
                width={16}
                height={16}
                fill={theme.colors.blueLightMedium}
                onClick={() => {
                  currentUsersDataMutate();
                }}
              />
            </Tag>
          </Flex>
        );
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '130px',
      render: (_, user) => {
        return editMode && user.id === selectedUser?.id ? ( // Düzenleme modu kontrolü
          <Space size={4}>
            <Button
              type="dashed"
              className="btn default-icon-button"
              htmlType="submit"
              onClick={handleSave}
              size="small"
            >
              Save
            </Button>
            <Button
              type="dashed"
              className="btn"
              onClick={handleCancel}
              size="small"
            >
              Cancel
            </Button>
          </Space>
        ) : (
          <ActionsMenu
            onDelete={() => {
              deleteUser(user.id);
              setTimeout(() => {
                usersDataMutate();
                currentUsersDataMutate();
              }, 500);
            }}
            onEdit={() => {
              handleEdit(user);
              tForm.setFieldsValue({
                fullName: user.fullName,
                email: user.email,
                title: user.jobTitle,
              });
            }}
          />
        );
      },
    },
  ];

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setIsDrawerVisible(false);
  };
  //TODO VALIDATE TRIGGER NOT WORKING
  //SOLUTION : BUTTON DISABLE IS REMOVED
  return (
    <StyledUserManagement>
      <PageHeader
        title="Users"
        description="You can view all the users and change role quickly."
      />
      <Card className="card-users ">
        <Row
          className="w-100"
          align="middle"
          justify="space-between"
          style={{
            padding: '7px 24px',
            borderBottom: `0.5px solid ${theme.colors.grayLightAccent}`,
          }}
        >
          <SearchInput
            placeholder="Search Input"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any) => setSearch(e.target.value)}
          />
          <Flex gap={24} align="center">
            <RUsageLimitIndicator
              text={user?.organization.subscription.plan.name
                .charAt(0)
                .toUpperCase()
                .concat(user?.organization.subscription.plan.name.slice(1))
                .concat(' Plan')}
              currentValue={currentUsers?.count || 0}
              maxValue={infinitySymbol(
                user?.organization.subscription.plan.userLimit
              )}
            />
            <InviteBanner />
            <Button type="primary" className="invite-btn" onClick={showDrawer}>
              <IconInvite fill={theme.colors.light} /> Invite
            </Button>
          </Flex>
        </Row>
        <Form form={tForm} onFinish={handleSave}>
          <RTable
            rowKey="id"
            rowClassName="cursor-pointer"
            columns={usersTableColumn}
            dataSource={users}
            tableLayout="fixed"
            loading={usersDataIsLoading}
            size="small"
            pagination={{
              total: usersData?.count || 0,
              pageSize: pageSize,
              onChange: handlePaginationChange,
              hideOnSinglePage: true,
              defaultCurrent: 1,
            }}
            scroll={{ x: 900 }}
          />
        </Form>
      </Card>

      <RDrawer
        title="Invite User"
        description="You can invite a new user to your organization."
        rightSideSection={
          <RUsageLimitIndicator
            text="Remaining Users"
            currentValue={currentUsers?.count}
            maxValue={infinitySymbol(
              user?.organization.subscription.plan.userLimit
            )}
          />
        }
        onClose={onClose}
        open={isDrawerVisible}
        drawerLock={{
          lockText: 'To add a new user, you need to upgrade your plan.',
          isLocked:
            (currentUsers ? currentUsers.count : 0) >=
            (user != undefined
              ? user?.organization.subscription.plan.userLimit
              : 0),
        }}
      >
        <Form
          onFinish={handleFormFinish}
          id="createUserForm"
          name="createUserForm"
          form={form}
          layout="vertical"
          className="h-100"
        >
          <Flex vertical justify="space-between" className="h-100">
            <Card $paddingBody="16px" className="mt-24">
              <Flex vertical gap={40}>
                <Row style={{ height: '65px' }}>
                  <Col span={12}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Email field is required!',
                        },
                        { validator: validateEmail },
                      ]}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <Input autoComplete="off" className="w-100" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  align="middle"
                  gutter={16}
                  wrap={false}
                  style={{ height: '65px' }}
                >
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Name field is required!',
                        },
                      ]}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <Input className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Last Name"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: 'Name field is required!',
                        },
                      ]}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <Input className="w-100" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  align="middle"
                  gutter={16}
                  wrap={false}
                  style={{ height: '65px' }}
                >
                  <Col span={12}>
                    <Form.Item
                      label="New Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                        { validator: validateStrongPassword },
                      ]}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <Input.Password
                        autoComplete={'12312312321'}
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Verify Password"
                      name="verifyPassword"
                      hasFeedback
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('The two passwords do not match!')
                            );
                          },
                        }),
                      ]}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <Input.Password autoComplete={'nope'} className="w-100" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  align="middle"
                  gutter={16}
                  wrap={false}
                  style={{ height: '65px' }}
                >
                  <Col span={12}>
                    <Form.Item label="Role" name="role">
                      <Select
                        disabled={true}
                        options={ROLES_LIST}
                        placeholder="Admin"
                        value={'admin'}
                        onChange={(value) =>
                          form.setFieldsValue({ role: value })
                        }
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Job Title"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: 'Title field is required!',
                        },
                      ]}
                    >
                      <Input className="w-100" />
                    </Form.Item>
                  </Col>
                </Row>
              </Flex>
            </Card>
            <Flex gap={8} justify="end" style={{ padding: '12px 24px' }}>
              <Form.Item>
                <Button
                  type="default"
                  onClick={() => {
                    onClose();
                  }}
                  style={{
                    padding: '9px 24px',
                    lineHeight: '14px',
                    width: '90px',
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    padding: '9px 24px',
                    lineHeight: '14px',
                    width: '90px',
                  }}
                >
                  Invite
                </Button>
              </Form.Item>
            </Flex>
          </Flex>
        </Form>
      </RDrawer>
    </StyledUserManagement>
  );
};

export default UserManagement;
