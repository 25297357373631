import { Col, Row } from 'antd';
import { motion } from 'framer-motion';

import AnnualEnergyFlow from './AnnualEnergyFlow';
import BatteryAndLifeCycle from './BatteryAndLifeCycle';
import InfoCard from './BatteryAndLifeCycle/InfoCard';
import BatteryFlowForCharge from './BatteryFlowForCharge';
import BatteryFlowForDischarge from './BatteryFlowForDischarge';
import EnergyActivity from './EnergyActivity';
import EnergyActivityAtMarket from './EnergyActivityAtMarket';
import GenerationFlow from './GenerationFlow';

const Energy = () => {
  const scrollAnimation = (direction: 'left' | 'right') => ({
    initial: { opacity: 0, x: direction === 'left' ? -100 : 100 },
    whileInView: { opacity: 1, x: 0 },
    transition: { type: 'spring', stiffness: 300, damping: 30 },
  });
  return (
    <Row gutter={24} justify="center" align="top">
      <Col span={20}>
        <motion.div {...scrollAnimation('left')}>
          <BatteryAndLifeCycle />
        </motion.div>
      </Col>
      <Col span={4}>
        <motion.div {...scrollAnimation('right')}>
          <InfoCard />
        </motion.div>
      </Col>
      <Col span={24}>
        <motion.div {...scrollAnimation('left')}>
          <EnergyActivity />
        </motion.div>
      </Col>
      <Col span={24}>
        <motion.div {...scrollAnimation('right')}>
          <EnergyActivityAtMarket />
        </motion.div>
      </Col>
      <Col span={24}>
        <motion.div {...scrollAnimation('left')}>
          <AnnualEnergyFlow />
        </motion.div>
      </Col>
      <Col span={12}>
        <motion.div {...scrollAnimation('left')}>
          <BatteryFlowForCharge />
        </motion.div>
      </Col>
      <Col span={12}>
        <motion.div {...scrollAnimation('right')}>
          <BatteryFlowForDischarge />
        </motion.div>
      </Col>
      <Col span={12}>
        <motion.div {...scrollAnimation('left')}>
          <GenerationFlow />
        </motion.div>
      </Col>
    </Row>
  );
};
export default Energy;
