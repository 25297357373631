import { FC, useState } from 'react';

import {
  Button,
  Col,
  Flex,
  Form,
  FormProps,
  Input,
  Row,
  notification,
} from 'antd';
import useSWR, { mutate } from 'swr';

import Card from '@/components/RCard';
import { handleError } from '@/utils';

import { authApi } from '../../../api';
import PageHeader from '../../../components/RPageHeader';
import { UserResponse } from '../../../types/auth';

const ChangePassword: FC<FormProps> = ({ ...formProps }) => {
  const { data: user } = useSWR<UserResponse>('/auth/user/me/');
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  async function changePass() {
    try {
      setIsLoading(true);
      await form.validateFields();

      const formResult = form.getFieldsValue();
      if (formResult) {
        const body = {
          oldPassword: formResult.password,
          password: formResult.newPassword,
        };
        if (user) {
          const result = await authApi.patchUser(user.id, body);
          if (result) {
            mutate(result);
          }
        }
      }
      notification.success({ message: 'Password changed succesfully.' });

      setTimeout(() => {
        window.location.reload();
      }, 1000);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error, 'Failed to change password');
    }
  }

  return (
    <>
      <PageHeader title="General" description="You can change your password." />
      <Form form={form} layout="vertical" {...formProps}>
        <Card
          // title={
          //   <CardTitleWithButton
          //     title="Change Password"
          //     isLoading={isLoading}
          //     buttonText="Change"
          //     disabled={false}
          //     onClick={() => changePass()}
          //   />
          // }
          title="Change Password"
          $paddingBody="24px"
        >
          <Row align="middle" gutter={10} wrap={false}>
            <Col span={8}>
              <Form.Item
                label="Old Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Password field is required!',
                  },
                ]}
              >
                <Input.Password className="w-100" />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={10} wrap={false}>
            <Col span={8}>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'New password field is required!',
                  },
                ]}
              >
                <Input.Password className="w-100" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Verify Password"
                name="verifyPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The new password that you entered do not match!'
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password className="w-100" />
              </Form.Item>
            </Col>
          </Row>
          <Flex justify="end" style={{ marginTop: '33px' }}>
            <Button
              type="primary"
              loading={isLoading}
              onClick={() => changePass()}
            >
              Change
            </Button>
          </Flex>
        </Card>
      </Form>
    </>
  );
};

export default ChangePassword;
