import { FC, useEffect, useState } from 'react';

import { Button, Modal, Row, Typography } from 'antd';
import dayjs from 'dayjs';

import useAuth from '@/hooks/useAuth';
import PlansModal from '@/pages/Organization/Payments/PlansModal';

import Wrapper from './styles';

const HeaderTrial: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [days, setDays] = useState<number>();
  const { user, isMembershipActive } = useAuth();
  const date = dayjs(user?.organization.subscription.periodEndDate);
  const formattedDate = date.format('MMMM D, YYYY');

  useEffect(() => {
    if (user?.isVerified && user.organization.subscription.plan.name) {
      const leftDay = dayjs(user.organization.subscription.periodEndDate).diff(
        Date.now(),
        'days'
      );
      setDays(leftDay);
    }
  }, [user]);

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const handleRenewClick = () => {
    setIsModalVisible(true);
  };

  //TODO ADD UPGRADE PLAN PAGE

  if (isMembershipActive || (days && days < 7)) {
    return (
      <Wrapper>
        <Row className="duration-tag" align="middle">
          {isMembershipActive ? (
            <Typography.Text className="text fs-14-regular">
              Your plan ended!
              <span className="fs-14-bold"> Please renew the plan.</span>
            </Typography.Text>
          ) : (
            <Typography.Text className="text fs-14-regular">
              Your plan ends in{' '}
              <span className="fs-14-bold">
                {days} days on {formattedDate}
              </span>
            </Typography.Text>
          )}
          <Button type="default" className="btn" onClick={handleRenewClick}>
            Renew Plan
          </Button>
        </Row>
        <Modal
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          destroyOnClose
          centered
          width={'100%'}
        >
          <PlansModal />
        </Modal>
      </Wrapper>
    );
  } else {
    return;
  }
};

export default HeaderTrial;
