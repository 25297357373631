import { Row, Col } from 'antd';
import styled from 'styled-components';

export default styled(Col)`
  .action-button-alert {
    :hover {
      border: 1px solid ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.red};
    }
  }
  .plan-subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
  }
  .plan-text {
    font-size: 12px !important;
    line-height: 15px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grayAccent};
  }
  .team-card {
    .more-users {
      text-underline-offset: 3px;
    }
    .add-more-btn {
      padding: 9px 24px;
    }
  }
`;

export const TableActionsWrapper = styled(Row)`
  /* border: 1px solid red; */
  /* background-color: #fafafa; */
  /* border: 1px solid #f0f0f0; */
  border-bottom: none;
  padding-bottom: 16px;
  padding-top: 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  .search-group {
    height: 32px;
  }
`;
