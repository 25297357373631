import { FC } from 'react';

import { Flex, Spin, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import Table from '@/components/RTable';
import {
  SimulationResult,
  SimulationResultYearlyFinancialAnalysis,
  SimulationResultYearlyFinancialAnalysisData,
} from '@/types/simulations';
import { formatNumberWithSuffix, getYears } from '@/utils';

import { StyledTable } from './styles';

interface AncillaryTableProps {
  simulationData: SimulationResult;
}

const AncillaryTable: FC<AncillaryTableProps> = ({ simulationData }) => {
  const resultId = useParams<Record<string, string>>();
  const { data: financialData, isLoading } =
    useSWR<SimulationResultYearlyFinancialAnalysis>(
      `/simulation/${resultId.resultId}/yearly_financial_analysis/`
    );

  const theme = useTheme();
  // Ignoring the hour and minute part of the date because of the timezone issue
  const years: string[] = getYears(
    simulationData?.startDate.slice(0, 10) || '',
    simulationData?.endDate.slice(0, 10) || ''
  ).map(String);

  const scrollX =
    years.length > 5 ? 600 + years.length * 50 : 600 + years.length * 50;

  type DataType = {
    key: React.Key;
    name: string;
    width: number;
  };

  const columns: ColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      fixed: 'left',
    },
    ...years.map((year) => ({
      title: year,
      dataIndex: year,
      key: year,
      width: 85,
    })),
  ];

  const getValuesForYears = (
    graphData: SimulationResultYearlyFinancialAnalysisData[] | undefined,
    key: string
  ) => {
    const values: Record<string, number> = {};
    if (graphData) {
      years.forEach((year) => {
        const data = graphData.find((item) => item.year === Number(year));
        values[year] = data ? data[key] : 0;
      });
    }
    return values;
  };

  const dataSource = [
    {
      key: '1',
      name: (
        <Flex
          gap={6}
          align="center"
          style={{
            padding: '10px 24px',
            background: theme.colors.blueLightest,
          }}
        >
          <Typography.Text className="fs-14-bold">
            Offered Capacity for PFC (MWh)
          </Typography.Text>
        </Flex>
      ),
      ...Object.fromEntries(
        Object.entries(
          getValuesForYears(financialData?.graphData, 'offeredCapacityOfPfc')
        ).map(([year, value]) => [year, formatNumberWithSuffix(value)])
      ),
    },
    {
      key: '2',
      name: (
        <Flex
          gap={6}
          align="center"
          style={{
            padding: '10px 24px',
            background: theme.colors.blueLightest,
          }}
        >
          <Typography.Text className="fs-14-bold">
            Reserved Capacity for PFC (MWh)
          </Typography.Text>
        </Flex>
      ),
      ...Object.fromEntries(
        Object.entries(
          getValuesForYears(financialData?.graphData, 'reservedCapacityOfPfc')
        ).map(([year, value]) => [year, formatNumberWithSuffix(value)])
      ),
    },
    {
      key: '3',
      name: (
        <Flex
          gap={6}
          align="center"
          style={{
            padding: '10px 24px',
            background: theme.colors.blueLightest,
          }}
        >
          <Typography.Text className="fs-14-bold">
            Discharged Energy for PFC (MWh)
          </Typography.Text>
        </Flex>
      ),
      ...Object.fromEntries(
        Object.entries(
          getValuesForYears(financialData?.graphData, 'dischargedEnergyForPfc')
        ).map(([year, value]) => [year, formatNumberWithSuffix(value)])
      ),
    },
    {
      key: '4',
      name: (
        <Flex
          gap={6}
          align="center"
          style={{
            padding: '10px 24px',
            background: theme.colors.blueLightest,
          }}
        >
          <Typography.Text className="fs-14-bold">
            Offered Capacity for SFC (MWh)
          </Typography.Text>
        </Flex>
      ),
      ...Object.fromEntries(
        Object.entries(
          getValuesForYears(financialData?.graphData, 'offeredCapacityOfSfc')
        ).map(([year, value]) => [year, formatNumberWithSuffix(value)])
      ),
    },
    {
      key: '5',
      name: (
        <Flex
          gap={6}
          align="center"
          style={{
            padding: '10px 24px',
            background: theme.colors.blueLightest,
          }}
        >
          <Typography.Text className="fs-14-bold">
            Reserved Capacity for SFC (MWh)
          </Typography.Text>
        </Flex>
      ),
      ...Object.fromEntries(
        Object.entries(
          getValuesForYears(financialData?.graphData, 'reservedCapacityOfSfc')
        ).map(([year, value]) => [year, formatNumberWithSuffix(value)])
      ),
    },
    {
      key: '6',
      name: (
        <Flex
          gap={6}
          align="center"
          style={{
            padding: '10px 24px',
            background: theme.colors.blueLightest,
          }}
        >
          <Typography.Text className="fs-14-bold">
            Discharged Energy for SFC (MWh)
          </Typography.Text>
        </Flex>
      ),
      ...Object.fromEntries(
        Object.entries(
          getValuesForYears(financialData?.graphData, 'dischargedEnergyForSfc')
        ).map(([year, value]) => [year, formatNumberWithSuffix(value)])
      ),
    },
  ];

  return (
    <StyledTable>
      {isLoading ? (
        <Spin style={{ margin: '20px' }} />
      ) : (
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
          size="small"
          scroll={{ x: scrollX }}
        />
      )}
    </StyledTable>
  );
};
export default AncillaryTable;
