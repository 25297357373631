import { Button, Row, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import IconInfo from '@/assets/icons/icon-info.svg?react';
import IconEye from '@/assets/icons/icon-view.svg?react';
import { Scenario } from '@/types/scenario';

type Props = {
  prepareSimulationItem: (id: string, type: string) => void;
};

const useNewSimulationScenarioTableColumns = (props: Props) => {
  const columns: ColumnsType<Scenario> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{ maxWidth: '200px' }}
            className="text"
            ellipsis={{
              rows: 1,
              tooltip: name,
              expandable: false,
            }}
          >
            {name}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150,
      sorter: (a, b) =>
        a.description && b.description
          ? a.description.localeCompare(b.description)
          : 0,
      render: (description) => (
        <Typography.Paragraph
          style={{ maxWidth: '250px' }}
          className="text"
          ellipsis={{
            rows: 1,
            tooltip: description,
            expandable: false,
          }}
        >
          {description}
        </Typography.Paragraph>
      ),
    },
    {
      title: (
        <Tooltip className="cursor-pointer" trigger="click" title="TODO">
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Prices and Load</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'pricesAndLoad',
      key: 'pricesAndLoad',
      width: 180,
      align: 'center',
      render: (fileLink) =>
        fileLink && (
          <Typography.Link href={fileLink}>Prices And Load</Typography.Link>
        ),
    },
    {
      title: (
        <Tooltip className="cursor-pointer" trigger="click" title="TODO">
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Created Date</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 180,
      render: (dataIndex) => moment(dataIndex).format('DD.MM.YYYY HH:mm'),
      sorter: (a, b) =>
        new Date(a.createdAt || '').getTime() -
        new Date(b.createdAt || '').getTime(),
    },

    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '50px',
      render: (_, scenario) => (
        <Row align="middle" justify="space-between">
          <Tooltip title="View">
            <Button
              className="default-icon-button"
              type="default"
              onClick={() => {
                props.prepareSimulationItem(scenario.id, 'Scenario');
              }}
              icon={<IconEye />}
            />
          </Tooltip>
        </Row>
      ),
    },
  ];

  return columns;
};

export default useNewSimulationScenarioTableColumns;
