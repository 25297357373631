// import { FC, useRef } from 'react'

import { useEffect, useRef, useState } from 'react';

import { motion, useInView } from 'framer-motion';

type AnimatedTextProps = {
  text: string;
  el?: keyof JSX.IntrinsicElements;
  className?: string;
};

const defaultAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export const AnimatedText = ({
  text,
  el: Wrapper = 'p',
  className,
}: AnimatedTextProps) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5, once: true });

  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [text]);

  return (
    <Wrapper key={key} className={className}>
      {/* Screen Reader */}
      {/* <span className="sr-only">{text}</span> */}
      <motion.span
        ref={ref}
        variants={{
          visible: { transition: { staggerChildren: 0.03 } },
          hidden: {},
        }}
        initial="hidden"
        animate={isInView ? 'visible' : 'hidden'}
        aria-hidden
      >
        {text.split('').map((char, charIndex) => (
          <motion.span
            variants={defaultAnimation}
            key={`${char}-${charIndex}`}
            className="inline-block loading-text"
          >
            {char}
          </motion.span>
        ))}
      </motion.span>
    </Wrapper>
  );
};
