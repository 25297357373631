import { Flex } from 'antd';
import styled from 'styled-components';

export default styled(Flex)<{ width?: number }>`
  padding: 12px;
  width: ${({ width }) => (width ? `${width}px` : '465px')};
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 8px;
  box-shadow: 4px 4px 8px 0px #00000040;

  .line {
    border: none;
    margin: 4px 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayLightAccent};
  }
`;
