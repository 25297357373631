import { useEffect, useState } from 'react';

import { Button, Flex, Typography, Tour } from 'antd';
import { useNavigate } from 'react-router';
import { useTheme } from 'styled-components';

import IconRocket from '@/assets/icons/icon-rocket.svg?react';
import { TourSteps } from '@/constants';
import useAuth from '@/hooks/useAuth';
import useProductTourStore from '@/store/useProductTourStore';

import { getTourSteps } from './steps';
import { StyledModal } from './styles';
import { getOffsetGap } from './tourOffsetGap';

const RProductTour = () => {
  const { user } = useAuth();
  const {
    isTourActive,
    currentStep,
    startTour,
    endTour,
    changeStep,
    stepReferences,
    showModal,
    setShowModal,
  } = useProductTourStore();
  const [open, setOpen] = useState<boolean>(false);
  const [fadeClass, setFadeClass] = useState<string>('fade-enter');

  const theme = useTheme();
  const navigate = useNavigate();

  // Assing the step references to the tour steps
  const steps = getTourSteps(stepReferences);

  useEffect(() => {
    if (!user?.lastLogin && !isTourActive && !showModal) {
      setShowModal(true);
    }
  }, [isTourActive, showModal, setShowModal, user?.lastLogin]);

  const handleStartTour = () => {
    startTour();
    setOpen(true);
    setShowModal(false);
    setFadeClass('fade-enter');
  };

  const handleSkipTour = () => {
    setShowModal(false);
    setFadeClass('fade-exit');
    setTimeout(() => {
      endTour(); // to finish the animation
    }, 500);
  };

  const handleEndTour = () => {
    setFadeClass('fade-exit');
    setTimeout(() => {
      endTour();
      setOpen(false);
      setShowModal(false);
    }, 500);
  };

  const handleChangeStep = (stepNumber: number) => {
    const navigateTo = (path: string) => {
      navigate(path);
    };
    // Page navigation according to the current step
    switch (stepNumber) {
      case TourSteps.PLANTS:
      case TourSteps.VIEW_PLANTS:
        navigateTo('/plants');
        break;
      case TourSteps.SCENARIOS:
      case TourSteps.VIEW_SCENARIOS:
        navigateTo('/scenarios');
        break;
      case TourSteps.STORAGE_SYSTEMS:
      case TourSteps.VIEW_STORAGE_SYSTEMS:
        navigateTo('/storage-systems');
        break;
      case TourSteps.CREATE_SIMULATION:
        navigateTo('/simulations/?createSimulation');
        break;
      default:
        break;
    }

    changeStep(stepNumber);
  };

  // Making the mask offset according to the current step function
  const offsetGap = getOffsetGap(currentStep);

  return (
    <>
      <StyledModal
        maskClosable={false}
        open={showModal}
        getContainer={false}
        onCancel={handleSkipTour}
        footer={[
          <Flex gap={10} align="center" justify="space-between">
            <Button key="skip" onClick={handleSkipTour}>
              Skip Tour
            </Button>

            <Button
              key="start"
              type="primary"
              onClick={handleStartTour}
              icon={<IconRocket fill={theme.colors.light} />}
            >
              Proceed Tour
            </Button>
          </Flex>,
        ]}
      >
        <Flex vertical gap={10} align="center">
          <Typography.Title level={4}>
            Hello, {user?.fullName}! Welcome to RATIO SIM.
          </Typography.Title>
          <Typography.Text>
            Participating in the simulation creation tour is essential for
            understanding the application. Once you've completed the tour,
            you'll be able to create your own simulations with ease.
          </Typography.Text>
          <Typography.Text>
            Don’t worry if you miss the tour; you can restart it later under the
            help icon (?) located in the header section.
          </Typography.Text>
        </Flex>
      </StyledModal>

      <Tour
        key={currentStep}
        open={open && isTourActive}
        onClose={handleEndTour}
        steps={steps}
        current={currentStep}
        onChange={handleChangeStep}
        disabledInteraction={true}
        className={fadeClass}
        gap={{
          offset: offsetGap as number | [number, number],
          radius: 5,
        }}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </>
  );
};

export default RProductTour;
