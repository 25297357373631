import { Layout } from 'antd';
import { between } from 'polished';
import styled from 'styled-components';

export default styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: ${between('20px', '50px')};
  padding-right: ${between('20px', '50px')};
  background-color: ${({ theme }) => theme.colors.light};

  position: sticky;
  top: 0;
  z-index: 999;

  .ant-image {
    width: 96px;
  }
`;
