import { FC } from 'react';

import { Form, Input } from 'antd';
import { useTheme } from 'styled-components';

import { scenarioApi } from '@/api';
import { ScenarioForm } from '@/types/scenario';
import { isValidAssetName } from '@/utils';

type Props = {
  disabled?: boolean;
};

const Description: FC<Props> = ({ disabled = false }) => {
  const theme = useTheme();

  return (
    <Form.Item
      wrapperCol={{ span: 12 }}
      className="p-16 pb-40"
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
    >
      <Form.Item<ScenarioForm>>
        <Form.Item<ScenarioForm>
          wrapperCol={{ span: 24 }}
          name="name"
          hasFeedback
          validateDebounce={1000}
          label="Scenario Name"
          rules={[
            { required: true, message: 'Scenario name cannot be empty.' },
            () => ({
              async validator(_rule, value) {
                if (isValidAssetName(value).valid) {
                  return new Promise<void>((resolve, reject) => {
                    scenarioApi
                      .searchScenarioByExactName(encodeURIComponent(value))
                      .then((response) => {
                        if (response.results?.length > 0) {
                          reject(
                            'Scenario name you have entered already exist.'
                          );
                        } else {
                          resolve();
                        }
                      })
                      .catch(() => resolve());
                  });
                } else
                  return new Promise((_resolve, reject) =>
                    reject(isValidAssetName(value).error)
                  );
              },
            }),
          ]}
        >
          <Input disabled={disabled} placeholder="Scenario Name" />
        </Form.Item>
        <Form.Item<ScenarioForm>
          wrapperCol={{ span: 24 }}
          name="description"
          label="Description"
          rules={[{ required: false }]}
        >
          <Input.TextArea
            disabled={disabled}
            style={{ resize: 'none' }}
            placeholder="A brief description about your scenario"
            rows={4}
            showCount
            maxLength={140}
          />
        </Form.Item>
      </Form.Item>
    </Form.Item>
  );
};

export default Description;
