import {
  useState,
  ChangeEvent,
  useImperativeHandle,
  useCallback,
  ForwardRefRenderFunction,
  forwardRef,
} from 'react';

import { Col } from 'antd';

import StyledOtpContainer, { StyledInput } from './styles';

interface OTPInputProps {
  onChange: (otp: string[]) => void;
  handleVerifyCode: (code: string) => void;
}

interface ChildRef {
  handleClear: () => void;
}

const OTPInput: ForwardRefRenderFunction<ChildRef, OTPInputProps> = (
  { onChange, handleVerifyCode },
  ref
) => {
  const initialOtp: string[] = ['', '', '', '', '', ''];
  const [otp, setOtp] = useState<string[]>(initialOtp);

  const handleChange = async (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    if (isNaN(Number(value))) {
      return;
    }

    const newOtp = otp.map((digit, i) => (i === index ? value : digit));
    setOtp(newOtp);

    // Auto focus on the next input
    if (value !== '' && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }

    onChange(newOtp);

    // Check if all inputs are filled and trigger verification
    if (newOtp.every((digit) => digit !== '')) {
      // Perform OTP verification
      handleVerifyCode(newOtp.join(''));
    }
  };

  // useCallback kullanarak handleClear fonksiyonunu bir kere oluşturuyoruz, change eventi yeniden tetiklenerek resetlenmiş otp gönderilir.
  const handleClear = useCallback(() => {
    setOtp(initialOtp);
    onChange(initialOtp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Parent component'in ref'i üzerinden bu fonksiyonu çağırabilmek için useImperativeHandle kullanılır
  useImperativeHandle(
    ref,
    () => ({
      handleClear,
    }),
    [handleClear]
  );

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');

    //Focus on the last input after pasting the OTP
    const lastInput = document.getElementById(`otp-input-${otp.length - 1}`);
    if (lastInput) {
      lastInput.focus();
    }

    // Check if the pasted data is valid OTP (6 digits)
    if (pastedData.length === 6 && /^\d+$/.test(pastedData)) {
      const newOtp = pastedData.split('').slice(0, 6);
      setOtp(newOtp);
      onChange(newOtp);

      // Trigger verification if all inputs are filled
      if (newOtp.every((digit) => digit !== '')) {
        handleVerifyCode(newOtp.join(''));
      }
    }
  };

  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    // If backspace is pressed and input is empty, focus on the previous input
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      const prevInput = document.getElementById(`otp-input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  return (
    <StyledOtpContainer align="middle" justify="space-between">
      {otp.map((digit, index) => (
        <Col>
          <StyledInput
            key={index}
            type="text"
            id={`otp-input-${index}`}
            value={digit}
            onChange={(e) => handleChange(e, index)}
            maxLength={1}
            onPaste={handlePaste}
            onKeyDown={(e) => handleBackspace(e, index)}
          />
        </Col>
      ))}
    </StyledOtpContainer>
  );
};

const ForwardedChildComponent = forwardRef<ChildRef, OTPInputProps>(OTPInput);

export default ForwardedChildComponent;
