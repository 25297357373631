import { useLayoutEffect } from 'react';

import { Button, Row, Space, Flex, Typography } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { paymentApi } from '@/api';
import Check from '@/assets/icons/icon-check.svg?react';
import useAuth from '@/hooks/useAuth';
import paths from '@/routes/paths';
import LocalStorageService from '@/services/LocalStorageService';
import { PlansCardProps } from '@/types/plan';
import { convertNumberToCommaSeperated, handleError } from '@/utils';

import StyledPlansCard from './styles';

const PlansCard: React.FC<PlansCardProps> = ({
  title,
  description,
  amount,
  payPlan,
  savedAmount,
  details,
  button = 'Get Started',
  $isEnterpriseXL = false,
  $isEnterprise = false,
  $isIndividual = false,
  $isTeam = false,
  yearly,
  save = true,
  //planKey,
  setLoading,
}) => {
  const { revalidateUser } = useAuth();
  const navigate = useNavigate();
  const params = useSearchParams();
  const { data, isLoading } = useSWR('/payment/plans');
  const demoKey = data[0]?.key;
  const requestDemo = {
    planKey: demoKey,
    cardId: 0,
  };

  const handleSubscribeDemo = async () => {
    try {
      setLoading && setLoading(true);
      if (!isLoading) {
        await paymentApi.postSubscribe(requestDemo);
      } else {
        throw new Error('No plan key available.');
      }
      setTimeout(async () => {
        await revalidateUser();
        navigate(paths.simulation.base);
      }, 7000);
      LocalStorageService.clearLocalStorage();
    } catch (error) {
      handleError(error);
      setLoading && setLoading(false);
    }
  };

  useLayoutEffect(() => {
    const subscribe = async () => {
      if (!demoKey || isLoading || params[0]?.toString() !== 'try-free=')
        return;
      try {
        setLoading && setLoading(true);
        await paymentApi.postSubscribe(requestDemo);
        setTimeout(async () => {
          await revalidateUser();
          navigate(paths.simulation.base);
        }, 7000);
        LocalStorageService.clearLocalStorage();
      } catch (error) {
        console.error(error);
      }
    };

    subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demoKey, isLoading]);

  return (
    <StyledPlansCard $isTeam={$isTeam} $isEnterpriseXL={$isEnterpriseXL}>
      <Row className="first-row">
        <Space direction="vertical" size="small">
          <Typography.Title level={3} className="text-dark-color fs-20-bold">
            {title}
          </Typography.Title>
          <Typography.Text
            className="text-faded-color fs-16-regular"
            style={{ display: 'block', height: '42px' }}
          >
            {description}
          </Typography.Text>
          {!$isEnterpriseXL && (
            <Space size="small">
              <Typography.Title
                level={4}
                className="text-dark-color fs-36-bold title-amount"
              >
                {$isIndividual || $isTeam
                  ? convertNumberToCommaSeperated(Number(amount)) + '€'
                  : !$isEnterpriseXL && amount}
              </Typography.Title>
              <Typography.Text className="text-dark-color fs-16-regular d-block pt-8 title-plan">
                {$isIndividual || $isTeam ? '/' + payPlan : ''}
              </Typography.Text>
            </Space>
          )}
          <Row>
            <Typography.Text className="text-dark-color fs-16-regular">
              {$isIndividual || $isTeam ? (
                yearly ? (
                  'Billed Yearly'
                ) : (
                  'Billed Monthly'
                )
              ) : (
                <span style={{ visibility: 'hidden' }}>Lorem</span>
              )}
            </Typography.Text>
          </Row>
          <Flex vertical>
            {save && yearly ? (
              <Typography.Text className="fs-14-bold text-blue-color">
                Save {savedAmount} €
              </Typography.Text>
            ) : (
              !$isEnterprise && (
                <Typography.Text style={{ visibility: 'hidden' }}>
                  Save {savedAmount} €
                </Typography.Text>
              )
            )}
          </Flex>
        </Space>
      </Row>
      {$isEnterpriseXL ? (
        <>
          <Row className="second-row">
            <Space direction="vertical" size="small">
              {details?.map((detail, index) => (
                <Space key={index} size="small">
                  <Check fill="#3E8AC6" />
                  <Typography.Text className="text-dark-color fs-16-regular">
                    {detail}
                  </Typography.Text>
                </Space>
              ))}
            </Space>
          </Row>
          <Row className="third-row">
            <Link to={paths.register.contactUs}>
              <Button type="default" className="fs-14-medium btn">
                {button}
              </Button>
            </Link>
          </Row>
        </>
      ) : (
        <>
          <Row className="enterprise-row">
            <Space direction="vertical" size="small">
              {details?.map((detail, index) => (
                <Space key={index} size="small">
                  <Check fill="#3E8AC6" />
                  <Typography.Text className="text-dark-color fs-16-regular">
                    {detail}
                  </Typography.Text>
                </Space>
              ))}
            </Space>
          </Row>
          {button === 'Get Started' ? (
            <Row justify="center" className="bottom-row">
              <Button
                type="default"
                className="fs-14-medium btn"
                onClick={handleSubscribeDemo}
              >
                {button}
              </Button>
            </Row>
          ) : (
            <Row justify="center" className="bottom-row">
              <Link to={paths.register.contactUs}>
                <Button type="default" className="fs-14-medium btn">
                  {button}
                </Button>
              </Link>
            </Row>
          )}
        </>
      )}
    </StyledPlansCard>
  );
};

export default PlansCard;
