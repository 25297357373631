import { Col } from 'antd';
import styled from 'styled-components';

export default styled(Col)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .home-btn {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 160px;
    transition: all 0.4s;

    &:hover {
      width: 200px;
      transition: all 0.4s;
      /* transform:scaleY(1.1); */
      cursor: pointer;
      padding-right: 40px;

      .ant-btn-icon {
        width: auto;
        height: 12px;
        opacity: 1;
        transform: translateX(20px);
        .arrow-right {
          height: 12px;
        }
      }
    }

    .ant-btn-icon {
      position: absolute;
      right: 20px;
      width: 0px;
      height: 0px;
      /* margin-right: 20px; */
      height: 16px;
      opacity: 0;
      transition: all 0.4s;
      .arrow-right {
        height: 0px;
      }
    }
  }
`;
