import { useState } from 'react';

import { ColumnsType } from 'antd/lib/table';
import { useParams } from 'react-router';
import useSWR from 'swr';

import Table from '@/components/RTable';
import {
  SimulationResult,
  SimulationResultYearlyFinancialAnalysis,
} from '@/types/simulations';
import { converToMillion, formatNumberWithCommas } from '@/utils';

import StyledProfit from './styles';

interface DataType {
  key: React.Key;
  total?: string | JSX.Element;
  energyAmount?: string;
  unit?: string;
  averagePrice?: string;
  charge?: string;
}

const ProfitAndLossTableYearly = () => {
  const resultId = useParams<Record<string, string>>();
  const { data: financialData } =
    useSWR<SimulationResultYearlyFinancialAnalysis>(
      `/simulation/${resultId.resultId}/yearly_financial_analysis/`
    );
  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`
  );
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (page: number) => {
    setCurrentPage(page);
  };

  let years: number[] = [];

  financialData?.graphData.forEach((item) => {
    years.push(item.year);
  });

  years = [...new Set(years)].sort((a, b) => a - b);

  if (!financialData || !simulationData) {
    return null;
  }

  const generateDataSource = (page: number): DataType[] => {
    if (page === 1) {
      // Total page using simulationData
      return [
        {
          key: '1',
          total: 'Storage',
        },
        {
          key: '2',
          total: 'Sold Energy from Generated and Stored',
          energyAmount: formatNumberWithCommas(
            Number(
              simulationData.result.soldEnergyFromDischargePlant.toFixed(2)
            )
          ),
          unit: 'MWh',
          averagePrice:
            simulationData.result.averageSalesPriceFromDischargePlant.toFixed(
              2
            ) + ' $',
          charge:
            converToMillion(simulationData.result.revenueFromDischargePlant) +
            ' $',
        },
        {
          key: '3',
          total: 'Sold Energy from Purchased and Stored',
          energyAmount: formatNumberWithCommas(
            Number(
              simulationData.result.soldEnergyFromDischargePurchased.toFixed(2)
            )
          ),
          unit: 'MWh',
          averagePrice:
            simulationData.result.averageSalesPriceFromDischargePurchased.toFixed(
              2
            ) + ' $',
          charge:
            converToMillion(
              simulationData.result.revenueFromDischargePurchased
            ) + ' $',
        },
        {
          key: '4',
          total: 'Sold Energy from Storage',
          energyAmount: formatNumberWithCommas(
            Number(simulationData.result.soldEnergyFromDischarge.toFixed(2))
          ),
          unit: 'MWh',
          averagePrice:
            simulationData.result.averageSalesPriceFromDischarge.toFixed(2) +
            ' $',
          charge:
            converToMillion(simulationData.result.revenueFromDischarge) + ' $',
        },
        {
          key: '5',
          total: <div style={{ opacity: 0 }}>A</div>,
        },
        {
          key: '6',
          total: 'Offered Capacity for PFC',
          energyAmount: formatNumberWithCommas(
            Number(simulationData.result.offeredCapacityOfPfc.toFixed(2))
          ),
          unit: 'MW',
          averagePrice:
            simulationData.result.averagePriceOfPfc.toFixed(2) + ' $',
          charge:
            converToMillion(
              simulationData.result.revenueFromOfferedCapacityOfPfc
            ) + ' $',
        },
        {
          key: '7',
          total: 'Offered Capacity for SFC',
          energyAmount: formatNumberWithCommas(
            Number(simulationData.result.offeredCapacityOfSfc.toFixed(2))
          ),
          unit: 'MW',
          averagePrice:
            simulationData.result.averagePriceOfSfc.toFixed(2) + ' $',
          charge:
            converToMillion(
              simulationData.result.revenueFromOfferedCapacityOfSfc
            ) + ' $',
        },
        {
          key: '8',
          total: 'Offered Capacity for Ancillary Services',
          energyAmount: formatNumberWithCommas(
            Number(simulationData.result.totalOfferedCapacity.toFixed(2))
          ),
          unit: 'MW',
          averagePrice:
            simulationData.result.averagePriceOfAncillaryServices.toFixed(2) +
            ' $',
          charge:
            converToMillion(simulationData.result.revenueFromOfferedCapacity) +
            ' $',
        },
        {
          key: '9',
          total: <div style={{ opacity: 0 }}>A</div>,
        },
        {
          key: '10',
          total: 'Plant',
        },
        {
          key: '11',
          total: 'Sold Energy from Plant',
          energyAmount: formatNumberWithCommas(
            Number(simulationData.result.soldEnergyFromPlant.toFixed(2))
          ),
          unit: 'MWh',
          averagePrice:
            simulationData.result.averageSalesPriceFromPlant.toFixed(2) + ' $',

          charge:
            converToMillion(simulationData.result.revenueFromPlant) + ' $',
        },
        {
          key: '12',
          total: <div style={{ opacity: 0 }}>A</div>,
        },
        {
          key: '13',
          total: 'Cost',
        },
        {
          key: '14',
          total: 'Energy Purchased',
          energyAmount: formatNumberWithCommas(
            Number(simulationData.result.purchasedEnergy.toFixed(2))
          ),
          unit: 'MWh',
          averagePrice:
            simulationData.result.averageCostOfPurchasedEnergy.toFixed(2) +
            ' $',
          charge:
            converToMillion(simulationData.result.costOfPurchasedEnergy) + ' $',
        },
      ];
    } else {
      // Financial data for other pages
      return [
        {
          key: '1',
          total: 'Storage',
        },
        {
          key: '2',
          total: 'Sold Energy from Generated and Stored',
          energyAmount: formatNumberWithCommas(
            Number(
              financialData.graphData[
                currentPage - 2
              ].soldEnergyFromDischargePlant.toFixed(2)
            )
          ),
          unit: 'MWh',
          averagePrice:
            financialData.graphData[
              currentPage - 2
            ].averageSalesPriceFromDischargePlant.toFixed(2) + ' $',
          charge:
            converToMillion(
              financialData.graphData[currentPage - 2].revenueFromDischargePlant
            ) + ' $',
        },
        {
          key: '3',
          total: 'Sold Energy from Purchased and Stored',
          energyAmount: formatNumberWithCommas(
            Number(
              financialData.graphData[
                currentPage - 2
              ].soldEnergyFromDischargePurchased.toFixed(2)
            )
          ),
          unit: 'MWh',
          averagePrice:
            financialData.graphData[
              currentPage - 2
            ].averageSalesPriceFromDischargePurchased.toFixed(2) + ' $',
          charge:
            converToMillion(
              financialData.graphData[currentPage - 2].batteryCapex
            ) + ' $',
        },
        {
          key: '4',
          total: 'Sold Energy from Storage',
          energyAmount: formatNumberWithCommas(
            Number(
              financialData.graphData[
                currentPage - 2
              ].soldEnergyFromDischarge.toFixed(2)
            )
          ),
          unit: 'MWh',
          averagePrice:
            financialData.graphData[
              currentPage - 2
            ].averageSalesPriceFromDischarge.toFixed(2) + ' $',
          charge:
            converToMillion(
              financialData.graphData[currentPage - 2].revenueFromDischarge
            ) + ' $',
        },
        {
          key: '5',
          total: <div style={{ opacity: 0 }}>A</div>,
        },
        {
          key: '6',
          total: 'Offered Capacity for PFC',
          energyAmount: formatNumberWithCommas(
            Number(
              financialData.graphData[
                currentPage - 2
              ].offeredCapacityOfPfc.toFixed(2)
            )
          ),
          unit: 'MW',
          averagePrice:
            financialData.graphData[currentPage - 2].averagePriceOfPfc.toFixed(
              2
            ) + ' $',
          charge:
            converToMillion(
              financialData.graphData[currentPage - 2]
                .revenueFromOfferedCapacityOfPfc
            ) + ' $',
        },
        {
          key: '7',
          total: 'Offered Capacity for SFC',
          energyAmount: formatNumberWithCommas(
            Number(
              financialData.graphData[
                currentPage - 2
              ].offeredCapacityOfSfc.toFixed(2)
            )
          ),
          unit: 'MW',
          averagePrice:
            financialData.graphData[currentPage - 2].averagePriceOfSfc.toFixed(
              2
            ) + ' $',
          charge:
            converToMillion(
              financialData.graphData[currentPage - 2]
                .revenueFromOfferedCapacityOfSfc
            ) + ' $',
        },
        {
          key: '8',
          total: 'Offered Capacity for Ancillary Services',
          energyAmount: formatNumberWithCommas(
            Number(
              financialData.graphData[
                currentPage - 2
              ].totalOfferedCapacity.toFixed(2)
            )
          ),
          unit: 'MW',
          averagePrice:
            financialData.graphData[
              currentPage - 2
            ].averagePriceOfAncillaryServices.toFixed(2) + ' $',
          charge:
            converToMillion(
              financialData.graphData[currentPage - 2].batteryCapex
            ) + ' $',
        },
        {
          key: '9',
          total: <div style={{ opacity: 0 }}>A</div>,
        },
        {
          key: '10',
          total: 'Plant',
        },
        {
          key: '11',
          total: 'Sold Energy from Plant',
          energyAmount: formatNumberWithCommas(
            Number(
              financialData.graphData[
                currentPage - 2
              ].soldEnergyFromPlant.toFixed(2)
            )
          ),
          unit: 'MWh',
          averagePrice:
            financialData.graphData[
              currentPage - 2
            ].averageSalesPriceFromPlant.toFixed(2) + ' $',
          charge:
            converToMillion(
              financialData.graphData[currentPage - 2].revenueFromPlant
            ) + ' $',
        },
        {
          key: '12',
          total: <div style={{ opacity: 0 }}>A</div>,
        },
        {
          key: '13',
          total: 'Cost',
        },
        {
          key: '14',
          total: 'Energy Purchased',
          energyAmount: formatNumberWithCommas(
            Number(
              financialData.graphData[currentPage - 2].purchasedEnergy.toFixed(
                2
              )
            )
          ),
          unit: 'MWh',
          averagePrice:
            financialData.graphData[
              currentPage - 2
            ].averageCostOfPurchasedEnergy.toFixed(2) + ' $',
          charge:
            converToMillion(
              financialData.graphData[currentPage - 2].costOfPurchasedEnergy
            ) + ' $',
        },
      ];
    }
  };

  const generateColumns = (firstColumnTitle: string): ColumnsType<DataType> => [
    {
      title: firstColumnTitle,
      dataIndex: 'total',
      key: 'total',
      width: '24%',
    },
    {
      title: 'Energy Amount',
      dataIndex: 'energyAmount',
      key: 'energyAmount',
      width: '19%',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      width: '19%',
    },
    {
      title: 'Average Price ($)',
      dataIndex: 'averagePrice',
      key: 'averagePrice',
      width: '19%',
    },
    {
      title: 'Charge ($)',
      dataIndex: 'charge',
      key: 'charge',
      width: '19%',
    },
  ];

  const firstColumnTitle =
    currentPage === 1 ? 'Total' : `Year ${currentPage - 1}`;
  const columns = generateColumns(firstColumnTitle);

  return (
    <>
      <StyledProfit>
        <Table
          dataSource={generateDataSource(currentPage)}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: 16,
            total: (years.length + 2) * 14,
            onChange: handleChange,
            showSizeChanger: false,
          }}
        />
      </StyledProfit>
    </>
  );
};

export default ProfitAndLossTableYearly;
