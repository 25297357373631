import { useEffect, useState } from 'react';

import { Table } from 'antd';
import { TableProps } from 'antd/lib';

import { initColumns } from '@/constants/OnboardingPaymentsPlansTable/dataComparisonColumns';
import { tableData } from '@/constants/OnboardingPaymentsPlansTable/dataComparisonTable';
import { ComparisonType, ComparisonProps, Plan } from '@/types/plan';
import { convertNumberToCommaSeperated } from '@/utils';

import StyledComparison from './styles';

const ComparisonTable: React.FC<ComparisonProps> = ({
  yearly,
  data,
  monthlyPlan,
  yearlyPlan,
  percentage,
}) => {
  const [columns, setColumns] =
    useState<TableProps<ComparisonType>['columns']>(initColumns);

  data?.forEach((item: Plan) => {
    item.features = [
      `${item.durationAmount} ${item.durationUnit}`,
      `${item.dataRetentionAmount} ${item.dataRetentionUnit}s`,
      `${item.simultaneousSimLimit}`,
      `${item.userLimit} User`,
      `${item.simAssetLimit}`,
      `${item.other.support}`,
    ];
    return item;
  });

  const selectedPlan = yearly ? yearlyPlan : monthlyPlan;

  selectedPlan?.forEach((item: Plan) => {
    if (item.name === 'enterprise') {
      item.features = [
        `${item.durationAmount} ${item.durationUnit}`,
        `${item.dataRetentionAmount} ${item.dataRetentionUnit}s`,
        item.simultaneousSimLimit === 32767
          ? 'Unlimited'
          : `${item.simultaneousSimLimit}`,
        item.userLimit === 32767 ? 'Unlimited' : `${item.userLimit}`,
        item.simAssetLimit === 32767 ? 'Unlimited' : `${item.simAssetLimit}`,
        `${item.other.support}`,
      ];
      return item;
    }
    if (item.name === 'team') {
      item.features = [
        `${item.durationAmount} ${item.durationUnit}`,
        `${item.dataRetentionAmount} ${item.dataRetentionUnit}s`,
        `${item.simultaneousSimLimit}`,
        `${item.userLimit} User`,
        item.simAssetLimit === 32767 ? 'Unlimited' : `${item.simAssetLimit}`,
        `${item.other.support}`,
      ];
      return item;
    }
    item.features = [
      `${item.durationAmount} ${item.durationUnit}`,
      `${item.dataRetentionAmount} ${item.dataRetentionUnit}s`,
      `${item.simultaneousSimLimit}`,
      `${item.userLimit} User`,
      `${item.simAssetLimit}`,
      `${item.other.support}`,
    ];

    return item;
  });

  // Update columns based on yearly prop
  useEffect(() => {
    const newColumns = [...(initColumns || [])];
    newColumns[3].title = (
      <div>
        <p style={{ margin: '0' }}>Team </p>
        <p style={{ margin: '0' }} className="fs-12-regular">
          {yearly ? ' yearly' : ' monthly'}
        </p>
      </div>
    );
    newColumns[2].title = (
      <div>
        <p style={{ margin: '0' }}>Individual</p>
        <p style={{ margin: '0' }} className="fs-12-regular">
          {yearly ? ' yearly' : ' monthly'}
        </p>
      </div>
    );
    newColumns[4].title = (
      <div>
        <p style={{ margin: '0' }}>Enterprise</p>
        <p style={{ margin: '0' }} className="fs-12-regular">
          {yearly ? ' yearly' : ' monthly'}
        </p>
      </div>
    );
    newColumns[0].title = (
      <div>
        <p>See all features</p>
      </div>
    );
    setColumns(newColumns);
  }, [yearly, percentage]);

  // Update tableData based on BACKEND DATA
  useEffect(() => {
    if (yearly) {
      if (yearlyPlan) {
        // Prices
        tableData[21].Individual =
          convertNumberToCommaSeperated(Number(yearlyPlan[0]?.amount)) + ' €';
        tableData[21].Team =
          convertNumberToCommaSeperated(Number(yearlyPlan[1]?.amount)) + ' €';
        // Time
        tableData[22].Demo = data[0]?.features?.[0].concat('s') || '';
        tableData[22].Individual = yearlyPlan[0]?.features?.[0] || '';
        tableData[22].Team = yearlyPlan[1]?.features?.[0] || '';
        tableData[22].Enterprise = yearlyPlan[2]?.features?.[0] || '';
        // Data Retention After Suspension
        tableData[23].Demo = data[0]?.features?.[1] || '';
        tableData[23].Individual = yearlyPlan[0]?.features?.[1] || '';
        tableData[23].Team = yearlyPlan[1]?.features?.[1] || '';
        tableData[23].Enterprise = yearlyPlan[2]?.features?.[1] || '';
        // Simultaneous Simulations
        tableData[24].Demo = data[0]?.features?.[2] || '';
        tableData[24].Individual = yearlyPlan[0]?.features?.[2] || '';
        tableData[24].Team = yearlyPlan[1]?.features?.[2] || '';
        tableData[24].Enterprise = yearlyPlan[2]?.features?.[2] || '';
        // Total Users
        tableData[25].Demo = data[0]?.features?.[3] || '';
        tableData[25].Individual = yearlyPlan[0]?.features?.[3] || '';
        tableData[25].Team = yearlyPlan[1]?.features?.[3]?.concat('s') || '';
        tableData[25].Enterprise = yearlyPlan[2]?.features?.[3] || '';
        // Sim Asset Limit - Plant, Storage, Scenario Templates
        tableData[26].Demo = data[0]?.features?.[4] || '';
        tableData[26].Individual = yearlyPlan[0]?.features?.[4] || '';
        tableData[26].Team = yearlyPlan[1]?.features?.[4] || '';
        tableData[26].Enterprise = yearlyPlan[2]?.features?.[4] || '';
        // Role Based Access
        tableData[27].Enterprise = 'Optional';
        tableData[28].Enterprise = 'Optional';
        tableData[29].Enterprise = 'Optional';
        tableData[30].Enterprise = 'Optional';
        tableData[31].Enterprise = 'Optional';
        tableData[32].Enterprise = 'Optional';
        // Support
        tableData[33].Demo = data[0]?.features?.[5] || '';
        tableData[33].Individual = yearlyPlan[0]?.other.support || '';
        tableData[33].Team = yearlyPlan[1]?.other.support || '';
        tableData[33].Enterprise = yearlyPlan[2]?.other.support || '';
      }
    } else {
      if (monthlyPlan) {
        // Prices
        tableData[21].Individual =
          convertNumberToCommaSeperated(Number(monthlyPlan[0]?.amount)) + ' €';
        tableData[21].Team =
          convertNumberToCommaSeperated(Number(monthlyPlan[1]?.amount)) + ' €';
        // Time
        tableData[22].Demo = data[0]?.features?.[0].concat('s') || '';
        tableData[22].Individual = monthlyPlan[0]?.features?.[0] || '';
        tableData[22].Team = monthlyPlan[1]?.features?.[0] || '';
        tableData[22].Enterprise = monthlyPlan[2]?.features?.[0] || '';
        // Data Retention After Suspension
        tableData[23].Demo = data[0]?.features?.[1] || '';
        tableData[23].Individual = monthlyPlan[0]?.features?.[1] || '';
        tableData[23].Team = monthlyPlan[1]?.features?.[1] || '';
        tableData[23].Enterprise = monthlyPlan[2]?.features?.[1] || '';
        // Simultaneous Simulations
        tableData[24].Demo = data[0]?.features?.[2] || '';
        tableData[24].Individual = monthlyPlan[0]?.features?.[2] || '';
        tableData[24].Team = monthlyPlan[1]?.features?.[2] || '';
        tableData[24].Enterprise = monthlyPlan[2]?.features?.[2] || '';
        // Total Users
        tableData[25].Demo = data[0]?.features?.[3] || '';
        tableData[25].Individual = monthlyPlan[0]?.features?.[3] || '';
        tableData[25].Team = monthlyPlan[1]?.features?.[3]?.concat('s') || '';
        tableData[25].Enterprise = monthlyPlan[2]?.features?.[3] || '';
        // Sim Asset Limit - Plant, Storage, Scenario Templates
        tableData[26].Demo = data[0]?.features?.[4] || '';
        tableData[26].Individual = monthlyPlan[0]?.features?.[4] || '';
        tableData[26].Team = monthlyPlan[1]?.features?.[4] || '';
        tableData[26].Enterprise = monthlyPlan[2]?.features?.[4] || '';
        // Role Based Access
        tableData[27].Enterprise = 'Optional';
        tableData[28].Enterprise = 'Optional';
        tableData[29].Enterprise = 'Optional';
        tableData[30].Enterprise = 'Optional';
        tableData[31].Enterprise = 'Optional';
        tableData[32].Enterprise = 'Optional';
        // Support
        tableData[33].Demo = data[0]?.features?.[5] || '';
        tableData[33].Individual = monthlyPlan[0]?.other.support || '';
        tableData[33].Team = monthlyPlan[1]?.other.support || '';
        tableData[33].Enterprise = monthlyPlan[2]?.other.support || '';
      }
    }
  }, [yearly, data, monthlyPlan, yearlyPlan]);

  return (
    <StyledComparison>
      <Table
        columns={columns}
        dataSource={tableData}
        bordered
        pagination={false}
        style={{ tableLayout: 'fixed' }}
      />
    </StyledComparison>
  );
};

export default ComparisonTable;
