import { AxiosProgressEvent } from 'axios';

import type { FilterParams } from '@/types/general';

import type {
  Scenario,
  ScenarioDraftPNLPFile,
  ScenarioDraftPNLPFileResponse,
  ScenarioPost,
  ScenariosResponse,
} from '../../types/scenario';
import { apiV1 } from '../instances';

export default {
  fetchScenarios(params: FilterParams): Promise<ScenariosResponse> {
    const fullFill = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== '')
    );
    const query = new URLSearchParams({
      ...fullFill,
      limit: fullFill.limit?.toString(),
      offset: fullFill.offset?.toString(),
    });
    return apiV1.get(`/scenario/?${query.toString()}/`);
  },

  createScenario(scenario: ScenarioPost) {
    return apiV1.postFormData('/scenario/', scenario);
  },
  editScenario(scenarioId: string, scenario: Scenario) {
    return apiV1.put(`/scenario/${scenarioId}/`, scenario);
  },
  deleteScenario(scenarioId: string) {
    return apiV1.delete(`/scenario/${scenarioId}/`);
  },
  duplicateScenario(scenarioId: string, scenario: ScenarioPost) {
    return apiV1.postFormData(`/scenario/${scenarioId}/duplicate/`, scenario);
  },
  viewScenario(scenarioId: string): Promise<Scenario> {
    return apiV1.get(`/scenario/${scenarioId}/`);
  },
  deactiveScenario(scenarioId: string) {
    return apiV1.delete(`/scenario/${scenarioId}/`);
  },
  activateScenario(scenarioId: string) {
    return apiV1.post(`/scenario/${scenarioId}/activate/`);
  },
  searchScenarioByName(scenarioName: string) {
    return apiV1.get(`/scenario/?name=${scenarioName}`);
  },
  searchScenarioByExactName(scenarioName: string) {
    return apiV1.get(`/scenario/?name_exact=${scenarioName}`);
  },

  postDraftScenarioPNLFile: (
    pnl: ScenarioDraftPNLPFile,
    onProgress?: (progress: { percent: number }) => void
  ): Promise<ScenarioDraftPNLPFileResponse> => {
    return apiV1.postFormData('/scenario/draft/', pnl, {
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress && onProgress({ percent: percentCompleted });
        }
      },
    });
  },

  // getDraftPlantGenerationFileGraph(
  //   id: number,
  //   params: ScenarioDraftPNLGraphFilterParams
  // ): Promise<ScenarioPricesAndLoad> {
  //   const fullFill = Object.fromEntries(
  //     Object.entries(params).filter(([, value]) => value !== '')
  //   );
  //   const query = new URLSearchParams(fullFill);

  //   return apiV1.get(`/scenario/${id}/prices_load_graph/?${query}`);
  // },
};
