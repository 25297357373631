import { create } from 'zustand';

import { ScrollDirection } from '@/types/global';

type ScrollState = {
  $scrollDirection: ScrollDirection;
  setScrollDirection: ($scrollDirection: ScrollDirection) => void;
  reset: () => void;
};

export const useContentScrollDirection = create<ScrollState>((set) => ({
  $scrollDirection: null,
  setScrollDirection: ($scrollDirection) => {
    set({ $scrollDirection: $scrollDirection });
  },
  reset: () => {
    set({ $scrollDirection: null });
  },
}));
