import { StepsProps } from 'antd';

import StyledSteps from './styles';

/**
 * TODO/SB : No need for ExtendedStepsProps, we can only use StepsProps
 */

interface ExtendedStepsProps extends StepsProps {}

const CustomSteps: React.FC<ExtendedStepsProps> = ({ ...props }) => {
  return <StyledSteps {...props}></StyledSteps>;
};

export default CustomSteps;
