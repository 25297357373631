import { Button, Flex, FlexProps } from 'antd';
import styled from 'styled-components';

type ButtonGroupProps = FlexProps & {
  height?: string;
};

export const StyledButtonGroup = styled(Flex)<ButtonGroupProps>`
  width: auto;
  padding: 4px 5px;
  gap: 8px;
  border-radius: 6px;
  height: 32px;
  border: 0.5px solid ${({ theme }) => theme.colors.grayLightAccent};
  height: ${({ height }) => height || '32px'};
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled(Button)<{ $active: boolean }>`
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.blueDarkAccent : 'transparent'};
  color: ${({ theme, $active }) =>
    $active ? theme.colors.light : theme.colors.grayDarkPrimary};
  border: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding: 1.5px 21px;
  border-radius: 6px;
  height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${({ theme, $active }) =>
      $active ? theme.colors.blueDarkAccent : theme.colors.light};
  }
`;
