import { useEffect, useState } from 'react';

import { Form, Button, Typography, Row, Col, Input, Flex } from 'antd';
import Helmet from 'react-helmet';

import { authApi } from '@/api';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Redirector from '@/components/RRedirector';
import Tooltip from '@/components/RTooltip';
import LocalStorageService from '@/services/LocalStorageService';
import { LoginPayload } from '@/types/auth';
import {
  displayNotification,
  handleError,
  validateStrongPassword,
} from '@/utils';

import PasswordRecoveryStyles from './styles';

type NewPasswordForm = {
  passwordValidation: string;
  password: string;
};

const { Success } = Redirector;

const PasswordRecovery = () => {
  const [form] = Form.useForm();
  const [email, setEmail] = useState<string>('');
  const [isEmailSentSuccessfully, setIsEmailSentSuccessfully] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(true);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [decodedValue, setDecodedValue] = useState('');

  useEffect(() => {
    const query = location.search.substring(1);

    if (query) {
      const decoded = atob(query); // Decode the base64 string
      setDecodedValue(decoded);
    }
  }, []);
  const userId = decodedValue ? decodedValue.split('&')[0].split('=')[1] : '';
  const code = decodedValue ? decodedValue.split('&')[1].split('=')[1] : '';

  const isInitialState = !userId && !code && !isEmailSentSuccessfully;
  const isNewPasswordCreationStep = userId && code && !isEmailSentSuccessfully;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsSubmitButtonDisabled(!e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { passwordValidation } = form.getFieldsValue();
    setIsSubmitButtonDisabled(
      !e.target.value ||
        !passwordValidation ||
        e.target.value !== passwordValidation
    );
  };

  const onFinish = async (values: LoginPayload) => {
    const currentTimestamp = new Date().getTime();
    const lastEmailSentTimestamp = Number(
      LocalStorageService.getEmailSenderTS()
    );
    const oneMinuteInMilliseconds = 60000;
    const isMoreThanOneMinutePassed =
      !lastEmailSentTimestamp ||
      currentTimestamp - lastEmailSentTimestamp > oneMinuteInMilliseconds;
    const { email } = values;

    if (isMoreThanOneMinutePassed) {
      try {
        LocalStorageService.setEmailSenderTS(currentTimestamp.toString());
        await authApi.resetPasswordSend(email);
        setIsEmailSentSuccessfully(true);
      } catch (error) {
        handleError(error);
      }
    } else {
      displayNotification(
        'error',
        'Please wait for 1 minute before sending another email.'
      );
    }
  };

  const onFinishNewPasswordForm = async (values: NewPasswordForm) => {
    const { password, passwordValidation } = values;

    if (!password || !passwordValidation || !code || !userId) {
      throw new Error('Some required values are undefined.');
    }
    try {
      await authApi.resetPasswordConfirm({ code, password, userId });
      setIsPasswordChanged(true);
    } catch (error) {
      handleError(error);
    }
  };

  const onFinishFailed = (errorInfo: unknown) => {
    console.log('Failed:', errorInfo);
  };

  const description = isEmailSentSuccessfully
    ? `An email has been sent to ${email}.`
    : 'Your password has been successfully changed.';

  return (
    <>
      {isEmailSentSuccessfully || isPasswordChanged ? (
        <Redirector redirectionTimeMS={5000}>
          <Success description={description} />
        </Redirector>
      ) : (
        <PasswordRecoveryStyles>
          <Helmet>
            <title>Create Account - RatioSIM</title>
          </Helmet>
          <Row className="w-100 container">
            <Col span={24} className="sign-col">
              <Flex vertical className="w-100" align="center" gap={8}>
                <Typography.Title level={1} className="fs-42-semi-bold">
                  {isNewPasswordCreationStep
                    ? 'Create New Password'
                    : 'Password Recovery'}
                </Typography.Title>
                {!isNewPasswordCreationStep ? (
                  <Typography.Text className="fs-14-regular text-center ">
                    Please enter your e-mail to recover your password.
                  </Typography.Text>
                ) : (
                  <Typography.Text className="fs-14-regular text-center">
                    Almost done. Please enter your new password to complete the
                    process.
                  </Typography.Text>
                )}
              </Flex>
              <Flex vertical className="w-100">
                {isInitialState ? (
                  <Form
                    style={{ minWidth: '400px' }}
                    name="passwordRecovery"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Form.Item
                      label={
                        <Typography.Text className="fs-14-regular">
                          <span style={{ color: '#FF0000' }}>*</span> Email
                        </Typography.Text>
                      }
                      name="email"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        placeholder="example@example.com"
                        className="input-style"
                        size="large"
                        value={email}
                        onChange={handleEmailChange}
                        type="email"
                      />
                    </Form.Item>

                    <Button
                      className="btn-style"
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                      disabled={isSubmitButtonDisabled}
                    >
                      Recover My Password
                    </Button>
                  </Form>
                ) : (
                  isNewPasswordCreationStep && (
                    <>
                      <Form
                        style={{ minWidth: '400px' }}
                        name="createNewPassword"
                        form={form}
                        onFinish={onFinishNewPasswordForm}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                        requiredMark={false}
                      >
                        <Form.Item<NewPasswordForm>
                          label={
                            <Flex align="center" gap={8}>
                              <Typography.Text className="fs-14-regular">
                                New Password
                              </Typography.Text>
                              <Tooltip
                                title="Strong Password Policy"
                                description="Your password must include uppercase and lowercase letters, numbers, and at least one special character. "
                              >
                                <IconInfo />
                              </Tooltip>
                            </Flex>
                          }
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your password!',
                            },
                            { validator: validateStrongPassword },
                          ]}
                        >
                          <Input.Password
                            onChange={handlePasswordChange}
                            className="input-style"
                            autoComplete="new-password"
                          />
                        </Form.Item>
                        <Form.Item<NewPasswordForm>
                          label={
                            <Flex align="center" gap={8}>
                              <Typography.Text className="fs-14-regular">
                                Confirm New Password
                              </Typography.Text>
                              <Tooltip
                                title="Strong Password Policy"
                                description="Your password must include uppercase and lowercase letters, numbers, and at least one special character. "
                              >
                                <IconInfo />
                              </Tooltip>
                            </Flex>
                          }
                          name="passwordValidation"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue('password') === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error('The two passwords do not match!')
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            className="input-style"
                            autoComplete="new-password-validation"
                            onChange={handlePasswordChange}
                          />
                        </Form.Item>

                        <Button
                          className="btn-style"
                          type="primary"
                          htmlType="submit"
                          size="large"
                          block
                          disabled={isSubmitButtonDisabled}
                        >
                          Recover My Password
                        </Button>
                      </Form>
                    </>
                  )
                )}
              </Flex>
            </Col>
          </Row>
        </PasswordRecoveryStyles>
      )}
    </>
  );
};

export default PasswordRecovery;
