import { type FC } from 'react';

import { Outlet } from 'react-router-dom';

import Content from './Content';
import Header from './Header';
import StyledLayout from './styles';

const LayoutGuest: FC = () => {
  return (
    <StyledLayout>
      <Header />
      <Content>
        <Outlet />
      </Content>
      {/* <Footer /> */}
    </StyledLayout>
  );
};

export default LayoutGuest;
