import { Button, Typography } from 'antd';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import NotFoundImage from '@/assets/files/404.png';
import IconArrowRight from '@/assets/icons/icon-right.svg?react';
import paths from '@/routes/paths';

import StyledNotFound from './styles';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <StyledNotFound span={24}>
      <motion.div
        animate={{ y: 20 }}
        transition={{ repeat: Infinity, duration: 2, repeatType: 'reverse' }}
      >
        <img
          style={{ marginBottom: '5rem' }}
          src={NotFoundImage}
          alt="not-found"
        />
      </motion.div>
      <Typography.Title level={2}>Page not Found.</Typography.Title>
      <Button
        onClick={() => navigate(paths.landing)}
        className="mt-24 home-btn"
        icon={<IconArrowRight fill="white" className="arrow-right" />}
        size="large"
        type="primary"
      >
        Get back to Home
      </Button>
    </StyledNotFound>
  );
};

export default NotFound;
