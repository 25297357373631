import { FC } from 'react';

import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import { useTheme } from 'styled-components';

import IconPlus from '@/assets/icons/icon-plus.svg?react';
import { StorageSystemForm } from '@/types/storageSystem';
import { normalizePositiveNumericValuesInput } from '@/utils';

type Props = {
  disabled?: boolean;
  form: FormInstance<StorageSystemForm>;
};

const RateCapability: FC<Props> = ({ disabled = false, form }) => {
  const theme = useTheme();

  function appendToArrayField(fieldName: keyof StorageSystemForm) {
    const curValue = form.getFieldValue(fieldName) || [];
    form.setFieldValue(fieldName, [...curValue, {}]);
  }
  return (
    <Form.Item
      wrapperCol={{ span: 24 }}
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
      className="p-16"
    >
      <Typography.Text type="secondary">
        Storage Systems: Charge Speed vs. Efficiency and Charge/Depletion Time
        <br />
        <br />
        At higher C rates, not all batteries can maintain the same level of
        stored energy due to energy dissipation, such as heat, during high or
        low discharge rates
        <br />
        <br />- High Power Applications: Benefit from batteries with a high
        Power-to-Energy ratio, providing quick bursts of energy Energy ratio
        battery
        <br />- High Energy Applications: Benefit from batteries with a low
        Power-to-Energy ratio, ensuring longer sustained energy delivery.
      </Typography.Text>
      <Row className="mt-24" gutter={20}>
        <Col span={12}>
          <Form.Item
            label="Min Allowed C-Rate"
            name="minAllowedCRateForCharging"
            tooltip="Min charging speed allowed in terms of C-Rate"
            dependencies={['maxAllowedCRateForCharging']}
            rules={[
              { required: true, message: 'Min Allowed C-Rate cannot be empty' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const maxC = parseFloat(
                    getFieldValue('maxAllowedCRateForCharging')
                  );
                  const minC = parseFloat(value);
                  if (minC === 0) {
                    return Promise.reject('Min Allowed C-Rate cannot be 0');
                  }
                  if (maxC !== undefined && minC > maxC) {
                    return Promise.reject(
                      'Min Allowed C-Rate must be less than Max Allowed C-Rate'
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              placeholder="Min Allowed C-Rate"
              addonAfter="C"
              className="w-100"
              disabled={disabled}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Max Allowed C-Rate"
            name="maxAllowedCRateForCharging"
            tooltip="Max charging speed allowed in terms of C-Rate"
            rules={[
              { required: true, message: 'Max Allowed C-Rate cannot be empty' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const minC = parseFloat(
                    getFieldValue('minAllowedCRateForCharging')
                  );
                  const maxC = parseFloat(value);
                  if (maxC === 0) {
                    return Promise.reject('Min Allowed C-Rate cannot be 0');
                  }
                  if (minC !== undefined && maxC < minC) {
                    return Promise.reject(
                      'Max Allowed C-Rate must be greater than Min Allowed C-Rate'
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              placeholder="Max Allowed C-Rate"
              addonAfter="C"
              step={0.01}
              className="w-100"
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider
        style={{
          border: '0.5px solid',
          borderColor: theme.colors.grayLightAccent,
        }}
        className="mt-24 mb-24"
      />
      <Form.List initialValue={[{}]} name="rateCapabilities">
        {(fields, { remove }) => (
          <Col>
            {fields.map(({ key, name, ...restField }) => (
              <Row gutter={10} key={key} align="bottom" wrap={false}>
                <Col span={5.5}>
                  <Form.Item
                    label="Type"
                    {...restField}
                    name={[name, 'mode']}
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Select option"
                      style={{ minWidth: '200px', height: '34px' }}
                      disabled={disabled}
                    >
                      <Select.Option value={0}>Charge</Select.Option>
                      <Select.Option value={1}>Discharge</Select.Option>
                      <Select.Option value={2}>
                        Charge and Discharge
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5.5}>
                  <Form.Item
                    label="C-Rate"
                    {...restField}
                    name={[name, 'cRate']}
                    rules={[{ required: true }]}
                    normalize={normalizePositiveNumericValuesInput}
                  >
                    <Input
                      placeholder="C-Rate"
                      addonAfter="C"
                      className="w-100"
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={5.5}>
                  <Form.Item
                    {...restField}
                    label="Cycle Time"
                    name={[name, 'cycleLife']}
                    rules={[{ required: true }]}
                    normalize={normalizePositiveNumericValuesInput}
                  >
                    <Input
                      placeholder="Cycle Time"
                      addonAfter="Cycle"
                      className="w-100"
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={5.5}>
                  <Form.Item
                    {...restField}
                    label="Round-Trip Efficiency"
                    name={[name, 'batteryRoundTripEfficiency']}
                    rules={[{ required: true }]}
                    normalize={normalizePositiveNumericValuesInput}
                  >
                    <Input
                      placeholder="Round-Trip Efficiency"
                      className="w-100"
                      addonAfter="%"
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item>
                    <Button
                      disabled={disabled || fields.length === 1}
                      danger
                      block
                      onClick={() => {
                        remove(name);
                      }}
                      style={{ height: '34px' }}
                    >
                      Delete
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Row className="mt-12" align="middle" justify="end">
              <Button
                icon={<IconPlus fill={theme.colors.bluePrimary} />}
                type="dashed"
                onClick={() => appendToArrayField('rateCapabilities')}
                style={{ height: '34px' }}
                disabled={disabled}
              >
                Add C Rate
              </Button>
            </Row>
          </Col>
        )}
      </Form.List>
      <Divider
        style={{
          border: '0.5px solid',
          borderColor: theme.colors.grayLightAccent,
        }}
        className="mt-24 mb-24"
      />
    </Form.Item>
  );
};

export default RateCapability;
