import { Col, Drawer } from 'antd';
import styled from 'styled-components';

export default styled(Col)`
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .steps {
    width: 70%;
    display: flex;
    align-items: center;
    gap: 50px;
  }
  .ant-card-bordered {
    overflow: auto;
  }
  .content-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .cards {
      overflow: auto;
      .current-card {
        min-height: 200px;
        overflow: auto;
      }
    }
    .btn-next {
      width: 90px;
      height: 32px;
      margin-left: 8px;
    }
    .btn-cancel {
      width: 72px;
      height: 32px;
      color: ${({ theme }) => theme.colors.blueLightMedium};
      border-color: ${({ theme }) => theme.colors.blueLightMedium};
    }
    .btn-back {
      width: 83px;
      height: 32px;
      color: ${({ theme }) => theme.colors.blueLightMedium};
      border-color: ${({ theme }) => theme.colors.blueLightMedium};
    }
    .bottom-button-group {
      position: fixed;
      width: 85%;
      bottom: 0;
      right: 0;
      background: white;
      padding: 24px;
      box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1);
      z-index: 10;
    }
  }
  .ant-form-item .ant-form-item-label > label {
    color: ${({ theme }) => theme.colors.dark} !important;
  }
  .ant-collapse-expand-icon {
    height: 40px !important;
    padding-inline-end: 10.5px !important;
    span > svg {
      width: 18px;
      height: 18px;
    }
  }
  .ant-collapse-extra {
    display: flex;
    align-items: center;
    height: 40px;
    span {
      display: flex;
      align-items: center;
      height: 28px;
      div > span {
        display: flex;
        align-items: center;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 21px !important;
      }
    }
  }
  .ant-steps-item-title {
    display: flex;
    align-items: center;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 21px !important;
    height: 32px !important;
    padding-inline-end: 8px !important;
  }
  .ant-steps-label-horizontal > .ant-steps-item {
    flex: none !important;
  }
  .ant-steps-label-horizontal > .ant-steps-item {
    flex: none;
    .ant-steps-item-container {
      width: 250px !important;
    }
  }
  .ant-steps-label-horizontal .ant-steps-item:nth-child(2) > div {
    width: 190px !important;
  }
  .ant-steps-label-horizontal > .ant-steps-item:nth-child(n + 2) {
    padding-inline-start: 8px !important;
  }
  .ant-steps-item-icon {
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .ant-steps-icon > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-steps-item-active {
    .ant-steps-item-icon {
      background-color: ${({ theme }) => theme.colors.bluePrimary} !important;
      .ant-steps-icon {
        color: ${({ theme }) => theme.colors.light} !important;
      }
    }
  }
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    background-color: ${({ theme }) => theme.colors.light} !important;
    padding: 0px;
  }

  .sidebar {
    position: absolute !important;
    z-index: 9999;
    top: 0;
    left: 0;
    height: 100%;
    padding-top: 24px;
    width: 50px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.blueLightest};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .rotated-text {
      transform: rotate(90deg);
      transform-origin: left top;
      white-space: nowrap;
      width: 200px;
      margin-left: 42px;
      font-size: 21px;
      font-weight: 700;
      line-height: 30px;
    }
  }

  .header {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9998;
    width: 100%;

    padding: 0px 16px 0px 50px;
    background-color: ${({ theme }) => theme.colors.blueLightest};
    .ant-row-end {
      padding-top: 24px;
    }
    .page-header-sticky-top {
      padding: 24px 0px 24px 16px !important;
    }
    .ant-flex {
      align-items: center;
      margin-left: 5px;
    }
  }

  .content {
    position: absolute;
    left: 50px;
    right: 0px;
  }

  .assets-view {
    padding: 24px !important;
    overflow-x: hidden;
  }
`;
