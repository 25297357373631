import { useEffect } from 'react';

import { FormInstance } from 'antd';

import { CreateSimSteps, TourSteps } from '@/constants';
import { useAssets } from '@/hooks/useAssets';
import useProductTour from '@/hooks/useProductTour';
import useProductTourStore from '@/store/useProductTourStore';
import { useSelectedAssetsStore } from '@/store/useSelectedAssetsStore';

export const useProductTourSelectAsset = (
  current: number | undefined,
  form: FormInstance
) => {
  const simulationStartDate = form.getFieldValue('simulationStartDate');
  const years = form.getFieldValue('years');
  const { selectedAssets, setSelectedAssets } = useSelectedAssetsStore();
  const { assets } = useAssets(simulationStartDate, years);
  const { currentStep, isTourActive } = useProductTourStore();

  const assetSelectionEmptyFaceRef = useProductTour('assetSelectionEmptyFace');
  const assetSelectionFillFaceRef = useProductTour('assetSelectionFillFace');

  const activeAssetSelectionRef =
    currentStep === TourSteps.SELECT_ASSET_FILL_FACE
      ? assetSelectionFillFaceRef
      : assetSelectionEmptyFaceRef;

  const shouldAutoSelectAssets =
    currentStep === TourSteps.SELECT_ASSET_FILL_FACE &&
    isTourActive &&
    assets.plants.length > 0 &&
    assets.scenarios.length > 0 &&
    assets.storageSystems.length > 0 &&
    selectedAssets.plants.length === 0;

  useEffect(() => {
    if (!isTourActive) return;

    if (
      currentStep < TourSteps.SELECT_ASSET_FILL_FACE &&
      current === CreateSimSteps.ASSET_SELECTION
    ) {
      setSelectedAssets({
        plants: [],
        scenario: { id: '', name: '' },
        storageSystem: { id: '', name: '' },
      });
      return;
    }
    if (shouldAutoSelectAssets) {
      const firstPlant = assets.plants[0];
      const firstScenario = assets.scenarios[0];
      const firstStorageSystem = assets.storageSystems[0];

      setSelectedAssets({
        ...selectedAssets,
        plants: [{ id: firstPlant.id.toString(), name: firstPlant.name }],
        scenario: { id: firstScenario.id.toString(), name: firstScenario.name },
        storageSystem: {
          id: firstStorageSystem.id.toString(),
          name: firstStorageSystem.name,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets.plants, assets.scenarios, assets.storageSystems, currentStep]);

  return {
    activeAssetSelectionRef,
  };
};
