import { FC } from 'react';

import { Row, Typography } from 'antd';
import { TagProps } from 'antd/lib';
import { useTheme } from 'styled-components';

import Active from '@/assets/icons/icon-active.svg?react';
import Failure from '@/assets/icons/icon-close-circle.svg?react';
import Inactive from '@/assets/icons/icon-inactive.svg?react';
import Queued from '@/assets/icons/icon-queued.svg?react';
import Revoked from '@/assets/icons/icon-revoked.svg?react';
import Success from '@/assets/icons/icon-success.svg?react';

import StyledTag from './styles';

interface TagConfig {
  [key: string]: {
    icon: JSX.Element;
    text: string;
  };
}

// TODO/SB : We can make type as transient prop, since it is used for StyledComponents
interface ExtendedTagProps extends TagProps {
  type: string;
}

const RTag: FC<ExtendedTagProps> = ({ type }) => {
  const theme = useTheme();
  const tagConfig: TagConfig = {
    success: {
      icon: <Success fill={theme.colors.tagSuccess} />,
      text: 'Success',
    },
    failure: {
      icon: <Failure fill={theme.colors.tagFailure} />,
      text: 'Failed',
    },
    revoked: {
      icon: <Revoked fill={theme.colors.tagRevoked} />,
      text: 'Revoked',
    },
    inactive: {
      icon: <Inactive width={16} height={16} fill={theme.colors.tagFailure} />,
      text: 'Inactive',
    },
    active: {
      icon: <Active width={16} height={16} fill={theme.colors.tagSuccess} />,
      text: 'Active',
    },
    started: {
      icon: <Success fill={theme.colors.tagSuccess} />,
      text: 'Started',
    },
    queued: {
      icon: <Queued fill={theme.colors.tagQueued} />,
      text: 'Queued',
    },
  };
  const { icon, text } = tagConfig[type || 'success'];
  return (
    <StyledTag type={type}>
      <Row align="middle">
        {icon}
        <Typography.Text className="pl-8 fs-12">{text}</Typography.Text>
      </Row>
    </StyledTag>
  );
};

export default RTag;
