import { FC } from 'react';

import { TabsProps } from 'antd';

import StyledTab from './styles';

const RTab: FC<TabsProps> = (props) => {
  return <StyledTab destroyInactiveTabPane={true} {...props} />;
};
export default RTab;
