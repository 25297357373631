import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  top: 35%;
  .ant-modal-content {
    background: linear-gradient(180deg, #d6c8d7 0%, #ffffff 100%);
  }
  .ant-btn-default {
    background-color: transparent;
  }
`;
