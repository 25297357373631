import { useState } from 'react';

import { Button, Flex, Spin, Typography } from 'antd';
import { useParams } from 'react-router';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconDownload from '@/assets/icons/icon-download.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Card from '@/components/RCard';
import RToggleButtons from '@/components/RToggleButtons';
import Tooltip from '@/components/RTooltip';
import { SimulationResult } from '@/types/simulations';

import AncillaryTable from './AncillaryTable';
import EnergyTable from './EnergyTable';
import TotalTable from './TotalTable';

const IncomeBreakdown = () => {
  const resultId = useParams<Record<string, string>>();
  const {
    data: simulationData,
    isLoading,
    error,
  } = useSWR<SimulationResult>(`/simulation/${resultId.resultId}/`, {
    revalidateOnFocus: false,
  });
  const buttons = ['Energy Sales', 'Ancillary Services', 'Total'];
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const theme = useTheme();
  const handleButtonClick = (index: number) => {
    setActiveIndex(index);
  };
  return (
    <Card
      title={
        <Flex gap={8} align="center">
          <Typography.Title level={4} className="fs-17-bold">
            Income Breakdown
          </Typography.Title>
          <Tooltip
            title=" Income Breakdown"
            description="Earnings attributed to specific value streams"
          >
            <IconInfo />
          </Tooltip>
        </Flex>
      }
      extra={
        <Flex gap={16} align="center">
          <RToggleButtons labels={buttons} onButtonClick={handleButtonClick} />
          <Button icon={<IconDownload fill={theme.colors.bluePrimary} />} />
        </Flex>
      }
      styles={{ header: { padding: '14px 24px' } }}
      $paddingBody="12px 0"
      style={{ marginTop: '24px' }}
    >
      {isLoading ? (
        <Flex justify="center" align="center" style={{ height: 304 }}>
          <Spin style={{ margin: '20px' }} />
        </Flex>
      ) : error ? (
        <Flex
          vertical
          gap={12}
          justify="center"
          align="center"
          style={{ height: 304 }}
        >
          <IconError width={50} height={50} fill={theme.colors.tagFailure} />
          <Typography.Text className="fs-14-regular text-gray-color">
            An error occured while fetching data. Please check your inputs or
            contact support if the issue persists.
          </Typography.Text>
        </Flex>
      ) : (
        <>
          {' '}
          <Flex
            gap={8}
            justify="center"
            align="center"
            style={{
              padding: '19.5px 24px',
              backgroundColor: theme.colors.bgBlueLightTransparent,
            }}
          >
            <Typography.Text className="fs-14-bold">
              {buttons[activeIndex]}
            </Typography.Text>
          </Flex>
          {activeIndex === 0 && simulationData && (
            <EnergyTable simulationData={simulationData} />
          )}
          {activeIndex === 1 && simulationData && (
            <AncillaryTable simulationData={simulationData} />
          )}
          {activeIndex === 2 && simulationData && (
            <TotalTable simulationData={simulationData} />
          )}
        </>
      )}
    </Card>
  );
};
export default IncomeBreakdown;
