import styled from 'styled-components';

export const AvatarContainer = styled.div`
  position: relative;
  .team-avatar {
    width: 35px;
    height: 37.5px;
  }
  .avatar-company-name {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.light};
  }
`;
