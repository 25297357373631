import { Flex } from 'antd';
import styled from 'styled-components';

export default styled(Flex)`
  height: 511.5px;
  padding: 14.5px;
  text-align: center;
  .title {
    font-size: 17px;
    line-height: 25px;
    color: ${({ theme }) => theme.colors.bgBlack};
  }
  .number {
    font-size: 24px;
    line-height: 36px;
    color: ${({ theme }) => theme.colors.dark};
  }
`;
