import { Menu } from 'antd';
import styled from 'styled-components';

export default styled(Menu)`
  background-color: transparent;
  color: black;
  border: none;
  width: 209px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  li {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100% !important;
    margin-inline: 0 !important;
    margin-block: 0 !important;
    border-radius: ${({ theme }) => theme.main.borderRadius}px !important;
    padding-inline: 29px 13px !important;
    color: ${({ theme }) => theme.colors.grayPrimary};
    font-weight: normal;

    &.ant-menu-item {
      display: flex;
      align-items: center;
      justify-content: start;

      svg {
        fill: ${({ theme }) => theme.colors.dark} !important;
      }

      &:hover {
        svg {
          fill: ${({ theme }) => theme.colors.light} !important;
        }
      }
    }

    .ant-menu-item-icon {
      min-width: 24px !important;
    }

    .ant-menu-title-content {
      a {
        color: ${({ theme }) => theme.colors.dark};
      }

      &:hover {
        a {
          color: ${({ theme }) => theme.colors.light} !important;
        }
      }
    }

    &.ant-menu-item-selected {
      svg {
        fill: ${({ theme }) => theme.colors.light} !important;
      }
      color: ${({ theme }) => theme.colors.light};

      background-color: ${({ theme }) => theme.colors.primary} !important;
      a {
        color: ${({ theme }) => theme.colors.light} !important;
      }
      &:hover {
        a {
          color: ${({ theme }) => theme.colors.light} !important;
        }
      }
    }
  }
`;
