import { ReactNode, FC } from 'react';

import { Row, Col, Typography } from 'antd';

import { useContentScrollDirection } from '@/store/useContentScrollDirection';

import StyledHeader from './styles';

//TODO/SB : This component includes business logic (isMembershipActive), also isResultPage
type PageHeaderProps = {
  title: string;
  description?: string | ReactNode;
  rightSideSection?: ReactNode;
  stickyTop?: boolean;
  bgColor?: string;
};

const RPageHeader: FC<PageHeaderProps> = ({
  title,
  description,
  rightSideSection,
  stickyTop = true,
  bgColor = '#F7F8FA',
}) => {
  const $scrollDirection = useContentScrollDirection(
    (state) => state.$scrollDirection
  );
  const $isResultPage = location.pathname.includes('result');

  return (
    <StyledHeader
      $scrollDirection={$scrollDirection}
      $isResultPage={$isResultPage}
      style={{ backgroundColor: bgColor }}
      className={stickyTop && !$isResultPage ? 'page-header-sticky-top' : ''}
    >
      <Row justify="space-between" className="page-header">
        <Col flex="1">
          <Row>
            <Typography.Title level={2} className="title">
              {title}
            </Typography.Title>
          </Row>
          {description && (
            <Row className="description">
              {typeof description === 'string' ? (
                <Typography.Text>{description}</Typography.Text>
              ) : (
                description
              )}
            </Row>
          )}
        </Col>
        {rightSideSection}
      </Row>
    </StyledHeader>
  );
};

export default RPageHeader;
