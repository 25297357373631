import { Row, Input } from 'antd';
import styled from 'styled-components';

export default styled(Row)`
  width: 500px;
`;

export const StyledInput = styled(Input)`
  height: 60px;
  width: 60px;

  font-size: 28px;
  padding-left: 21px;
  color: ${({ theme }) => theme.colors.primary};
`;
