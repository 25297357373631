import { Col } from 'antd';
import styled from 'styled-components';

export default styled(Col)`
  .input-node {
    padding: 0 !important;
  }
  .invite-btn {
    padding: 8px 24px;
  }
  .btn {
    border: none;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary} !important;
      color: ${({ theme }) => theme.colors.light} !important;
    }
  }
  .ant-form-item {
    margin-bottom: 0 !important; /* Ensure sufficient space for error message */
  }

  .ant-form-item-explain-error {
    margin-top: -25px;
    display: flex;
    justify-content: center;
  }
  .ant-table-cell {
    vertical-align: middle;
  }
  .ant-table-measure-row {
    display: none;
  }
`;
