import { FC } from 'react';

import { Button, Col, Form, FormInstance, Input, Row, Typography } from 'antd';
import { useTheme } from 'styled-components';

import IconPlus from '@/assets/icons/icon-plus.svg?react';
import { StorageSystemForm } from '@/types/storageSystem';

type Props = {
  disabled?: boolean;
  form: FormInstance<StorageSystemForm>;
};

const CycleLife: FC<Props> = ({ disabled = false, form }) => {
  const theme = useTheme();
  function appendToArrayField(fieldName: keyof StorageSystemForm) {
    const curValue = form.getFieldValue(fieldName) || [];

    form.setFieldValue(fieldName, [...curValue, {}]);
  }

  return (
    <Form.Item
      wrapperCol={{ span: 24 }}
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
      className="p-16"
    >
      <Typography.Text type="secondary">
        Storage system lifetime parameters.
        <br />
        <b>Note: Higher DoD means lower cycle life in general.</b>
      </Typography.Text>
      <Row className="mt-12" gutter={10}>
        <Col span={12}>
          <Form.Item
            name="calendarAging"
            label="Calendar Aging"
            tooltip="Suggested when expected cycle depths and current rates are low or operational periods are shorter than the idle periods"
          >
            <Input
              type="number"
              placeholder="Enter Calendar Aging"
              className="w-100"
              addonAfter="Years"
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.List name="dodCycles" initialValue={[{}]}>
        {(fields, { remove }) => (
          <Col>
            {fields.map(({ key, name, ...restField }) => (
              <Row key={key} align="bottom" gutter={10} wrap={false}>
                <Col flex={1}>
                  <Form.Item label="DoD" {...restField} name={[name, 'dod']}>
                    <Input
                      type="number"
                      placeholder="Enter Depth of Discharge"
                      className="w-100"
                      addonAfter="%"
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
                <Col flex={1}>
                  <Form.Item
                    label="Cycle Number"
                    {...restField}
                    name={[name, 'cycleNumber']}
                  >
                    <Input
                      type="number"
                      style={{ height: '34px' }}
                      placeholder="Enter Cycle Number"
                      className="w-100"
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      danger
                      style={{ height: '34px' }}
                      onClick={() => remove(name)}
                      disabled={disabled}
                    >
                      Delete
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Row className="mt-12" align="middle" justify="end">
              <Button
                icon={<IconPlus fill={theme.colors.bluePrimary} />}
                type="dashed"
                onClick={() => appendToArrayField('dodCycles')}
                style={{ height: '34px' }}
                disabled={disabled}
              >
                Add Cycle
              </Button>
            </Row>
          </Col>
        )}
      </Form.List>
    </Form.Item>
  );
};

export default CycleLife;
