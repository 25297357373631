import { Row, Typography } from 'antd';
import { TagProps } from 'antd/lib';

import Selected from '@/assets/icons/icon-selected.svg?react';

import { StyledTag } from './styles';

//TODO/SB : Remove successText and errorText, We can just make a text props.
interface ExtendedTagProps extends TagProps {
  type?: string;
  successText?: string;
  errorText?: string;
}

const CollapseTag: React.FC<ExtendedTagProps> = ({
  type,
  successText,
  errorText,
}: ExtendedTagProps) => {
  return (
    <StyledTag type={type}>
      <Row align="middle">
        {type === 'error' ? (
          <Typography.Text className="numbercolor-text ">
            {errorText}
          </Typography.Text>
        ) : (
          <>
            <Selected />
            <Typography.Text className="pl-8 fs-12 diesel-text">
              {successText}
            </Typography.Text>
          </>
        )}
      </Row>
    </StyledTag>
  );
};

export default CollapseTag;
