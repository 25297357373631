import { useEffect } from 'react';

import { FormInstance } from 'antd';
import dayjs from 'dayjs';

import { CreateSimSteps, TourSteps } from '@/constants';
import useProductTourStore from '@/store/useProductTourStore';

import useProductTour from './useProductTour';

type UseProductTourHookParams = {
  current: number;
  form: FormInstance;
  prev: () => void;
  next: () => void;
  submitSimulation: () => void;
};

// Hook tanımı
const useProductTourNewSimulation = ({
  current,
  form,
  next,
  prev,
  submitSimulation,
}: UseProductTourHookParams) => {
  const { currentStep, isTourActive } = useProductTourStore();
  const simulationAssetsRef = useProductTour('simulationAssets');
  const reviewRef = useProductTour('reviewSimulationAssets');

  useEffect(() => {
    if (!isTourActive) return; // Check if tour is active

    // Page navigation according to the current step
    switch (currentStep) {
      // Set form state according to current step
      case TourSteps.SIMULATION_ASSETS: {
        const randomInteger = Math.floor(Math.random() * 99) + 1;
        const randomLetter = String.fromCharCode(
          Math.floor(Math.random() * 26) + 65
        );

        form.setFieldsValue({
          simulationName: `Simulation-${randomInteger}${randomLetter}`,
          simulationDescription: 'Simulation description',
          simulationStartDate: dayjs(),
          years: 1,
        });
        if (current === CreateSimSteps.ASSET_SELECTION) prev();
        break;
      }
      case TourSteps.SELECT_ASSET_EMPTY_FACE:
        if (current === CreateSimSteps.DESC_OF_SIMULATION) next();
        break;
      case TourSteps.SELECT_ASSET_FILL_FACE:
        if (current === CreateSimSteps.OVERVIEW) prev();
        break;

      case TourSteps.REVIEW_SIMULATION_ASSETS:
        if (current === CreateSimSteps.ASSET_SELECTION) next();
        break;

      case TourSteps.RUNNING_SIMULATION:
        if (current === CreateSimSteps.OVERVIEW) submitSimulation();
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTourActive, currentStep, current]);

  return { simulationAssetsRef, reviewRef };
};

export default useProductTourNewSimulation;
