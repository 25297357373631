import { FC } from 'react';

import { EmptyProps } from 'antd';

import StyledEmpty from './styles';

type NotFoundContentProps = EmptyProps & {
  cta?: React.ReactNode;
};

const nfImage =
  'https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg';

const RNotFoundContent: FC<NotFoundContentProps> = ({
  description = 'No Data Found',
  cta,
  image = nfImage,
  imageStyle = { height: 60 },
  ...props
}) => {
  return (
    <StyledEmpty
      style={{ padding: '12px' }}
      image={image}
      imageStyle={imageStyle}
      description={description}
      {...props}
    >
      {cta}
    </StyledEmpty>
  );
};

export default RNotFoundContent;
