import { Row } from 'antd';
import styled from 'styled-components';

export default styled(Row)`
  width: 100%;
  .bgColor {
    background-color: ${({ theme }) => theme.colors.light} !important;
  }

  .title {
    font-size: 18px !important;
    line-height: 35px !important;
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.dark};
    margin: 0;
    padding: 8px 12px;
  }
  .property {
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 400 !important;
    color: ${({ theme }) => theme.colors.dark};
    margin: 0;
    padding: 8px 12px;
  }

  .ant-table-wrapper {
    background-color: ${({ theme }) => theme.colors.light} !important;
    width: 100% !important;
  }
  // Table Header
  .ant-table-thead tr > th {
    padding: 0px !important;
  }
  .ant-table-thead .ant-table-cell {
    font-size: 18px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
  }
  .ant-table-thead .ant-table-cell > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 14.5px 12px !important;
  }
  .ant-table-thead .ant-table-cell:first-child > div:first-child {
    display: block;
  }
  .ant-table-thead .ant-table-cell:nth-child(2) {
    padding: 14.5px 12px !important;
  }
  .ant-table-thead .ant-table-cell:first-child > div > p {
    margin: 0 !important;
    text-align: left;
  }
  @media (max-width: 1390px) {
    .ant-table-thead .ant-table-cell:nth-child(n + 2) div {
      flex-wrap: wrap !important;
      flex-direction: column !important;
    }
  }
  @media (max-width: 992px) {
    .ant-table-thead .ant-table-cell:nth-child(n + 2) div {
      flex-direction: row !important;
    }
  }

  .ant-spin-container {
    padding: 0 !important;
  }
  th.ant-table-cell {
    padding: 8px 12px !important;
    background-color: ${({ theme }) => theme.colors.grayLighter} !important;
    color: ${({ theme }) => theme.colors.dark} !important;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayMedium} !important;
  }
  th.ant-table-cell .pad-switch {
    margin-bottom: 0 !important;
    padding: 0;
  }
  .ant-table-cell {
    padding: 0 !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-radius: 0px !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-radius: 0px !important;
  }

  .ant-table-row .ant-table-cell {
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.dark};
  }
  td.ant-table-cell:nth-child(n + 2) {
    padding: 8px 12px !important;
    svg {
      position: relative;
      top: 3px;
    }
  }
`;
