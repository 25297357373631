import { Select } from 'antd';
import styled from 'styled-components';

import { RSelectProps } from './types';

export default styled(Select)<RSelectProps>`
  .ant-select-selector {
    color: ${({ theme }) => theme.colors.grayAccent};
    padding: 5.5px 12px !important;
    height: 32px;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.dark};
  }

  width: ${({ width }) => width + 'px' || '90px'} !important;
`;
