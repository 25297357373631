import { Row } from 'antd';
import styled from 'styled-components';

export default styled(Row)`
  width: 100%;
  padding: 0 ${({ theme }) => theme.main.paddingSide}px 80px;

  @media (max-width: 992px) {
    padding: 0 ${({ theme }) => theme.main.paddingSideSm}px 80px;
  }

  @media (max-width: 768px) {
    padding: 0 ${({ theme }) => theme.main.paddingSideXs}px 80px;
  }

  .bgColor {
    background-color: ${({ theme }) => theme.colors.light} !important;
  }

  .title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.dark};
    margin: 0;
    padding: 12px;
  }
  .property {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.dark};
    margin: 0;
    padding: 12px;
  }

  .ant-table-wrapper {
    background-color: ${({ theme }) => theme.colors.light} !important;
    width: 100% !important;
  }
  // Table Header
  .ant-table-thead .ant-table-cell {
    font-size: 20px !important;
    line-height: 30px !important;
    font-weight: 600 !important;
  }
  .ant-table-thead .ant-table-cell:nth-child(n + 2) div {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 5px !important;
  }
  @media (max-width: 1390px) {
    .ant-table-thead .ant-table-cell:nth-child(n + 2) div {
      flex-wrap: wrap !important;
      flex-direction: column !important;
    }
  }
  @media (max-width: 992px) {
    .ant-table-thead .ant-table-cell:nth-child(n + 2) div {
      flex-direction: row !important;
    }
  }

  .ant-spin-container {
    padding: 0 !important;
  }
  th.ant-table-cell {
    padding: 12px !important;
    background-color: ${({ theme }) => theme.colors.grayLighter} !important;
    color: ${({ theme }) => theme.colors.dark} !important;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayMedium} !important;
  }
  th.ant-table-cell .pad-switch {
    margin-bottom: 0 !important;
    padding: 0;
  }
  .ant-table-cell {
    padding: 0 !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-radius: 0px !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-radius: 0px !important;
  }

  .ant-table-row .ant-table-cell {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.dark};
  }
`;
