import { Col } from 'antd';
import styled from 'styled-components';

export default styled(Col)`
  .action-button-alert {
    :hover {
      border: 1px solid ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.red};
    }
  }
  .btn-delete {
    background-color: #f6f8fa;
    border: 0.5px solid #d1d7dd !important;
    color: ${({ theme }) => theme.colors.tagFailure} !important;
    &:hover {
      color: ${({ theme }) => theme.colors.light} !important;
    }
  }
`;
