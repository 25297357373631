import { FC, useState } from 'react';

import { Row, MenuProps, Typography } from 'antd';
import { Link } from 'react-router-dom';

import IconDown from '@/assets/icons/icon-caret-down.svg?react';
import IconOrganization from '@/assets/icons/icon-organization.svg?react';
import { TourSteps } from '@/constants';
import useProductTour from '@/hooks/useProductTour';
import paths from '@/routes/paths';
import useProductTourStore from '@/store/useProductTourStore';
import { UserResponse } from '@/types/auth';

import StyledDropdown from './styles';
type Props = {
  user?: UserResponse;
};

const SiderOrganization: FC<Props> = ({ user }) => {
  const [open, setOpen] = useState(false);

  const { currentStep, isTourActive } = useProductTourStore();
  const dropdownWrapperRef = useProductTour('organizationSection');

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to={paths.organization.user.management}>User Management</Link>
      ),
      disabled: isTourActive,
    },
    {
      key: '2',
      label: <Link to={paths.organization.payments}>Payments</Link>,
      disabled: isTourActive,
    },
    {
      key: '3',
      label: <Link to={paths.organization.settings}>Settings</Link>,
      disabled: isTourActive,
    },
  ];

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  return (
    <div ref={dropdownWrapperRef}>
      <StyledDropdown
        menu={{ items }}
        onOpenChange={handleOpenChange}
        open={
          open ||
          (isTourActive && currentStep === TourSteps.ORGANIZATION_SECTION)
        }
        trigger={['click']}
      >
        <Row
          className="account-card-row-avatar cursor-pointer"
          align="middle"
          justify={'space-between'}
        >
          <div className="border-divide-left"></div>
          <IconDown />
          <IconOrganization />

          <Typography.Title className="text-center fs-14-regular mr-20">
            {user?.organization.name}
          </Typography.Title>
        </Row>
      </StyledDropdown>
    </div>
  );
};

export default SiderOrganization;
