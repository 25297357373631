import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.blueLightest};
  padding: 134px 190px;
  .grid {
    position: absolute;
    bottom: -100px;
    left: -100px;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sign-col {
    height: 100%;
    max-width: 860px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 60px;
    background-color: ${({ theme }) => theme.colors.light};
    border: 0.5px solid ${({ theme }) => theme.colors.grayLightAccent};
    border-radius: 10px;
  }
  .ant-form-vertical {
    gap: 24px !important;
  }

  .input-style {
    height: 32px;
    border: 0.5px solid ${({ theme }) => theme.colors.grayLightAccent} !important;
    border-radius: 4px !important;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    padding: 5.5px 12px !important;
    margin-bottom: 24px;
  }
  .btn-style {
    height: 40px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }
  .forget-style {
    margin-top: 16px;
    .forget {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.grayAccent};
    }
    .account {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.grayAccent};
    }
  }

  .ant-form-item {
    margin-bottom: 16px;
  }
  .ant-form-item-label {
    padding-bottom: 8px !important;
  }

  .ant-form-item-explain-error {
    display: none;
  }

  .ant-form-item-explain-error {
    display: block !important;
  }
  .ant-form-item-explain-connected {
    position: relative;
    top: -24px;
  }
`;
