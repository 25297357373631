import { useState } from 'react';

import { Button, Flex, Spin, Typography } from 'antd';
import { useParams } from 'react-router';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconDownload from '@/assets/icons/icon-download.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Card from '@/components/RCard';
import RToggleButtons from '@/components/RToggleButtons';
import Tooltip from '@/components/RTooltip';
import { SimulationResult } from '@/types/simulations';

import AncillaryTable from './AncillaryTable';
import ExcessTable from './ExcessTable';
import StorageTable from './StorageTable';

const AnalysisTable = () => {
  const theme = useTheme();
  const resultId = useParams<Record<string, string>>();
  const {
    data: simulationData,
    isLoading,
    error,
  } = useSWR<SimulationResult>(`/simulation/${resultId.resultId}/`, {
    revalidateOnFocus: false,
  });
  const buttons = [
    'Excess Energy',
    'Storage Premium',
    'Ancillary Services Analysis',
  ];
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const handleButtonClick = (index: number) => {
    setActiveIndex(index);
  };
  const getTitle = () => {
    if (activeIndex === 0) return 'Excess Energy Analysis';
    if (activeIndex === 1) return 'Storage Premium Analysis';
    if (activeIndex === 2)
      return 'Participating at Ancillary Services Analysis';
    return '';
  };
  const getDescription = () => {
    if (activeIndex === 0)
      return 'An overview of curtailment scenarios involving storage and non-storage options, including considerations for storage inefficiency and net energy surplus.';
    if (activeIndex === 1)
      return 'Analysis of the financial performance of energy sales before and after storage, highlighting the impact of storage on sales prices';
    if (activeIndex === 2)
      return 'The table analyzes the annual offered capacity for ancillary services, specifically Secondary Frequency Control (SFC) and Primary Frequency Control (PFC)';
    return '';
  };
  return (
    <Card
      title={
        <Flex gap={8} align="center">
          <Typography.Title level={4} className="fs-17-bold">
            {activeIndex === 0 && 'Excess Energy Analysis '}
            {activeIndex === 1 && 'Storage Premium Analysis '}
            {activeIndex === 2 &&
              'Participating at Ancillary Services Analysis'}
          </Typography.Title>
          <Tooltip title={getTitle()} description={getDescription()}>
            <IconInfo />
          </Tooltip>
        </Flex>
      }
      extra={
        <Flex gap={16} align="center">
          <RToggleButtons
            labels={buttons}
            initialActiveIndex={0}
            onButtonClick={handleButtonClick}
          />
          <Button icon={<IconDownload fill={theme.colors.bluePrimary} />} />
        </Flex>
      }
      styles={{ header: { padding: '14px 24px' } }}
      $paddingBody="12px 0"
      style={{ width: '100%', marginTop: '24px' }}
    >
      {isLoading ? (
        <Flex justify="center" align="center" style={{ height: 220 }}>
          <Spin style={{ margin: '20px' }} />
        </Flex>
      ) : error ? (
        <Flex
          vertical
          gap={12}
          justify="center"
          align="center"
          style={{ height: 220 }}
        >
          <IconError width={50} height={50} fill={theme.colors.tagFailure} />
          <Typography.Text className="fs-14-regular text-gray-color">
            An error occured while fetching data. Please check your inputs or
            contact support if the issue persists.
          </Typography.Text>
        </Flex>
      ) : (
        <>
          {activeIndex === 0 && simulationData && (
            <ExcessTable simulationData={simulationData} />
          )}
          {activeIndex === 1 && simulationData && (
            <StorageTable simulationData={simulationData} />
          )}
          {activeIndex === 2 && simulationData && (
            <AncillaryTable simulationData={simulationData} />
          )}
        </>
      )}
    </Card>
  );
};
export default AnalysisTable;
