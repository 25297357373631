import { Row, Col } from 'antd';
import styled from 'styled-components';

export default styled(Col)`
  .action-button {
    min-width: 101px;
  }

  .ant-tooltip-inner {
    background-color: ${({ theme }) => theme.colors.blueLightest};
  }
  .ant-empty {
    border: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
    border-radius: 5px;
  }
  .ant-empty-description {
    padding-bottom: 32px;
    padding-top: 16px;
  }

  .information-text {
    margin-top: -15px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .ant-pagination {
    padding-right: 28px;
  }
  .ant-table-measure-row {
    display: none;
  }
  .hourglass-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .hourglass-animation {
    width: 45px;
    height: 45px;
  }

  .completed-simulations .ant-table-tbody > tr > td.name-column {
    max-width: 140px;
  }
  .completed-simulations .ant-table-tbody > tr > td.storage-system-column {
    max-width: 120px;
  }
  .completed-simulations .ant-table-tbody > tr > td.scenario-column {
    max-width: 150px;
  }
  .completed-simulations .ant-table-tbody > tr > td.plants-column {
    max-width: 150px;
  }
  .completed-simulations .ant-table-tbody > tr > td.description-column {
    max-width: 100px;
  }
`;

export const TableActionsWrapper = styled(Row)`
  border-bottom: none;
  padding: 24px 24px 12px 24px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  .search-group {
    height: 32px !important;
    input.ant-input.ant-input-outlined {
      width: 148px;
      height: 32px;
    }
    span {
      display: flex;
      height: 32px !important;
      .ant-input-group-addon {
        display: contents;
        button {
          height: 32px;
          width: 32px !important;
        }
      }
    }
  }

  .example-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }

  .example-button .button-icon {
    transition: transform 0.3s ease;
    transform: scale(1);
  }

  .example-button:hover .button-icon {
    transform: scale(1.5);
  }

  .advanced-filters {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-left: 1px;
    border-right: 1px;
    border-bottom: 1px;
    border-style: dashed;
    border-top: none;
    border-radius: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-color: ${({ theme }) => theme.colors.grayLightAccent};
    padding: 24px;
    div {
      display: flex;
      gap: 12px;
    }
  }
`;
