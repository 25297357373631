import { Modal } from 'antd';
import styled from 'styled-components';
import { DefaultTheme } from 'styled-components/dist/types';

const getBorderColor = (type: string, theme: DefaultTheme) => {
  switch (type) {
    case 'success':
      return theme.colors.tagSuccess;
    case 'error':
      return theme.colors.tagFailure;
    case 'warning':
      return theme.colors.tagRevoked;
    default:
      return theme.colors.blueLightMedium;
  }
};

export default styled(Modal)<{ type: string }>`
  padding: 0;
  .ant-modal-content {
    width: 475px !important;
    padding: 24px;
    box-shadow: ${({ theme }) => theme.main.notificationCardShadow};
    border-radius: 8px;
    border: 0.5px solid;
    border-color: ${({ type, theme }) => getBorderColor(type, theme)};
  }
  .btn {
    padding: 9px 24px;
  }
`;
