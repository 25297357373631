import { FC } from 'react';

import {
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import { useTheme } from 'styled-components';

import { ScenarioForm } from '@/types/scenario';
import { normalizePositiveNumericValuesInput } from '@/utils';

type Props = {
  disabled?: boolean;
  form: FormInstance<ScenarioForm>;
};

const BatteryOptions: FC<Props> = ({ form, disabled = false }) => {
  const theme = useTheme();

  const batteryDepletedOptions = [
    { label: 'Replace Batteries Automatically', value: 'replace_batteries' },
    { label: 'Stop the Simulation', value: 'stop_simulation' },
    { label: 'Continue without Storage System', value: 'continue_wo_storage' },
  ];

  return (
    <Form.Item
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
      className="p-16 pb-40"
      wrapperCol={{ span: 24 }}
    >
      <Form.Item<ScenarioForm>
        layout="horizontal"
        name="unitDegradation"
        label="Ratio Shadow Cost"
        //TODO Reusable FormTooltips
        tooltip={{
          title: (
            <div>
              <>
                <Typography.Text className="fs-14-semi-bold">
                  Ratio Shadow Cost
                </Typography.Text>
                <div className="horizontal-line mt-4"></div>
              </>
              <Typography.Text className="fs-14-regular smoke mt-4">
                Selecting the most efficient use of battery life considering the
                cost of degradation.
              </Typography.Text>
            </div>
          ),
          overlayInnerStyle: {
            padding: theme.spacing.spacing12 + 'px',
            color: 'inherit',
          },
          color: theme.colors.light,
        }}
      >
        <Switch
          disabled={disabled}
          checkedChildren="ON"
          unCheckedChildren="OFF"
        />
      </Form.Item>
      <Row className="pt-24">
        <Col className="pr-8" span={12}>
          <Form.Item<ScenarioForm>
            name="whenBatteryIsDepleted"
            label="When Battery is Depleted"
            rules={[{ required: true, message: 'Please select an option' }]}
          >
            <Select
              disabled={disabled}
              placeholder="When Battery Is Depleted"
              options={batteryDepletedOptions}
            />
          </Form.Item>
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<ScenarioForm>
            shouldUpdate={(prevValues, currentValues) => {
              if (
                prevValues.whenBatteryIsDepleted === 'replace_batteries' &&
                currentValues.whenBatteryIsDepleted !== 'replace_batteries'
              ) {
                form.setFieldValue('replacementNumberLimit', undefined);
              }

              return currentValues.whenBatteryIsDepleted !== undefined;
            }}
          >
            {({ getFieldValue }) => {
              const isVisible =
                getFieldValue('whenBatteryIsDepleted') === 'replace_batteries';
              return isVisible ? (
                <Form.Item<ScenarioForm>
                  normalize={normalizePositiveNumericValuesInput}
                  name="replacementNumberLimit"
                  label="Replacement Battery Number"
                  rules={[
                    {
                      required: true,
                      message:
                        'Replacement Battery Number is required in order to use battery automatic replacement.',
                    },
                  ]}
                >
                  <Input
                    disabled={disabled}
                    placeholder="Replacement Battery Number"
                  />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default BatteryOptions;
