import { Row, Space, Typography, Switch } from 'antd';
import { useTheme } from 'styled-components';

import { SwitchPlansProps } from '@/types/plan';

const SwitchPlans: React.FC<SwitchPlansProps> = ({
  yearly,
  handleSwitch,
  percentage,
}) => {
  const theme = useTheme();
  return (
    <Row className="pad-switch">
      <Space align="center" size={8}>
        <Typography.Text
          className={yearly ? 'fs-14-regular' : 'fs-14-bold'}
          style={
            yearly
              ? { color: theme.colors.graySecondary }
              : { color: theme.colors.grayDarkPrimary }
          }
        >
          Monthly
        </Typography.Text>

        <Switch
          checked={yearly}
          onChange={handleSwitch}
          aria-label="Switch between monthly and yearly plans"
        />
        <Typography.Text
          style={
            !yearly
              ? { color: theme.colors.graySecondary }
              : { color: theme.colors.grayDarkPrimary }
          }
          className={yearly ? 'fs-14-bold' : 'fs-14-regular'}
        >
          Yearly
        </Typography.Text>
        {yearly && (
          <Typography.Text className=" fs-14 text-secondary-color">
            Save {percentage}%!
          </Typography.Text>
        )}
      </Space>
    </Row>
  );
};

export default SwitchPlans;
