import { Flex } from 'antd';
import styled from 'styled-components';

export default styled(Flex)`
  padding: 12px;
  width: 100%;
  border: 0.25px solid ${({ theme }) => theme.colors.grayLightAccent};
  .title {
    color: ${({ theme }) => theme.colors.bgBlack};
  }
  .money-card {
    width: 100%;
  }
  .money-title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
  }
  .money-subtitle {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.bgBlack};
  }
`;
