import { FC } from 'react';

import { Col, DatePicker, Form, FormInstance, Input, Row } from 'antd';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';

import IconCalendar from '@/assets/icons/icon-calender.svg?react';
import { PlantForm } from '@/types/plant';
import { normalizePositiveNumericValuesInput } from '@/utils';

type Props = {
  disabled?: boolean;
  form: FormInstance<PlantForm>;
};

const ParticipationFiT: FC<Props> = ({ disabled = false, form }) => {
  const theme = useTheme();

  return (
    <Form.Item<PlantForm>
      wrapperCol={{ span: 24 }}
      className="p-16 pb-40"
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
    >
      <Row>
        <Col className="pr-8" span={12}>
          <Form.Item<PlantForm>
            name="generationFeedInTariff"
            label="Feed-in-Tariff Generation"
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              disabled={disabled}
              placeholder="Feed-in-Tariff Generation"
              addonAfter="$/MWh"
            />
          </Form.Item>
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<PlantForm>
            shouldUpdate={(prevValues, currentValues) => {
              if (
                prevValues.generationFeedInTariff &&
                !currentValues.generationFeedInTariff
              ) {
                form.setFieldsValue({
                  generationFeedInTariffEndDate: undefined,
                });
              }
              return (currentValues.generationFeedInTariff ?? 0) >= 0;
            }}
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="generationFeedInTariffEndDate"
                label="Feed-in-Tariff Generation End Date"
                getValueProps={(i) => ({ value: i ? dayjs(i) : null })}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value && getFieldValue('generationFeedInTariff')) {
                        return Promise.reject(
                          new Error(
                            'You must select a date if you want to use Feed-in-Tariff Generation'
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{
                    format: 'HH:mm',
                    use12Hours: false,
                    hourStep: 1,
                    minuteStep: 1,
                    defaultValue: dayjs('00:00', 'HH:mm'),
                  }}
                  showMinute={false}
                  showSecond={false}
                  disabled={
                    !getFieldValue('generationFeedInTariff') || disabled
                  }
                  className="w-100"
                  suffixIcon={<IconCalendar />}
                  placeholder="Select Date"
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className="pr-8" span={12}>
          <Form.Item<PlantForm>
            name="generationFeedInTariffWithStorage"
            label="Feed-in-Tariff With Storage"
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              disabled={disabled}
              placeholder="Feed-in-Tariff Generation"
              addonAfter="$/MWh"
            />
          </Form.Item>
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<PlantForm>
            shouldUpdate={(prevValues, currentValues) => {
              if (
                prevValues.generationFeedInTariffWithStorage &&
                !currentValues.generationFeedInTariffWithStorage
              ) {
                form.setFieldsValue({
                  generationFeedInTariffWithStorageEndDate: undefined,
                });
              }
              return (
                (currentValues.generationFeedInTariffWithStorage ?? 0) >= 0
              );
            }}
          >
            {({ getFieldValue }) => (
              <Form.Item
                getValueProps={(i) => ({ value: i ? dayjs(i) : null })}
                name="generationFeedInTariffWithStorageEndDate"
                label="Feed-in-Tariff With Storage End Date"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        !value &&
                        getFieldValue('generationFeedInTariffWithStorage')
                      ) {
                        return Promise.reject(
                          new Error(
                            'You must select a date if you want to use Feed-in-Tariff With Storage'
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{
                    format: 'HH:mm',
                    use12Hours: false,
                    hourStep: 1,
                    minuteStep: 1,
                    defaultValue: dayjs('00:00', 'HH:mm'),
                  }}
                  showMinute={false}
                  showSecond={false}
                  disabled={
                    !getFieldValue('generationFeedInTariffWithStorage') ||
                    disabled
                  }
                  className="w-100"
                  suffixIcon={<IconCalendar />}
                  placeholder="Select Date"
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className="pr-8" span={12}>
          <Form.Item<PlantForm>
            name="generationLocalContributionAmount"
            label="Generation Local Contribution Amount"
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              disabled={disabled}
              placeholder="Generation Local Contribution Amount"
              addonAfter="$/MWh"
            />
          </Form.Item>
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<PlantForm>
            shouldUpdate={(prevValues, currentValues) => {
              if (
                prevValues.generationLocalContributionAmount &&
                !currentValues.generationLocalContributionAmount
              ) {
                form.setFieldsValue({
                  generationLocalContributionEndDate: undefined,
                });
              }
              return (currentValues.generationFeedInTariff ?? 0) >= 0;
            }}
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="generationLocalContributionEndDate"
                label="Generation Local Contribution End Date"
                getValueProps={(i) => ({ value: i ? dayjs(i) : null })}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        !value &&
                        getFieldValue('generationLocalContributionAmount')
                      ) {
                        return Promise.reject(
                          new Error(
                            'You must select a date if you want to use Generation Local Contribution Amount'
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{
                    format: 'HH:mm',
                    use12Hours: false,
                    hourStep: 1,
                    minuteStep: 1,
                    defaultValue: dayjs('00:00', 'HH:mm'),
                  }}
                  showMinute={false}
                  showSecond={false}
                  disabled={
                    !getFieldValue('generationLocalContributionAmount') ||
                    disabled
                  }
                  className="w-100"
                  suffixIcon={<IconCalendar />}
                  placeholder="Select Date"
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className="pr-8" span={12}>
          <Form.Item<PlantForm>
            name="storageLocalContributionAmount"
            label="Storage Local Contribution Amount"
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              disabled={disabled}
              placeholder="Storage Local Contribution Amount"
              addonAfter="$/MWh"
            />
          </Form.Item>
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<PlantForm>
            shouldUpdate={(prevValues, currentValues) => {
              if (
                prevValues.storageLocalContributionAmount &&
                !currentValues.storageLocalContributionAmount
              ) {
                form.setFieldsValue({
                  storageLocalContributionEndDate: undefined,
                });
              }
              return (currentValues.storageLocalContributionAmount ?? 0) >= 0;
            }}
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="storageLocalContributionEndDate"
                label="Storage Local Contribution End Date"
                getValueProps={(i) => ({ value: i ? dayjs(i) : null })}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        !value &&
                        getFieldValue('storageLocalContributionAmount')
                      ) {
                        return Promise.reject(
                          new Error(
                            'You must select a date if you want to use Storage Local Contribution Amount'
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{
                    format: 'HH:mm',
                    use12Hours: false,
                    hourStep: 1,
                    minuteStep: 1,
                    defaultValue: dayjs('00:00', 'HH:mm'),
                  }}
                  showMinute={false}
                  showSecond={false}
                  disabled={
                    !getFieldValue('storageLocalContributionAmount') || disabled
                  }
                  className="w-100"
                  suffixIcon={<IconCalendar />}
                  placeholder="Select Date"
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default ParticipationFiT;
