/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, type FunctionComponent } from 'react';

import { Navigate } from 'react-router-dom';

import useAuth from '@/hooks/useAuth';
import paths, {
  ONBOARDING_ORGANIZATON,
  ONBOARDING_PLANS,
  ONBOARDING_PROFILE,
} from '@/routes/paths';

import { withCondition } from './withCondition';

export const withLoggedIn = (Component: React.FC) => {
  const { user } = useAuth();
  return withCondition(Component, !!user && !!user.isVerified, paths.login);
};

export const withOnboardingProfile = (Component: React.FC) => {
  const { user } = useAuth();
  return withCondition(
    Component,
    !!user && !!user.isVerified && !user.organization?.subscription?.plan?.name,
    paths.login
  );
};

export function withOnboardingOrganization(Component: FunctionComponent) {
  return function InnerComponent(props?: any) {
    const { user } = useAuth();
    const isPlanSelected = user?.organization?.subscription?.plan?.name;
    const isProfileCreateStepCompleted =
      !!user && user.fullName && !isPlanSelected;

    return isProfileCreateStepCompleted ? (
      <Component {...props} />
    ) : (
      <Navigate to={ONBOARDING_PROFILE} replace />
    );
  };
}

export function withOnboardingPlans(Component: FunctionComponent) {
  return function InnerComponent(props?: any) {
    const { user, revalidateUser } = useAuth();
    useEffect(() => {
      if (!user?.fullName && !location.pathname.includes('login'))
        revalidateUser();
    }, [user?.fullName, revalidateUser]);
    const isPlanSelected = user?.organization?.subscription?.plan?.name;

    const isProfileCreateStepCompleted =
      !!user && user.fullName && !isPlanSelected;

    const isOrganizationCreateStepCompleted =
      !!user && user?.fullName && user.organization?.name && !isPlanSelected;

    return isOrganizationCreateStepCompleted ? (
      <Component {...props} />
    ) : (
      isProfileCreateStepCompleted && <Navigate to={paths.landing} replace />
    );
  };
}

export function withLoggedInAndCompletedRequirements(
  Component: FunctionComponent
) {
  return function InnerComponent(props?: any) {
    const { user } = useAuth();
    const isEmailVerificationSentPhase = !!user && !user.isVerified;
    const isEmailVerifiedPhase = !!user && user.isVerified;
    const isOnboardingStep1Phase = !!user && user.isVerified && user.fullName;
    const isOnboardingStep2Phase =
      !!user && user.isVerified && user.fullName && user.organization?.name;
    const isUserCompletedAllRequirements =
      !!user &&
      user.isVerified &&
      user.fullName &&
      user.organization?.name &&
      !!user.organization?.subscription?.plan?.name;

    return isUserCompletedAllRequirements ? (
      <Component {...props} />
    ) : isOnboardingStep2Phase ? (
      <Navigate to={ONBOARDING_PLANS} replace />
    ) : isOnboardingStep1Phase ? (
      <Navigate to={ONBOARDING_ORGANIZATON} replace />
    ) : isEmailVerifiedPhase ? (
      <Navigate to={ONBOARDING_PROFILE} replace />
    ) : isEmailVerificationSentPhase ? (
      <Navigate to={paths.register.base} replace />
    ) : (
      <Navigate to={paths.login} replace />
    );
  };
}

export function withLoggedOut(Component: FunctionComponent) {
  return function InnerComponent(props?: any) {
    const { user } = useAuth();
    const userIsNotValidated = !user || !user.isVerified;
    const isUserCompletedAllRequirements =
      !!user &&
      user.isVerified &&
      user.fullName &&
      user.organization?.name &&
      !!user.organization?.subscription?.plan?.name;

    return userIsNotValidated ? (
      <Component {...props} />
    ) : isUserCompletedAllRequirements ? (
      <Navigate to={paths.simulation.base} replace />
    ) : (
      <Component {...props} />
    );
  };
}
