import { FilterParams } from '@/types/general';

import {
  SimulationPost,
  SimulationsRaw,
  SimulationResult,
  SimulationResultPlants,
  SimulationResultBatteryStates,
  SimulationResultFinancialAnalysis,
  SimulationResultTimeSeriesAnalysis,
  SimulationResultDetailsParams,
  SimulationResultYearlyFinancialAnalysis,
} from '../../types/simulations';
import { apiV1 } from '../instances';

export default {
  fetchSimulations(params: FilterParams): Promise<SimulationsRaw> {
    const fullFill = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== '')
    );
    const query = new URLSearchParams();
    for (const [key, value] of Object.entries(fullFill)) {
      query.append(key, String(value));
    }
    return apiV1.get(`/simulation/?${query.toString()}`);
  },

  createSimulation(simulation: SimulationPost) {
    return apiV1.post('/simulation/', simulation);
  },

  fetchRunningSimulations(): Promise<SimulationsRaw> {
    return apiV1.get(`/simulation/?status=QUEUED&status=STARTED`);
  },

  getSimulationResult(id: string): Promise<SimulationResult> {
    return apiV1.get(`/simulation/${id}/`);
  },

  killSimulation(id: string) {
    return apiV1.delete(`/simulation/${id}/`);
  },
  searchSimulationByName(simulationName: string) {
    return apiV1.get(`/simulation/?name=${simulationName}`);
  },
  searchSimulationByExactName(simulationName: string) {
    return apiV1.get(`/simulation/?name_exact=${simulationName}`);
  },

  getSimulationResultPlantsDetails(
    id: string,
    params: SimulationResultDetailsParams
  ): Promise<SimulationResultPlants> {
    const fullFill = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== '')
    );
    const query = new URLSearchParams(fullFill);
    return apiV1.get(`/simulation/${id}/plants/?${query}`);
  },
  getSimulationResultBatteryStates(
    id: string,
    params: SimulationResultDetailsParams
  ): Promise<SimulationResultBatteryStates> {
    const fullFill = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== '')
    );
    const query = new URLSearchParams(fullFill);
    return apiV1.get(`/simulation/${id}/battery_states/?${query}`);
  },
  getSimulationResultFinancialAnalysis(
    id: string,
    params: SimulationResultDetailsParams
  ): Promise<SimulationResultFinancialAnalysis> {
    const fullFill = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== '')
    );
    const query = new URLSearchParams(fullFill);
    return apiV1.get(`/simulation/${id}/financial_analysis/?${query}`);
  },
  getSimulationResultTimeSeriesAnalysis(
    id: string,
    params: SimulationResultDetailsParams
  ): Promise<SimulationResultTimeSeriesAnalysis> {
    const fullFill = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== '')
    );
    const query = new URLSearchParams(fullFill);
    return apiV1.get(`/simulation/${id}/time_series_analysis/?${query}`);
  },
  getSimulationResultYearlyFinancialAnalysis(
    id: string
  ): Promise<SimulationResultYearlyFinancialAnalysis> {
    return apiV1.get(`/simulation/${id}/yearly_financial_analysis/`);
  },
};
