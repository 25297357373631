import { FC } from 'react';

import { Button, Flex, Image, Typography } from 'antd';
import { useNavigate } from 'react-router';

import ImageRedirect from '@/assets/files/redirection.png';
import paths from '@/routes/paths';

export type SuccessProps = {
  title?: string;
  description: string;
  buttonText?: string;
  showBtn?: boolean;
  countdown?: number;
  redirectPath?: string;
};

const Success: FC<SuccessProps> = ({
  description,
  title = 'Success',
  buttonText = 'Return To Login',
  showBtn = true,
  countdown,
  redirectPath = paths.login,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Flex vertical align="center">
        <Typography.Title level={2} className="title text-center">
          {title}
        </Typography.Title>
        <Typography.Text className="description text-center">
          {description} <br />
          <span className="text-blue-color">{countdown}</span> seconds you will
          be redirected.
        </Typography.Text>
      </Flex>
      <Image preview={false} src={ImageRedirect} width={265} height={305} />
      {showBtn && (
        <Button
          type="primary"
          className="cta-btn"
          block
          onClick={() => navigate(redirectPath!, { replace: true })}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
};

export default Success;
