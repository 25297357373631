import { Col, Flex, Row, Spin, Typography } from 'antd';
import { useTheme } from 'styled-components';

import IconError from '@/assets/icons/icon-error.svg?react';
import Card from '@/components/RCard';
import { DataProps } from '@/types/result';
import { converToMillion, formatDollars } from '@/utils';

import DataCard from './MetricCard';

const KeyMetrics: React.FC<DataProps> = ({ data, isLoading, error }) => {
  const theme = useTheme();
  const cardData = [
    {
      title: 'Average Sales Price',
      withStorage: data?.result.averageSalesPrice.toFixed(2) + ' $/MWh',
      withoutStorage: data?.result.averageSalesPriceWos.toFixed(2) + ' $/MWh',
      toolTip: 'Average Sales Price',
      toolTipDescription:
        'This represents the overall revenue generated per MWh of energy (without PFC and SFC), considering both direct sales from the plant and revenue generated by discharging stored energy. It provides a holistic view of the financial performance of the entire system.',
    },
    {
      title: 'EBITDA',
      withStorage: formatDollars(data?.result.ebitda || 0),
      withoutStorage: formatDollars(data?.result.ebitdaWos || 0),
      toolTip: 'EBITDA',
      toolTipDescription:
        'Earnings Before Interest Taxes, Depreciation and Amortization',
    },
    {
      title: 'Net Present Value',
      withStorage: formatDollars(data?.result.netPresentValue || 0),
      withoutStorage: formatDollars(data?.result.netPresentValueWos || 0),
      toolTip: 'Net Present Value',
      toolTipDescription:
        'Financial metric that evaluates the profitability of an investment by comparing the present value of cash inflows with the present value of cash outflows over time.',
    },
    {
      title: 'Sold Energy',
      withStorage: converToMillion(data?.result.totalSoldEnergy || 0) + ' MWh',
      withoutStorage: converToMillion(data?.result.soldEnergyWos || 0) + ' MWh',
      toolTip: 'Sold Energy',
      toolTipDescription: (
        <Flex vertical gap={12}>
          <Typography.Text>
            <strong>With Storage :</strong> Sum of energy sold from the plant
            and energy sold from discharge. It represents the overall amount of
            energy sold by the system.
          </Typography.Text>
          <Typography.Text>
            <strong>Without Storage :</strong> Energy sold from the plant when a
            storage system is not installed.
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: 'Levelized Cost of Energy',
      withStorage: data?.result.levelizedCostOfEnergy?.toFixed(2) + ' $/MWh',
      withoutStorage:
        data?.result.levelizedCostOfEnergyWos?.toFixed(2) + ' $/MWh',
      toolTip: 'Levelized Cost of Energy',
      toolTipDescription:
        'A measure used to assess the total cost of generating a unit of electricity over the lifetime of an energy project.',
    },
    {
      title: 'Battery Usage Cost per MWh',
      withStorage: data?.result.batteryUsageCostPerMwh.toFixed() + ' $/MWh',
      withoutStorage: '-',
      toolTip: 'Battery Usage Cost per MWh',
      toolTipDescription:
        'Cost associated with using a battery to store and discharge one megawatt-hour of energy, encompassing degradation and efficiency losses.',
    },
  ];
  return (
    <Card
      title="Comparison Metrics for With vs Without Storage System"
      $paddingBody="16px 24px"
      style={{ marginTop: '24px' }}
    >
      {isLoading ? (
        <Flex justify="center" align="center" style={{ height: 250 }}>
          <Spin style={{ margin: '20px' }} />
        </Flex>
      ) : error ? (
        <Flex
          vertical
          gap={12}
          justify="center"
          align="center"
          style={{ height: 250 }}
        >
          <IconError width={50} height={50} fill={theme.colors.tagFailure} />
          <Typography.Text className="fs-14-regular text-gray-color">
            An error occured while fetching data. Please check your inputs or
            contact support if the issue persists.
          </Typography.Text>
        </Flex>
      ) : (
        <Row gutter={[24, 24]}>
          {cardData.map((data, index) => (
            <Col key={index} xs={24} sm={12} md={8}>
              <DataCard
                title={data.title}
                withStorage={data.withStorage}
                withoutStorage={data.withoutStorage}
                toolTip={data.toolTip}
                toolTipDescription={data.toolTipDescription}
              />
            </Col>
          ))}
        </Row>
      )}
    </Card>
  );
};

export default KeyMetrics;
