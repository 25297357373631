import { FC, useEffect, useState } from 'react';

import { motion } from 'framer-motion';

import { StyledButton, StyledButtonGroup } from './styles';

// TODO/SB : We can make height as transient prop, since it is used for StyledComponents
interface ButtonGroupProps {
  labels: string[];
  initialActiveIndex?: number;
  selectedLabel?: string;
  onLabelChange?: (index: string) => void;
  onButtonClick?: (index: number) => void;
  height?: string;
  disabled?: boolean;
}
const RToggleButtons: FC<ButtonGroupProps> = ({
  labels,
  initialActiveIndex = 0,
  selectedLabel,
  onButtonClick,
  onLabelChange,
  height,
  disabled,
}) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);

  useEffect(() => {
    if (selectedLabel) {
      const index = labels.indexOf(selectedLabel);
      if (index !== -1) {
        setActiveIndex(index);
      }
    }
  }, [selectedLabel, labels]);

  const handleClick = (index: number) => {
    setActiveIndex(index);
    if (onButtonClick) {
      onButtonClick(index);
    }
    if (onLabelChange) {
      onLabelChange(labels[index]);
    }
  };
  return (
    <StyledButtonGroup height={height}>
      {labels.map((labels, index) => (
        <motion.div key={index} whileTap={{ scale: 0.9 }}>
          <StyledButton
            disabled={disabled}
            key={index}
            $active={index === activeIndex}
            onClick={() => handleClick(index)}
          >
            {labels}
          </StyledButton>
        </motion.div>
      ))}
    </StyledButtonGroup>
  );
};
export default RToggleButtons;
