import { useEffect, useState } from 'react';

import { Button, Col, Flex, Form, Input, Row, Typography } from 'antd';

import { authApi } from '@/api';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Tooltip from '@/components/RTooltip';
import useAuth from '@/hooks/useAuth';
import LocalStorageService from '@/services/LocalStorageService';
import { OrganizationForm } from '@/types/general';
import { handleError } from '@/utils';

interface OrganizationStepProps {
  setCurrentStep: (value: number) => void;
}
export const OrganizationStep: React.FC<OrganizationStepProps> = ({
  setCurrentStep,
}) => {
  const [form] = Form.useForm();
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const { user, revalidateUser } = useAuth();
  const userId = user?.id;

  const hasInitials = !!(
    user?.organization?.name && user?.organization?.teamSize
  );

  useEffect(() => {
    revalidateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values: OrganizationForm) => {
    setLoading(true);
    const { name, teamSize, country } = values;

    if (!userId) {
      setLoading(false);
      throw new Error('Email address is missing.');
    }
    try {
      await authApi.createOrganization({ name, teamSize });
      await authApi.patchUser(userId, { country });

      await revalidateUser();
      setCurrentStep(3);
      LocalStorageService.setCurrentStep(3);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: unknown) => {
    console.log('Failed:', errorInfo);
  };

  const handleFormChange = () => {
    const fields = ['name', 'teamSize', 'country'];
    const requiredFields = form.getFieldsValue(fields);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hasNullOrUndefined = (obj: any) => {
      return Object.values(obj).some(
        (value) => value === null || value === undefined
      );
    };

    const hasErrors = form
      .getFieldsError(fields)
      .some((field) => field.errors && field.errors.length > 0);

    setIsSubmitButtonDisabled(
      hasErrors || hasNullOrUndefined(requiredFields) || !userId
    );
  };

  return (
    <>
      <Typography.Text className="info-text fs-14-regular text-faded-color text-center">
        We just need to ask you a few questions about your organization.
      </Typography.Text>
      <Form
        initialValues={{
          name: user?.organization?.name,
          teamSize: user?.organization?.teamSize,
          country: user?.country,
        }}
        onFieldsChange={handleFormChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        name="organizationForm"
        layout="vertical"
        form={form}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item<OrganizationForm>
              label={
                <Flex align="center" gap={8}>
                  <Typography.Text className="fs-14-regular">
                    Organization Name
                  </Typography.Text>
                  <Tooltip
                    title="Organization"
                    description=" If you don't have an organization, you can write your name."
                  >
                    <IconInfo />
                  </Tooltip>
                </Flex>
              }
              name="name"
              rules={[{ required: true }]}
            >
              <Input
                className="input-style"
                disabled={hasInitials}
                autoComplete="new-organization-name"
                placeholder="Eg: Ratio Energy"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item<OrganizationForm>
              label="Team Size"
              name="teamSize"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                disabled={hasInitials}
                autoComplete="new-team-size"
                placeholder="Eg: 500"
                type="number"
                className="input-style"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item<OrganizationForm>
              label={
                <Typography.Text className="fs-14-regular">
                  Country
                </Typography.Text>
              }
              name="country"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                disabled={hasInitials}
                className="input-style"
                placeholder="Example"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item<OrganizationForm>
              label={
                <Typography.Text className="fs-14-regular">
                  Address Line 1
                </Typography.Text>
              }
              name="address"
            >
              <Input
                disabled={hasInitials}
                className="input-style"
                placeholder="Description"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item<OrganizationForm>
              label={
                <Typography.Text className="fs-14-regular">
                  Address Line 2
                </Typography.Text>
              }
              name="address2"
            >
              <Input
                disabled={hasInitials}
                className="input-style"
                placeholder="Description"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item<OrganizationForm>
              label={
                <Typography.Text className="fs-14-regular">
                  Postal Code
                </Typography.Text>
              }
              name="postalCode"
            >
              <Input
                disabled={hasInitials}
                className="input-style"
                placeholder="Eg: 12345"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item<OrganizationForm>
              label={
                <Typography.Text className="fs-14-regular">
                  District
                </Typography.Text>
              }
              name="district"
            >
              <Input
                disabled={hasInitials}
                className="input-style"
                placeholder="Example"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item<OrganizationForm>
              label={
                <Typography.Text className="fs-14-regular">
                  Province
                </Typography.Text>
              }
              name="province"
            >
              <Input
                disabled={hasInitials}
                className="input-style"
                placeholder="Example"
              />
            </Form.Item>
          </Col>
        </Row>
        {!hasInitials && (
          <Flex className="btn-group">
            <Button
              onClick={() => setCurrentStep(1)}
              className="btn-back"
              type="default"
              htmlType="submit"
            >
              Back
            </Button>
            <Button
              className="btn-next"
              type="primary"
              htmlType="submit"
              disabled={isSubmitButtonDisabled}
              loading={loading}
            >
              Next
            </Button>
          </Flex>
        )}
      </Form>
    </>
  );
};
