import { FC, useState } from 'react';

import { Dropdown, Row, MenuProps, Image } from 'antd';
import { Link } from 'react-router-dom';

import IconDown from '@/assets/icons/icon-caret-down.svg?react';
import IconUser from '@/assets/icons/icon-user.svg?react';
import IconProfileVector from '@/assets/images/icon-profile-frame.svg';
import { TourSteps } from '@/constants';
import useAuth from '@/hooks/useAuth';
import useProductTour from '@/hooks/useProductTour';
import paths from '@/routes/paths';
import useProductTourStore from '@/store/useProductTourStore';
import { UserResponse } from '@/types/auth';
import { getNameInitials } from '@/utils';

import { AvatarContainer } from './styles';

type Props = {
  user?: UserResponse;
};

const SiderAccount: FC<Props> = ({ user }) => {
  const [open, setOpen] = useState(false);
  const { logout } = useAuth();
  const { currentStep, isTourActive } = useProductTourStore();
  const dropdownWrapperRef = useProductTour('userProfile');

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Link to={paths.user.base}>Personal Settings</Link>,
      disabled: isTourActive,
    },
    {
      key: '2',
      label: 'Logout',
      disabled: isTourActive,
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '2') {
      setOpen(false);
      return logout();
    }
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };
  // dropdown stillendirmesi genişlik aynı olacak
  return (
    <div ref={dropdownWrapperRef}>
      <Dropdown
        menu={{ onClick: handleMenuClick, items }}
        onOpenChange={handleOpenChange}
        open={open || (isTourActive && currentStep === TourSteps.USER_PROFILE)}
        trigger={['click']}
      >
        <Row
          className="account-card-row-avatar cursor-pointer"
          align="middle"
          justify={'space-between'}
        >
          <IconDown />
          {user?.fullName ? (
            <AvatarContainer className="avatar-cont">
              <Image
                preview={false}
                className="team-avatar"
                src={IconProfileVector}
              />
              <span className="avatar-company-name">
                {user?.fullName &&
                  getNameInitials(user?.fullName)?.toUpperCase()}
              </span>
            </AvatarContainer>
          ) : (
            <AvatarContainer className="avatar-cont">
              <Image
                preview={false}
                className="team-avatar"
                src={IconProfileVector}
              />
              <span className="avatar-company-name">
                <IconUser fill="white" />
              </span>
            </AvatarContainer>
          )}
        </Row>
      </Dropdown>
    </div>
  );
};

export default SiderAccount;
