import { useEffect, useRef, useState } from 'react';

import { Col, Flex, Row, Space, Spin, Typography } from 'antd';
import useSWR from 'swr';

import IconDown from '@/assets/icons/icon-down.svg?react';
import { Plan } from '@/types/plan';

import ComparisonTable from './ComparisonTable';
import PlansCards from './PlansCards';
import StyledPlans from './styles';

const PlansModal = () => {
  const [yearly, setYearly] = useState<boolean>(true);
  const [showComparison, setShowComparison] = useState<boolean>(false);
  const { data, isLoading, error } = useSWR('/payment/plans');
  const comparisonRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showComparison) {
      comparisonRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [showComparison]);

  if (isLoading) {
    return (
      <StyledPlans
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </StyledPlans>
    );
  }
  if (error) {
    return (
      <Row justify="center" className="mb-64">
        <Typography.Text type="danger">Failed to load data</Typography.Text>
      </Row>
    );
  }

  data?.forEach((item: Plan) => {
    if (item.name === 'enterprise') {
      item.feature = [
        `${item.durationAmount} ${item.durationUnit}`,
        `${item.dataRetentionAmount} ${item.dataRetentionUnit}s data retention`,
        `Unlimited simultaneous simulations`,
        `Unlimited total user`,
        `Unlimited simulation assets`,
        `${item.other.support}`,
      ];
    } else {
      item.feature = [
        `${item.durationAmount} ${item.durationUnit}`,
        `${item.dataRetentionAmount} ${item.dataRetentionUnit}s data retention`,
        `${item.simultaneousSimLimit} simultaneous simulations`,
        `${item.userLimit} total user`,
        `${item.simAssetLimit} simulation assets`,
        `${item.other.support}`,
      ];
    }

    if (item.name === 'demo') {
      item.amount = 'Free';
    }

    item.feature = item.feature.map((feature) =>
      feature.includes('32767')
        ? feature.replace('32767', 'Unlimited')
        : feature
    );
  });

  const handleSwitch = () => {
    setYearly(!yearly);
  };

  const monthlyPlan = data?.filter(
    (item: { durationUnit: string }) => item.durationUnit === 'month'
  );

  const yearlyPlan = data?.filter(
    (item: { durationUnit: string }) => item.durationUnit === 'year'
  );

  const selectedPlan = yearly ? yearlyPlan : monthlyPlan;

  const plansWithFeatures: Plan[] = selectedPlan || [];

  const percentage = (
    100 -
    (yearlyPlan[0].amount / (12 * monthlyPlan[0].amount)) * 100
  ).toFixed(0);

  const toggleComparison = () => {
    setShowComparison(!showComparison);
  };

  return (
    <StyledPlans>
      <div
        ref={scrollContainerRef}
        style={{ height: '100%', overflowY: 'auto' }}
      >
        <Col span={24}>
          <Flex vertical gap={4} className="header-title">
            <Typography.Title className="fs-20-bold text-dark-color" level={3}>
              Plans
            </Typography.Title>
            <Typography.Text className="fs-14-regular text-dark-color">
              You can view all prices and features.
            </Typography.Text>
          </Flex>
        </Col>
        <Col span={24}>
          <PlansCards
            monthlyPlan={monthlyPlan}
            yearlyPlan={yearlyPlan}
            yearly={yearly}
            plansWithFeatures={plansWithFeatures}
            handleSwitch={() => handleSwitch()}
            percentage={percentage}
          />
        </Col>
        <Col span={24}>
          <Space className="compare-title" onClick={() => toggleComparison()}>
            <Typography.Title className="fs-20-bold text-dark-color" level={3}>
              Compare Plans
            </Typography.Title>
            {showComparison ? (
              <IconDown style={{ transform: 'rotate(180deg)' }} />
            ) : (
              <IconDown />
            )}
          </Space>
        </Col>
        <div ref={comparisonRef} />
        {showComparison && (
          <Col span={24}>
            <ComparisonTable
              data={data}
              yearly={yearly}
              monthlyPlan={monthlyPlan}
              yearlyPlan={yearlyPlan}
              handleSwitch={() => handleSwitch()}
              percentage={percentage}
            />
          </Col>
        )}
      </div>
    </StyledPlans>
  );
};

export default PlansModal;
