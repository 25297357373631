/* eslint no-bitwise: "off" */
/* eslint no-nested-ternary: "off" */

import { presetPalettes, presetPrimaryColors } from '@ant-design/colors';
/* import { transparentize } from 'polished'; */
import type { DefaultTheme } from 'styled-components';

// some options may be initialized in the "croco.config.js"
// these options are marked with comments -> "//craco.config"
// please make same changes on craco.config.js

export const colors = {
  // Primary Colors
  primary: '#00296B',
  primaryDark: '#0F3054',
  primaryDarkAlt: '#1D4E7B',
  primaryFaded: '#27AAE11A',
  primaryHighlight: '#3E8AC61A',

  // Tag Colors
  tagSuccess: '#99c2a2',
  tagFailure: '#f25e69',
  tagRevoked: '#dbab6a',
  tagInactive: '#ff4d4f',
  tagQueued: '#4F8BA5',

  // Background Colors
  bgPrimary: '#27AAE10D',
  bgGrayLight: '#E6E6E6',
  bgGrayDark: '#88888833',
  bgBluePrimary: '#3E8AC633',
  bgPink: '#9B056533',
  bgPurple: '#79347C33',
  bgRed: '#F47B8433',
  bgYellow: '#FFC35933',
  bgBrown: '#823C0C33',
  bgBlack: '#000000BF',
  bgBlueLightTransparent: '#F7F8FA80',

  // Blue Shades
  bluePrimary: '#3E8AC6',
  blueSecondary: '#27AAE1',
  blueDark: '#273142',
  blueLight: '#5E97B9',
  blueGray: '#989FC3',
  bluePale: '#CADAE6',
  blueAccent: '#6775A8',
  blueDarkAccent: '#1D1849',
  blueLightAccent: '#9AAEBD',
  blueLightest: '#F7F8FA',
  blueLightMedium: '#7087AC',
  blueLightPale: '#C6E0EB',

  // Brown Shades
  brownPrimary: '#823C0C',
  brownSecondary: '#8D7258',
  brownDark: '#50424F',
  brownLight: '#B9A6B2',

  // Green Shades
  greenPrimary: '#79C46B',
  greenSecondary: '#588F86',
  greenLight: '#78AE49',
  greenPale: '#7BB6B3',

  // Gray Shades
  grayPrimary: '#4B4B4B',
  grayDark: '#757575',
  grayLight: '#CCCCCC',
  grayLighter: '#FAFAFA',
  grayPale: '#F3F3F3',
  grayAccent: '#888888',
  grayDarkAccent: '#4A4A4A',
  graySecondary: '#9E9E9E',
  grayLightAccent: '#C5C5C5',
  grayMedium: '#D9D9D9',
  grayVeryLight: '#EDEFF2',
  grayPaleAccent: '#DCDCDC',
  grayDarkPrimary: '#050100',
  grayDarkSecondary: '#191D1E',

  // Miscellaneous Colors
  orangePrimary: '#F35F0E',
  orangeSecondary: '#C9955B',
  pinkPrimary: '#9B0565',
  purplePrimary: '#9C5386',
  purpleSecondary: '#A56391',
  purpleLight: '#AA85A9',
  purpleAccent: '#79347C',
  redPrimary: '#FF7275',
  turquoisePrimary: '#00C2BC',
  yellowPrimary: '#E8D5B5',
  yellowSecondary: '#FFC359',
  yellowAccent: '#DBAD6A',
  yellowDark: '#FFD591',

  // Utility Colors
  light: '#ffffff',
  dark: '#000000',
  darkTransparent: '#00000070',
  lightTransparent: '#ffffff42',
  transparent: 'transparent',
  inherit: 'inherit',

  // Alert Colors
  alertWarning: '#FAAC14',
  alertError: '#FF4D4E',

  // Hover Effects
  hoverBg: '#c0dcff',

  // Preset Colors
  cyan: presetPrimaryColors.cyan,
  geekblue: presetPrimaryColors.geekblue,
  gold: presetPrimaryColors.gold,
  grey: presetPrimaryColors.grey,
  lime: presetPrimaryColors.lime,
  magenta: presetPrimaryColors.magenta,
  purple: presetPrimaryColors.purple,
  red: presetPrimaryColors.red,
  volcano: presetPrimaryColors.volcano,
  yellow: presetPrimaryColors.yellow,
};

export const palettes = {
  blue: presetPalettes.blue,
  cyan: presetPalettes.cyan,
  geekblue: presetPalettes.geekblue,
  gold: presetPalettes.gold,
  green: presetPalettes.green,
  grey: presetPalettes.grey,
  lime: presetPalettes.lime,
  magenta: presetPalettes.magenta,
  orange: presetPalettes.orange,
  purple: presetPalettes.purple,
  red: presetPalettes.red,
  volcano: presetPalettes.volcano,
  yellow: presetPalettes.yellow,
};

export type Color = keyof typeof colors;

const getThemedColor = (
  color: Color | undefined,
  themeObj: DefaultTheme
): string => {
  const validColor: Color = color || 'dark';
  return themeObj.colors[validColor];
};
const createAnimation = (duration = 0.3): string =>
  `${duration}s cubic-bezier(.25,.8,.25,1)`;

// ** INDEX **
// COLORS
// MAIN
// COMPONENTS
// PAGES
// FUNCTIONS

const theme = {
  // MAIN
  colors,
  palettes,
  limits: {
    xsMaxSize: 576,
    smMinSize: 576,
    mdMinSize: 768,
    lgMinSize: 992,
    xlMinSize: 1200,
    xxlMinSize: 1600,

    limitedContentWidth: 1425,
  },
  spacing: {
    spacing0: 0,
    spacing2: 2,
    spacing4: 4,
    spacing8: 8,
    spacing10: 10,
    spacing12: 12,
    spacing14: 14,
    spacing15: 15,
    spacing16: 16,
    spacing18: 18,
    spacing20: 20,
    spacing21: 21,
    spacing24: 24,
    spacing30: 30,
    spacing32: 32,
    spacing36: 36,
    spacing42: 42,
    spacing48: 48,
    spacing64: 64,
    spacing80: 80,
    spacing120: 120,
    spacing155: 155,
    spacing240: 240,
  },
  fontSizes: {
    fontSize11: 11,
    fontSize12: 12,
    fontSize13: 13,
    fontSize14: 14,
    fontSize16: 16,
    fontSize17: 17,
    fontSize18: 18,
    fontSize19: 19,
    fontSize20: 20,
    fontSize21: 21,
    fontSize23: 23,
    fontSize24: 24,
    fontSize28: 28,
    fontSize32: 32,
    fontSize33: 33,
    fontSize34: 34,
    fontSize36: 36,
    fontSize40: 40,
    fontSize42: 42,
    fontSize48: 48,
    fontSize50: 50,
    fontSize60: 60,
    fontSize64: 64,
    fontSize72: 72,
  },
  main: {
    contentMaxWidth: 1200,
    paddingSide: 64,
    paddingSideSm: 32,
    paddingSideXs: 16,
    borderRadius: 6,
    shadow:
      '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
    shadowHover:
      '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);',
    notificationCardShadow: '0px 4px 4px 0px #00000040',
    defaultAnimation: createAnimation(),
  },

  // COMPONENTS
  header: {
    height: 60,
  },
  footer: {
    height: 64,
  },
  sider: {
    width: 220,
  },
  modal: {
    borderRadius: 15,
  },
  button: {
    hoverBg: 'linear-gradient(180deg, #25629a 0%, #040f1b 120%)',
  },
  table: {
    borderColor: '#686868',
  },
  font: {
    family: `'Lato', sans-serif`,
    familySecondary: 'Montserrat',
    primary: colors.grayDarkPrimary,
  },

  // FUNCTIONS
  getThemedColor(color: Color | undefined): string {
    return getThemedColor(color, theme);
  },
  createAnimation,
};

export const lightenColor = (color: Color, strength = 0.4): string => {
  // strength 0 - 1
  const themedColor = theme.getThemedColor(color);

  const num = parseInt(themedColor.replace('#', ''), 16);
  const amt = Math.round(2.55 * strength * 100);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt;
  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1)}`;
};

export default theme;
export type Theme = typeof theme;
