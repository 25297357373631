import { Table } from 'antd';
import styled from 'styled-components';

import { ExtendedTableProps } from './types';

export default styled(Table)<ExtendedTableProps>`
  .ant-table-placeholder {
    .ant-table-cell {
      border-bottom: none !important;
    }
  }

  &.remove-locale {
    .ant-table-empty {
      display: none !important;
    }
  }

  &.show-locale {
    .ant-table-empty {
      display: block !important;
    }
  }
`;
