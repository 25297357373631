import { FC } from 'react';

import { InputProps } from 'antd/lib/input';

import StyledSearchInput from './styles';

const SearchInput: FC<InputProps> = (inputProps) => {
  return <StyledSearchInput {...inputProps} />;
};

export default SearchInput;
