import { Flex, Modal } from 'antd';
import styled from 'styled-components';

export default styled(Flex)<{
  backgroundColor?: string;
  $isLockShown?: boolean;
  fontSize?: number;
  opacity?: number;
}>`
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor, opacity }) =>
    backgroundColor || `rgba(247,248,250,${opacity})`};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
    line-height: ${({ fontSize }) =>
      fontSize ? `${fontSize * 1.5}px` : '21px'};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.dark};
    margin-top: ${({ $isLockShown }) =>
      $isLockShown ? '24px !important' : '0'};
    margin-bottom: ${({ $isLockShown }) =>
      $isLockShown ? '16px !important' : '0'};
  }
  .button {
    width: 95px;
    height: 32px;
    padding: 9px 24px;
    line-height: 14px;
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-close {
    right: 40px !important;
  }
`;
