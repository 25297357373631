import { CollapseProps } from 'antd';

import { ExtendedItem } from '@/types/collapse';

import CollapseTag from './CollapseTag';
import StyledCollapse from './styles';

interface ExtendedCollapseProps extends CollapseProps {
  dataSource: ExtendedItem[];
}

const CustomCollapse: React.FC<ExtendedCollapseProps> = ({
  dataSource,
  ...props
}) => {
  const enhancedDataSource = dataSource.map((item) => {
    const {
      successMessage,
      errorMessage,
      condition,
      closableWhenUnselected,
      ...rest
    } = item;
    return {
      ...rest,
      extra: condition ? (
        <CollapseTag type="success" successText={successMessage} />
      ) : (
        <CollapseTag type="error" errorText={errorMessage} />
      ),
      closable: closableWhenUnselected ? 'true' : 'false',
    };
  });

  return (
    <StyledCollapse accordion {...props}>
      {enhancedDataSource.map((item) => (
        <StyledCollapse.Panel {...item}>{item.children}</StyledCollapse.Panel>
      ))}
    </StyledCollapse>
  );
};

export default CustomCollapse;
