import { useEffect, useRef } from 'react';

import { useSearchParams } from 'react-router-dom';

import useProductTour from '@/hooks/useProductTour';
import useProductTourStore from '@/store/useProductTourStore';
import { Simulation } from '@/types/simulations';

interface UseSimulationTourProps {
  isNewSimulationDrawerVisible: boolean;
  setNewSimulationDrawerVisible: (visible: boolean) => void;
  runningSimulations: Simulation[];
}

const useProductTourCreateSimulation = ({
  isNewSimulationDrawerVisible,
  setNewSimulationDrawerVisible,
  runningSimulations,
}: UseSimulationTourProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setStepReference } = useProductTourStore();

  const runningSimulationRef = useRef<HTMLDivElement>(null);
  const completedSimulationRef = useProductTour('completedSimulation');
  const exampleSimulationRef = useProductTour('exampleSimulation');

  const shouldSetRunningSimulationRef =
    !isNewSimulationDrawerVisible &&
    runningSimulationRef.current &&
    runningSimulations.length > 0;

  useEffect(() => {
    // If there are running simulations, set the running simulation ref
    if (shouldSetRunningSimulationRef) {
      setStepReference('runningSimulation', runningSimulationRef.current);
    }
  }, [shouldSetRunningSimulationRef, setStepReference]);

  useEffect(() => {
    // If createSimulation query param is set, show the new simulation drawer
    if (searchParams.has('createSimulation')) {
      setNewSimulationDrawerVisible(true);
      searchParams.delete('createSimulation');
      setSearchParams(searchParams);
    }
  }, [searchParams, setNewSimulationDrawerVisible, setSearchParams]);

  return { runningSimulationRef, completedSimulationRef, exampleSimulationRef };
};

export default useProductTourCreateSimulation;
