import { Row, Space, Button } from 'antd';

import { SwitchPlansProps } from '@/types/plan';

const SwitchPlans: React.FC<SwitchPlansProps> = ({
  yearly,
  handleSwitch,
  //percentage,
}) => {
  return (
    <Row className="pad-switch">
      <Space className="btn-groups" align="center" size={8}>
        <Button
          onClick={handleSwitch}
          className={yearly ? 'nonSelected-btn' : 'selected-btn'}
        >
          Monthly
        </Button>

        <Button
          onClick={handleSwitch}
          className={yearly ? 'selected-btn' : 'nonSelected-btn'}
        >
          Yearly
        </Button>

        {/* {yearly && (
          <Typography.Text className="fs-14 text-secondary-color">
            Save {percentage}%!
          </Typography.Text>
        )} */}
      </Space>
    </Row>
  );
};

export default SwitchPlans;
