import {
  CreditCard,
  CreditCardPayload,
  Subscription,
  SubscriptionRequest,
} from '@/types/payment';

import { apiV1 } from '../instances';

export default {
  postSubscribe(payload: SubscriptionRequest): Promise<Subscription> {
    return apiV1.post(`/payment/subscriptions/`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  saveCreditCard(payload: CreditCardPayload): Promise<CreditCard> {
    return apiV1.post(`/payment/cards/`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  deleteCreditCard(cardId: number) {
    return apiV1.delete(`/payment/cards/${cardId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  validateCreditCardFromNumber(number: string) {
    return apiV1.post(
      `/payment/cards/validate`,
      { number },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  },
};
