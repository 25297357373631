import { Row } from 'antd';
import styled from 'styled-components';

export default styled(Row)`
  padding: 60px 270px 120px 270px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.blueLightest};
  /* height: 100%; */
  .container {
    background-color: ${({ theme }) => theme.colors.light};
    border-radius: 10px;
    border: 0.5px solid ${({ theme }) => theme.colors.grayLightAccent};
    padding: 60px;
    .title {
      font-size: 17px;
      font-weight: 600;
      line-height: 25px;
      text-align: center;
    }
    .description {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: ${({ theme }) => theme.colors.grayAccent};
      margin-top: 8px;
      padding: 0px 40px;
    }
    .line {
      width: 100%;
      color: ${({ theme }) => theme.colors.grayLightAccent};
      margin: 0;
      height: 1px;
      margin-top: 24px;
    }

    .form-contact-us {
      margin-top: 24px;
      .ant-form-item-explain-error {
        display: block !important;
      }

      .ant-form-item {
        margin-bottom: 40px;
      }

      .ant-form-item-label {
        padding-bottom: 8px !important;
      }

      .ant-form-item-explain-error {
        display: none;
      }
    }
  }
  .btn-group {
    margin-top: 8px;
    display: flex;
    align-self: flex-end;
    gap: 16px;
    .btn-send {
      width: 90px;
      height: 32px;
    }
    .btn-cancel {
      width: 90px;
      height: 32px;
    }
  }
`;
