import { ReactNode } from 'react';

import { Link } from 'react-router-dom';

import LOGO from '@/assets/logo/logo.png';
import useAuth from '@/hooks/useAuth';
import paths from '@/routes/paths';

import HeaderAccount from './HeaderAccount';
import SiderOrganization from './HeaderOrganization';
import HeaderToolbar from './HeaderToolbar';
import HeaderTrial from './HeaderTrial';
import StyledHeader from './styles';

const Header: React.FC<{ children?: ReactNode }> = (props) => {
  const { user } = useAuth();
  const planType = user?.organization.subscription.plan.name;

  return (
    <StyledHeader {...props}>
      <Link to={paths.simulation.base}>
        <img className="sider-logo" alt="Logo" src={LOGO} />
      </Link>
      <div className="toolbar">
        {planType && 'demo' && <HeaderTrial />}

        <SiderOrganization user={user} />
        <HeaderToolbar />
        <HeaderAccount user={user} />
      </div>
    </StyledHeader>
  );
};

export default Header;
