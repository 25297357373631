import { ReactNode } from 'react';

import { useTheme } from 'styled-components';

import IconDiesel from '@/assets/icons/icon-diesel.svg?react';
import IconOther from '@/assets/icons/icon-other.svg?react';
import IconSolar from '@/assets/icons/icon-solar.svg?react';
import IconWind from '@/assets/icons/icon-wind.svg?react';
import { GenerationTypeKey } from '@/types/plant';

type GenerationOptionType = {
  label: string;
  value: GenerationTypeKey;
  icon: ReactNode;
};

type GenerationType = {
  wind: GenerationOptionType;
  solar: GenerationOptionType;
  diesel: GenerationOptionType;
  other: GenerationOptionType;
};

const useGenerationTypes = () => {
  const theme = useTheme();

  const generationTypes: GenerationType = {
    wind: {
      label: 'Wind',
      value: 'wind',
      icon: <IconWind fill={theme.colors.greenPale} />,
    },
    solar: {
      label: 'Solar',
      value: 'solar',
      icon: <IconSolar fill={theme.colors.yellowAccent} />,
    },
    diesel: {
      label: 'Diesel',
      value: 'diesel',
      icon: <IconDiesel fill={theme.colors.bluePrimary} />,
    },
    other: {
      label: 'Other',
      value: 'other',
      icon: <IconOther fill={theme.colors.grayPrimary} />,
    },
  };

  return generationTypes;
};

export default useGenerationTypes;
