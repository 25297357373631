import { forwardRef } from 'react';

import { Tooltip, TooltipProps, Typography } from 'antd';
import { TooltipRef } from 'antd/es/tooltip';
import { useTheme } from 'styled-components';

type ExtendedTooltipProps = TooltipProps & {
  title?: string;
  description: string | JSX.Element;
};

const RTooltip = forwardRef<TooltipRef, ExtendedTooltipProps>(
  ({ title, description, children, ...props }, ref) => {
    const theme = useTheme();

    return (
      <Tooltip
        ref={ref}
        title={
          <div>
            {title && (
              <>
                <Typography.Text className="fs-14-semi-bold">
                  {title}
                </Typography.Text>
                <div className="horizontal-line mt-4"></div>
              </>
            )}

            <Typography.Text className="fs-14-regular smoke mt-4">
              {description}
            </Typography.Text>
          </div>
        }
        overlayInnerStyle={{
          padding: theme.spacing.spacing12 + 'px',
          color: 'inherit',
        }}
        color={theme.colors.light}
        {...props}
      >
        <div>{children}</div>
      </Tooltip>
    );
  }
);

export default RTooltip;
