import { useState } from 'react';

import { Button, Flex, Select, Spin, Typography } from 'antd';
import { motion } from 'framer-motion';
import { useParams } from 'react-router';
import {
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconDownload from '@/assets/icons/icon-download.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Card from '@/components/RCard';
import CustomGraphicTooltip from '@/components/RGraphicTooltip';
import RTooltip from '@/components/RTooltip';
import {
  SimulationBatteryDegradation,
  SimulationResult,
} from '@/types/simulations';
import { getTickStep, getYears, tickFormatterWithoutDollars } from '@/utils';

import StyledBatteryLife from './styles';

const BatteryAndLifeCycle = () => {
  const resultId = useParams<Record<string, string>>();
  const [selectedYear, setSelectedYear] = useState<number | string>(
    'All Years'
  );
  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`,
    {
      revalidateOnFocus: false,
    }
  );
  const [closedDataKeys, setClosedDataKeys] = useState<string[]>([]);
  const theme = useTheme();

  const years = [
    'All Years',
    ...getYears(simulationData?.startDate || '', simulationData?.endDate || ''),
  ];

  const getEndpoint = () => {
    if (selectedYear === 'All Years') {
      return `/simulation/${resultId.resultId}/battery_degradation/?timeUnit=year`;
    } else {
      const startDate = `${selectedYear}-01-01T00:00:00Z`;
      const endDate = `${selectedYear}-12-31T23:59:59Z`;
      return `/simulation/${resultId.resultId}/battery_degradation/?endDatetime=${endDate}&startDatetime=${startDate}&timeUnit=month`;
    }
  };
  const endpoint = getEndpoint();
  const {
    data: batteryDegData,
    isLoading,
    error,
  } = useSWR<SimulationBatteryDegradation>(endpoint, {
    revalidateOnFocus: false,
  });
  const isDataZero = batteryDegData?.graphData.length === 0;

  const toggleClosedDataKeys = (key: string = '') => {
    if (closedDataKeys.includes(key)) {
      setClosedDataKeys(closedDataKeys.filter((item) => item !== key));
    } else {
      setClosedDataKeys([...closedDataKeys, key]);
    }
  };

  const handleYearChange = (value: number | string) => {
    setSelectedYear(value);
  };

  const yearData =
    batteryDegData?.graphData?.map((item) => {
      const filteredItem: {
        name: string;
        fullCycleForAncillaryServices: string;
        fullCycle: string;
        soh: string;
      } = {
        name:
          selectedYear === 'All Years'
            ? new Date(item.dt).getFullYear().toString()
            : new Date(item.dt).toLocaleString('en-US', { month: 'short' }),
        fullCycleForAncillaryServices: '',
        fullCycle: '',
        soh: (item.soh * 100)?.toFixed(1),
      };
      if (!closedDataKeys.includes('fullCycleForAncillaryServices')) {
        filteredItem.fullCycleForAncillaryServices =
          item.batteryFullCycleNumberOnlyAs?.toFixed(1);
      }
      if (!closedDataKeys.includes('fullCycle')) {
        filteredItem.fullCycle = item.batteryFullCycleNumber?.toFixed(1);
      }
      return filteredItem;
    }) || [];

  if (!yearData) {
    return null;
  }

  const maxValuesRightAxis = Math.max(
    ...yearData.map(
      (item) =>
        Number(item.fullCycleForAncillaryServices) + Number(item.fullCycle)
    )
  );

  const roundToNearest = (num: number, nearest: number) => {
    return Math.round((num / nearest) * nearest);
  };

  const tickStepRight = getTickStep(maxValuesRightAxis);

  const upperBoundRight = roundToNearest(
    maxValuesRightAxis * 1.1,
    tickStepRight
  );
  const stepRight = upperBoundRight / 10 || upperBoundRight;

  const ticksRight = Array.from({ length: 11 }).map((_, i) =>
    (i * stepRight).toFixed(1)
  );

  const domainRight = [0, upperBoundRight];

  return (
    <StyledBatteryLife>
      <Card
        title={
          <Flex gap={8} align="center">
            <Typography.Title level={4} className="fs-17-bold">
              Battery Life and Cyle Number
            </Typography.Title>
            <RTooltip
              title="Battery Life and Cycle Number"
              description="This graph displays the number of cycles performed by the battery
              and its decreasing lifespan over time, shown on a yearly or
              monthly basis."
            >
              <IconInfo />
            </RTooltip>
          </Flex>
        }
        extra={
          <Flex gap={16} align="center">
            <Flex gap={8} align="center">
              <motion.div
                whileTap={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300, damping: 24 }}
              >
                <Select
                  defaultValue={selectedYear ? selectedYear : 'All Years'}
                  style={{ width: '110px' }}
                  onChange={handleYearChange}
                >
                  {years.map((year) => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
              </motion.div>
            </Flex>
            <Button icon={<IconDownload fill={theme.colors.bluePrimary} />} />
          </Flex>
        }
        $paddingBody="24px"
        style={{ width: '100%', marginTop: '24px' }}
        styles={{ header: { padding: '14px 24px' } }}
      >
        {isLoading ? (
          <Flex justify="center" align="center" style={{ height: '400px' }}>
            <Spin style={{ margin: '20px' }} />
          </Flex>
        ) : error || isDataZero ? (
          <Flex
            vertical
            gap={12}
            justify="center"
            align="center"
            style={{ height: 400 }}
          >
            <IconError width={50} height={50} fill={theme.colors.tagFailure} />
            {error && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                An error occured while fetching data. Please check your inputs
                and contact support if the issue persists.
              </Typography.Text>
            )}
            {isDataZero && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                No data is currently available. Please review your inputs.
              </Typography.Text>
            )}
          </Flex>
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart data={yearData} stackOffset="sign">
              <CartesianGrid vertical={false} horizontal={true} />
              <XAxis dataKey="name" axisLine={false} tickLine={false} />
              <YAxis
                yAxisId="left"
                domain={[0, 100]}
                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                axisLine={false}
                tickLine={false}
                tick={{ dx: -8 }}
                label={{
                  value: 'Percentage(%)',
                  angle: -90,
                  dx: -15,
                  fontSize: 12,
                }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                domain={domainRight}
                ticks={ticksRight}
                tickFormatter={tickFormatterWithoutDollars}
                tick={{ dx: 2 }}
                axisLine={false}
                tickLine={false}
                label={{
                  value: 'Full Cycle',
                  angle: -90,
                  dx: 28,
                  fontSize: 12,
                }}
              />
              <Tooltip content={<CustomGraphicTooltip />} />
              <Legend
                payload={[
                  {
                    value: 'Full Cycle from Ancillary Services',
                    id: 'fullCycleForAncillaryServices',
                    type: 'circle',
                    color: theme.colors.purplePrimary,
                    inactive: closedDataKeys.includes(
                      'fullCycleForAncillaryServices'
                    ),
                  },
                  {
                    value: 'Full Cycle',
                    id: 'fullCycle',
                    type: 'circle',
                    color: theme.colors.blueAccent,
                    inactive: closedDataKeys.includes('fullCycle'),
                  },
                  {
                    value: 'SoH',
                    id: 'soh',
                    type: 'line',
                    color: theme.colors.turquoisePrimary,
                    inactive: closedDataKeys.includes('soh'),
                  },
                ]}
                iconSize={10}
                onClick={({ id }) => toggleClosedDataKeys(id)}
              />
              {!closedDataKeys.includes('soh') && (
                <Line
                  name="SoH"
                  yAxisId="left"
                  type="linear"
                  dataKey="soh"
                  dot={false}
                  stroke={theme.colors.turquoisePrimary}
                  strokeWidth={2}
                  unit={'%'}
                />
              )}
              {!closedDataKeys.includes('fullCycle') && (
                <Bar
                  name="Full Cycle"
                  yAxisId="right"
                  dataKey="fullCycle"
                  barSize={16}
                  stackId={1}
                  fill={theme.colors.blueAccent}
                />
              )}
              {!closedDataKeys.includes('fullCycleForAncillaryServices') && (
                <Bar
                  name="Full Cycle from Ancillary Services"
                  yAxisId="right"
                  dataKey="fullCycleForAncillaryServices"
                  barSize={16}
                  stackId={1}
                  fill={theme.colors.purplePrimary}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </Card>
    </StyledBatteryLife>
  );
};
export default BatteryAndLifeCycle;
