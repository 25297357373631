import { DrawerLockState } from './index';
import Mask from '../RMask';

const renderMaskContent = (
  isMembershipActive: boolean | undefined,
  isMaskVisible: boolean,
  drawerLock?: DrawerLockState,
  onClose?: () => void
) => {
  const maskProps = isMembershipActive
    ? {
        text: 'Subscription period has ended. Please renew to continue.',
        onClose,
      }
    : isMaskVisible && drawerLock?.isLocked
      ? {
          text: drawerLock.lockText,
          onClose,
          lockButtons: drawerLock.buttons,
        }
      : null;
  return maskProps ? <Mask {...maskProps} /> : null;
};

export default renderMaskContent;
