import { FC } from 'react';

import {
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputProps,
  Row,
  Typography,
} from 'antd';
import { FormInstance } from 'antd/lib';
import { useTheme } from 'styled-components';

import { StorageSystemForm } from '@/types/storageSystem';
import { normalizePositiveNumericValuesInput } from '@/utils';

type Props = {
  disabled?: boolean;
  form: FormInstance<StorageSystemForm>;
};

const BatteryCapability: FC<Props> = ({ disabled = false, form }) => {
  const theme = useTheme();

  const onChangeRatedEnergy: InputProps['onChange'] = (event) => {
    const val = event.target.value;
    const numericValue = val ? parseFloat(val) : null;

    if (numericValue !== null) {
      form.setFieldsValue({
        internalBatteryVoltageV: undefined,
        ratedCapacityAh: undefined,
      });
    }
  };

  const onChangeRatedEnergyParameter = () => {
    const { ratedCapacityAh, internalBatteryVoltageV } = form.getFieldsValue();
    const calculatedRatedEnergy =
      ((ratedCapacityAh || 0) * (internalBatteryVoltageV || 0)) / 1e6;
    form.setFieldsValue({ ratedEnergyMwh: calculatedRatedEnergy });
  };

  const onChangeRoundTripEfficiencyParameter = () => {
    const {
      inverterEfficiency,
      powerConversionUnitEfficiency,
      batteryRoundTripEfficiency,
    } = form.getFieldsValue();

    const calculatedRoundTripEfficiency = (
      ((inverterEfficiency || 0) *
        (powerConversionUnitEfficiency || 0) *
        (batteryRoundTripEfficiency || 0)) /
      (100 * 100)
    ).toFixed(2);

    form.setFieldsValue({
      netOverallRoundTripEfficiency: Number(calculatedRoundTripEfficiency),
    });
  };

  const onChangeRoundTripEfficiency = () => {
    form.setFieldsValue({
      inverterEfficiency: undefined,
      powerConversionUnitEfficiency: undefined,
      batteryRoundTripEfficiency: undefined,
    });
  };

  return (
    <Form.Item
      wrapperCol={{ span: 24 }}
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
      className="p-16"
    >
      <Row>
        <Col className="pr-8" span={12}>
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="ratedPowerMw"
            label="Rated Power"
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                required: true,
                message: 'Rated Power cannot be empty.',
              },
            ]}
          >
            <Input
              addonAfter="MW"
              disabled={disabled}
              placeholder="Rated Power"
            />
          </Form.Item>
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="minAllowedSoh"
            label="Minimum Allowed State of Health"
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                required: true,
                message: 'Minimum Allowed State of Health cannot be empty.',
              },
              {
                validator(_, value) {
                  const maxValue = parseFloat(value);
                  if (isNaN(maxValue) || maxValue < 1) {
                    return Promise.reject(
                      new Error('Value must be between 1 and 99.')
                    );
                  } else if (maxValue > 99) {
                    form.setFieldsValue({ minAllowedSoh: 99 });
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              className="w-100"
              addonAfter="%"
              disabled={disabled}
              placeholder="Min Allowed State of Charge"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="ratedCapacityAh"
            label="Rated Capacity"
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              onChange={onChangeRatedEnergyParameter}
              addonAfter="Ah"
              disabled={disabled}
              placeholder="Rated Capacity"
            />
          </Form.Item>
        </Col>
        <Flex className="pl-8 pr-8" vertical align="center" justify="center">
          <Typography.Text
            className="fs-16 pt-8"
            style={{ color: theme.colors.bluePrimary }}
          >
            X
          </Typography.Text>
        </Flex>

        <Col span={8}>
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="internalBatteryVoltageV"
            label="Internal Battery Voltage"
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              onChange={onChangeRatedEnergyParameter}
              addonAfter="V"
              disabled={disabled}
              placeholder="Internal Battery Voltage"
            />
          </Form.Item>
        </Col>
        <Flex className="pl-8 pr-8" vertical align="center" justify="center">
          <Typography.Text
            className="fs-24 pt-8"
            style={{ color: theme.colors.bluePrimary }}
          >
            =
          </Typography.Text>
        </Flex>
        <Col flex="auto">
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="ratedEnergyMwh"
            label="Rated Energy"
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                required: true,
                message: 'Rated Energy"cannot be empty.',
              },
            ]}
          >
            <Input
              onChange={onChangeRatedEnergy}
              addonAfter="MWh"
              disabled={disabled}
              placeholder="Rated Energy"
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider
        style={{
          border: '0.5px solid',
          borderColor: theme.colors.grayLightAccent,
        }}
        className="mt-24 mb-24"
      />
      <Row>
        <Col className="pr-8" span={12}>
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="minAllowedStateOfCharge"
            label="Min Allowed State of Charge (SoC)"
            normalize={normalizePositiveNumericValuesInput}
            dependencies={['maxAllowedStateOfCharge']}
            rules={[
              {
                required: true,
                message: 'Min Allowed State of Charge cannot be empty.',
              },
              {
                validator(_, value) {
                  const minValue = parseFloat(value);
                  if (isNaN(minValue) || minValue < 0) {
                    return Promise.reject(
                      new Error('Value must be between 0 and 100.')
                    );
                  } else if (minValue > 100) {
                    form.setFieldsValue({ minAllowedStateOfCharge: 100 });
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const maxAllowedStateOfCharge = parseFloat(
                    getFieldValue('maxAllowedStateOfCharge')
                  );
                  const minAllowedStateOfCharge = parseFloat(value);
                  if (minAllowedStateOfCharge >= maxAllowedStateOfCharge) {
                    return Promise.reject(
                      new Error(
                        'Min Allowed State of Charge cannot be greater than Max Allowed State of Charge'
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="w-100"
              addonAfter="%"
              disabled={disabled}
              placeholder="Min Allowed State of Charge"
            />
          </Form.Item>
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="maxAllowedStateOfCharge"
            label="Max Allowed State of Charge (SoC)"
            dependencies={['minAllowedStateOfCharge']}
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                required: true,
                message: 'Max Allowed State of Charge cannot be empty.',
              },
              {
                validator(_, value) {
                  const maxValue = parseFloat(value);
                  if (isNaN(maxValue) || maxValue < 0) {
                    return Promise.reject(
                      new Error('Value must be between 0 and 100.')
                    );
                  } else if (maxValue > 100) {
                    form.setFieldsValue({ maxAllowedStateOfCharge: 100 });
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const minAllowedStateOfCharge = parseFloat(
                    getFieldValue('minAllowedStateOfCharge')
                  );
                  const maxAllowedStateOfCharge = parseFloat(value);
                  if (maxAllowedStateOfCharge <= minAllowedStateOfCharge) {
                    return Promise.reject(
                      new Error(
                        'Max Allowed State of Charge cannot be less than Min Allowed State of Charge'
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="w-100"
              addonAfter="%"
              disabled={disabled}
              placeholder="Maximum Allowed State of Charge"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={5}>
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="batteryRoundTripEfficiency"
            label="Battery Round Trip Efficiency"
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                validator(_, value) {
                  if (value === undefined || value === null || value === '') {
                    return Promise.resolve();
                  }

                  const maxValue = parseFloat(value);
                  if (isNaN(maxValue) || maxValue < 0) {
                    return Promise.reject(
                      new Error('Value must be between 0 and 100.')
                    );
                  } else if (maxValue > 100) {
                    form.setFieldsValue({ batteryRoundTripEfficiency: 100 });
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              onChange={onChangeRoundTripEfficiencyParameter}
              className="w-100"
              addonAfter="%"
              disabled={disabled}
              placeholder="Battery Round Trip Efficiency"
            />
          </Form.Item>
        </Col>
        <Flex className="pl-8 pr-8" vertical align="center" justify="center">
          <Typography.Text
            className="fs-16 pt-8"
            style={{ color: theme.colors.bluePrimary }}
          >
            X
          </Typography.Text>
        </Flex>
        <Col span={5}>
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="powerConversionUnitEfficiency"
            label="Power Conversion Unit Efficiency"
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                validator(_, value) {
                  if (value === undefined || value === null || value === '') {
                    return Promise.resolve();
                  }

                  const maxValue = parseFloat(value);
                  if (isNaN(maxValue) || maxValue < 0) {
                    return Promise.reject(
                      new Error('Value must be between 0 and 100.')
                    );
                  } else if (maxValue > 100) {
                    form.setFieldsValue({ powerConversionUnitEfficiency: 100 });
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              onChange={onChangeRoundTripEfficiencyParameter}
              className="w-100"
              addonAfter="%"
              disabled={disabled}
              placeholder="Power Conversion Unit Efficiency"
            />
          </Form.Item>
        </Col>
        <Flex className="pl-8 pr-8" vertical align="center" justify="center">
          <Typography.Text
            className="fs-16 pt-8"
            style={{ color: theme.colors.bluePrimary }}
          >
            X
          </Typography.Text>
        </Flex>
        <Col span={5}>
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="inverterEfficiency"
            label="Inverter Efficiency"
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                validator(_, value) {
                  if (value === undefined || value === null || value === '') {
                    return Promise.resolve();
                  }

                  const maxValue = parseFloat(value);
                  if (isNaN(maxValue) || maxValue < 0) {
                    return Promise.reject(
                      new Error('Value must be between 0 and 100.')
                    );
                  } else if (maxValue > 100) {
                    form.setFieldsValue({ inverterEfficiency: 100 });
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              onChange={onChangeRoundTripEfficiencyParameter}
              className="w-100"
              addonAfter="%"
              disabled={disabled}
              placeholder="Inverter Efficiency"
            />
          </Form.Item>
        </Col>
        <Flex className="pl-8 pr-8" vertical align="center" justify="center">
          <Typography.Text
            className="fs-24 pt-8"
            style={{ color: theme.colors.bluePrimary }}
          >
            =
          </Typography.Text>
        </Flex>
        <Col flex="auto">
          <Form.Item<StorageSystemForm>
            wrapperCol={{ span: 24 }}
            name="netOverallRoundTripEfficiency"
            label="Net Overall Round-Trip Efficiency"
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                required: true,
                message: 'Net Overall Round-Trip Efficiency cannot be empty.',
              },
              {
                validator(_, value) {
                  const maxValue = parseFloat(value);
                  if (isNaN(maxValue) || maxValue < 0) {
                    return Promise.reject(
                      new Error('Value must be between 0 and 100.')
                    );
                  } else if (maxValue > 100) {
                    form.setFieldsValue({ netOverallRoundTripEfficiency: 100 });
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              onChange={onChangeRoundTripEfficiency}
              className="w-100"
              addonAfter="%"
              disabled={disabled}
              placeholder="Net Overall Round-Trip Efficiency"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default BatteryCapability;
