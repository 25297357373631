import { useEffect } from 'react';

import moment, { Moment } from 'moment';

export interface EndDateFilters {
  startDate?: string;
  inputValue?: number;
  timeUnit?: 'full' | 'year' | 'month' | 'week' | 'day' | 'hour';
  endDate?: string;
}

const useCalculateEndDate = (
  filters: EndDateFilters,
  setFilters: React.Dispatch<React.SetStateAction<EndDateFilters>>
) => {
  useEffect(() => {
    if (
      !filters.startDate ||
      filters.inputValue === undefined ||
      !filters.timeUnit
    )
      return;

    let endDateMoment: Moment = moment(filters.startDate);
    const { inputValue, timeUnit } = filters;

    const units: {
      [key in NonNullable<EndDateFilters['timeUnit']>]: {
        unit: moment.unitOfTime.DurationConstructor;
        end: 'endOf' | 'startOf';
        value: moment.unitOfTime.StartOf;
        multiplier?: number;
      };
    } = {
      year: { unit: 'years', end: 'endOf', value: 'year' },
      month: { unit: 'months', end: 'endOf', value: 'month' },
      week: { unit: 'days', end: 'endOf', value: 'week', multiplier: 7 },
      day: { unit: 'days', end: 'startOf', value: 'day' },
      hour: { unit: 'hours', end: 'startOf', value: 'hour' },
      full: { unit: 'years', end: 'endOf', value: 'year' },
    };

    const unitConfig = units[timeUnit];
    if (unitConfig) {
      const { unit, end, value, multiplier = 1 } = unitConfig;

      endDateMoment = endDateMoment
        .clone()
        .add((inputValue - 1) * multiplier, unit)
        [end](value);

      let formattedEndDate: string;
      if (timeUnit === 'hour') {
        formattedEndDate = endDateMoment.toISOString();
      } else if (['day', 'month', 'week'].includes(timeUnit)) {
        formattedEndDate = endDateMoment
          .startOf('day')
          .format('YYYY-MM-DDT23:59:59.SSS[Z]');
      } else {
        formattedEndDate = endDateMoment
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      }

      setFilters((prevState) => ({
        ...prevState,
        endDate: formattedEndDate,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.inputValue, filters.timeUnit, filters.startDate]);
};

export default useCalculateEndDate;
