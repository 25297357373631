import { Col } from 'antd';
import styled from 'styled-components';

export default styled(Col)`
  .duration-tag {
    display: flex;
    align-items: center;
    padding: 10px;
    height: 52px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.blueLightPale};
    margin: 0px 27px;
    .text {
    }
    .btn {
      margin-left: 16px;
      background-color: ${({ theme }) => theme.colors.blueLightPale};
    }
  }
`;
