import { Tag, TagProps } from 'antd';
import styled from 'styled-components';
import type { DefaultTheme } from 'styled-components';

interface ExtendedTagProps extends TagProps {
  type: string;
}

const getColor = (type: string, theme: DefaultTheme) => {
  switch (type) {
    case 'success':
    case 'started':
      return theme.colors.tagSuccess;
    case 'failure':
      return theme.colors.tagFailure;
    case 'revoked':
      return theme.colors.tagRevoked;
    case 'inactive':
      return theme.colors.tagInactive;
    case 'active':
      return theme.colors.tagSuccess;
    case 'queued':
      return theme.colors.tagQueued;
    default:
      return theme.colors.dark;
  }
};

export default styled(Tag)<ExtendedTagProps>`
  display: inline-flex;
  gap: 8px;
  padding: 6.5px 7px;
  background-color: ${({ theme }) => theme.colors.light};
  span {
    margin-left: 0 !important;
  }
  .anticon {
    font-size: 14px;
    color: ${({ type, theme }) => getColor(type, theme)};
  }
  .ant-typography {
    font-size: 14px;
    line-height: 15.4px;
    font-weight: 400;
    letter-spacing: -0.05px;
    color: ${({ type, theme }) => getColor(type, theme)};
  }
  border: 0.5px solid ${({ type, theme }) => getColor(type, theme)};
`;
