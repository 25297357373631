import { useEffect, useState } from 'react';
import type { FC } from 'react';

import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { useTheme } from 'styled-components';

import { authApi } from '@/api';
import IconClose from '@/assets/icons/icon-close.svg?react';
import IconEdit from '@/assets/icons/icon-edit.svg?react';
import IconSave from '@/assets/icons/icon-save.svg?react';
import Card from '@/components/RCard/index';
import Mask from '@/components/RMask';
import RNotificationCard from '@/components/RNotificationCard';
import PageHeader from '@/components/RPageHeader';
import Redirector from '@/components/RRedirector';
import useAuth from '@/hooks/useAuth';
import paths from '@/routes/paths';
import CookieService from '@/services/CookieService';
import LocalStorageService from '@/services/LocalStorageService';
import { handleError } from '@/utils';

import StyledPage from './styles';

const { Success } = Redirector;

const OrganizationSettings: FC = () => {
  const { user, revalidateUser } = useAuth();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [form_general] = Form.useForm();
  const [form_billing] = Form.useForm();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isNameEditted, setNameEditted] = useState<boolean>(false);
  const [initialName, setInitialName] = useState<string>('');
  const [isOrganizationDeleted, setOrganizationDeleted] =
    useState<boolean>(false);
  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [domainPlaceholder, setDomainPlaceholder] = useState<string>('');
  const theme = useTheme();
  const domain = import.meta.env.MODE;
  useEffect(() => {
    switch (domain) {
      case 'local-mode':
        setDomainPlaceholder('dev.ratiosim.com');
        break;
      case 'development':
        setDomainPlaceholder('dev.ratiosim.com');
        break;
      case 'production':
        setDomainPlaceholder('app.ratiosim.com');
        break;
      case 'test':
        setDomainPlaceholder('test.ratiosim.com');
        break;
      default:
        setDomainPlaceholder('app.ratiosim.com');
        break;
    }
  }, [domain]);

  useEffect(() => {
    form_general.setFieldsValue({
      name: user?.organization.name,
    });
    setInitialName(user?.organization.name || '');
  }, [user, form_general]);

  const confirmDelete = async () => {
    try {
      if (user) {
        await authApi.deleteOrganization(user.organization.id);
        setOrganizationDeleted(true);
        await CookieService.clearCookies();
        await LocalStorageService.clearLocalStorage();
      } else {
        throw new Error('User is missing!');
      }
    } catch (error) {
      handleError(error, 'Failed to delete organization!');
    }
  };

  const patchOrganization = async () => {
    try {
      await form_general.validateFields();
      const formResult = form_general.getFieldsValue();

      if (user && formResult.name) {
        const body = { name: formResult.name };
        const result = await authApi.patchOrganization(
          user.organization.id,
          body
        );

        if (result) {
          notification.success({ message: 'Name changed successfully!' });
          setDisabled(true);
          setNameEditted(false);
          revalidateUser(); // Assuming this refreshes user-related data
        } else {
          throw new Error("Failed to update the organization's name.");
        }
      } else {
        notification.error({
          message: 'User or organization name is missing!',
        });
      }
    } catch (error) {
      handleError(error, 'Failed to update organization name!');
    }
  };

  return (
    <>
      {isOrganizationDeleted ? (
        <Modal
          centered
          width={1300}
          open={isOrganizationDeleted}
          footer={null}
          closable={false}
        >
          <Redirector redirectPath={paths.login} redirectionTimeMS={5000}>
            <Success
              title="Organization Deleted"
              description="Your organization has been successfully deleted."
              showBtn={false}
            />
          </Redirector>
        </Modal>
      ) : (
        <StyledPage>
          <PageHeader
            title="Settings"
            description="You can set new rules and change settings for your organization"
          />
          <Form
            form={form_general}
            layout="vertical"
            initialValues={{
              name: user?.organization?.name,
            }}
            onChange={() => setNameEditted(true)}
            onValuesChange={() => {
              const nameValue = form_general.getFieldValue('name');
              setSaveDisabled(!nameValue);
            }}
          >
            <Card
              title="General Settings"
              $paddingBody="12px 24px"
              className="mt-24"
            >
              <Flex vertical gap={40} style={{ paddingTop: '12px' }}>
                <Row
                  align="middle"
                  gutter={24}
                  wrap={false}
                  style={{ height: '65px' }}
                >
                  <Col span={12}>
                    <Row align={'middle'}>
                      <Col flex={1} className="mr-8">
                        <Form.Item
                          label="Organization Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: 'Name field is required!',
                            },
                          ]}
                        >
                          <Input className="w-100" disabled={disabled} />
                        </Form.Item>
                      </Col>
                      <Flex align="center">
                        {!disabled &&
                          isNameEditted &&
                          (!isSaveDisabled ? (
                            <Popconfirm
                              title={`Are you sure you want to change organization name?`}
                              onConfirm={patchOrganization}
                              okText="Continue"
                            >
                              <Tooltip title={'Save'}>
                                <Button
                                  className="mr-8"
                                  style={{ marginTop: '10px' }}
                                  icon={
                                    <IconSave fill={theme.colors.bluePrimary} />
                                  }
                                />
                              </Tooltip>
                            </Popconfirm>
                          ) : (
                            <Tooltip
                              title={
                                'You can not save without an organization name!'
                              }
                            >
                              <Button
                                className="mr-8"
                                style={{ marginTop: '10px' }}
                                icon={
                                  <IconEdit fill={theme.colors.bluePrimary} />
                                }
                                disabled
                              />
                            </Tooltip>
                          ))}
                        {disabled && (
                          <Tooltip title={'Edit Name'}>
                            <Button
                              style={{ marginTop: '10px' }}
                              onClick={() => {
                                setDisabled(false);
                              }}
                              icon={
                                <IconEdit fill={theme.colors.bluePrimary} />
                              }
                            />
                          </Tooltip>
                        )}
                        {!disabled && (
                          <Tooltip title={'Discard Changes'}>
                            <Button
                              style={{ marginTop: '10px' }}
                              onClick={() => {
                                form_general.setFieldsValue({
                                  name: initialName,
                                });
                                setDisabled(true);
                                setNameEditted(false);
                              }}
                              icon={
                                <IconClose
                                  width={12}
                                  height={12}
                                  fill={theme.colors.bluePrimary}
                                />
                              }
                            />
                          </Tooltip>
                        )}
                      </Flex>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Domain"
                      name="domain"
                      tooltip="Domain will be use in the link to your app. It should be unique.  Exp=: https://domain.ratiosim.com"
                      rules={[
                        {
                          required: false,
                          message: 'Domain field is required!',
                        },
                      ]}
                    >
                      <Input
                        disabled
                        placeholder={domainPlaceholder}
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Flex>
            </Card>
          </Form>
          <Form form={form_billing} layout="vertical">
            <Card
              title="Billing Information"
              $paddingBody="12px 24px"
              className="billing-info mt-24"
            >
              <Mask
                text="This section is currently under development. Thank you for your patience
        and understanding!"
                $isLockShown={true}
                isButtonShown={false}
                fontSize={14}
              />
              <Flex vertical gap={40} style={{ paddingTop: '12px' }}>
                <Row
                  align="middle"
                  gutter={24}
                  wrap={false}
                  style={{ height: '65px' }}
                >
                  <Col span={12}>
                    <Form.Item
                      label="Corporate Name"
                      name="corporate_name"
                      rules={[
                        {
                          required: true,
                          message: 'Name corporate name is required!',
                        },
                      ]}
                    >
                      <Input disabled className="w-100" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  align="middle"
                  gutter={24}
                  wrap={false}
                  style={{ height: '65px' }}
                >
                  <Col span={12}>
                    <Form.Item
                      label="Tax Administration"
                      name="tax_administration"
                      tooltip="Slug should be unique. exp: https://organization.ratiosim.com"
                      rules={[
                        {
                          required: true,
                          message: 'New tax administration field is required!',
                        },
                      ]}
                    >
                      <Input disabled className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="TAX No:"
                      name="tax_number"
                      tooltip="Slug should be unique. exp: https://organization.ratiosim.com"
                      rules={[
                        {
                          required: true,
                          message: 'New TAX no field is required!',
                        },
                      ]}
                    >
                      <Input disabled className="w-100" />
                    </Form.Item>
                  </Col>
                </Row>
                <Flex justify="end">
                  <Button type="primary" disabled>
                    Change
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </Form>
          {user?.role.name.toLocaleLowerCase() == 'admin' && (
            <Card $paddingBody="12px 24px" className="mt-24">
              <Flex justify="space-between" align="center">
                <Flex vertical>
                  <Typography.Title level={4} className="fs-17-bold">
                    Danger Zone
                  </Typography.Title>
                  <Typography.Text className="fs-14-regular">
                    Once you delete an organization, there is no going back.
                    Please be certain.{' '}
                  </Typography.Text>
                </Flex>
                <Button
                  type="primary"
                  danger
                  className="btn-delete"
                  onClick={() => setModalVisible(true)}
                >
                  Delete
                </Button>
              </Flex>
            </Card>
          )}
          <RNotificationCard
            title="Delete Organization"
            message="Are you sure you want to delete the organization?"
            type="warning"
            confirmText="OK"
            cancelText="Cancel"
            open={modalVisible}
            onConfirm={confirmDelete}
            onCancel={() => setModalVisible(false)}
          />
        </StyledPage>
      )}
    </>
  );
};

export default OrganizationSettings;
