import { Flex } from 'antd';
import styled from 'styled-components';

export default styled(Flex)`
  .company-name {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.dark};
  }
  .company-role {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.grayAccent};
  }
  .avatar-cont {
    position: relative;
    .team-avatar {
      width: 35px;
      height: 37.5px;
    }
    .avatar-company-name {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.bluePrimary};
    }
  }
`;
