import { useEffect, useState } from 'react';

import { Flex, Spin } from 'antd';
import { useParams } from 'react-router';

import { authApi } from '@/api';
import Redirector from '@/components/RRedirector';

const { Success } = Redirector;

const Reactivation: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    const reactivateOrganization = async () => {
      try {
        if (token) {
          await authApi.reactivateOrganization(token);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    reactivateOrganization();
  }, [token]);

  if (isLoading) {
    return (
      <Flex justify="center" align="center" style={{ height: '90vh' }}>
        <Spin size="large" />
      </Flex>
    );
  }

  return (
    <Redirector redirectPath="/login">
      <Success
        title="Reactivation successful"
        description="Your account has been reactivated successfully. You can now login with your credentials."
      />
    </Redirector>
  );
};
export default Reactivation;
