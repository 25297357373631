import { Col, Row } from 'antd';
import { motion } from 'framer-motion';
import { useParams } from 'react-router';
import useSWR from 'swr';

import { SimulationResult } from '@/types/simulations';

import KeyMetrics from './KeyMetrics';
import KeyMetricsTable from './KeyMetricsTable';
import AnnualEnergyFlow from '../Energy/AnnualEnergyFlow';
import CashFlow from '../Finance/CashFlow';

const Summary = () => {
  const resultId = useParams<Record<string, string>>();
  const { data, isLoading, error } = useSWR<SimulationResult>(
    resultId.resultId && `/simulation/${resultId.resultId}/`,
    {
      revalidateOnFocus: false,
    }
  );

  const scrollAnimation = (direction: 'left' | 'right') => ({
    initial: { opacity: 0, x: direction === 'left' ? -100 : 100 },
    whileInView: { opacity: 1, x: 0 },
    transition: { type: 'spring', stiffness: 300, damping: 30 },
  });

  return (
    <Row justify="center" align="top">
      <Col span={24}>
        <motion.div {...scrollAnimation('left')}>
          <KeyMetricsTable data={data} isLoading={isLoading} error={error} />
        </motion.div>
      </Col>
      <Col span={24}>
        <motion.div {...scrollAnimation('right')}>
          <KeyMetrics data={data} isLoading={isLoading} error={error} />
        </motion.div>
      </Col>
      <Col span={24}>
        <motion.div {...scrollAnimation('left')}>
          <AnnualEnergyFlow />
        </motion.div>
      </Col>
      <Col span={24}>
        <motion.div {...scrollAnimation('right')}>
          <CashFlow barWidth={29} />
        </motion.div>
      </Col>
    </Row>
  );
};

export default Summary;
