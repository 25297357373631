import { Input } from 'antd';
import styled from 'styled-components';
const { Search } = Input;

export default styled(Search)`
  display: flex;
  align-items: center;
  width: 180px;
  height: 32px;
  .ant-input {
    border-radius: 4px;
    height: 32px !important;
    padding: 5.5px 12px !important;
  }
  .ant-btn {
    background-color: ${({ theme }) => theme.colors.primary};
    height: 32px;
    .ant-btn-icon {
      color: ${({ theme }) => theme.colors.light};
    }
  }
`;
