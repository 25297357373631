import { create } from 'zustand';

import { StepReferences } from '@/components/RProductTour/steps';
import { TourSteps } from '@/constants';

interface ProductTourState {
  isTourActive: boolean;
  currentStep: TourSteps;
  stepReferences: StepReferences;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  setStepReference: (
    key: keyof StepReferences,
    element: HTMLElement | null
  ) => void;
  startTour: () => void;
  endTour: () => void;
  changeStep: (step: number) => void;
}

const useProductTourStore = create<ProductTourState>((set) => ({
  isTourActive: false,
  currentStep: TourSteps.USER_PROFILE,
  stepReferences: {} as StepReferences,
  showModal: false,
  setShowModal: (showModal) => set({ showModal }),
  setStepReference: (key, element) =>
    set((state) => ({
      stepReferences: {
        ...state.stepReferences,
        [key]: element,
      },
    })),
  startTour: () =>
    set({ isTourActive: true, currentStep: TourSteps.USER_PROFILE }),
  endTour: () => set({ isTourActive: false }),
  changeStep: (step) => set({ currentStep: step }),
}));

export default useProductTourStore;
