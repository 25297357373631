import * as React from 'react';

import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';

import { apiV1 } from '@/api/instances.ts';
// import { withAbortableMiddleware } from '@/hocs/withAbortableMiddleware.tsx';
import darkTheme from '@/theme';

import App from './App.tsx';

import '@/types.d.ts';

// const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
// const APP_VERSION = import.meta.env.VITE_APP_VERSION;
// const APP_NAME = import.meta.env.VITE_APP_NAME;

// Sentry.init({
//   dsn: SENTRY_DSN,
//   release: `${APP_NAME}-${APP_VERSION}`,
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/sigma\.ratiosim\.com/],
// });

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <StyleSheetManager>
        <ThemeProvider theme={darkTheme}>
          {/* //TODO */}
          <SWRConfig
            value={{
              loadingTimeout: 10000,
              // use: [withAbortableMiddleware],
              fetcher: (url: string) => apiV1.get(url).then((res) => res),
            }}
          >
            <App />
          </SWRConfig>
        </ThemeProvider>
      </StyleSheetManager>
    </BrowserRouter>
  </React.StrictMode>
);
