import { Row } from 'antd';
import styled from 'styled-components';

import Drawer from '@/components/RDrawer';

export default styled(Row)``;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    margin-top: 24px;

    background-color: ${({ theme }) => theme.colors.light} !important;
    padding: 16px;
    margin-bottom: 64px;
    padding-bottom: 24px;
  }

  &.view-drawer {
    .ant-drawer-body {
      border-radius: 10px;
      margin-bottom: 0 !important;
    }
  }
  .ant-steps-item-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    height: 32px;
    padding-inline-end: 8px;
  }
  .ant-steps-label-horizontal > .ant-steps-item {
    flex: none;
    display: flex;
  }
  .ant-steps-label-horizontal > .ant-steps-item:nth-child(1) {
    flex: 20.05;
  }
  .ant-steps-label-horizontal > .ant-steps-item:nth-child(2) {
    flex: 32.05;
  }
  .ant-steps-label-horizontal > .ant-steps-item:nth-child(3) {
    flex: 22.8;
  }
  .ant-steps-label-horizontal > .ant-steps-item:nth-child(4) {
    flex: 23.05;
  }

  .ant-steps-label-horizontal > .ant-steps-item:nth-child(n + 2) {
    padding-inline-start: 8px !important;
  }
  .ant-steps-item-icon {
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .ant-steps-icon > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-steps-item-active {
    .ant-steps-item-icon {
      background-color: ${({ theme }) => theme.colors.bluePrimary} !important;
      .ant-steps-icon {
        color: ${({ theme }) => theme.colors.light} !important;
      }
    }
  }

  .bottom-button-group {
    position: fixed;
    width: 85%;
    bottom: 0;
    right: 0;
    background: white;
    padding: 24px;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  .right-side-btn-group > button {
    width: 90px;
  }

  .ant-input-group-addon {
    background-color: ${({ theme }) => theme.colors.primaryHighlight};
    min-width: 90px !important;

    .ant-select {
      background-color: transparent !important;
    }
  }

  .loading-wrapper {
    width: 100%;
    position: absolute;
    opacity: 0.8;
    z-index: 1000;
    height: calc(100% - 1px);
    top: 0.5px;
    left: 0;
    background-color: ${({ theme }) => theme.colors.grayPale};
    border-radius: ${({ theme }) => theme.main.borderRadius}px;
  }
`;
