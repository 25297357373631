import { FC } from 'react';

import StyledSelect from './styles';
import { RSelectProps } from './types';

const RSelect: FC<RSelectProps> = (props) => {
  return <StyledSelect {...props} />;
};

export default RSelect;
