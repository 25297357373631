import { RcFile } from 'antd/lib/upload';

import { TimeUnitType } from './general';

export type ScenariosResponse = {
  count: number;
  next: undefined;
  previous: undefined;
  results: Scenario[];
};

export type QueryParamsFilterProps = {
  name: string;
  isActive?: 'all' | 'active' | 'inactive';
};

export type ModelType = 'feed_in_tariff_only' | 'market_only' | 'hybrid';
export type InterestType = 'simple' | 'compound';
export type BatteryDepletedType =
  | 'replace_batteries'
  | 'stop_simulation'
  | 'continue_wo_storage';

export enum ParticipationStatus {
  DoNotParticipate = 0,
  Participate = 1,
  DependsOnTheModel = 2,
}

export const ParticipationStatusDescriptions = {
  [ParticipationStatus.DoNotParticipate]: 'Do not participate',
  [ParticipationStatus.Participate]: 'Participate',
  [ParticipationStatus.DependsOnTheModel]: 'Depends on the model',
};

export const participationStatusOptions = Object.entries(
  ParticipationStatusDescriptions
).map(([key, value]) => ({
  label: value,
  value: key,
}));

export type Scenario = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  createdAt?: string;
  createdBy?: string;
  pricesAndLoad?: string | RcFile | Blob;
  isActive?: boolean;
  sellToGrid: boolean;
  purchaseFromGrid: boolean;
  marketTimeInterval?: number;
  replaceBattery: boolean;
  taxRate: number;
  interestRate: number;
  interestTimeFrame: 'monthly' | 'yearly';
  interestType: InterestType;
  modelType?: ModelType;
  unitDegradation: boolean;
  hurdleRate?: number;
  replacementNumberLimit?: number;
  whenBatteryIsDepleted: BatteryDepletedType;
  batteryPriceDeclinePercentagePerYear: number;
  pfkOption: number;
  sfkOption: number;
  pfcParticipation: string | number;
  sfcParticipation: string | number;
  description?: string;
  organization?: number;
  cAndISegment: number;
};

export type ScenarioPost = Partial<
  Pick<
    Scenario,
    | 'name'
    | 'description'
    | 'pricesAndLoad'
    | 'sellToGrid'
    | 'purchaseFromGrid'
    | 'unitDegradation'
    | 'whenBatteryIsDepleted'
    | 'replacementNumberLimit'
    | 'modelType'
    | 'taxRate'
    | 'marketTimeInterval'
    | 'interestRate'
    | 'interestTimeFrame'
    | 'interestType'
    | 'batteryPriceDeclinePercentagePerYear'
    | 'hurdleRate'
    | 'pfcParticipation'
    | 'sfcParticipation'
    | 'replaceBattery'
    | 'pfkOption'
    | 'id'
    | 'sfkOption'
    | 'cAndISegment'
  >
> & {
  draftId?: number;
};

export type ScenarioForm = Partial<
  Pick<
    Scenario,
    | 'name'
    | 'description'
    | 'sellToGrid'
    | 'purchaseFromGrid'
    | 'pricesAndLoad'
    | 'purchaseFromGrid'
    | 'marketTimeInterval'
    | 'id'
    | 'isActive'
    | 'replaceBattery'
    | 'taxRate'
    | 'modelType'
    | 'unitDegradation'
    | 'hurdleRate'
    | 'replacementNumberLimit'
    | 'whenBatteryIsDepleted'
    | 'batteryPriceDeclinePercentagePerYear'
    | 'sfcParticipation'
    | 'pfcParticipation'
    | 'pfkOption'
    | 'sfkOption'
    | 'cAndISegment'
  >
> & {
  gridPowerOutflowConstraint?: number;
  gridOutflowCost?: number;
  gridPowerInflowConstraint?: number;
  gridInflowCost?: number;
  ratioShadowCost?: boolean;
  gridActions?: string[];

  [key: string]: string | number | undefined | File | Blob | boolean | string[];
};

export type ScenarioDraftPNLPFile = {
  file: string | RcFile | Blob;
  draftId?: number;
};

export type ScenarioDraftPNLPFileResponse = {
  draftId: number;
  startDate: string;
  endDate: string;
};
export type ScenarioDraftPNLGraphFilterParams = {
  endDatetime?: string;
  startDatetime?: string;
  timeUnit?: TimeUnitType;
};

export type DailyPNLDataPoint = {
  damp: number[];
  load: number[];
  pfcp: number[];
  sfcp: number[];
};

export type DailyPNLGraphData = {
  avg: DailyPNLDataPoint;
  all: DailyPNLDataPoint &
    {
      day: string;
    }[];
};

export type AnnualPNLGraphData = {
  dt: string;
  avgDamp: number;
  totalLoad: number;
  avgPfcp: number;
  avgSfcp: number;
}[];

export type DailyPNLGraph = {
  id: number;
  name: string;
  graphData: DailyPNLGraphData;
};

export type AnnualPNLGraph = {
  id: number;
  name: string;
  graphData: AnnualPNLGraphData;
};
