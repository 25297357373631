import * as React from 'react';

import { Route, Routes, Navigate } from 'react-router-dom';

import {
  withLoggedInAndCompletedRequirements,
  withLoggedOut,
} from '@/hocs/withAuth';
import LayoutGuest from '@/layout/Guest';
import LayoutMain from '@/layout/Main';
import Login from '@/pages/Login';
import NotFound from '@/pages/NotFound';
import Organization from '@/pages/Organization';
import AccessManagement from '@/pages/Organization/AccessManagement';
import Payments from '@/pages/Organization/Payments';
import OrganizationSettings from '@/pages/Organization/Settings';
import UserManagement from '@/pages/Organization/UserManagement';
import PasswordRecovery from '@/pages/PasswordRecovery';
import Plants from '@/pages/Plants';
import Reactivation from '@/pages/Reactivation';
import Register from '@/pages/Register';
import ContactUs from '@/pages/Register/ContactUs';
import Result from '@/pages/Result';
import Scenarios from '@/pages/Scenarios';
import Simulations from '@/pages/Simulation';
import StorageSytems from '@/pages/StorageSystems';
import UserProfile from '@/pages/User';
import ChangePassword from '@/pages/User/ChangePassword';
import paths from '@/routes/paths';

const AllRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={withLoggedInAndCompletedRequirements(LayoutMain)()}>
        <Route
          path={paths.landing}
          element={<Navigate to={paths.simulation.base} />}
        />

        <Route
          path={paths.simulation.base}
          element={withLoggedInAndCompletedRequirements(Simulations)()}
        />
        <Route
          path={paths.simulation.result}
          element={withLoggedInAndCompletedRequirements(Result)()}
        />

        <Route
          path={paths.plants.base}
          element={withLoggedInAndCompletedRequirements(Plants)()}
        />
        <Route
          path={paths.scenarios.base}
          element={withLoggedInAndCompletedRequirements(Scenarios)()}
        />
        <Route
          path={paths.storageSystems.base}
          element={withLoggedInAndCompletedRequirements(StorageSytems)()}
        />
        <Route
          path={paths.organization.base}
          element={withLoggedInAndCompletedRequirements(Organization)()}
        />
        <Route
          path={paths.organization.user.management}
          element={withLoggedInAndCompletedRequirements(UserManagement)()}
        />
        <Route
          path={paths.organization.settings}
          element={withLoggedInAndCompletedRequirements(OrganizationSettings)()}
        />
        <Route
          path={paths.organization.payments}
          element={withLoggedInAndCompletedRequirements(Payments)()}
        />
        <Route
          path={paths.organization.access}
          element={withLoggedInAndCompletedRequirements(AccessManagement)()}
        />
        {/* TODO check this later*/}
        <Route
          path={paths.user.base}
          element={withLoggedInAndCompletedRequirements(UserProfile)()}
        />
        <Route
          path={paths.user.changePassword}
          element={withLoggedInAndCompletedRequirements(ChangePassword)()}
        />
      </Route>

      <Route element={withLoggedOut(LayoutGuest)()}>
        <Route path={paths.login} element={withLoggedOut(Login)()} />
        <Route path={paths.register.base} element={withLoggedOut(Register)()} />
        <Route
          path={paths.passwordRecovery}
          element={withLoggedOut(PasswordRecovery)()}
        />
        <Route
          path={paths.register.contactUs}
          element={withLoggedOut(ContactUs)()}
        />
        <Route path={paths.reactivate} element={<Reactivation />} />
        <Route path={`${paths.reactivate}/:token`} element={<Reactivation />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AllRoutes;
