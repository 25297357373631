import styled from 'styled-components';

export default styled.div`
  .ant-select {
    background-color: transparent !important;
    min-width: 90px;
  }

  .ant-input-group-addon {
    background-color: ${({ theme }) => theme.colors.light};

    .ant-select-focused {
      .ant-select-selector {
        color: inherit;
      }
    }
    .ant-select-selection-item {
      color: inherit;
    }
  }
  span.ant-select-selection-item {
    cursor: pointer;
    img {
      position: relative;
      top: -1.5px;
    }
  }
`;
