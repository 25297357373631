/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, type FC, type ReactNode } from 'react';

import { useContentScrollDirection } from '@/store/useContentScrollDirection';

import StyledContent from './styles';

const Content: FC<{ children: ReactNode }> = ({ children }) => {
  const $isResultPage = location.pathname.includes('result');
  const contentRef = useRef<HTMLDivElement>(null);

  const $scrollDirection = useContentScrollDirection(
    (state) => state.$scrollDirection
  );

  const setScrollDirection = useContentScrollDirection(
    (state) => state.setScrollDirection
  );

  const resetScrollDirection = useContentScrollDirection(
    (state) => state.reset
  );

  useEffect(() => {
    let lastScrollTop = contentRef.current?.scrollTop || 0;
    const handleScroll = () => {
      const currentScrollTop = contentRef.current?.scrollTop || 0;
      if (currentScrollTop === 0) {
        resetScrollDirection();
      } else if ($scrollDirection === null) {
        setScrollDirection(currentScrollTop > lastScrollTop ? 'down' : 'up');
      } else {
        setScrollDirection(currentScrollTop > lastScrollTop ? 'down' : 'up');
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    };

    contentRef.current?.addEventListener('scroll', handleScroll);
    return () =>
      contentRef.current?.removeEventListener('scroll', handleScroll);
  }, [$scrollDirection]);

  return (
    <StyledContent $isResultPage={$isResultPage} ref={contentRef}>
      {children}
    </StyledContent>
  );
};

export default Content;
