import { Col, DatePicker, InputNumber, Select } from 'antd';
import styled from 'styled-components';

export default styled(Col)`
  .faded-text {
    color: rgba(0, 0, 0, 0.75);
  }
  .tags {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 3.5px 7px;
    border-radius: 4px;
    border: 0.5px solid ${({ theme }) => theme.colors.blueLightMedium};
    background-color: transparent;
    color: ${({ theme }) => theme.colors.dark};
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.blueLightMedium};
      color: ${({ theme }) => theme.colors.light};
    }
  }
  .switch-text {
    color: ${({ theme }) => theme.colors.graySecondary};
  }
  .ant-btn-icon-only {
    &:hover {
      svg {
        fill: ${({ theme }) => theme.colors.light};
      }
    }
  }
  .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
    border-inline-start: 0px !important;
    border-top: 0px !important;
  }
`;

export const StyledSearch = styled(InputNumber)`
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input {
    padding: 0px;
  }
  display: flex;
  align-items: center;
  padding: 5.5px 12px;
  width: 75px;
  height: 32px;
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    color: ${({ theme }) => theme.colors.grayAccent};
    padding: 5.5px 12px !important;
    height: 32px;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.dark};
  }
  width: 89px !important;
`;

export const StyledDatePicker = styled(DatePicker)`
  display: flex;
  position: relative;
  align-items: center;
  padding: 5.5px 8px;
  min-width: 135px !important;
  height: 32px;
  color: ${({ theme }) => theme.colors.grayAccent};
  .anticon-close-circle > svg {
    fill: ${({ theme }) => theme.colors.dark} !important;
    padding-left: 2px;
  }
`;
