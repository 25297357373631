import { Layout } from 'antd';
import styled from 'styled-components';

import theme from '../../../theme/index';

const { Content } = Layout;

const headerHeight = theme.header.height + 'px';

export default styled(Content)`
  height: calc(100vh - ${headerHeight});
`;
