import { Flex } from 'antd';
import styled from 'styled-components';

type Props = {
  $colorBar?: string;
  $colorText?: string;
};
export default styled(Flex)<Props>`
  .team-plan-cont {
    height: 25px;
    position: relative;
  }

  .team-plan-cont:after {
    content: '';
    position: absolute;
    bottom: -1.5px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${({ $colorBar }) => $colorBar};
    border-radius: 10px;
  }

  .text {
    padding-left: 24px;
    color: ${({ $colorText }) => $colorText};
  }
`;
