import { ConfigProvider } from 'antd';

import { UserProvider } from './hooks/useAuth';
import AllRoutes from './routes';
import GlobalStyles from './theme/GlobalStyles';
import Token from './theme/token';
import '@fontsource/montserrat/900.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/100.css';
import '@fontsource/lato';

function App() {
  return (
    <ConfigProvider theme={Token()}>
      <GlobalStyles />
      <UserProvider>
        <AllRoutes />
      </UserProvider>
    </ConfigProvider>
  );
}

export default App;
