import { useEffect, useState } from 'react';

import { Row, Form, Input, Col, Button, Typography, Flex } from 'antd';
import en from 'world_countries_lists/data/countries/en/world.json';

import { authApi } from '@/api';
import RCountryPhoneInput, {
  ConfigProvider,
  CountryPhoneInputValue,
} from '@/components/RPhoneInput';
import useAuth from '@/hooks/useAuth';
import LocalStorageService from '@/services/LocalStorageService';
import { ProfileForm } from '@/types/general';
import {
  containsNullOrUndefined,
  displayNotification,
  fullNameRegExpPattern,
  handleError,
  validateStrongPassword,
} from '@/utils';

interface ProfileStepProps {
  setCurrentStep: (value: number) => void;
}

export const ProfileStep: React.FC<ProfileStepProps> = ({ setCurrentStep }) => {
  const [form] = Form.useForm();
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(true);
  const { user, revalidateUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const userId = user?.id;

  const hasInitials = !!(user?.fullName && user?.phoneNumber && user?.jobTitle);

  useEffect(() => {
    const fields = ['name', 'lastName', 'countryPhone', 'title'];
    const requiredFields = form.getFieldsValue(fields);
    const allFieldsFilled = !containsNullOrUndefined(requiredFields);
    if (allFieldsFilled && hasInitials) {
      setIsSubmitButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    revalidateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onFinish = async (values: ProfileForm) => {
    const {
      name,
      lastName,
      countryPhone,
      password,
      title,
      passwordValidation,
    } = values;
    setLoading(true);
    if (!userId) {
      setLoading(false);
      displayNotification('error', 'User ID is missing.');
      return;
    }

    const phoneNumber = `${countryPhone.code}${countryPhone.phone}`;

    try {
      await authApi.patchUser(userId, {
        fullName: `${name} ${lastName}`,
        phoneNumber: phoneNumber,
        jobTitle: title,
        password,
        password2: passwordValidation,
      });

      await revalidateUser();
      setCurrentStep(2);
      LocalStorageService.setCurrentStep(2);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: unknown) => {
    console.log('Failed:', errorInfo);
  };

  const handleFormChange = () => {
    const fields = [
      'name',
      'lastName',
      'countryPhone',
      'title',
      'password',
      'passwordValidation',
    ];
    const requiredFields = form.getFieldsValue(fields);
    const hasErrors = form
      .getFieldsError(fields)
      .some((field) => field.errors && field.errors.length > 0);
    setIsSubmitButtonDisabled(
      hasErrors || containsNullOrUndefined(requiredFields) || !userId
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const phoneNumberValidator = (_: any, value: CountryPhoneInputValue) => {
    if (!value || !value.phone) {
      return Promise.reject(new Error('Please input your phone number!'));
    }
    if (value.phone.length < 5) {
      return Promise.reject(
        new Error('Phone number must be at least 5 digits long!')
      );
    }
    return Promise.resolve();
  };

  return (
    <>
      <Typography.Text className="info-text fs-14-regular text-faded-color text-center">
        To create your profile, we need a few information about you. <br />
        Please fill in this information, and then proceed to the next step!
      </Typography.Text>
      <ConfigProvider locale={en}>
        <Form
          initialValues={{
            name: user?.fullName?.split(' ')[0],
            lastName: user?.fullName?.split(' ')[1],
            title: user?.jobTitle,
            countryPhone: {
              code: user?.phoneNumber?.match(/^\+?\d{1,4}/)?.[0],
              phone: user?.phoneNumber?.replace(/^\+?\d{1,4}/, ''),
            },
          }}
          onFieldsChange={handleFormChange}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          name="profileForm"
          layout="vertical"
          form={form}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item<ProfileForm>
                label={
                  <Typography.Text className="fs-14-regular">
                    Name
                  </Typography.Text>
                }
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your name',
                  },
                  {
                    pattern: fullNameRegExpPattern(),
                    message:
                      'Field does not accept special characters or numbers',
                  },
                ]}
              >
                <Input
                  placeholder="Eg: Jane"
                  className="input-style"
                  type="text"
                  disabled={hasInitials}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<ProfileForm>
                label={
                  <Typography.Text className="fs-14-regular">
                    Last Name
                  </Typography.Text>
                }
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your last name',
                  },
                  {
                    pattern: fullNameRegExpPattern(),
                    message:
                      'Field does not accept special characters or numbers',
                  },
                ]}
              >
                <Input
                  placeholder="Eg: Doe"
                  className="input-style"
                  disabled={hasInitials}
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item<ProfileForm>
                label={
                  <Typography.Text className="fs-14-regular">
                    Phone
                  </Typography.Text>
                }
                name="countryPhone"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: phoneNumberValidator,
                  },
                ]}
              >
                <RCountryPhoneInput disabled={hasInitials} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<ProfileForm>
                label={
                  <Typography.Text className="fs-14-regular">
                    Title
                  </Typography.Text>
                }
                name="title"
                rules={[{ required: true, message: 'Please enter your title' }]}
              >
                <Input
                  placeholder="Eg: Site Engineer"
                  className="input-style"
                  disabled={hasInitials}
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>

          {!hasInitials && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item<ProfileForm>
                  tooltip="Strong Password Policy: Your password must include uppercase and lowercase letters, numbers, and at least one special character."
                  label={
                    <Typography.Text className="fs-14-regular">
                      Password
                    </Typography.Text>
                  }
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    { validator: validateStrongPassword },
                  ]}
                >
                  <Input.Password
                    className="input-style"
                    autoComplete="new-password"
                    onChange={() => {
                      form.validateFields(['passwordValidation']);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item<ProfileForm>
                  tooltip="Strong Password Policy: Your password must include uppercase and lowercase letters, numbers, and at least one special character."
                  label={
                    <Typography.Text className="fs-14-regular">
                      Password Validation
                    </Typography.Text>
                  }
                  name="passwordValidation"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('The two passwords do not match!')
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="input-style"
                    autoComplete="new-password-validation"
                    onChange={() => {
                      form.validateFields(['passwordValidation']);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Flex className="btn-group">
            <Button
              onClick={() => setCurrentStep(0)}
              className="btn-back"
              type="default"
            >
              Back
            </Button>
            <Button
              className="btn-next"
              type="primary"
              onClick={() => {
                if (hasInitials) {
                  setCurrentStep(2);
                } else {
                  form.submit();
                }
              }}
              disabled={!hasInitials && isSubmitButtonDisabled}
              loading={loading}
            >
              Next
            </Button>
          </Flex>
        </Form>
      </ConfigProvider>
    </>
  );
};
