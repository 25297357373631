import { FC } from 'react';

import {
  Button,
  Col,
  Divider,
  Flex,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import { useTheme } from 'styled-components';

import IconDelete from '@/assets/icons/icon-delete.svg?react';
import IconPlus from '@/assets/icons/icon-plus.svg?react';
import IconSearch from '@/assets/icons/icon-search.svg?react';
import { FilterOption, filterOptions } from '@/constants';

type RAdvancedSimFiltersProps = {
  selectedFilters: Record<number, string>;
  setFilterRows: React.Dispatch<React.SetStateAction<number[]>>;
  filterRows: number[];
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<Record<number, string>>
  >;
  setInputValues: React.Dispatch<React.SetStateAction<Record<number, string>>>;
  search: string | undefined;
  filterStatus: string;
  setCompletedSimulationgQueryString: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  inputValues: Record<number, string>;
  buildQueryParams: (queryParams: Record<string, string | string[]>) => string;
};

const RAdvancedSimFilters: FC<RAdvancedSimFiltersProps> = ({
  selectedFilters,
  filterRows,
  inputValues,
  search,
  filterStatus,
  buildQueryParams,
  setCompletedSimulationgQueryString,
  setSelectedFilters,
  setInputValues,
  setFilterRows,
}) => {
  const theme = useTheme();

  // Add Filter Row to Advanced Filters
  const handleAddFilterRow = () => {
    if (filterOptions.length > Object.values(selectedFilters).length) {
      setFilterRows([...filterRows, filterRows.length + 1]);
    }
  };

  const handleRemoveFilterRow = (index: number) => {
    const newSelectedFilters = { ...selectedFilters };
    delete newSelectedFilters[index];
    setSelectedFilters(newSelectedFilters);

    const newInputValues = { ...inputValues };
    delete newInputValues[index];
    setInputValues(newInputValues);

    const newFilterRows = filterRows.filter((_, i) => i !== index);
    setFilterRows(newFilterRows);

    const updatedFilters = Object.fromEntries(
      Object.entries(newSelectedFilters).map(([, value], idx) => [idx, value])
    );
    setSelectedFilters(updatedFilters);
  };

  // Select Change Event for Filter
  const handleSelectChange = (value: string, index: number) => {
    const filterLabel =
      filterOptions.find((option) => option.value === value)?.label || '';
    setSelectedFilters({ ...selectedFilters, [index]: filterLabel });
  };
  // Get Available Options for Select in Filter
  const getAvailableOptions = (index: number): FilterOption[] => {
    const selectedValues = Object.entries(selectedFilters)
      .filter(([key]) => parseInt(key) !== index)
      .map(([, value]) => value);

    return filterOptions.filter(
      (option) => !selectedValues.includes(option.label)
    );
  };

  // Filter Input Change
  const handleInputChange = (index: number, value: string) => {
    setInputValues({
      ...inputValues,
      [index]: value,
    });
  };

  // Enter or Click Event for Input in Filter
  const handleInputEnterOrClick = () => {
    const queryParams: Record<string, string | string[]> = {};

    if (search) {
      queryParams.name = search;
    }

    if (filterStatus !== 'all') {
      queryParams.status = filterStatus;
    }

    if (filterStatus === 'all') {
      queryParams.status = ['SUCCESS', 'FAILURE'];
    }

    filterRows.forEach((_, index) => {
      const selectedFilter = selectedFilters[index];
      const inputValue = inputValues[index];

      if (selectedFilter && inputValue) {
        switch (selectedFilter) {
          case 'Description':
            queryParams.description = inputValue;
            break;
          case 'Storage System':
            queryParams.storageName = inputValue;
            break;
          case 'Scenario':
            queryParams.scenarioName = inputValue;
            break;
          case 'Plants':
            queryParams.plantName = inputValue;
            break;
          default:
            break;
        }
      }
    });

    const queryString = buildQueryParams(queryParams);

    setCompletedSimulationgQueryString(queryString);
  };

  return (
    <Col className="filter-section" span={24}>
      <Flex vertical>
        <Divider
          dashed
          style={{
            marginBottom: '-12px',
            borderColor: theme.colors.grayLightAccent,
          }}
          orientation="left"
        >
          <Typography.Text>ADVANCED FILTERS</Typography.Text>
        </Divider>

        <Row className="advanced-filters">
          {filterRows.map((_, index) => (
            <Col span={24} key={index}>
              <Select
                placeholder="Filter Type"
                defaultValue="1"
                style={{ width: 120 }}
              >
                <Select.Option value="1">Search by</Select.Option>
              </Select>
              <Select
                placeholder="Select a filter"
                style={{ width: 150 }}
                onChange={(value) => handleSelectChange(value, index)}
                value={selectedFilters[index]}
              >
                {getAvailableOptions(index).map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
              <Input
                data-index={index}
                placeholder="Search"
                style={{ width: 200 }}
                value={inputValues[index] || ''}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onPressEnter={() => handleInputEnterOrClick()}
                suffix={
                  <IconSearch
                    onClick={() => handleInputEnterOrClick()}
                    style={{ cursor: 'pointer' }}
                  />
                }
              />
              <Button
                icon={<IconPlus fill={theme.colors.bluePrimary} />}
                onClick={handleAddFilterRow}
                disabled={filterRows.length >= 4}
              />
              {index > 0 && (
                <Button
                  icon={<IconDelete fill={theme.colors.redPrimary} />}
                  onClick={() => handleRemoveFilterRow(index)}
                />
              )}
            </Col>
          ))}
        </Row>
      </Flex>
    </Col>
  );
};

export default RAdvancedSimFilters;
