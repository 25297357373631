export const tableData = [
  {
    key: '1',
    feature: <p className="title">Plant</p>,
  },
  {
    key: '2',
    feature: <p className="property">Create/Duplicate/Deactivate</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '3',
    feature: <p className="property">View</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '4',
    feature: <p className="title">Scenarios</p>,
  },
  {
    key: '5',
    feature: <p className="property">Create/Duplicate/Deactivate</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '6',
    feature: <p className="property">View</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '7',
    feature: <p className="title">Storage</p>,
  },
  {
    key: '8',
    feature: <p className="property">Create/Duplicate/Deactivate</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '9',
    feature: <p className="property">View</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '10',
    feature: <p className="title">Simulation</p>,
  },
  {
    key: '11',
    feature: <p className="property">Run</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '12',
    feature: <p className="property">Kill</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '13',
    feature: <p className="property">View</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '14',
    feature: <p className="title">Result</p>,
  },
  {
    key: '15',
    feature: <p className="property">Comparison</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '16',
    feature: <p className="property">Energy Generation & Sales</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '17',
    feature: <p className="property">Revenue</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '18',
    feature: <p className="property">Financial Analysis</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '19',
    feature: <p className="property">P&L (yearly)</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '20',
    feature: <p className="property">Cash Flow (yearly)</p>,
    Demo: true,
    Team: true,
    Individual: true,
    Enterprise: true,
  },
  {
    key: '21',
    feature: <p className="title">Usage limits</p>,
  },
  {
    key: '22',
    feature: <p className="property">Price</p>,
    Demo: 'Free',
    Team: '',
    Individual: '',
    Enterprise: 'Contact',
  },
  {
    key: '23',
    feature: <p className="property">Time</p>,
    Demo: '',
    Team: '',
    Individual: '',
    Enterprise: '',
  },
  {
    key: '24',
    feature: <p className="property">Data Retention After Suspension</p>,
    Demo: '',
    Team: '',
    Individual: '',
    Enterprise: '',
  },
  {
    key: '25',
    feature: <p className="property">Simultaneous Simulations</p>,
    Demo: '',
    Team: '',
    Individual: '',
    Enterprise: '',
  },
  {
    key: '26',
    feature: <p className="property">Total Users</p>,
    Demo: '',
    Team: '',
    Individual: '',
    Enterprise: '',
  },
  {
    key: '27',
    feature: <p className="property">Plant, Storage, Scenario Templates</p>,
    Demo: '',
    Team: '',
    Individual: '',
    Enterprise: '',
  },
  {
    key: '28',
    feature: <p className="property">Role Based Access</p>,
    Demo: false,
    Team: false,
    Individual: false,
    Enterprise: true,
  },
  {
    key: '29',
    feature: <p className="property">Custom Domain Name</p>,
    Demo: false,
    Team: false,
    Individual: false,
    Enterprise: true,
  },
  {
    key: '30',
    feature: <p className="property">Private VPN Network</p>,
    Demo: false,
    Team: false,
    Individual: false,
    Enterprise: true,
  },
  {
    key: '31',
    feature: <p className="property">Excel Report Download</p>,
    Demo: false,
    Team: false,
    Individual: false,
    Enterprise: true,
  },
  {
    key: '32',
    feature: <p className="property">Usage / Simulation Analytics</p>,
    Demo: false,
    Team: false,
    Individual: false,
    Enterprise: true,
  },
  {
    key: '33',
    feature: <p className="property">Weekly Dedicated Consultancy</p>,
    Demo: false,
    Team: false,
    Individual: false,
    Enterprise: true,
  },
  {
    key: '34',
    feature: <p className="property">Support</p>,
    Demo: '',
    Team: '',
    Individual: '',
    Enterprise: '',
  },
];
