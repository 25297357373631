import { Steps } from 'antd';
import styled from 'styled-components';

//TODO NOT WORKING PROPERLY

export default styled(Steps)`
  .ant-steps-item-finish .ant-steps-item-icon {
    background: #bed6ed;
    border: #bed6ed;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background: ${({ theme }) => theme.colors.bluePale};
    border-color: ${({ theme }) => theme.colors.bluePale};

    .ant-steps-icon {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .ant-steps-item-title::after {
    position: absolute;
    top: 16px;
    inset-inline-start: 100%;
    display: block;
    width: 9999px;
    height: 2px;
    background: rgba(255, 255, 255, 0.94);
    content: '';
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: ${({ theme }) => theme.colors.bluePale};
    border-color: ${({ theme }) => theme.colors.bluePale};
  }
`;
