import { Row } from 'antd';
import styled from 'styled-components';

export default styled(Row)`
  padding: 60px 165px;
  background-color: ${({ theme }) => theme.colors.light} !important;
  border: 0.5px solid ${({ theme }) => theme.colors.grayLightAccent};
  border-radius: 10px;

  .pad-switch {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  .btn {
    border-radius: 6px !important;
  }
  .btn-row {
    display: flex;
    flex-wrap: wrap;
  }
  .pad-plans {
    margin: 0 !important;
    padding: 0;
  }
  .compare-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    cursor: pointer;
  }
  .btn-groups {
    padding: 5px;
    border: 1px solid ${({ theme }) => theme.colors.blueDarkAccent};
    border-radius: 6px;
    .selected-btn {
      background-color: ${({ theme }) =>
        theme.colors.blueDarkAccent} !important;
      color: ${({ theme }) => theme.colors.light} !important;
      border-radius: 6px !important;
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      height: 40px;
      width: 110px;
    }
    .nonSelected-btn {
      border: none !important;
      color: ${({ theme }) => theme.colors.grayDarkPrimary} !important;
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      height: 40px;
      width: 110px;
      &:hover {
        color: ${({ theme }) => theme.colors.light} !important;
      }
    }
  }
  .loading-wrapper {
    margin-top: 20vh;
    top: 0px;
    left: 0px;
    z-index: 11;
    position: relative;
  }
  .animated-text {
    font-size: 16px;
    font-weight: 300;
  }
`;
