import { useState } from 'react';

import { Button, Flex, Modal, Row, Typography } from 'antd';
import { useTheme } from 'styled-components';

import IconGiftBox from '@/assets/icons/icon-gift-box.svg?react';
import PlansModal from '@/pages/Organization/Payments/PlansModal';

import StyledSaveUpPlan, { StyledClosedSaveUpPlan } from './styles';

export const SaveUpPlan = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const theme = useTheme();
  return (
    <>
      <StyledSaveUpPlan>
        <Flex vertical gap={16} align="center">
          <IconGiftBox fill={theme.colors.purpleAccent} />
          <Row style={{ width: '116px' }}>
            <Typography.Title level={5} className="fs-20-regular text-center">
              Save up to <br />
              <span
                className="fs-24-bold"
                style={{ color: theme.colors.purpleAccent }}
              >
                %50
              </span>
              <br />
              on Team Plan
            </Typography.Title>
          </Row>

          <Button
            type="default"
            className="btn"
            onClick={() => setIsModalVisible(true)}
          >
            Buy Team Plan
          </Button>
        </Flex>
      </StyledSaveUpPlan>
      <Modal
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        destroyOnClose
        centered
        width={'100%'}
      >
        <PlansModal />
      </Modal>
    </>
  );
};

export const ClosedSaveUpPlan = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const theme = useTheme();
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <StyledClosedSaveUpPlan>
        <Flex vertical gap={23} align="center">
          <IconGiftBox fill={theme.colors.purpleAccent} />
          <Row style={{ width: '70px' }}>
            <Typography.Title level={5} className="fs-14-regular text-center">
              Save up to <br />
              <span className="fs-17-bold">%50</span>
              <br />
              on Team Plan
            </Typography.Title>
          </Row>

          <Button
            type="default"
            className="btn"
            onClick={() => setIsModalVisible(true)}
          >
            Team Plan
          </Button>
        </Flex>
      </StyledClosedSaveUpPlan>
      <Modal
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        destroyOnClose
        centered
        width={'100%'}
      >
        <PlansModal />
      </Modal>
    </>
  );
};
