/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  type ReactNode,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import CookieService from '@/services/CookieService';
import { checkIsMembershipStatusActive, handleError } from '@/utils';

import { authApi } from '../api';
import paths from '../routes/paths';
import { UserResponse } from '../types/auth';

type AuthContextType = {
  user?: UserResponse;
  isMembershipActive?: boolean;
  login: (email: string, password: string) => void;
  logout: () => void;
  revalidateUser: () => void;
};

export const DefaultAuthContext: AuthContextType = {
  user: undefined,
  isMembershipActive: false,
  login: (_email: string, _password: string) => {},
  logout: () => {},
  revalidateUser: () => {},
};

export const AuthContext = createContext(DefaultAuthContext);

export function UserProvider({ children }: { children?: ReactNode }) {
  const [user, setUser] = useState<UserResponse>();
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const navigate = useNavigate();

  const login = async (email: string, password: string) => {
    try {
      const { access, refresh } = await authApi.login(email, password);
      CookieService.setAuthToken(access);
      CookieService.setRefreshToken(refresh);
      const user = await authApi.me();
      setUser(user);

      navigate(paths.simulation.base);
    } catch (error: any) {
      handleError(error, 'Wrong email or password, please try again');
    }
  };

  const logout = () => {
    CookieService.clearCookies();
    setUser(undefined);
    navigate(paths.login);
  };

  const revalidateUser = async () => {
    try {
      const user = await authApi.me();
      setUser(user);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const token = CookieService.getAuthToken();
    if (token) {
      authApi
        .me()
        .then((user) => {
          setUser(user);
        })
        .catch((error) => {
          // Error mekanizması değişebilir.
          handleError(error);
        })
        .finally(() => setLoadingInitial(false));
    } else {
      setLoadingInitial(false);
    }
  }, []);

  const memoedValue = useMemo(
    () => ({
      user,
      isMembershipActive: user && checkIsMembershipStatusActive(user),
      login,
      logout,
      revalidateUser,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
