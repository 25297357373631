/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import { Flex, Select, Spin, Typography } from 'antd';
import { motion } from 'framer-motion';
import { useParams } from 'react-router';
import ReactFlow, {
  ReactFlowProvider,
  NodeProps,
  Handle,
  Position,
  Edge,
  MarkerType,
  Node,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconBattery from '@/assets/icons/icon-battery.svg?react';
import IconConsumption from '@/assets/icons/icon-consumption.svg?react';
import IconCurtailment from '@/assets/icons/icon-curtailment.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconGeneration from '@/assets/icons/icon-generation.svg?react';
import IconGrid from '@/assets/icons/icon-grid.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Card from '@/components/RCard';
import RToggleButtons from '@/components/RToggleButtons';
import Tooltip from '@/components/RTooltip';
import { MONTHS_WITH_VALUES } from '@/constants/index';
import { EnergyFlow } from '@/types/result';
import { formatNumberWithCommas } from '@/utils';

interface handleType {
  id: string;
  type: 'source' | 'target';
  position: Position;
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
}

const AnnualEnergyFlow = () => {
  const resultId = useParams<Record<string, string>>();
  const [isTotal, setIsTotal] = useState(true);
  const [timeUnit, setTimeUnit] = useState<string>('year');
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(1);
  const theme = useTheme();

  const handleYearChange = (value: string) => {
    setTimeUnit(value.toLowerCase());
    setSelectedMonthValue(1);
  };

  const handleMonthChange = (value: number) => {
    setSelectedMonthValue(value);
    setTimeUnit('month');
  };

  const handleSwitchChange = (value: string) => {
    setIsTotal(value === 'Total');
  };

  const {
    data: dataFlowTotal,
    isLoading,
    error,
  } = useSWR<EnergyFlow>(
    `simulation/${resultId.resultId}/energy_flow_total/?timeUnit=${timeUnit}${
      timeUnit === 'year' ? '' : `&value=${selectedMonthValue}`
    }`,
    {
      revalidateOnFocus: false,
    }
  );
  const isDataFlowTotalZero = dataFlowTotal?.graphData.length === 0;

  const { data: dataFlowAvg } = useSWR<EnergyFlow>(
    `simulation/${resultId.resultId}/energy_flow_avg/?timeUnit=${timeUnit}${
      timeUnit === 'year' ? '' : `&value=${selectedMonthValue}`
    }`,
    {
      revalidateOnFocus: false,
    }
  );

  const isDataFlowAvgZero = dataFlowTotal?.graphData.length === 0;

  const data = isTotal
    ? dataFlowTotal?.graphData[0]
    : dataFlowAvg?.graphData[0];

  const checkVisibility = (
    data: Record<string, number>
  ): Record<string, boolean> => {
    const visibility: Record<string, boolean> = {};

    for (const [key, value] of Object.entries(data)) {
      visibility[`is${key.charAt(0).toUpperCase() + key.slice(1)}Visible`] =
        value !== 0;
    }

    return visibility;
  };
  const visibility = checkVisibility(data ?? {});

  const nodeTypes = {
    customNode: ({ data }: NodeProps) => {
      return (
        <Flex
          justify="center"
          align="center"
          style={{
            width: 195,
            height: 144,
          }}
        >
          <Flex vertical gap={8} align="center">
            <Typography.Text
              className="fs-17-semi-bold text-gray-color"
              style={{
                backgroundColor: data.bgColor,
                border: `1px solid ${data.borderColor}`,
                padding: '1px 8px',
                borderRadius: '4px',
              }}
            >
              {data.title}
            </Typography.Text>
            {data.icon ? data.icon : <div style={{ height: '64px' }}></div>}
            <Flex align="center">
              <Typography.Text className="fs-14-regular text-dark-color">
                {data.text}: {data.value}
              </Typography.Text>
            </Flex>
          </Flex>
          {data.handles.map((handle: handleType) => (
            <Handle
              key={handle.id}
              type={handle.type}
              position={handle.position}
              id={handle.id}
              isConnectable={false}
              style={{
                bottom: handle.bottom,
                right: handle.right,
                left: handle.left,
                top: handle.top,
                transform: 'translateX(-50%)',
              }}
            />
          ))}
        </Flex>
      );
    },
  };

  const nodes = [
    visibility.isPurchasedFromDayAheadVisible
      ? {
          id: '1',
          type: 'customNode',
          data: {
            title: 'Grid',
            icon: <IconGrid width={64} height={64} />,
            value:
              formatNumberWithCommas(data?.purchasedFromDayAhead || 0) + ' MWh',
            text: 'Grid',
            bgColor: theme.colors.bgBrown,
            borderColor: theme.colors.brownPrimary,
            handles: [
              {
                id: 'e1-2-bottom',
                type: 'source',
                position: Position.Bottom,
              },
              {
                id: 'e1-3-left',
                type: 'target',
                position: Position.Left,
                top: 20,
                left: 0,
              },
              {
                id: 'e1-5-bottom',
                type: 'source',
                position: Position.Left,
                top: 120,
                left: 0,
              },
              {
                id: 'e5-1-bottom',
                type: 'target',
                position: Position.Bottom,
                left: 70,
              },
            ],
          },
          position: { x: 543, y: 0 },
        }
      : null,
    visibility.isInternalConsumptionVisible
      ? {
          id: '2',
          type: 'customNode',
          data: {
            title: 'Consumption',
            icon: <IconConsumption width={64} height={64} />,
            value:
              formatNumberWithCommas(data?.internalConsumption || 0) + ' MWh',
            text: 'Consumption',
            bgColor: theme.colors.bgGrayDark,
            borderColor: theme.colors.grayAccent,
            handles: [
              {
                id: 'e2-5-right',
                type: 'target',
                position: Position.Left,
                top: 20,
                left: 0,
              },
              {
                id: 'e2-1-top',
                type: 'target',
                position: Position.Top,
              },
              {
                id: 'e2-3-right',
                type: 'target',
                position: Position.Left,
                left: 0,
                top: 50,
              },
            ],
          },
          position: { x: 543, y: 210 },
        }
      : null,
    visibility.isGenerationVisible
      ? {
          id: '3',
          type: 'customNode',
          data: {
            title: 'Generation',
            icon: <IconGeneration width={64} height={64} />,
            value: formatNumberWithCommas(data?.generation || 0) + ' MWh',
            text: 'Generation',
            bgColor: theme.colors.bgYellow,
            borderColor: theme.colors.yellowSecondary,
            handles: [
              {
                id: 'e3-1-left',
                type: 'source',
                position: Position.Right,
                top: 20,
              },
              {
                id: 'e3-2-left',
                type: 'source',
                position: Position.Bottom,
                left: 110,
              },
              {
                id: 'e3-5-bottom',
                type: 'source',
                position: Position.Right,
                top: 120,
              },
              {
                id: 'e3-4-right',
                type: 'source',
                position: Position.Bottom,
                left: 80,
              },
            ],
          },
          position: { x: 0, y: 0 },
        }
      : null,
    visibility.isCurtailedVisible
      ? {
          id: '4',
          type: 'customNode',
          data: {
            title: 'Curtailment',
            icon: <IconCurtailment width={64} height={64} />,
            value: formatNumberWithCommas(data?.curtailed || 0) + ' MWh',
            text: 'Curtailment',
            bgColor: theme.colors.bgPink,
            borderColor: theme.colors.pinkPrimary,
            handles: [
              {
                id: 'e4-3-top',
                type: 'target',
                position: Position.Top,
                left: 80,
                top: 0,
              },
            ],
          },
          position: { x: 0, y: 265 },
        }
      : null,
    visibility.isDischargedVisible
      ? {
          id: '5',
          type: 'customNode',
          data: {
            title: 'Battery',
            icon: <IconBattery width={64} height={64} />,
            value: formatNumberWithCommas(data?.discharged || 0) + ' MWh',
            text: 'Discharge',
            bgColor: theme.colors.bgBluePrimary,
            borderColor: theme.colors.bluePrimary,
            handles: [
              {
                id: 'e5-1-left',
                type: 'source',
                position: Position.Right,
                top: 90,
                right: 35,
              },
              {
                id: 'e5-1-bottom',
                type: 'target',
                position: Position.Right,
                top: 15,
                right: 35,
              },
              {
                id: 'e5-2-left',
                type: 'source',
                position: Position.Right,
                top: 125,
              },
              {
                id: 'e5-3-top',
                type: 'target',
                position: Position.Left,
                top: 15,
                left: 40,
              },
            ],
          },
          position: { x: 280, y: 105 },
        }
      : null,
  ].filter((node) => node !== null) as Node<any, string | undefined>[];

  const edges: Edge<any>[] | undefined = [
    {
      id: 'e1-2',
      source: '1',
      target: '2',
      sourceHandle: 'e1-2-bottom',
      targetHandle: 'e2-1-top',
      animated: false,
      label: formatNumberWithCommas(data?.internalConsumptionFromDayAhead || 0),
      labelStyle: {
        fontSize: '14px',
        fill: theme.colors.grayPrimary,
        dominantBaseline: 'text-after-edge',
        transform: 'translateX(30px)',
      },
      type: 'smoothstep',
      style: { stroke: theme.colors.redPrimary, strokeWidth: 2 },
      markerEnd: {
        type: MarkerType.Arrow,
        color: theme.colors.redPrimary,
        width: 15,
        height: 15,
      },
    }, // Grid to Site Consumption
    {
      id: 'e1-5',
      source: '1',
      target: '5',
      sourceHandle: 'e1-5-bottom',
      targetHandle: 'e5-1-bottom',
      animated: false,
      label: formatNumberWithCommas(data?.chargedFromDayAhead || 0),
      labelStyle: {
        fontSize: '14px',
        fill: theme.colors.grayPrimary,
        dominantBaseline: 'text-after-edge',
        transform: 'translate(5px,-10px)',
      },
      type: 'smoothstep',
      style: { stroke: theme.colors.redPrimary, strokeWidth: 2 },
      markerEnd: {
        type: MarkerType.Arrow,
        color: theme.colors.redPrimary,
        width: 15,
        height: 15,
      },
    }, // Grid to Battery
    {
      id: 'e3-1',
      source: '3',
      target: '1',
      sourceHandle: 'e3-1-left',
      targetHandle: 'e1-3-left',
      animated: false,
      label: formatNumberWithCommas(data?.soldFromGenerationDirectly || 0),
      labelStyle: {
        fontSize: '14px',
        fill: theme.colors.grayPrimary,
        dominantBaseline: 'text-after-edge',
        transform: 'translateY(-10px)',
      },
      type: 'smoothstep',
      style: { stroke: theme.colors.greenSecondary, strokeWidth: 2 },
      markerEnd: {
        type: MarkerType.Arrow,
        color: theme.colors.greenSecondary,
        width: 15,
        height: 15,
      },
    }, // Generation to Grid
    {
      id: 'e5-1',
      source: '5',
      target: '1',
      sourceHandle: 'e5-1-left',
      targetHandle: 'e5-1-bottom',
      animated: false,
      label: formatNumberWithCommas(data?.dischargeToGrid || 0),
      labelStyle: {
        fontSize: '14px',
        fill: theme.colors.grayPrimary,
        dominantBaseline: 'text-after-edge',
        transform: 'translate(-5px,-10px)',
      },
      type: 'step',
      style: { stroke: theme.colors.greenSecondary, strokeWidth: 2 },
      markerEnd: {
        type: MarkerType.Arrow,
        color: theme.colors.greenSecondary,
        width: 15,
        height: 15,
      },
    }, // Battery to Grid
    {
      id: 'e5-2',
      source: '5',
      target: '2',
      sourceHandle: 'e5-2-left',
      targetHandle: 'e2-5-right',
      animated: false,
      label: formatNumberWithCommas(data?.dischargeToConsumption || 0),
      labelStyle: {
        fontSize: '14px',
        fill: theme.colors.grayPrimary,
        dominantBaseline: 'text-after-edge',
        transform: 'translateY(-10px)',
      },
      type: 'smoothstep',
      style: { stroke: theme.colors.orangeSecondary, strokeWidth: 2 },
      markerEnd: {
        type: MarkerType.Arrow,
        color: theme.colors.orangeSecondary,
        width: 15,
        height: 15,
      },
    }, // Battery to Site Consumption
    {
      id: 'e3-2',
      source: '3',
      target: '2',
      sourceHandle: 'e3-2-left',
      targetHandle: 'e2-3-right',
      animated: false,
      label: formatNumberWithCommas(
        data?.internalConsumptionFromGenerationDirectly || 0
      ),
      labelStyle: {
        fontSize: '14px',
        fill: theme.colors.grayPrimary,
        dominantBaseline: 'text-after-edge',
        transform: 'translate(45px,20px)',
      },
      type: 'step',
      style: { stroke: theme.colors.orangeSecondary, strokeWidth: 2 },
      markerEnd: {
        type: MarkerType.Arrow,
        color: theme.colors.orangeSecondary,
        width: 15,
        height: 15,
      },
    }, // Generation to Site Consumption
    {
      id: 'e3-5',
      source: '3',
      target: '5',
      sourceHandle: 'e3-5-bottom',
      targetHandle: 'e5-3-top',
      animated: false,
      label: formatNumberWithCommas(data?.chargedFromGeneration || 0),
      labelStyle: {
        fontSize: '14px',
        fill: theme.colors.grayPrimary,
        dominantBaseline: 'text-after-edge',
        transform: 'translateY(-10px)',
      },
      type: 'smoothstep',
      style: { stroke: theme.colors.orangeSecondary, strokeWidth: 2 },
      markerEnd: {
        type: MarkerType.Arrow,
        color: theme.colors.orangeSecondary,
        width: 15,
        height: 15,
      },
    }, // Generation to Battery
    {
      id: 'e3-4',
      source: '3',
      target: '4',
      sourceHandle: 'e3-4-right',
      targetHandle: 'e4-3-top',
      animated: false,
      label: formatNumberWithCommas(data?.curtailed || 0),
      labelStyle: {
        fontSize: '14px',
        fill: theme.colors.grayPrimary,
        dominantBaseline: 'text-after-edge',
        transform: 'translateX(-35px)',
      },
      type: 'smoothstep',
      style: { stroke: theme.colors.orangeSecondary, strokeWidth: 2 },
      markerEnd: {
        type: MarkerType.Arrow,
        color: theme.colors.orangeSecondary,
        width: 15,
        height: 15,
      },
    }, // Generation to Curtailment
  ];

  return (
    <Card
      title={
        <Flex gap={8} align="center">
          <Typography.Title level={4} className="fs-17-bold">
            Annual Energy Flow
          </Typography.Title>
          <Tooltip
            title="Annual Energy Flow"
            description="This diagram shows the flow of energy between the grid, plant,
            consumption, battery and curtailment. It displays the average and
            total energy amounts on a monthly and annual basis."
          >
            <IconInfo />
          </Tooltip>
        </Flex>
      }
      extra={
        <Flex gap={12} align="center">
          <motion.div
            whileTap={{ scale: 1.1 }}
            transition={{ type: 'spring', stiffness: 300, damping: 24 }}
          >
            <Select
              defaultValue="Year"
              style={{ width: '110px' }}
              onChange={handleYearChange}
            >
              <Select.Option value="Year">Year</Select.Option>

              <Select.Option value="Month">Month</Select.Option>
            </Select>
          </motion.div>
          {timeUnit !== 'year' && (
            <motion.div
              whileTap={{ scale: 1.1 }}
              transition={{ type: 'spring', stiffness: 300, damping: 24 }}
            >
              <Select
                style={{ width: '120px' }}
                disabled={timeUnit === 'year'}
                onChange={handleMonthChange}
                value={selectedMonthValue}
              >
                {MONTHS_WITH_VALUES.map((month) => (
                  <Select.Option key={month.value} value={month.value}>
                    {month.label}
                  </Select.Option>
                ))}
              </Select>
            </motion.div>
          )}
          <RToggleButtons
            labels={['Total', 'Average']}
            onLabelChange={handleSwitchChange}
          />
        </Flex>
      }
      styles={{
        header: { padding: '14px 24px' },
        body: { display: 'flex', justifyContent: 'center' },
      }}
      style={{ marginTop: '24px' }}
    >
      {isLoading ? (
        <Flex justify="center" align="center" style={{ height: 448 }}>
          <Spin style={{ margin: '20px' }} />
        </Flex>
      ) : error || isDataFlowAvgZero || isDataFlowTotalZero ? (
        <Flex
          vertical
          gap={12}
          justify="center"
          align="center"
          style={{ height: 448 }}
        >
          <IconError width={50} height={50} fill={theme.colors.tagFailure} />
          {error && (
            <Typography.Text className="fs-14-regular text-gray-color text-center">
              An error occured while fetching data. Please check your inputs or
              contact support if the issue persists.
            </Typography.Text>
          )}
          {isDataFlowAvgZero ||
            (isDataFlowTotalZero && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                No data is currently available. Please review your inputs.
              </Typography.Text>
            ))}
        </Flex>
      ) : (
        <Flex style={{ width: 777, height: 448, padding: '20px' }}>
          <ReactFlowProvider>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              zoomOnScroll={false}
              zoomOnPinch={false}
              panOnDrag={false}
              nodesDraggable={false}
              nodesConnectable={false}
              elementsSelectable={false}
              zoomOnDoubleClick={false}
              preventScrolling={false}
            />
          </ReactFlowProvider>
        </Flex>
      )}
    </Card>
  );
};

export default AnnualEnergyFlow;
