import { Row } from 'antd';
import styled from 'styled-components';

export default styled(Row)`
  width: 100%;
  .recharts-default-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 16px;
    li {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      margin: 0 !important;
      gap: 8px !important;
      svg {
        margin: 0 !important;
      }
    }
  }
  .redText {
    color: red;
  }
`;
