import { create } from 'zustand';

type Asset = {
  id: string;
  name: string;
};

type Assets = {
  plants: Asset[];
  scenario: Asset;
  storageSystem: Asset;
};

type SelectedAssetsState = {
  selectedAssets: Assets;
  setSelectedAssets: (assets: Assets) => void;
};

export const useSelectedAssetsStore = create<SelectedAssetsState>((set) => ({
  selectedAssets: {
    plants: [],
    scenario: { id: '', name: '' },
    storageSystem: { id: '', name: '' },
  },
  setSelectedAssets: (assets) => set({ selectedAssets: assets }),
}));
