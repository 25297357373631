import { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router';

import { authApi } from '@/api';
import CountryPhoneInput from '@/components/RPhoneInput';
import Redirector from '@/components/RRedirector';
import LocalStorageService from '@/services/LocalStorageService';

import StyledContactUs from './styles';

const { Option } = Select;
const ORGANIZATION_TYPE_COMPANY = 'company';
const ORGANIZATION_TYPE_ACADEMY = 'academy';

const { Success } = Redirector;

const ContactUs = () => {
  const [organizationType, setOrganizationType] = useState<
    typeof ORGANIZATION_TYPE_COMPANY | typeof ORGANIZATION_TYPE_ACADEMY | ''
  >('');

  const [submittable, setSubmittable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isContactSuccess, setIsContactSuccess] = useState<boolean>(false);
  const [form] = useForm();
  const navigate = useNavigate();
  const values = Form.useWatch([], form);

  const handleSubmit = async () => {
    try {
      const formData = form.getFieldsValue();
      const formattedData = {
        name: formData.name,
        surname: formData.surname,
        title: formData.title || `${formData.name} ${formData.surname}`,
        phoneNumber: `+${formData.phone.code}${formData.phone.phone}`,
        organizationType: formData.organization,
        organizationName: formData.organizationName,
        organizationEmail: formData.organizationEmail,
        message: formData.message,
      };
      LocalStorageService.clearLocalStorage();
      await authApi.postContact(formattedData);
      setIsContactSuccess(true);
    } catch (error) {
      setLoading(false);
      console.error('Error submitting form: ', error);
    }
  };

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values, organizationType]);

  return (
    <>
      {isContactSuccess ? (
        <Redirector>
          <Success description="Thank you for contacting us. We will get back to you as soon as possible" />
        </Redirector>
      ) : (
        <StyledContactUs>
          <Flex vertical className="container">
            <Typography.Title level={2} className="title">
              Contact Us
            </Typography.Title>
            <Typography.Text className="description">
              You can connect with us to learn more about RATIO SIM. If you
              would like to know what we can do for you, please fill in a form.
            </Typography.Text>
            <hr className="line" />
            {loading ? (
              <Flex
                vertical
                className="w-100"
                align="center"
                justify="center"
                style={{ height: '400px' }}
              >
                <Spin />
              </Flex>
            ) : (
              <>
                <Form
                  form={form}
                  layout="vertical"
                  className="form-contact-us"
                  onFinish={handleSubmit}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your name!',
                          },
                          {
                            pattern: /^[A-Za-z ]+$/,
                            message: 'Please input only letters!',
                          },
                        ]}
                      >
                        <Input className="input" placeholder="Your Name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="surname"
                        label="Surname"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your surname!',
                          },
                          {
                            pattern: /^[A-Za-z ]+$/,
                            message: 'Please input only letters!',
                          },
                        ]}
                      >
                        <Input className="input" placeholder="Your Surname" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="phone"
                        label="Phone"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your phone!',
                          },
                        ]}
                      >
                        <CountryPhoneInput />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="organization"
                        label="Organization"
                        rules={[
                          {
                            required: true,
                            message: 'Please select an organization!',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please select an organization"
                          onSelect={(value) => {
                            setOrganizationType(value);
                          }}
                        >
                          <Option value="company" className="select">
                            Company
                          </Option>
                          <Option value="academy" className="select">
                            Academy
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Col className="input-section">
                    <Col
                      className={`organization-section ${
                        organizationType != ''
                          ? ''
                          : 'organization-section-hidden'
                      }`}
                    >
                      {organizationType != '' && (
                        <>
                          <Row gutter={16}>
                            <Col span={12}>
                              <Form.Item
                                name="organizationName"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input organization name!',
                                  },
                                ]}
                                label={
                                  organizationType == 'company'
                                    ? 'Company Name'
                                    : 'University Name'
                                }
                              >
                                <Input
                                  className="input"
                                  placeholder={
                                    organizationType == 'company'
                                      ? 'Your Company Name'
                                      : 'Your University Name'
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              {' '}
                              <Form.Item
                                name="organizationEmail"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input organization email!',
                                  },
                                  {
                                    type: 'email',
                                    message: 'Please enter a valid email!',
                                  },
                                ]}
                                label={
                                  organizationType == 'company'
                                    ? 'Company E-mail'
                                    : 'University E-mail'
                                }
                              >
                                <Input
                                  className="input"
                                  placeholder={
                                    organizationType == 'company'
                                      ? 'Your Company E-mail'
                                      : 'Your University E-mail'
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Form.Item
                            name="message"
                            label="Your Message"
                            rules={[
                              {
                                required: true,
                                message: 'Please input your message!',
                              },
                            ]}
                          >
                            <Input.TextArea
                              rows={5}
                              placeholder="Please, briefly describe your need for RATIO SIM"
                              className="input"
                            />
                          </Form.Item>
                        </>
                      )}
                    </Col>
                  </Col>
                </Form>
                <Flex className="btn-group">
                  <Button
                    className="btn-cancel"
                    type="default"
                    onClick={() => {
                      navigate(-1);
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-send"
                    type="primary"
                    htmlType="submit"
                    disabled={!submittable}
                    onClick={handleSubmit}
                  >
                    Send
                  </Button>
                </Flex>
              </>
            )}
          </Flex>
        </StyledContactUs>
      )}
    </>
  );
};
export default ContactUs;
