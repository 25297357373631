import { FC } from 'react';

import { Flex, Typography } from 'antd';
import { useTheme } from 'styled-components';

import IconInfo from '@/assets/icons/icon-info.svg?react';
import Table from '@/components/RTable';
import Tooltip from '@/components/RTooltip';
import { SimulationResult } from '@/types/simulations';
import { formatNumberWithCommas } from '@/utils';

import { StyledTable } from './styles';

interface AncillaryTableDataTypes {
  key: string;
  title: string | JSX.Element;
  unit: string;
  value: number | string;
}

interface AncillaryTableProps {
  simulationData: SimulationResult;
}

const AncillaryTable: FC<AncillaryTableProps> = ({ simulationData }) => {
  const theme = useTheme();

  const ancillaryDataTable: AncillaryTableDataTypes[] = [
    {
      key: '1',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '10px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Capacity of PFC
          </Typography.Text>
          <Tooltip
            title="Capacity of PFC"
            description="Total capacity of a battery offered for providing Primer Frequency Control services."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      unit: 'MW',
      value: formatNumberWithCommas(
        Number(simulationData.result.offeredCapacityOfPfc.toFixed(0))
      ),
    },
    {
      key: '2',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '10px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Revenue of PFC
          </Typography.Text>
          <Tooltip
            title="Revenue of PFC"
            description="Income generated from providing Primer Frequency Control services."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      unit: '$',
      value: formatNumberWithCommas(
        Number(simulationData.result.revenueFromOfferedCapacityOfPfc.toFixed(0))
      ),
    },
    {
      key: '3',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '10px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Capacity of SFC
          </Typography.Text>
          <Tooltip
            title="Capacity of SFC"
            description="Total capacity of a battery offered for providing Secondary Frequency Control services."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      unit: 'MW',
      value: formatNumberWithCommas(
        Number(simulationData.result.offeredCapacityOfSfc.toFixed(0))
      ),
    },
    {
      key: '4',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '10px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Revenue of SFC
          </Typography.Text>
          <Tooltip
            title="Revenue of SFC"
            description="Income generated from providing Secondary Frequency Control services."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      unit: '$',
      value: formatNumberWithCommas(
        Number(simulationData.result.revenueFromOfferedCapacityOfSfc.toFixed(0))
      ),
    },
  ];

  const ancillaryColumns = [
    {
      dataIndex: 'title',
      width: '28%',
    },
    {
      title: (
        <Flex justify="center" align="center" gap={8}>
          <Typography.Text className="fs-14-bold">Value</Typography.Text>
        </Flex>
      ),
      dataIndex: 'unit',
      key: 'unit',
      width: '36%',
    },
    {
      title: (
        <Flex justify="center" align="center" gap={8}>
          <Typography.Text className="fs-14-bold">
            Earned Amount
          </Typography.Text>
          <Tooltip
            title="Earned Amount"
            description="Shows how much extra profit storage batteries contribute to gross profit."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      dataIndex: 'value',
      key: 'value',
      width: '36%',
    },
  ];
  return (
    <StyledTable>
      <Table
        dataSource={ancillaryDataTable}
        columns={ancillaryColumns}
        pagination={false}
      />
    </StyledTable>
  );
};

export default AncillaryTable;
