import { Row, Col } from 'antd';
import styled from 'styled-components';

export default styled(Col)`
  .action-button-alert {
    :hover {
      border: 1px solid ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const TableActionsWrapper = styled(Row)`
  /* border: 1px solid red; */
  /* background-color: #fafafa; */
  /* border: 1px solid #f0f0f0; */
  border-bottom: none;
  padding-bottom: 16px;
  padding-top: 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  .search-group {
    height: 32px;
  }
`;
