import { FC, ReactNode, useMemo } from 'react';

import { Flex, Typography } from 'antd';
import useSWR from 'swr';

import useAuth from '@/hooks/useAuth';

import StyledPlanStatusTag from './styles';

type RUsageLimitIndicatorProps = {
  text?: string;
  currentValue?: ReactNode;
  maxValue?: ReactNode;
  $colorBar?: string;
  $colorText?: string;
};

// Utility function to safely check and display values
const formatValue = (
  value: unknown,
  isMaxValue: boolean = false
): ReactNode => {
  if (typeof value === 'number' && isFinite(value)) {
    return isMaxValue && value > 32000 ? '∞' : value;
  }
  return '∞';
};

// Main component
export const RUsageLimitIndicator: FC<RUsageLimitIndicatorProps> & {
  Plants: FC<Partial<RUsageLimitIndicatorProps>>;
  Scenarios: FC<Partial<RUsageLimitIndicatorProps>>;
  Storages: FC<Partial<RUsageLimitIndicatorProps>>;
} = ({
  text,
  currentValue,
  maxValue,
  $colorBar = '#F25E69',
  $colorText = '#F25E69',
}) => (
  <StyledPlanStatusTag $colorBar={$colorBar} $colorText={$colorText}>
    <Flex justify="space-between" align="center" className="team-plan-cont">
      <Typography.Text className="fs-12-regular">{text}</Typography.Text>
      <Typography.Text className="fs-17-extra-bold text">
        {formatValue(currentValue)}
        <span className="fs-12-regular"> / {formatValue(maxValue, true)}</span>
      </Typography.Text>
    </Flex>
  </StyledPlanStatusTag>
);

// Subcomponents
const PlantIndicator: FC<Partial<RUsageLimitIndicatorProps>> = ({
  text = 'Remaining Plants',
  ...props
}) => {
  const { data: plansData } = useSWR('/plant/', {
    revalidateOnFocus: false,
  });
  const { user } = useAuth();
  const assetLimit = user?.organization.subscription.plan.simAssetLimit || '∞';

  const memoizedIndicator = useMemo(() => {
    return (
      <RUsageLimitIndicator
        text={text}
        currentValue={formatValue(plansData?.count)}
        maxValue={formatValue(assetLimit, true)}
        {...props}
      />
    );
  }, [text, plansData, assetLimit, props]);

  return memoizedIndicator;
};

const ScenarioIndicator: FC<Partial<RUsageLimitIndicatorProps>> = ({
  text = 'Remaining Scenarios',
  ...props
}) => {
  const { data: scenarioData } = useSWR('/scenario/', {
    revalidateOnFocus: false,
  });
  const { user } = useAuth();
  const assetLimit = user?.organization.subscription.plan.simAssetLimit || '∞';

  const memoizedIndicator = useMemo(() => {
    return (
      <RUsageLimitIndicator
        text={text}
        currentValue={formatValue(scenarioData?.count)}
        maxValue={formatValue(assetLimit, true)}
        {...props}
      />
    );
  }, [text, scenarioData, assetLimit, props]);

  return memoizedIndicator;
};

const StorageIndicator: FC<Partial<RUsageLimitIndicatorProps>> = ({
  text = 'Remaining Storage Systems',
  ...props
}) => {
  const { data: storageSystemsData } = useSWR('/storage/', {
    revalidateOnFocus: false,
  });
  const { user } = useAuth();
  const assetLimit = user?.organization.subscription.plan.simAssetLimit || '∞';

  const memoizedIndicator = useMemo(() => {
    return (
      <RUsageLimitIndicator
        text={text}
        currentValue={formatValue(storageSystemsData?.count)}
        maxValue={formatValue(assetLimit, true)}
        {...props}
      />
    );
  }, [text, storageSystemsData, assetLimit, props]);

  return memoizedIndicator;
};

RUsageLimitIndicator.Plants = PlantIndicator;
RUsageLimitIndicator.Scenarios = ScenarioIndicator;
RUsageLimitIndicator.Storages = StorageIndicator;

export default RUsageLimitIndicator;
