import axios from 'axios';

import HttpService from '../services/HttpService';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const api = axios.create({
  baseURL: API_BASE_URL,
});

export const apiV1 = new HttpService({ baseURL: API_BASE_URL });
