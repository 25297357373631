/* eslint-disable @typescript-eslint/no-explicit-any */

import { Select } from 'antd';
import { OptionProps, SelectProps } from 'antd/es/select';
import ReactCountryFlag from 'react-country-flag';

import { filterOption } from './shared';
import defaultAreas from './sources';

export interface AreaSelectProps extends SelectProps<any> {
  optionProps?: OptionProps;
}

export const AreaSelect = ({
  optionProps,
  ...selectProps
}: AreaSelectProps) => {
  //const { areas } = useContext(configContext);
  return (
    <Select
      {...selectProps}
      showSearch
      variant="borderless"
      popupMatchSelectWidth={false}
      optionLabelProp="label"
      filterOption={filterOption as any}
      // defaultValue={defaultAreas[186].phoneCode}
    >
      {defaultAreas.map((item) => {
        const code = `${item.short} ${item.phoneCode}`;
        const fixedProps = {
          code,
          value: item.phoneCode,
          label: (
            <>
              <ReactCountryFlag
                countryCode={item.short}
                svg
                style={{
                  width: '1.5em',
                  height: '1.5em',
                }}
              />
              {'  '}+{item.phoneCode}
            </>
          ),
        };

        return (
          <Select.Option
            key={`${item.short}-${item.phoneCode}`}
            {...optionProps}
            {...fixedProps}
          >
            <ReactCountryFlag
              countryCode={item.short}
              svg
              style={{
                width: '1.5em',
                height: '1.5em',
              }}
            />{' '}
            {code}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default AreaSelect;
