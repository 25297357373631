import styled from 'styled-components';

export default styled.div`
  .ant-table-row {
    .ant-table-row-expand-icon-cell {
      display: none;
    }
  }
  .ant-table-wrapper {
    margin: 0 !important;
  }

  .ant-table-tbody > tr > td {
    padding: 3.5px 12px !important;
    color: ${({ theme }) => theme.colors.grayPrimary} !important;
    border-bottom: none !important;
  }
  .ant-table-tbody > tr > td:nth-child(n + 2) {
    text-align: right;
  }
  .ant-table-tbody > tr > td:first-child {
    font-weight: 400 !important;
  }
  .ant-table-cell-with-append {
    display: flex;
    align-items: center;
    svg:hover {
      cursor: pointer;
    }
  }

  //Energy Revenues
  .ant-table-row[data-row-key='1-1'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 1px;
    height: 24px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  //Revenue from Discharge
  .ant-table-row[data-row-key='1-1-1'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 17px;
    height: 24px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px
    );
  }
  //Revenue from Discharge Plant
  .ant-table-row[data-row-key='1-1-1-1'] > td:first-child > span {
    margin-right: 5px !important;
  }
  .ant-table-row[data-row-key='1-1-1-1'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 33px;
    height: 24px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px,
      transparent 17px,
      transparent 31px,
      ${({ theme }) => theme.colors.grayDark} 31px,
      ${({ theme }) => theme.colors.grayDark} 32px,
      transparent 32px,
      transparent 33px /* Üçüncü çizgi */
    );
  }
  .ant-table-row[data-row-key='1-1-1-1'] > td:first-child::after {
    background-color: red;
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    left: 37px;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  //Revenue from Discharge Purchase
  .ant-table-row[data-row-key='1-1-1-2'] > td:first-child > span {
    margin-right: 5px !important;
  }
  .ant-table-row[data-row-key='1-1-1-2'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 34px;
    height: 12.5px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px,
      transparent 17px,
      transparent 31px,
      ${({ theme }) => theme.colors.grayDark} 31px,
      ${({ theme }) => theme.colors.grayDark} 32px,
      transparent 32px,
      transparent 33px /* Üçüncü çizgi */
    );
  }
  .ant-table-row[data-row-key='1-1-1-2'] > td:first-child::after {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    left: 37px;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  // Revenue From Plant
  .ant-table-row[data-row-key='1-1-2'] > td:first-child > span {
    margin-right: 1px !important;
  }
  .ant-table-row[data-row-key='1-1-2'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 17px;
    height: 24px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px
    );
  }

  // Capacity Revenues
  .ant-table-row[data-row-key='1-2'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 1px;
    height: 24px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  // PFC
  .ant-table-row[data-row-key='1-2-1'] > td:first-child > span {
    margin-right: 5px !important;
  }
  .ant-table-row[data-row-key='1-2-1'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 17px;
    height: 24px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px
    );
  }
  .ant-table-row[data-row-key='1-2-1'] > td:first-child::after {
    display: block;
    position: absolute;
    top: 14px;
    left: 22px;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  // SFC
  .ant-table-row[data-row-key='1-2-2'] > td:first-child > span {
    margin-right: 5px !important;
  }
  .ant-table-row[data-row-key='1-2-2'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 17px;
    height: 12.5px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px
    );
  }
  .ant-table-row[data-row-key='1-2-2'] > td:first-child::after {
    display: block;
    position: absolute;
    top: 14px;
    left: 22px;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }

  //Operating Expenses
  .ant-table-row[data-row-key='4-1'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 1px;
    height: 24px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  .ant-table-row[data-row-key='4-1-1'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 17px;
    height: 24px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px
    );
  }
  .ant-table-row[data-row-key='4-1-1-1'] > td:first-child > span {
    margin-right: 5px !important;
  }
  .ant-table-row[data-row-key='4-1-1-1'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 33px;
    height: 24px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px,
      transparent 17px,
      transparent 31px,
      ${({ theme }) => theme.colors.grayDark} 31px,
      ${({ theme }) => theme.colors.grayDark} 32px,
      transparent 32px,
      transparent 33px /* Üçüncü çizgi */
    );
  }
  .ant-table-row[data-row-key='4-1-1-1'] > td:first-child::after {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    left: 37px;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  .ant-table-row[data-row-key='4-1-1-2'] > td:first-child > span {
    margin-right: 5px !important;
  }
  .ant-table-row[data-row-key='4-1-1-2'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 34px;
    height: 12.5px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px,
      transparent 17px,
      transparent 31px,
      ${({ theme }) => theme.colors.grayDark} 31px,
      ${({ theme }) => theme.colors.grayDark} 32px,
      transparent 32px,
      transparent 33px /* Üçüncü çizgi */
    );
  }
  .ant-table-row[data-row-key='4-1-1-2'] > td:first-child::after {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    left: 37px;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  .ant-table-row[data-row-key='4-1-2'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 17px;
    height: 24px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px
    );
  }
  .ant-table-row[data-row-key='4-1-2-1'] > td:first-child > span {
    margin-right: 5px !important;
  }
  .ant-table-row[data-row-key='4-1-2-1'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 33px;
    height: 24px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px,
      transparent 17px,
      transparent 31px,
      ${({ theme }) => theme.colors.grayDark} 31px,
      ${({ theme }) => theme.colors.grayDark} 32px,
      transparent 32px,
      transparent 33px /* Üçüncü çizgi */
    );
  }
  .ant-table-row[data-row-key='4-1-2-1'] > td:first-child::after {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    left: 37px;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  .ant-table-row[data-row-key='4-1-2-2'] > td:first-child > span {
    margin-right: 5px !important;
  }
  .ant-table-row[data-row-key='4-1-2-2'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 34px;
    height: 12.5px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px,
      transparent 17px,
      transparent 31px,
      ${({ theme }) => theme.colors.grayDark} 31px,
      ${({ theme }) => theme.colors.grayDark} 32px,
      transparent 32px,
      transparent 33px /* Üçüncü çizgi */
    );
  }
  .ant-table-row[data-row-key='4-1-2-2'] > td:first-child::after {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    left: 37px;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  .ant-table-row[data-row-key='4-2'] > td:first-child > span {
    margin-right: 13px !important;
  }
  .ant-table-row[data-row-key='4-2'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 17px;
    height: 24px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px
    );
  }
  .ant-table-row[data-row-key='4-2'] > td:first-child::after {
    display: block;
    position: absolute;
    top: 14px;
    left: 22px;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  .ant-table-row[data-row-key='4-3'] > td:first-child > span {
    margin-right: 13px !important;
  }
  .ant-table-row[data-row-key='4-3'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 17px;
    height: 12.5px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px
    );
  }
  .ant-table-row[data-row-key='4-3'] > td:first-child::after {
    display: block;
    position: absolute;
    top: 14px;
    left: 22px;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  //Depreciation
  .ant-table-row[data-row-key='6-1'] > td:first-child > span {
    margin-right: 13px !important;
  }
  .ant-table-row[data-row-key='6-1'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 17px;
    height: 24px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px
    );
  }
  .ant-table-row[data-row-key='6-1'] > td:first-child::after {
    display: block;
    position: absolute;
    top: 14px;
    left: 22px;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  .ant-table-row[data-row-key='6-2'] > td:first-child > span {
    margin-right: 13px !important;
  }
  .ant-table-row[data-row-key='6-2'] > td:first-child::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 18px;
    width: 17px;
    height: 12.5px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.grayDark} 0%,
      ${({ theme }) => theme.colors.grayDark} 1px,
      transparent 1px,
      transparent 16px,
      ${({ theme }) => theme.colors.grayDark} 16px,
      ${({ theme }) => theme.colors.grayDark} 17px
    );
  }
  .ant-table-row[data-row-key='6-2'] > td:first-child::after {
    display: block;
    position: absolute;
    top: 14px;
    left: 22px;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }

  .ant-table-row[data-row-key='2'] > td:first-child > span,
  .ant-table-row[data-row-key='3'] > td:first-child > span,
  .ant-table-row[data-row-key='5'] > td:first-child > span,
  .ant-table-row[data-row-key='7'] > td:first-child > span,
  .ant-table-row[data-row-key='8'] > td:first-child > span,
  .ant-table-row[data-row-key='10'] > td:first-child > span,
  .ant-table-row[data-row-key='12'] > td:first-child > span,
  .ant-table-row[data-row-key='9'] > td:first-child > span {
    margin-right: 0px !important;
  }

  .ant-table-row[data-row-key='3'],
  .ant-table-row[data-row-key='5'],
  .ant-table-row[data-row-key='7'] {
    background-color: ${({ theme }) => theme.colors.blueLightest} !important;
  }
  .ant-table-row[data-row-key='3'] > td,
  .ant-table-row[data-row-key='5'] > td,
  .ant-table-row[data-row-key='7'] > td {
    border-top: 0.5px solid ${({ theme }) => theme.colors.grayPrimary} !important;
  }
  .ant-table-row[data-row-key='4'] > td:first-child,
  .ant-table-row[data-row-key='6'] > td:first-child,
  .ant-table-row[data-row-key='10'] > td:first-child,
  .ant-table-row[data-row-key='11'] > td:first-child {
    font-weight: 700 !important;
  }

  .ant-table-row[data-row-key='9'] {
    background-color: ${({ theme }) => theme.colors.blueLightest} !important;
    border-radius: none !important;
  }
  .ant-table-row[data-row-key='9'] > td {
    border-top: 0.5px solid ${({ theme }) => theme.colors.grayPrimary} !important;
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.grayPrimary} !important;
  }
  .ant-table-wrapper
    .ant-table-container
    table
    > tbody
    > tr:last-child
    > *:last-child {
    border-end-end-radius: 0px !important;
  }
  .ant-table-wrapper
    .ant-table-container
    table
    > tbody
    > tr:last-child
    > *:first-child {
    border-end-start-radius: 0px !important;
  }
  .ant-table-row-expand-icon {
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  .ant-table-expanded-row {
    position: relative;
  }

  .ant-table-row[data-row-key='1'] > td:first-child,
  .ant-table-row[data-row-key='1-1'] > td:first-child,
  .ant-table-row[data-row-key='1-1-1'] > td:first-child,
  .ant-table-row[data-row-key='1-1-1-1'] > td:first-child,
  .ant-table-row[data-row-key='1-1-1-2'] > td:first-child,
  .ant-table-row[data-row-key='1-1-2'] > td:first-child,
  .ant-table-row[data-row-key='1-2'] > td:first-child,
  .ant-table-row[data-row-key='1-2-1'] > td:first-child,
  .ant-table-row[data-row-key='1-2-2'] > td:first-child,
  .ant-table-row[data-row-key='2'] > td:first-child,
  .ant-table-row[data-row-key='4'] > td:first-child,
  .ant-table-row[data-row-key='4-1'] > td:first-child,
  .ant-table-row[data-row-key='4-1-1'] > td:first-child,
  .ant-table-row[data-row-key='4-1-1-1'] > td:first-child,
  .ant-table-row[data-row-key='4-1-1-2'] > td:first-child,
  .ant-table-row[data-row-key='4-1-2'] > td:first-child,
  .ant-table-row[data-row-key='4-1-2-1'] > td:first-child,
  .ant-table-row[data-row-key='4-1-2-2'] > td:first-child,
  .ant-table-row[data-row-key='4-2'] > td:first-child,
  .ant-table-row[data-row-key='4-3'] > td:first-child,
  .ant-table-row[data-row-key='6'] > td:first-child,
  .ant-table-row[data-row-key='6-1'] > td:first-child,
  .ant-table-row[data-row-key='6-2'] > td:first-child,
  .ant-table-row[data-row-key='8'] > td:first-child,
  .ant-table-row[data-row-key='12'] > td:first-child,
  .ant-table-row[data-row-key='10'] > td:first-child,
  .ant-table-row[data-row-key='11'] > td:first-child {
    background-color: ${({ theme }) => theme.colors.light} !important;
  }

  .ant-table-row[data-row-key='3'] > td:first-child,
  .ant-table-row[data-row-key='5'] > td:first-child,
  .ant-table-row[data-row-key='7'] > td:first-child,
  .ant-table-row[data-row-key='9'] > td:first-child {
    background-color: ${({ theme }) => theme.colors.blueLightest} !important;
  }

  .ant-table-measure-row {
    display: none;
  }
  .ant-table-row[data-row-key='9'] > td {
    height: 31px !important;
  }
`;

/* .ant-table-thead > tr > th:nth-child(2),
  .ant-table-row > td:nth-child(2) {
    border-right: 0.5px solid ${({ theme }) => theme.colors.grayPrimary} !important;
    border-left: 0.5px solid ${({ theme }) => theme.colors.grayPrimary} !important;
  } */
