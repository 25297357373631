import { Col } from 'antd';
import styled from 'styled-components';

export default styled(Col)`
  height: 264px;
  padding: 24px 35px;
  background: ${({ theme }) => theme.colors.bgPurple};
  border-radius: 10px;
  .btn {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    background-color: transparent;
    padding: 9px 24px !important;
  }
`;

export const StyledClosedSaveUpPlan = styled(Col)`
  height: 264px;
  padding: 24.5px 4px;
  background: ${({ theme }) => theme.colors.bgPurple};
  border-radius: 10px;
  .btn {
    height: 21px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    background-color: transparent;
    padding: 3.5px 6.25px !important;
  }
`;
