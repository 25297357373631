import { AxiosProgressEvent } from 'axios';

import type { FilterParams } from '@/types/general';
import type {
  Plant,
  PlantPost,
  PlantDraftGenerationFile,
  PlantDraftGenerationFileResponse,
  PlantsResponse,
} from '@/types/plant';

import { apiV1 } from '../instances';

export default {
  fetchPlants(params: FilterParams): Promise<PlantsResponse> {
    const fullFill = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== '')
    );
    const query = new URLSearchParams({
      ...fullFill,
      limit: fullFill.limit?.toString(),
      offset: fullFill.offset?.toString(),
    });
    return apiV1.get(`/plant/?${query}`);
  },
  createPlant(plant: PlantPost) {
    return apiV1.postFormData('/plant/', plant);
  },
  getPlant(plantId: string): Promise<Plant> {
    return apiV1.get(`/plant/${plantId}`);
  },
  duplicatePlant(plantId: string, plant: PlantPost) {
    return apiV1.postFormData(`/plant/${plantId}/duplicate/`, plant);
  },
  editPlant(plant: PlantPost) {
    return apiV1.post(`/plant/`, plant, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  viewPlant(plantId: string) {
    return apiV1.get(`/plant/${plantId}/`);
  },
  deactivatePlant(plantId: string) {
    return apiV1.delete(`/plant/${plantId}/`);
  },
  activatePlant(plantId: string) {
    return apiV1.post(`/plant/${plantId}/activate/`);
  },
  searchPlantByName(plantName: string) {
    return apiV1.get(`/plant/?name=${plantName}`);
  },
  searchPlantByExactName(plantName: string) {
    return apiV1.get(`/plant/?name_exact=${plantName}`);
  },

  postDraftPlantGenerationFile: (
    generation: PlantDraftGenerationFile,
    onProgress?: (progress: { percent: number }) => void
  ): Promise<PlantDraftGenerationFileResponse> => {
    return apiV1.postFormData('/plant/draft/', generation, {
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress && onProgress({ percent: percentCompleted });
        }
      },
    });
  },

  // getDraftPlantGenerationFileGraph(
  //   id: number,
  //   params: PlantDraftGenerationGraphFilterParams
  // ): Promise<PlantGeneration> {
  //   const fullFill = Object.fromEntries(
  //     Object.entries(params).filter(([, value]) => value !== '')
  //   );
  //   const query = new URLSearchParams(fullFill);

  //   return apiV1.get(`/plant/${id}/generation_graph/?${query}`);
  // },
};
