import { Flex } from 'antd';
import styled from 'styled-components';

export default styled(Flex)`
  height: 66px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.bgRed};
  border-radius: 10px;
  padding: 8px;
`;
