import { Button, Row, Space, Flex, Typography } from 'antd';
import { Link } from 'react-router-dom';

import Check from '@/assets/icons/icon-check.svg?react';
import paths from '@/routes/paths';
import { PlansCardProps } from '@/types/plan';
import { convertNumberToCommaSeperated } from '@/utils';

import StyledPlansCard from './styles';

const PlansCard: React.FC<PlansCardProps> = ({
  title,
  description,
  amount,
  payPlan,
  savedAmount,
  details,
  button = 'Contact sales',
  $isEnterprise = false,
  $isIndividual = false,
  $isTeam = false,
  yearly,
  save = true,
}) => {
  return (
    <StyledPlansCard $isTeam={$isTeam} $isEnterprise={$isEnterprise}>
      <Row className="first-row">
        <Space direction="vertical" size="small">
          <Typography.Title level={3} className="text-dark-color fs-20-bold">
            {title}
          </Typography.Title>
          <Typography.Text
            className="text-faded-color fs-14-regular"
            style={{ display: 'block', height: '42px' }}
          >
            {description}
          </Typography.Text>

          <Space size="small">
            <Typography.Title
              level={4}
              className="text-dark-color fs-36-bold title-amount"
            >
              {$isIndividual || $isTeam
                ? convertNumberToCommaSeperated(Number(amount)) + ' €'
                : $isEnterprise && (
                    <Typography.Title
                      level={4}
                      className="text-dark-color fs-36-bold title-amount"
                    >
                      Contact
                    </Typography.Title>
                  )}
            </Typography.Title>
            <Typography.Text className="text-dark-color fs-14-regular d-block pt-8 title-plan">
              {$isIndividual || $isTeam ? '/' + payPlan : ''}
            </Typography.Text>
          </Space>
          <Row>
            <Typography.Text className="text-dark-color fs-14-regular">
              {$isIndividual || $isTeam
                ? yearly
                  ? 'Billed Yearly'
                  : 'Billed Monthly'
                : $isEnterprise && (
                    <>
                      <Typography.Text style={{ visibility: 'hidden' }}>
                        'Billed Yearly'
                      </Typography.Text>
                    </>
                  )}
            </Typography.Text>
          </Row>
          <Flex vertical>
            {save && yearly ? (
              <>
                <Typography.Text className="fs-14-bold text-blue-color">
                  Save {savedAmount} €
                </Typography.Text>
              </>
            ) : (
              $isEnterprise && (
                <>
                  <Typography.Text style={{ visibility: 'hidden' }}>
                    Save {savedAmount} €
                  </Typography.Text>
                </>
              )
            )}
          </Flex>
        </Space>
      </Row>
      <Row className="second-row">
        <Space direction="vertical" size="small">
          {details?.map((detail, index) => (
            <Space key={index} size="small">
              <Check fill="#3E8AC6" />
              <Typography.Text className="text-dark-color fs-14-regular">
                {detail}
              </Typography.Text>
            </Space>
          ))}
        </Space>
      </Row>
      <Row justify="center" className="bottom-row">
        <Link to={paths.external.enterpriseContact} target="_blank">
          <Button type="default" className="fs-14-medium btn">
            {button}
          </Button>
        </Link>
      </Row>
    </StyledPlansCard>
  );
};

export default PlansCard;
