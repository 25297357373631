import { Collapse } from 'antd';
import styled from 'styled-components';

export default styled(Collapse)`
  width: 100%;

  .ant-collapse {
    border-radius: 0px;
  }

  .ant-collapse-header {
    padding: 21px 16px !important;
    border-radius: 0px !important;
    background-color: ${({ theme }) => theme.colors.primaryFaded};
  }

  .ant-collapse-content-box {
    border-radius: 0px 0px 8px 8px;
    background-color: ${({ theme }) => theme.colors.light} !important;
  }
`;
