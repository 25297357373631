// eslint-disable-next-line import/default
import React, { FC, useEffect, useState, ReactNode, ReactElement } from 'react';

import { Flex } from 'antd';
import { useNavigate } from 'react-router';

import paths from '@/routes/paths';

import StyledSuccess from './styles';
import Success, { SuccessProps } from './Success';

type RedirectorProps = {
  redirectPath?: string;
  redirectionTimeMS?: number;
  children: ReactNode;
};

interface RedirectorComposition {
  Success: FC<SuccessProps>;
}

const Redirector: FC<RedirectorProps> & RedirectorComposition = ({
  redirectPath = paths.login,
  redirectionTimeMS = 3000,
  children,
}) => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(redirectionTimeMS / 1000);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const redirectTimeout = setTimeout(() => {
      window.location.reload();
      navigate(redirectPath, { replace: true });
    }, redirectionTimeMS);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimeout);
    };
  }, [navigate, redirectPath, redirectionTimeMS]);

  return (
    <StyledSuccess>
      <Flex vertical gap={24} className="container" align="center">
        {React.cloneElement(children as ReactElement<{ countdown: number }>, {
          countdown,
        })}
      </Flex>
    </StyledSuccess>
  );
};

Redirector.Success = Success;

export default Redirector;
