import { Col, Row } from 'antd';
import { motion } from 'framer-motion';

import AnalysisTable from './AnalysisTable';

const Analysis = () => {
  return (
    <Row justify="center" align="top">
      <Col span={24}>
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.4,
            type: 'spring',
            damping: 20,
            stiffness: 100,
          }}
        >
          <AnalysisTable />
        </motion.div>
      </Col>
    </Row>
  );
};
export default Analysis;
