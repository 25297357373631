import { Row } from 'antd';
import styled from 'styled-components';

export default styled(Row)`
  .column {
    padding: 10px 24px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.blueLightest};
    border: 0.5px solid ${({ theme }) => theme.colors.light};
    border-left: 0;
    justify-content: space-between;
  }
  .value {
    padding: 10px;
    text-align: center;
    width: 100%;
    border: 0.5px solid ${({ theme }) => theme.colors.blueLightest};
    color: ${({ theme }) => theme.colors.grayPrimary};
  }
  .storage-value {
    display: flex;
    height: 86px;
    justify-content: center;
    align-items: center;
    background: #27aae117;
    border: 0.5px solid ${({ theme }) => theme.colors.blueLightest};
  }
  .weighted-value {
    height: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid ${({ theme }) => theme.colors.blueLightest};
  }
  .battery-value {
    width: 100%;
    text-align: center;
    padding: 3.5px;
    background: #27aae13d;
  }
  .battery-text {
    width: 100%;
    text-align: center;
    padding: 3.5px;
  }
`;
