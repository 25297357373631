import { ReactNode, useEffect, useState } from 'react';

import { Button, Typography } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

//import IconAccess from '@/assets/icons/icon-access-management.svg?react';
import IconCreditCard from '@/assets/icons/icon-credit-card.svg?react';
import IconLeft from '@/assets/icons/icon-left.svg?react';
import IconLock from '@/assets/icons/icon-lock.svg?react';
import IconNewSimulation from '@/assets/icons/icon-new-simulation.svg?react';
import IconOverview from '@/assets/icons/icon-overview.svg?react';
import IconPlants from '@/assets/icons/icon-plants.svg?react';
import IconRight from '@/assets/icons/icon-right.svg?react';
import IconScenarios from '@/assets/icons/icon-scenarios.svg?react';
import IconSettings from '@/assets/icons/icon-settings.svg?react';
import IconSimulation from '@/assets/icons/icon-simulation.svg?react';
import IconStorageSystems from '@/assets/icons/icon-storage-systems.svg?react';
import IconUser from '@/assets/icons/icon-user.svg?react';
import NewSimulation from '@/components/NewSimulation';
import { ClosedSaveUpPlan, SaveUpPlan } from '@/components/RSaveUpPlan';
import useAuth from '@/hooks/useAuth';
import useProductTour from '@/hooks/useProductTour';
import paths from '@/routes/paths';
import { blockedUsers } from '@/utils';

import SiderMenu from './SiderMenu';
import StyledSider from './styles';

type MenuItem = {
  key: string;
  icon: ReactNode;
  label: ReactNode;
  path: string;
  disabled?: boolean;
  style?: string;
  ref?: React.RefObject<HTMLDivElement>;
};

const Sider: React.FC<{ children?: ReactNode }> = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const location = useLocation();
  const [selected, setSelected] = useState('');
  const [menu, setMenu] = useState('general');
  const [isNewSimulationDrawerVisible, setNewSimulationDrawerVisible] =
    useState<boolean>(false);
  const $isResultPage = location.pathname.includes('result');
  const { user } = useAuth();
  const { data: runningSimulationsData, mutate: fetchRunningSimulation } =
    useSWR(
      isNewSimulationDrawerVisible
        ? `/simulation/?status=QUEUED&status=STARTED`
        : '',
      {
        refreshInterval: 5000,
      }
    );

  const plantsRef = useProductTour('plants');
  const scenariosRef = useProductTour('scenarios');
  const storageRef = useProductTour('storageSystems');

  const theme = useTheme();

  const mainItems: {
    icon: ReactNode;
    key: string;
    label: string;
    path: string;
    disabled?: boolean;
    auth?: boolean;
    style?: string;
    ref?: React.RefObject<HTMLDivElement>;
  }[] = [
    {
      icon: (
        <IconSimulation
          height={collapsed ? 24 : 20}
          width={collapsed ? 24 : 20}
        />
      ),
      key: 'simulations',
      label: 'Simulations',
      path: paths.simulation.base,
    },
    {
      icon: (
        <IconPlants height={collapsed ? 24 : 20} width={collapsed ? 24 : 20} />
      ),
      key: 'plants',
      label: 'Plants',
      path: paths.plants.base,
      ref: plantsRef,
    },
    {
      icon: (
        <IconScenarios
          height={collapsed ? 24 : 20}
          width={collapsed ? 24 : 20}
        />
      ),
      key: 'scenarios',
      label: 'Scenarios',
      path: paths.scenarios.base,
      ref: scenariosRef,
    },
    {
      icon: (
        <IconStorageSystems
          height={collapsed ? 24 : 20}
          width={collapsed ? 24 : 20}
        />
      ),
      key: 'storageSystems',
      label: 'Storage Systems',
      path: paths.storageSystems.base,
      ref: storageRef,
    },
    {
      icon: (
        <IconNewSimulation
          height={collapsed ? 24 : 20}
          width={collapsed ? 24 : 20}
        />
      ),
      key: 'new-simulation',
      label: 'New Simulation',
      path: paths.simulation.new,
      auth: blockedUsers(['2']),
      style: 'new-simulation',
    },
  ];

  const userItems: {
    icon: ReactNode;
    key: string;
    label: string;
    path: string;
    disabled?: boolean;
  }[] = [
    {
      icon: (
        <IconUser width={collapsed ? 24 : 20} height={collapsed ? 24 : 20} />
      ),
      key: 'general',
      label: 'General',
      path: paths.user.base,
    },
    {
      icon: (
        <IconLock width={collapsed ? 24 : 20} height={collapsed ? 24 : 20} />
      ),
      key: 'change-password',
      label: 'Change Password',
      path: paths.user.changePassword,
    },
  ];

  const organizationItems: {
    icon: ReactNode;
    key: string;
    label: string;
    path: string;
    disabled?: boolean;
  }[] = [
    {
      icon: (
        <IconOverview
          width={collapsed ? 24 : 20}
          height={collapsed ? 24 : 20}
        />
      ),
      key: 'overview',
      label: 'Overview',
      path: paths.organization.base,
    },
    {
      icon: (
        <IconUser width={collapsed ? 24 : 20} height={collapsed ? 24 : 20} />
      ),
      key: 'users',
      label: 'Users',
      path: paths.organization.user.management,
    },
    // {
    //   icon: <IconAccess width={collapsed ? 24 : 20} height={collapsed ? 24 : 20} />,
    //   key: 'access',
    //   label: 'Access Management',
    //   path: paths.organization.access,
    // },
    {
      icon: (
        <IconCreditCard
          width={collapsed ? 24 : 20}
          height={collapsed ? 24 : 20}
        />
      ),
      key: 'payments',
      label: 'Payments',
      path: paths.organization.payments,
    },
    {
      icon: (
        <IconSettings
          width={collapsed ? 24 : 20}
          height={collapsed ? 24 : 20}
        />
      ),
      key: 'settings',
      label: 'Settings',
      path: paths.organization.settings,
    },
  ];

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if ($isResultPage) setCollapsed(true);
    else setCollapsed(false);

    const curPath = location.pathname;
    const pathArr = curPath.split('/');
    if (pathArr.includes('user')) {
      setMenu('user');
      const selectedSiderItem = userItems.find(({ path }) => path === curPath);
      if (selectedSiderItem) {
        setSelected(selectedSiderItem?.key);
      }
    } else if (pathArr.includes('organization')) {
      setMenu('organization');
      const selectedSiderItem = organizationItems.find(
        ({ path }) => path === curPath
      );
      if (selectedSiderItem) {
        setSelected(selectedSiderItem?.key);
      }
    } else {
      setMenu('general');
      const selectedSiderItem = mainItems.find(({ path }) => path === curPath);
      if (selectedSiderItem) {
        setSelected(selectedSiderItem?.key);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [location.pathname, $isResultPage]);

  const generateMenuItems = (items: MenuItem[]) =>
    items.map(({ icon, label, path, disabled, key, style, ref }) => ({
      key,
      icon: <div ref={ref}>{icon}</div>,
      label:
        key === 'new-simulation' ? (
          <Typography.Link
            key={key}
            onClick={() => setNewSimulationDrawerVisible(true)}
            style={{ color: theme.colors.blueSecondary }}
          >
            {label}
          </Typography.Link>
        ) : (
          <Link key={key} to={path}>
            {label}
          </Link>
        ),
      disabled,
      className: style,
    }));
  const userMenuItems = generateMenuItems(userItems);
  const organizationMenuItems = generateMenuItems(organizationItems);
  const mainMenuItems = generateMenuItems(
    mainItems.filter((item) => item.auth !== false)
  );

  const getMenuItems = () => {
    switch (menu) {
      case 'user':
        return userMenuItems;
      case 'organization':
        return organizationMenuItems;
      default:
        return mainMenuItems;
    }
  };

  return (
    <StyledSider
      {...props}
      width={216}
      collapsed={collapsed}
      onCollapse={toggleCollapse}
    >
      {/* REFACTOR */}
      <SiderMenu
        activeKey={mainItems[0].key}
        selectedKeys={[selected]}
        mode="vertical"
        items={getMenuItems()}
      />

      {/* Upgrade Card */}
      {!collapsed ? <SaveUpPlan /> : <ClosedSaveUpPlan />}
      {/* Toggle Sider Button */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          right: '4px',
          transform: 'translateY(-50%)',
        }}
      >
        <Button
          icon={
            collapsed ? <IconRight fill="white" /> : <IconLeft fill="white" />
          }
          onClick={toggleCollapse}
          className="toggle-sider-btn"
        />
      </div>
      {isNewSimulationDrawerVisible && (
        <NewSimulation
          isNewSimulationDrawerVisible={isNewSimulationDrawerVisible}
          setNewSimulationDrawerVisible={setNewSimulationDrawerVisible}
          mutateFunction={fetchRunningSimulation}
          isLocked={
            (runningSimulationsData ? runningSimulationsData.count : 0) >=
            (user != undefined
              ? user?.organization.subscription.plan.simultaneousSimLimit
              : 0)
          }
        />
      )}
    </StyledSider>
  );
};

export default Sider;
