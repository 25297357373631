import { useState } from 'react';

import { Button, Flex, Spin, Typography } from 'antd';
import { useParams } from 'react-router';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconDownload from '@/assets/icons/icon-download.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Card from '@/components/RCard';
import RToggleButtons from '@/components/RToggleButtons';
import Tooltip from '@/components/RTooltip';
import { SimulationResult } from '@/types/simulations';

import ProfitAndLossTable from './ProfitAndLossTable';
import ProfitAndLossTableYearly from './ProfitAndLossTableYearly';

const ProfitAndLossTableCompact = () => {
  const theme = useTheme();
  const resultId = useParams<Record<string, string>>();
  const [selected, setSelected] = useState('Compact');
  const handleViewChange = (view: string) => {
    setSelected(view);
  };
  const { isLoading, error } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`,
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <Card
      title={
        selected === 'Compact' ? (
          <Flex gap={8} align="center">
            <Typography.Title level={4} className="fs-17-bold">
              Profit and Loss / Compact
            </Typography.Title>
            <Tooltip
              title="Profit and Loss / Compact"
              description="All prices are in nominal terms."
            >
              <IconInfo />
            </Tooltip>
          </Flex>
        ) : (
          <Flex gap={8} align="center">
            <Typography.Title level={4} className="fs-17-bold">
              Profit and Loss / Yearly
            </Typography.Title>
            <Tooltip
              title="Profit and Loss / Yearly"
              description="All prices are in nominal terms."
            >
              <IconInfo />
            </Tooltip>
          </Flex>
        )
      }
      $paddingBody="12px 0"
      extra={
        <Flex gap={16} align="center">
          <RToggleButtons
            labels={['Compact', 'Yearly']}
            selectedLabel={selected}
            onLabelChange={handleViewChange}
          />
          <Button icon={<IconDownload fill={theme.colors.bluePrimary} />} />
        </Flex>
      }
      styles={{ header: { padding: '14px 24px' } }}
      style={{ marginTop: '24px' }}
    >
      {isLoading ? (
        <Flex justify="center" align="center" style={{ height: 334 }}>
          <Spin style={{ margin: '20px' }} />
        </Flex>
      ) : error ? (
        <Flex
          vertical
          gap={12}
          justify="center"
          align="center"
          style={{ height: 334 }}
        >
          <IconError width={50} height={50} fill={theme.colors.tagFailure} />
          <Typography.Text className="fs-14-regular text-gray-color">
            An error occured while fetching data. Please check your inputs or
            contact support if the issue persists.
          </Typography.Text>
        </Flex>
      ) : selected === 'Compact' ? (
        <ProfitAndLossTable />
      ) : (
        <ProfitAndLossTableYearly />
      )}
    </Card>
  );
};
export default ProfitAndLossTableCompact;
