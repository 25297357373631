import { useEffect, useState } from 'react';

import { Steps, Flex } from 'antd';
import Helmet from 'react-helmet';

import LocalStorageService from '@/services/LocalStorageService';

import { EmailStep, EmailStepVerification } from './EmailStep';
import { OrganizationStep } from './OrganizationStep';
import Plans from './Plans';
import { ProfileStep } from './ProfileStep';
import RegisterStyles from './styles';

const Register = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [emailSent, setEmailSent] = useState<boolean>(false);

  useEffect(() => {
    const storedStep = LocalStorageService.getCurrentStep();
    if (storedStep !== null) {
      setCurrentStep(storedStep);
    }
  }, []);

  const steps = [
    {
      title: currentStep > 0 ? 'Finished' : 'In Progress',
      description: 'Verify Email',
      content: !emailSent ? (
        <EmailStep setEmailSent={setEmailSent} />
      ) : (
        <EmailStepVerification
          setCurrentStep={setCurrentStep}
          setEmailSent={setEmailSent}
        />
      ),
    },
    {
      title: currentStep === 1 ? 'In Progress' : 'Waiting',
      description: 'Profile',
      content: <ProfileStep setCurrentStep={setCurrentStep} />,
    },
    {
      title: currentStep === 2 ? 'In Progress' : 'Waiting',
      description: 'Organization',
      content: <OrganizationStep setCurrentStep={setCurrentStep} />,
    },
    {
      title: currentStep === 3 ? 'In Progress' : 'Waiting',
      description: 'Plans',
    },
  ];

  return (
    <RegisterStyles currentStep={currentStep}>
      <Helmet>
        <title>Create Account - RatioSIM</title>
      </Helmet>
      {currentStep !== 3 ? (
        <Flex className="container" vertical align="center">
          <Flex className="w-100" justify="space-between">
            <Steps
              current={currentStep}
              progressDot
              status="process"
              items={steps.map((step) => ({
                title: step.title,
                description: step.description,
              }))}
            />
          </Flex>
          <hr className="line" />
          {steps[currentStep].content}
        </Flex>
      ) : (
        <Plans />
      )}
    </RegisterStyles>
  );
};

export default Register;
