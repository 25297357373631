import { UserResponse } from '@/types/auth';
class LocalStorageService {
  authTokenKey: string;
  refreshTokenKey: string;
  tokenType: string;
  expDateKey: string;
  userData: string;
  emailResendBlockTimestamp: string;

  constructor() {
    this.authTokenKey = 'SIM_AUTH';
    this.refreshTokenKey = 'SIM_REFRESH';
    this.tokenType = 'Bearer';
    this.expDateKey = 'EXPD';
    this.userData = 'U';
    this.emailResendBlockTimestamp = 'SIM_E_SENDER_TS';
  }

  getEmailSenderTS() {
    return localStorage.getItem(this.emailResendBlockTimestamp);
  }

  getAuthToken() {
    return localStorage.getItem(this.authTokenKey);
  }

  setAuthToken(token = '') {
    localStorage.setItem(this.authTokenKey, `${this.tokenType} ${token}`);
  }

  setEmailSenderTS(timestamp = '') {
    localStorage.setItem(this.emailResendBlockTimestamp, `${timestamp}`);
  }

  getRefreshToken() {
    return localStorage.getItem(this.refreshTokenKey);
  }

  setRefreshToken(token = '') {
    localStorage.setItem(this.refreshTokenKey, `${token}`);
  }

  setUser(user: UserResponse) {
    localStorage.setItem(this.userData, JSON.stringify(user));
  }

  setAuthExpDate(date: number) {
    localStorage.setItem(this.expDateKey, `${date}`);
  }
  getAuthExpDate() {
    return Number(localStorage.getItem(this.expDateKey));
  }
  getCurrentStep() {
    const step = localStorage.getItem('currentStep');
    return step ? parseInt(step, 10) : null;
  }
  setCurrentStep(step: number) {
    localStorage.setItem('currentStep', step.toString());
  }

  clearLocalStorage() {
    localStorage.removeItem(this.authTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    localStorage.removeItem(this.expDateKey);
    localStorage.removeItem(this.userData);
    localStorage.removeItem(this.emailResendBlockTimestamp);
    localStorage.removeItem('currentStep');
  }
}

export default new LocalStorageService();
