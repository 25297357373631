import { Row, Col } from 'antd';
import styled from 'styled-components';

export default styled(Col)`
  .filter-buttons-styles {
    height: 100%;
    border-radius: 4px !important;
    width: 100%;
    svg {
      fill: ${({ theme }) => theme.colors.bluePrimary} !important;
    }
  }

  .selected-filter-buttons-styles {
    border-radius: 4px !important;
    height: 100%;
    width: 100%;
    svg {
      fill: white !important;
    }
  }
  .filter-buttons-styles:hover,
  .selected-filter-buttons-styles:hover {
    background-color: ${({ theme }) => theme.colors.blueLightMedium} !important;
  }
  .filter-buttons-styles:hover svg,
  .selected-filter-buttons-styles:hover svg {
    fill: ${({ theme }) => theme.colors.light} !important;
  }
  .ant-table-measure-row {
    display: none;
  }
`;

export const TableActionsWrapper = styled(Row)`
  border-bottom: none;
  padding-bottom: 16px;
  padding-top: 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
