import { useState } from 'react';

import { Button, Flex, Spin, Typography } from 'antd';
import { useParams } from 'react-router';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconDownload from '@/assets/icons/icon-download.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import Card from '@/components/RCard';
import CustomGraphicTooltip from '@/components/RGraphicTooltip';
import RToggleButtons from '@/components/RToggleButtons';
import RTooltip from '@/components/RTooltip';
import { SimulationResultYearlyFinancialAnalysis } from '@/types/simulations';
import { getTickStep, tickFormatter } from '@/utils';

import StyledPlanBatteryBOS from './styles';

const PlantBatteryBOS = () => {
  const resultId = useParams<Record<string, string>>();
  const {
    data: financialData,
    isLoading,
    error,
  } = useSWR<SimulationResultYearlyFinancialAnalysis>(
    `/simulation/${resultId.resultId}/yearly_financial_analysis/`,
    {
      revalidateOnFocus: false,
    }
  );
  const isDataZero = financialData?.graphData.length === 0;
  const [closedDataKeys, setClosedDataKeys] = useState<string[]>([]);
  const [selectedSwitch, setSelectedSwitch] = useState('Cost');
  const theme = useTheme();

  const toggleClosedDataKeys = (key: string = '') => {
    if (closedDataKeys.includes(key)) {
      setClosedDataKeys(closedDataKeys.filter((item) => item !== key));
    } else {
      setClosedDataKeys([...closedDataKeys, key]);
    }
  };

  const handleSwitchChange = (value: string) => {
    setSelectedSwitch(value);
  };

  const data =
    financialData?.graphData.map((item) => {
      const filteredItem: {
        year: number;
        Plant: number;
        Battery: number;
        BOS: number;
      } = {
        year: item.year,
        Plant: 0,
        Battery: 0,
        BOS: 0,
      };
      if (!closedDataKeys.includes('plant')) {
        filteredItem.Plant =
          selectedSwitch === 'Cost'
            ? item.plantTotalCost
            : item.plantDepreciation;
      }
      if (!closedDataKeys.includes('battery')) {
        filteredItem.Battery =
          selectedSwitch === 'Cost'
            ? item.batteryTotalCost
            : item.batteryDepreciation;
      }
      if (!closedDataKeys.includes('bos')) {
        filteredItem.BOS =
          selectedSwitch === 'Cost' ? item.bosTotalCost : item.bosDepreciation;
      }

      return filteredItem;
    }) || [];

  const allValues = data.map((item) => item.Plant + item.Battery + item.BOS);
  const maxValue = Math.max(...allValues);
  const minValue = Math.min(...allValues);

  const roundToNearest = (num: number, nearest: number) => {
    return Math.round(num / nearest) * nearest;
  };

  const tickStep = getTickStep(
    Math.max(Math.abs(minValue), Math.abs(maxValue))
  );
  const upperBound = roundToNearest(maxValue * 1.3, tickStep);
  const lowerBound = roundToNearest(minValue * 1.3, tickStep);
  const maxAbsBound = Math.max(Math.abs(upperBound), Math.abs(lowerBound));
  const domain =
    selectedSwitch === 'Cost' ? [-maxAbsBound, maxAbsBound] : [0, maxAbsBound];

  const step = selectedSwitch === 'Cost' ? maxAbsBound / 2 : maxAbsBound / 4;
  const ticks =
    selectedSwitch === 'Cost'
      ? [-2 * step, -1 * step, 0, 1 * step, 2 * step]
      : [0, 1 * step, 2 * step, 3 * step, 4 * step]
          .map((value) => roundToNearest(value, tickStep))
          .filter((value) => value !== 0 || maxValue !== 0 || minValue !== 0);

  return (
    <StyledPlanBatteryBOS>
      <Card
        title={
          <Flex gap={8} align="center">
            <Typography.Title level={4} className="fs-17-bold">
              Plant, Battery, BOS Costs
            </Typography.Title>
            <RTooltip
              title="Plant, Battery, BOS Costs"
              description="This graph shows all replacement costs and residual values, with
              residuals represented as negative since they are not costs. The
              depreciation table details the annual depreciation of BOS,
              battery, and plant."
            >
              <IconInfo />
            </RTooltip>
          </Flex>
        }
        extra={
          <Flex gap={16} align="center">
            <RToggleButtons
              labels={['Cost', 'Depreciation']}
              onLabelChange={handleSwitchChange}
            />
            <Button icon={<IconDownload fill={theme.colors.bluePrimary} />} />
          </Flex>
        }
        $paddingBody="24px"
        styles={{ header: { padding: '14px 24px' } }}
        style={{ marginTop: '24px', width: '100%' }}
      >
        {isLoading ? (
          <Flex justify="center" align="center" style={{ height: 400 }}>
            <Spin style={{ margin: '20px' }} />
          </Flex>
        ) : error || isDataZero ? (
          <Flex
            vertical
            gap={12}
            justify="center"
            align="center"
            style={{ height: 400 }}
          >
            <IconError width={50} height={50} fill={theme.colors.tagFailure} />
            {error && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                An error occured while fetching data. Please check your inputs
                and contact support if the issue persists.
              </Typography.Text>
            )}
            {isDataZero && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                No data is currently available. Please review your inputs.
              </Typography.Text>
            )}
          </Flex>
        ) : (
          <ResponsiveContainer height={400} width={'100%'}>
            <BarChart data={data}>
              <CartesianGrid vertical={false} horizontal={true} />
              <XAxis
                dataKey="year"
                angle={-45}
                textAnchor="end"
                height={60}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                tickFormatter={tickFormatter}
                domain={domain}
                ticks={ticks.length > 0 ? ticks : undefined}
                tick={{ dx: -4 }}
                axisLine={false}
                tickLine={false}
                label={{
                  angle: -90,
                  position: 'insideLeft',
                }}
              />
              <Tooltip content={<CustomGraphicTooltip />} />
              <Legend
                payload={[
                  {
                    value: 'Plant',
                    id: 'plant',
                    type: 'circle',
                    color: theme.colors.purpleSecondary,
                    inactive: closedDataKeys.includes('plant'),
                  },
                  {
                    value: 'Battery',
                    id: 'battery',
                    type: 'circle',
                    color: theme.colors.orangeSecondary,
                    inactive: closedDataKeys.includes('battery'),
                  },
                  {
                    value: 'BOS',
                    id: 'bos',
                    type: 'circle',
                    color: theme.colors.blueLight,
                    inactive: closedDataKeys.includes('bos'),
                  },
                ]}
                iconSize={10}
                onClick={({ id }) => toggleClosedDataKeys(id)}
              />
              {!closedDataKeys.includes('plant') && (
                <Bar
                  dataKey="Plant"
                  fill={theme.colors.purpleSecondary}
                  barSize={16}
                  stackId="a"
                  unit="$"
                />
              )}
              {!closedDataKeys.includes('battery') && (
                <Bar
                  dataKey="Battery"
                  fill={theme.colors.orangeSecondary}
                  barSize={16}
                  stackId="a"
                  unit="$"
                />
              )}
              {!closedDataKeys.includes('bos') && (
                <Bar
                  dataKey="BOS"
                  fill={theme.colors.blueLight}
                  barSize={16}
                  stackId="a"
                  unit="$"
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        )}
      </Card>
    </StyledPlanBatteryBOS>
  );
};
export default PlantBatteryBOS;
