import { FC, ReactNode, useState } from 'react';

import { Button, Flex, Image, Typography } from 'antd';
import { RowProps } from 'antd/lib';

import Lock from '@/assets/files/lock.png';
import PlansModal from '@/pages/Organization/Payments/PlansModal';

import StyledMask, { StyledModal } from './styles';

//TODO/SB : remove isButtonShown, just check for luckButtons !== null,
/**
 * TODO/SB: isLockShown is a transient prop, which means is used only by the styled component
 * for styling purposes and is not passed down to the DOM element, which prevents React from rendering these
 * props on the HTML elements and avoids HTML validation warnings. We can make backgroundColor, fontSize and opacity transient
 * props too.
 */
interface MaskProps extends RowProps {
  text: string;
  $isLockShown?: boolean;
  isButtonShown?: boolean;
  backgroundColor?: string;
  onClose?: () => void;
  fontSize?: number;
  opacity?: number;
  lockButtons?: React.ReactNode[];
}

const RMask: FC<MaskProps> = ({
  text,
  $isLockShown = true,
  isButtonShown = true,
  backgroundColor,
  fontSize,
  opacity = 0.6,
  lockButtons,
  onClose,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleShowModal = () => {
    setIsModalVisible(true);
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <StyledMask
        backgroundColor={backgroundColor}
        $isLockShown={$isLockShown}
        fontSize={fontSize}
        opacity={opacity}
      >
        {$isLockShown && (
          <Image
            src={Lock}
            alt="lock"
            width={104}
            height={104}
            preview={false}
          />
        )}
        <Typography.Text className="text">{text}</Typography.Text>
        {isButtonShown &&
          (lockButtons ? (
            <Flex gap={8}>{lockButtons.map((node: ReactNode) => node)}</Flex>
          ) : (
            <Flex gap={8}>
              <Button type="default" className="button" onClick={onClose}>
                Discard
              </Button>

              <Button
                type="primary"
                className="button"
                onClick={handleShowModal}
              >
                Upgrade
              </Button>
            </Flex>
          ))}
      </StyledMask>
      <StyledModal
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        destroyOnClose
        centered
        width={'100%'}
        className="mask-modal"
      >
        <PlansModal />
      </StyledModal>
    </>
  );
};

export default RMask;
