import { FC } from 'react';

import { Button, Flex, ModalProps, Typography } from 'antd';
import { useTheme } from 'styled-components';

import Cross from '@/assets/icons/icon-close.svg?react';
import Error from '@/assets/icons/icon-error.svg?react';
import Info from '@/assets/icons/icon-info.svg?react';
import Success from '@/assets/icons/icon-success.svg?react';
import Warning from '@/assets/icons/icon-warning.svg?react';

import StyledNotificationCard from './styles';

//TODO/SB : What is the difference between this component and Notification Card?
/**
 * TODO/SB : We can make type as transient prop since it is only consumed by StyledComponent
 */
interface NotificationCardProps extends ModalProps {
  type: 'success' | 'error' | 'warning' | 'info';
  title: string;
  message: string;
  confirmText: React.ReactNode | string;
  cancelText: React.ReactNode | string;
  open?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
}

const RNotificationCard: FC<NotificationCardProps> = ({
  type,
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  open,
}) => {
  const theme = useTheme();
  const icon = {
    success: <Success fill={theme.colors.tagSuccess} width={24} height={24} />,
    error: <Error fill={theme.colors.tagFailure} width={24} height={24} />,
    warning: <Warning fill={theme.colors.tagRevoked} width={24} height={24} />,
    info: <Info fill={theme.colors.blueLightMedium} width={24} height={24} />,
  }[type];
  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <StyledNotificationCard
      type={type}
      open={open}
      closeIcon={false}
      footer={null}
      width={475}
    >
      <Flex vertical gap={16}>
        <Flex justify="space-between" align="center">
          <Flex align="center" gap={10}>
            {icon}
            <Typography.Title level={5} className="fs-17-semi-bold">
              {title}
            </Typography.Title>
          </Flex>
          <Cross
            onClick={handleCancel}
            style={{ cursor: 'pointer' }}
            fill="black"
          />
        </Flex>
        <Typography.Text className="fs-14-regular">{message}</Typography.Text>
        <Flex justify="flex-end" gap={8}>
          <Button type="default" className="btn" onClick={handleCancel}>
            {cancelText}
          </Button>
          <Button type="primary" className="btn" onClick={handleConfirm}>
            {confirmText}
          </Button>
        </Flex>
      </Flex>
    </StyledNotificationCard>
  );
};

export default RNotificationCard;
