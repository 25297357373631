export const ENERGY_UNITS = {
  MW: 'MW',
  // KW: 'KW',
};
export const energyUnits = Object.values(ENERGY_UNITS);

export const RANGE_TYPES = {
  HOUR: 'H',
  DAY: 'D',
  WEEK: 'W',
  MONTH: 'M',
  YEAR: 'Y',
};

export const FILE_FORMATS = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
};

export const ROLES_LIST = [
  {
    id: 1,
    name: 'Admin',
    value: 'admin',
    label: 'Admin',
  },
  {
    id: 2,
    name: 'Finance',
    value: 'finance',
    label: 'Finance',
  },
  {
    id: 3,
    name: 'Energy',
    value: 'energy',
    label: 'Energy',
  },
  {
    id: 4,
    name: 'Standard',
    value: 'standart',
    label: 'Standard',
  },
];

export const MONTHS_WITH_VALUES = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

export const LINE_DATA_KEYS = [
  'accumulatedCashFlow',
  'soh',
  'inflow',
  'outflow',
  'mcpPrice',
  'pfcPrice',
  'sfcPrice',
  'totalGeneration',
  'inflowStorage',
  'outflowStorage',
  'inflowPlant',
  'outflowPlant',
];

export enum TourSteps {
  USER_PROFILE = 0,
  HELP_SECTION = 1,
  ORGANIZATION_SECTION = 2,
  PLANTS = 3,
  CREATE_PLANT = 4,
  VIEW_PLANTS = 5,
  SCENARIOS = 6,
  CREATE_SCENARIO = 7,
  VIEW_SCENARIOS = 8,
  STORAGE_SYSTEMS = 9,
  CREATE_STORAGE_SYSTEM = 10,
  VIEW_STORAGE_SYSTEMS = 11,
  CREATE_SIMULATION = 12,
  SIMULATION_ASSETS = 13,
  SELECT_ASSET_EMPTY_FACE = 14,
  SELECT_ASSET_FILL_FACE = 15,
  REVIEW_SIMULATION_ASSETS = 16,
  RUNNING_SIMULATION = 17,
  COMPLETED_SIMULATION = 18,
  EXAMPLE_SIMULATION = 19,
}

export enum CreateSimSteps {
  DESC_OF_SIMULATION = 0,
  ASSET_SELECTION = 1,
  OVERVIEW = 2,
}
export type FilterOption = {
  value: string;
  label: string;
};
export const filterOptions: FilterOption[] = [
  { value: '1', label: 'Description' },
  { value: '2', label: 'Storage System' },
  { value: '3', label: 'Scenario' },
  { value: '4', label: 'Plants' },
];
