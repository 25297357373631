import { TableProps } from 'antd/lib';

import Cross from '@/assets/icons/icon-close.svg?react';
import { ComparisonType } from '@/types/plan';

export const initColumns: TableProps<ComparisonType>['columns'] = [
  {
    title: 'See all features',
    dataIndex: 'feature',
    align: 'left',
    width: '25%',
  },
  {
    title: 'Demo',
    dataIndex: 'Demo',
    render: (text) => {
      if (typeof text === 'boolean') {
        return text ? <Cross style={{ fontSize: '14px' }} /> : '';
      } else {
        return text;
      }
    },
    align: 'center',
    width: '18.75%',
  },
  {
    title: (
      <div>
        <p>Individual</p>
        <p className="fs-14-regular"> / yearly</p>
      </div>
    ),
    dataIndex: 'Individual',
    render: (text) => {
      if (typeof text === 'boolean') {
        return text ? <Cross style={{ fontSize: '14px' }} /> : '';
      } else {
        return text;
      }
    },
    align: 'center',
    width: '18.75%',
  },
  {
    title: (
      <div>
        <p>Team</p>
        <p className="fs-14-regular"> / yearly</p>
      </div>
    ),
    dataIndex: 'Team',
    render: (text) => {
      if (typeof text === 'boolean') {
        return text ? <Cross style={{ fontSize: '14px' }} /> : '';
      } else {
        return text;
      }
    },
    align: 'center',
    width: '18.75%',
  },
  {
    title: (
      <div>
        <p>Enterprise</p>
        <p className="fs-14-regular"> / yearly</p>
      </div>
    ),
    dataIndex: 'Enterprise',
    render: (text) => {
      if (typeof text === 'boolean') {
        return text ? <Cross style={{ fontSize: '14px' }} /> : '';
      } else {
        return text;
      }
    },
    align: 'center',
    width: '18.75%',
  },
];
