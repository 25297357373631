import { FC } from 'react';

import PageHeader from '@/components/RPageHeader';

import StyledAccessManagement from './styles';

const AccessManagement: FC = () => {
  return (
    <StyledAccessManagement>
      <PageHeader
        title="Access Management"
        description="You can change users' access authorizations"
      />
    </StyledAccessManagement>
  );
};

export default AccessManagement;
