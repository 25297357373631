import type { FilterParams } from '@/types/general';
import type {
  StorageSystemPost,
  StorageSystemsResponse,
} from '@/types/storageSystem';

import { apiV1 } from '../instances';

export default {
  fetchStorageSystems(params: FilterParams): Promise<StorageSystemsResponse> {
    const fullFill = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== '')
    );
    const query = new URLSearchParams({
      ...fullFill,
      limit: fullFill.limit?.toString(),
      offset: fullFill.offset?.toString(),
    });
    return apiV1.get(`/storage/?${query.toString()}`);
  },
  createStorageSystems(storageSystem: StorageSystemPost) {
    return apiV1.post('/storage/', storageSystem);
  },
  deleteStorageSystem(storageSystemId: string) {
    return apiV1.delete(`/storage/${storageSystemId}/`);
  },

  duplicateStorageSystem(storageId: string, storage: StorageSystemPost) {
    return apiV1.post(`/storage/${storageId}/duplicate/`, storage, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  viewStorageSystem(storageId: string) {
    return apiV1.get(`/storage/${storageId}`);
  },
  deactiveStorageSystem(storageId: string) {
    return apiV1.delete(`/storage/${storageId}/`);
  },
  activateStorageSystem(storageId: string) {
    return apiV1.post(`/storage/${storageId}/activate/`);
  },
  searchStorageSystemByName(storageName: string) {
    return apiV1.get(`/storage/?name=${storageName}`);
  },
  searchStorageSystemByExactName(storageName: string) {
    return apiV1.get(`/storage/?name_exact=${storageName}`);
  },
};
