import { FC } from 'react';

import { Flex, Typography } from 'antd';
import { useTheme } from 'styled-components';

import IconInfo from '@/assets/icons/icon-info.svg?react';
import Table from '@/components/RTable';
import Tooltip from '@/components/RTooltip';
import { SimulationResult } from '@/types/simulations';
import { formatNumberWithCommas } from '@/utils';

import { StyledTable } from './styles';

interface TableData {
  key: string;
  title: string | JSX.Element;
  energyAmount: string | number;
  cost?: number | string;
}

interface ExcessTableProps {
  simulationData: SimulationResult;
}

const ExcessTable: FC<ExcessTableProps> = ({ simulationData }) => {
  const theme = useTheme();

  const dataTable: TableData[] = [
    {
      key: '1',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '10px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Curtailment without Storage
          </Typography.Text>
          <Tooltip
            title="Curtailment without Storage"
            description="Curtailed energy due to limitation of energy production or transmission due to restricted licensing or regulatory constraints, without the capability to store surplus energy for future utilization."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      energyAmount: formatNumberWithCommas(
        Number(simulationData.result.curtailedEnergyWos.toFixed(0))
      ),
      cost: formatNumberWithCommas(
        Number(simulationData.result.curtailedEnergyCostWos.toFixed(0))
      ),
    },
    {
      key: '2',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '10px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Curtailment with Storage
          </Typography.Text>
          <Tooltip
            title="Curtailment with Storage"
            description="Curtailed energy due to limitation of energy production or transmission due to restricted licensing or regulatory constraints, with the capability to store surplus energy for future utilization."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      energyAmount: formatNumberWithCommas(
        Number(simulationData.result.totalCurtailedEnergy.toFixed(0))
      ),
      cost: formatNumberWithCommas(
        Number(simulationData.result.curtailedEnergyCost.toFixed(0))
      ),
    },
    {
      key: '3',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '10px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Storage Inefficiency (Heat)
          </Typography.Text>
          <Tooltip
            title="Storage Inefficiency (Heat)"
            description="Losses during energy storage caused by system inefficiencies. "
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      energyAmount: formatNumberWithCommas(
        Number(simulationData.result.efficiencyLossInCurtailment.toFixed(0))
      ),
      cost: formatNumberWithCommas(
        Number(simulationData.result.efficiencyLossInCurtailmentCost.toFixed(0))
      ),
    },
    {
      key: '4',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '10px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Net Energy Excess from Storage
          </Typography.Text>
          <Tooltip
            title="Net Energy Excess from Storage"
            description="Additional energy gain achieved through stored energy, which reduces the amount of energy that would otherwise be curtailed or wasted."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      energyAmount: formatNumberWithCommas(
        Number(simulationData.result.netEnergyExcessFromStorage.toFixed(0))
      ),
      cost: formatNumberWithCommas(
        Number(
          simulationData.result.netEnergyExcessRevenueFromStorage.toFixed(0)
        )
      ),
    },
  ];

  const columns = [
    {
      dataIndex: 'title',
      width: '46%',
    },
    {
      title: 'Energy Amount (MWh)',
      dataIndex: 'energyAmount',
      key: 'energyAmount',
      width: '27%',
    },
    {
      title: 'Value ($)',
      dataIndex: 'cost',
      key: 'cost',
      width: '27%',
    },
  ];
  return (
    <StyledTable>
      <Table
        columns={columns}
        dataSource={dataTable}
        pagination={false}
        bordered
        size="small"
      />
    </StyledTable>
  );
};
export default ExcessTable;
