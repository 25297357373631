import { Col } from 'antd';
import styled from 'styled-components';

import { ExtendedColProps } from './type';

export default styled(Col)<ExtendedColProps>`
  padding-top: ${({ theme }) => theme.spacing.spacing24}px;
  padding-bottom: ${({ theme }) => theme.spacing.spacing24}px;

  .title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
  }

  .description {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin: 0px;
    margin-top: 4px;
  }

  &.page-header-sticky-top {
    position: sticky;
    width: 100%;
    top: 0px;
    width: 100%;
    z-index: 1000;
  }

  box-shadow: ${({ $scrollDirection }) => {
    if ($scrollDirection === 'up') {
      return '0px 20px 20px -15px rgba(0, 0, 0, 0.1)';
    } else if ($scrollDirection === 'down') {
      return '0px 20px 20px -15px rgba(0, 0, 0, 0.2)';
    } else {
      return 'none';
    }
  }};
  ${({ $isResultPage }) =>
    $isResultPage &&
    `
    box-shadow: none;
  `}
`;
