import styled from 'styled-components';

export default styled.div`
  .faded-text {
    color: rgba(0, 0, 0, 0.75);
  }
  .tags {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 3.5px 7px;
    border-radius: 4px;
    border: 0.5px solid ${({ theme }) => theme.colors.blueLightMedium};
    background-color: transparent;
    cursor: pointer;
    div {
      color: ${({ theme }) => theme.colors.dark} !important;
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.blueLightMedium};
      div {
        color: ${({ theme }) => theme.colors.light} !important;
      }
    }
  }
  .switch-text {
    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;
