import { Tabs } from 'antd';
import styled from 'styled-components';

export default styled(Tabs)`
  margin-top: 24px;
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      display: flex;
      gap: 3px;
      .ant-tabs-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 41px;
        width: 202px;
        margin: 0;
        padding: 10px;
        background-color: transparent;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.grayAccent};
        border: 0.5px solid ${({ theme }) => theme.colors.grayAccent};
        border-radius: 4px;
      }
      .ant-tabs-tab-active {
        background-color: ${({ theme }) => theme.colors.light};
        font-weight: 700;
        .ant-tabs-tab-btn {
          text-shadow: none;
        }
      }
      .ant-tabs-tab:hover {
        color: ${({ theme }) => theme.colors.primary} !important;
        background-color: ${({ theme }) =>
          theme.colors.blueLightest} !important;
        border-color: ${({ theme }) => theme.colors.primary} !important;
      }
    }
  }
  .ant-tabs-nav {
    margin-bottom: 0;
  }
`;
