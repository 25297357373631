import { FC, useEffect, useState } from 'react';

import {
  Button,
  Col,
  Flex,
  Form,
  FormProps,
  Input,
  Popconfirm,
  Row,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import { useTheme } from 'styled-components';

import { authApi } from '@/api';
import IconClose from '@/assets/icons/icon-close.svg?react';
import IconEdit from '@/assets/icons/icon-edit.svg?react';
import IconSave from '@/assets/icons/icon-save.svg?react';
import Card from '@/components/RCard';
import RNotificationCard from '@/components/RNotificationCard';
import PageHeader from '@/components/RPageHeader';
import RCountryPhoneInput, {
  CountryPhoneInputValue,
} from '@/components/RPhoneInput';
import useAuth from '@/hooks/useAuth';
import { fullNameRegExpPattern, handleError, validateEmail } from '@/utils';

type disableType = {
  isNameDisabled: boolean;
  isEmailDisabled: boolean;
  isPhoneNumberDisabled: boolean;
  isJobTitleDisabled: boolean;
};

type editType = {
  isNameEditted: boolean;
  isEmailEditted: boolean;
  isPhoneNumberEditted: boolean;
  isJobTitleEditted: boolean;
};

type initialValuesType = {
  nameInitialValue: string;
  emailInitialValue: string;
  phoneNumberInitialValue: CountryPhoneInputValue;
  jobTitleInitialValue: string;
};

const UserProfile: FC<FormProps> = ({ ...formProps }) => {
  const [form] = Form.useForm();
  const theme = useTheme();
  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);
  const { user, revalidateUser } = useAuth();

  const [phoneNumber, setPhoneNumber] = useState<CountryPhoneInputValue>({
    phone: '',
    code: 0,
  });

  const [disable, setDisable] = useState<disableType>({
    isNameDisabled: true,
    isEmailDisabled: true,
    isPhoneNumberDisabled: true,
    isJobTitleDisabled: true,
  });

  const [editted, setEditted] = useState<editType>({
    isNameEditted: false,
    isEmailEditted: false,
    isPhoneNumberEditted: false,
    isJobTitleEditted: false,
  });

  const [initialValues, setInitialValues] = useState<initialValuesType>({
    nameInitialValue: '',
    emailInitialValue: '',
    phoneNumberInitialValue: {
      phone: '',
      code: 0,
    },
    jobTitleInitialValue: '',
  });

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (user && user.phoneNumber) {
      const phoneParts = user.phoneNumber.match(
        /^\+?(\d+)\s*\((\d+)\)\s*(\d+-?\d*)$/
      );

      if (!phoneParts) {
        return;
      }

      const code = phoneParts[1];
      const rawPhone = phoneParts[2] + phoneParts[3].replace(/-/g, '');

      const formattedPhone = `(${rawPhone.slice(0, 3)}) ${rawPhone.slice(3)}`;

      const newPhoneNumber = {
        code: parseInt(code, 10),
        phone: formattedPhone,
      };
      setPhoneNumber(newPhoneNumber);

      form.setFieldsValue({
        fullName: user?.fullName,
        email: user?.email,
        phoneNumber: newPhoneNumber,
        jobTitle: user?.jobTitle,
        role: user?.role.name,
      });

      setInitialValues({
        nameInitialValue: user?.fullName || '',
        emailInitialValue: user?.email || '',
        phoneNumberInitialValue: newPhoneNumber,
        jobTitleInitialValue: user?.jobTitle || '',
      });
    }
  }, [user, form]);

  async function patchUser(id: string) {
    try {
      await form.validateFields([id]); // Validates only the field being edited

      const formResult = form.getFieldsValue([id]);

      if (!formResult) {
        notification.error({ message: 'No data found to update.' });
        return false;
      }

      let body = {};
      if (id === 'fullName') {
        body = { fullName: formResult.fullName };
      } else if (id === 'email') {
        body = { email: formResult.email };
      } else if (id === 'phoneNumber') {
        body = {
          phoneNumber: `+${phoneNumber.code} ${phoneNumber.phone}`,
        };
      } else if (id === 'jobTitle') {
        body = { jobTitle: formResult.jobTitle };
      }

      if (user && Object.keys(body).length > 0) {
        const result = await authApi.patchUser(user.id, body);
        if (result) {
          notification.success({ message: 'Update done successfuly!' });
          revalidateUser();
          return true;
        }
      }
    } catch (error) {
      handleError(error);
      return false;
    }
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const phoneNumberValidator = (_: any, value: CountryPhoneInputValue) => {
    if (!value || !value.phone) {
      return Promise.reject(new Error('Please input your phone number!'));
    }
    if (value.phone.length < 5) {
      return Promise.reject(
        new Error('Phone number must be at least 5 digits long!')
      );
    }
    return Promise.resolve();
  };

  const confirmDelete = async () => {
    try {
      if (user) {
        await authApi.deleteUser(user.id);
        window.location.reload();
      }
    } catch (error) {
      setModalVisible(false);
      setTimeout(() => {
        handleError(error, 'Failed to delete user');
      }, 500);
    }
  };

  return (
    <>
      <PageHeader
        title="General"
        description="You can view your personal information."
      />
      <Form
        form={form}
        layout="vertical"
        {...formProps}
        onValuesChange={() => {
          const fullName = form.getFieldValue('fullName');
          const email = form.getFieldValue('email');
          const phoneNumber = form.getFieldValue('phoneNumber').phone;
          const jobTitle = form.getFieldValue('jobTitle');
          setSaveDisabled(!fullName || !email || !phoneNumber || !jobTitle);
        }}
      >
        <Card title={'Personal Information'} $paddingBody="24px">
          <Row align="middle" gutter={20} wrap={false}>
            {/** FULL NAME */}
            <Col span={8}>
              <Row align="middle">
                <Col flex={1} className="mr-8">
                  <Form.Item
                    label="Full Name"
                    name="fullName"
                    rules={[
                      {
                        required: true,
                        message: 'Name field is required!',
                      },
                      {
                        pattern: fullNameRegExpPattern(),
                        message: 'Invalid characters!',
                      },
                    ]}
                  >
                    <Input
                      disabled={disable.isNameDisabled}
                      onChange={() => {
                        setEditted((prevState) => ({
                          ...prevState,
                          isNameEditted: true,
                        }));
                      }}
                      className="w-100"
                    />
                  </Form.Item>
                </Col>
                <Flex align="center">
                  {!disable.isNameDisabled &&
                    editted.isNameEditted &&
                    (!isSaveDisabled ? (
                      <Popconfirm
                        title={`Are you sure you want to change ${user?.fullName}'s name?`}
                        onConfirm={() => {
                          patchUser('fullName').then((success) => {
                            if (success) {
                              setTimeout(() => {
                                setDisable((prevState) => ({
                                  ...prevState,
                                  isNameDisabled: true,
                                }));
                                setEditted((prevState) => ({
                                  ...prevState,
                                  isNameEditted: false,
                                }));
                              }, 500);
                            }
                          });
                        }}
                        okText="Continue"
                      >
                        <Tooltip title={'Save'}>
                          <Button
                            className="mr-8"
                            style={{ marginTop: '10px' }}
                            icon={<IconSave fill={theme.colors.bluePrimary} />}
                          />
                        </Tooltip>
                      </Popconfirm>
                    ) : (
                      <Tooltip title={'You can not save without a name'}>
                        <Button
                          className="mr-8"
                          style={{ marginTop: '10px' }}
                          icon={<IconSave fill={theme.colors.bluePrimary} />}
                          disabled
                        />
                      </Tooltip>
                    ))}
                  {disable.isNameDisabled && (
                    <Tooltip title={'Edit Name'}>
                      <Button
                        style={{ marginTop: '10px' }}
                        disabled={
                          !disable.isEmailDisabled ||
                          !disable.isJobTitleDisabled ||
                          !disable.isPhoneNumberDisabled
                        }
                        onClick={() => {
                          setDisable((prevState) => ({
                            ...prevState,
                            isNameDisabled: false,
                          }));
                        }}
                        icon={<IconEdit fill={theme.colors.bluePrimary} />}
                      />
                    </Tooltip>
                  )}
                  {!disable.isNameDisabled && (
                    <Tooltip title={'Discard Changes'}>
                      <Button
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                          form.setFieldsValue({
                            fullName: initialValues.nameInitialValue,
                          });
                          setDisable((prevState) => ({
                            ...prevState,
                            isNameDisabled: true,
                          }));
                          setEditted((prevState) => ({
                            ...prevState,
                            isNameEditted: false,
                          }));
                        }}
                        icon={
                          <IconClose
                            width={12}
                            height={12}
                            fill={theme.colors.bluePrimary}
                          />
                        }
                      />
                    </Tooltip>
                  )}
                </Flex>
              </Row>
            </Col>

            {/** EMAIL */}
            <Col span={8}>
              <Row align="middle">
                <Col flex={1} className="mr-8">
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Email field is required!',
                      },
                      { validator: validateEmail },
                    ]}
                  >
                    <Input
                      disabled={disable.isEmailDisabled}
                      onChange={() => {
                        setEditted((prevState) => ({
                          ...prevState,
                          isEmailEditted: true,
                        }));
                      }}
                      className="w-100"
                    />
                  </Form.Item>
                </Col>
                <Flex align="center">
                  {!disable.isEmailDisabled &&
                    editted.isEmailEditted &&
                    (!isSaveDisabled ? (
                      <Popconfirm
                        title={`Are you sure you want to change ${user?.fullName}'s email?`}
                        onConfirm={() => {
                          patchUser('email').then((success) => {
                            if (success) {
                              setTimeout(() => {
                                setDisable((prevState) => ({
                                  ...prevState,
                                  isEmailDisabled: true,
                                }));
                                setEditted((prevState) => ({
                                  ...prevState,
                                  isEmailEditted: false,
                                }));
                              }, 500);
                            }
                          });
                        }}
                        okText="Continue"
                      >
                        <Tooltip title={'Save'}>
                          <Button
                            className="mr-8"
                            style={{ marginTop: '10px' }}
                            icon={<IconSave fill={theme.colors.bluePrimary} />}
                          />
                        </Tooltip>
                      </Popconfirm>
                    ) : (
                      <Tooltip title={'You can not save without an email'}>
                        <Button
                          className="mr-8"
                          style={{ marginTop: '10px' }}
                          icon={<IconSave fill={theme.colors.bluePrimary} />}
                          disabled
                        />
                      </Tooltip>
                    ))}
                  {disable.isEmailDisabled && (
                    <Tooltip title={'Edit Email'}>
                      <Button
                        style={{ marginTop: '10px' }}
                        disabled={
                          !disable.isNameDisabled ||
                          !disable.isJobTitleDisabled ||
                          !disable.isPhoneNumberDisabled
                        }
                        onClick={() => {
                          setDisable((prevState) => ({
                            ...prevState,
                            isEmailDisabled: false,
                          }));
                        }}
                        icon={<IconEdit fill={theme.colors.bluePrimary} />}
                      />
                    </Tooltip>
                  )}
                  {!disable.isEmailDisabled && (
                    <Tooltip title={'Discard Changes'}>
                      <Button
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                          form.setFieldsValue({
                            email: initialValues.emailInitialValue,
                          });
                          setDisable((prevState) => ({
                            ...prevState,
                            isEmailDisabled: true,
                          }));
                          setEditted((prevState) => ({
                            ...prevState,
                            isEmailEditted: false,
                          }));
                        }}
                        icon={
                          <IconClose
                            width={12}
                            height={12}
                            fill={theme.colors.bluePrimary}
                          />
                        }
                      />
                    </Tooltip>
                  )}
                </Flex>
              </Row>
            </Col>
          </Row>
          <Row align="middle" gutter={20} wrap={false}>
            {/** PHONE NUMBER */}
            <Col span={8}>
              <Row align="middle">
                <Col flex={1} className="mr-8">
                  <Form.Item
                    label="Phone Number"
                    name="phoneNumber"
                    rules={[{ validator: phoneNumberValidator }]}
                  >
                    <RCountryPhoneInput
                      value={phoneNumber}
                      disabled={disable.isPhoneNumberDisabled}
                      onChange={(value: CountryPhoneInputValue) => {
                        setPhoneNumber(value);
                        setEditted((prevState) => ({
                          ...prevState,
                          isPhoneNumberEditted: true,
                        }));
                      }}
                      className="w-100"
                    />
                  </Form.Item>
                </Col>
                <Flex align="center">
                  {!disable.isPhoneNumberDisabled &&
                    editted.isPhoneNumberEditted &&
                    (!isSaveDisabled ? (
                      <Popconfirm
                        title={`Are you sure you want to change ${user?.fullName}'s phone number?`}
                        onConfirm={() => {
                          patchUser('phoneNumber').then((success) => {
                            if (success) {
                              setTimeout(() => {
                                setDisable((prevState) => ({
                                  ...prevState,
                                  isPhoneNumberDisabled: true,
                                }));
                                setEditted((prevState) => ({
                                  ...prevState,
                                  isPhoneNumberEditted: false,
                                }));
                              }, 500);
                            }
                          });
                        }}
                        okText="Continue"
                      >
                        <Tooltip title={'Save'}>
                          <Button
                            className="mr-8"
                            style={{ marginTop: '10px' }}
                            icon={<IconSave fill={theme.colors.bluePrimary} />}
                          />
                        </Tooltip>
                      </Popconfirm>
                    ) : (
                      <Tooltip
                        title={'You can not save without a phone number'}
                      >
                        <Button
                          className="mr-8"
                          style={{ marginTop: '10px' }}
                          icon={<IconSave fill={theme.colors.bluePrimary} />}
                          disabled
                        />
                      </Tooltip>
                    ))}
                  {disable.isPhoneNumberDisabled && (
                    <Tooltip title={'Edit Phone Number'}>
                      <Button
                        style={{ marginTop: '10px' }}
                        disabled={
                          !disable.isEmailDisabled ||
                          !disable.isJobTitleDisabled ||
                          !disable.isNameDisabled
                        }
                        onClick={() => {
                          setDisable((prevState) => ({
                            ...prevState,
                            isPhoneNumberDisabled: false,
                          }));
                        }}
                        icon={<IconEdit fill={theme.colors.bluePrimary} />}
                      />
                    </Tooltip>
                  )}
                  {!disable.isPhoneNumberDisabled && (
                    <Tooltip title={'Discard Changes'}>
                      <Button
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                          form.setFieldsValue({
                            phoneNumber: initialValues.phoneNumberInitialValue,
                          });
                          setDisable((prevState) => ({
                            ...prevState,
                            isPhoneNumberDisabled: true,
                          }));
                          setEditted((prevState) => ({
                            ...prevState,
                            isPhoneNumberEditted: false,
                          }));
                        }}
                        icon={
                          <IconClose
                            width={12}
                            height={12}
                            fill={theme.colors.bluePrimary}
                          />
                        }
                      />
                    </Tooltip>
                  )}
                </Flex>
              </Row>
            </Col>

            {/** JOB TITLE */}
            <Col span={8}>
              <Row align="middle">
                <Col flex={1} className="mr-8">
                  <Form.Item
                    label="Job Title"
                    name="jobTitle"
                    rules={[
                      {
                        required: true,
                        message: 'Job Title field is required!',
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z\s]+$/),
                        message: 'Invalid characters!',
                      },
                    ]}
                  >
                    <Input
                      disabled={disable.isJobTitleDisabled}
                      onChange={() => {
                        setEditted((prevState) => ({
                          ...prevState,
                          isJobTitleEditted: true,
                        }));
                      }}
                      className="w-100"
                    />
                  </Form.Item>
                </Col>
                <Flex align="center">
                  {!disable.isJobTitleDisabled &&
                    editted.isJobTitleEditted &&
                    (!isSaveDisabled ? (
                      <Popconfirm
                        title={`Are you sure you want to change ${user?.fullName}'s job title?`}
                        onConfirm={() => {
                          patchUser('jobTitle').then((success) => {
                            if (success) {
                              setTimeout(() => {
                                setDisable((prevState) => ({
                                  ...prevState,
                                  isJobTitleDisabled: true,
                                }));
                                setEditted((prevState) => ({
                                  ...prevState,
                                  isJobTitleEditted: false,
                                }));
                              }, 500);
                            }
                          });
                        }}
                        okText="Continue"
                      >
                        <Tooltip title={'Save'}>
                          <Button
                            className="mr-8"
                            style={{ marginTop: '10px' }}
                            icon={<IconSave fill={theme.colors.bluePrimary} />}
                          />
                        </Tooltip>
                      </Popconfirm>
                    ) : (
                      <Tooltip title={'You can not save without a job title'}>
                        <Button
                          className="mr-8"
                          style={{ marginTop: '10px' }}
                          icon={<IconSave fill={theme.colors.bluePrimary} />}
                          disabled
                        />
                      </Tooltip>
                    ))}
                  {disable.isJobTitleDisabled && (
                    <Tooltip title={'Edit Job Title'}>
                      <Button
                        style={{ marginTop: '10px' }}
                        disabled={
                          !disable.isEmailDisabled ||
                          !disable.isNameDisabled ||
                          !disable.isPhoneNumberDisabled
                        }
                        onClick={() => {
                          setDisable((prevState) => ({
                            ...prevState,
                            isJobTitleDisabled: false,
                          }));
                        }}
                        icon={<IconEdit fill={theme.colors.bluePrimary} />}
                      />
                    </Tooltip>
                  )}
                  {!disable.isJobTitleDisabled && (
                    <Tooltip title={'Discard Changes'}>
                      <Button
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                          form.setFieldsValue({
                            jobTitle: initialValues.jobTitleInitialValue,
                          });
                          setDisable((prevState) => ({
                            ...prevState,
                            isJobTitleDisabled: true,
                          }));
                          setEditted((prevState) => ({
                            ...prevState,
                            isJobTitleEditted: false,
                          }));
                        }}
                        icon={
                          <IconClose
                            width={12}
                            height={12}
                            fill={theme.colors.bluePrimary}
                          />
                        }
                      />
                    </Tooltip>
                  )}
                </Flex>
              </Row>
            </Col>
          </Row>
          <Row align="middle" gutter={20} wrap={false}>
            <Col span={8}>
              <Form.Item label="Role" name="role">
                <Input className="w-100" disabled />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>

      <Card $paddingBody="12px 24px" className="mt-24">
        <Flex justify="space-between" align="center">
          <Flex vertical>
            <Typography.Title level={4} className="fs-17-bold">
              Danger Zone
            </Typography.Title>
            <Typography.Text className="fs-14-regular">
              Once you delete an user, there is no going back. Please be
              certain.{' '}
            </Typography.Text>
          </Flex>
          <Button
            type="primary"
            danger
            className="btn-delete"
            onClick={() => setModalVisible(true)}
          >
            Delete
          </Button>
        </Flex>
      </Card>

      <RNotificationCard
        title="Delete User"
        message="Are you sure you want to delete the user?"
        type="warning"
        confirmText="OK"
        cancelText="Cancel"
        open={modalVisible}
        onConfirm={confirmDelete}
        onCancel={() => setModalVisible(false)}
      />
    </>
  );
};

export default UserProfile;
