import { Layout } from 'antd';
import styled from 'styled-components';

export default styled(Layout.Header)`
  display: flex;
  height: ${({ theme }) => theme.header.height}px;
  background-color: ${({ theme }) => theme.colors.blueLightest};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.spacing24}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayVeryLight};

  .sider-logo {
    display: block;
    width: 110px;
    padding: 11px 0;
  }
  .toolbar {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-col {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .toolbar-tag {
    margin: 0;
  }
  .toolbar-icon {
    padding: 0px;
    background-color: none;
    height: 18px;
    gap: 8px;
    cursor: pointer;
  }

  .border-divide-left {
    height: 18px;
    padding-left: 19px;
    border-left: 1px solid ${({ theme }) => theme.colors.grayPaleAccent};
  }

  .border-divide-right {
    border-left: 1px solid ${({ theme }) => theme.colors.grayPaleAccent};
  }

  .account-card-row {
    padding: 0px 27px;
    background-color: none;
    height: 18px;
    gap: 9px;
    border-left: 1px solid ${({ theme }) => theme.colors.grayPaleAccent};
  }
  .account-card-row-avatar {
    padding: 0px;
    background-color: none;
    height: 60px;
    gap: 9px;
    padding-left: ${({ theme }) => theme.spacing.spacing14}px;
  }

  .account-card-user-name {
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    font-weight: 400;
  }

  .anticon-caret-down {
    font-size: 20px;
  }
`;
